import HomeApi from 'api/HomeApi'
import {
  newsGetInfoStart,
  newsGetInfoFail,
  newsGetInfoDone,
  newsGetInfoClear,
  newsGetSingleStart,
  newsGetSingleDone,
  newsGetSingleFail,
  newsUpdateFilter,
} from 'redux/reducers/news'

const clearNews = () => dispatch => {
  dispatch(newsGetInfoClear())

  return Promise.resolve()
}

const getShortNews = () => dispatch => {
  dispatch(newsGetInfoStart())

  const request = {
    cmd: 'CMD_GET_ALL_NEWS',
    count: '3',
    retrieveCount: 'true',
    start: '0',
  }

  return HomeApi.filterNews(request)
    .then(data => {
      if (!data.succes) {
        throw Error(data.message || data.errMsg)
      }

      dispatch(newsGetInfoDone(data.result))

      return Promise.resolve()
    })
    .catch(error => {
      dispatch(newsGetInfoFail())

      return Promise.reject()
    })
}

const filterNews = (filter = {}) => (dispatch, getState) => {
  dispatch(newsGetInfoStart())

  const newFilter = {
    ...getState().news.filter,
    ...filter,
  }

  dispatch(newsUpdateFilter(newFilter))

  const request = {
    cmd: 'CMD_GET_ALL_NEWS',
    count: newFilter.size,
    retrieveCount: 'true',
    start: newFilter.from,
  }

  return HomeApi.filterNews(request)
    .then(data => {
      if (!data.succes) {
        throw Error(data.message || data.errMsg)
      }

      dispatch(newsGetInfoDone(data.result))

      return Promise.resolve()
    })
    .catch(error => {
      dispatch(newsGetInfoFail())

      return Promise.reject()
    })
}

const getNewsByID = id => dispatch => {
  dispatch(newsGetSingleStart())

  const request = {
    cmd: 'CMD_GET_DETAIL_NEWS',
    newsId: `${id}`,
  }

  return HomeApi.getNewsByID(request)
    .then(data => {
      if (!data.succes) {
        throw Error(data.message || data.errMsg)
      }

      dispatch(newsGetSingleDone(data.result))

      return Promise.resolve()
    })
    .catch(error => {
      dispatch(newsGetSingleFail())

      return Promise.reject()
    })
}

export {
  filterNews,
  getShortNews,
  getNewsByID,
  clearNews,
}
