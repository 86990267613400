const H1 = 'H1'
const H2 = 'H2'
const H3 = 'H3'
const H4 = 'H4'
const H5 = 'H5'
const H6 = 'H6'
const LIST_ITEM = 'LIST_ITEM'
const LIST_BULLETED = 'LIST_BULLETED'
const LIST_NUMBERED = 'LIST_NUMBERED'
const BOLD = 'BOLD'
const ITALIC = 'ITALIC'
const UNDERLINE = 'UNDERLINE'
const STRIKETHROUGH = 'STRIKETHROUGH'
const HORIZONAL = 'HORIZONAL'
const QUOTE = 'QUOTE'
const CODE = 'CODE'
const UNDO = 'UNDO'
const REDO = 'REDO'
const ALIGNT_LEFT = 'ALIGNT_LEFT'
const ALIGNT_CENTER = 'ALIGNT_CENTER'
const ALIGNT_RIGHT = 'ALIGNT_RIGHT'
const ALIGNT_JUSTIFY = 'ALIGNT_JUSTIFY'
const LINK = 'LINK'
const LINK_CREATE = 'LINK_CREATE'
const LINK_REMOVE = 'LINK_REMOVE'
const IMAGE = 'IMAGE'
const YOUTUBE = 'YOUTUBE'

const EMOJI = 'EMOJI'
const STICKER = 'STICKER'
const HTML = 'HTML'

const EMBEDED_TYPES = [YOUTUBE, IMAGE]


const TYPES = [
  LIST_ITEM,
  LIST_BULLETED,
  LIST_NUMBERED,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  BOLD,
  ITALIC,
  UNDERLINE,
  STRIKETHROUGH,
  HORIZONAL,
  LINK,
  QUOTE,
  CODE,
  UNDO,
  REDO,
  ALIGNT_LEFT,
  ALIGNT_CENTER,
  ALIGNT_RIGHT,
  ALIGNT_JUSTIFY,
  LINK_CREATE,
  LINK_REMOVE,
  IMAGE,
  YOUTUBE,
  EMOJI,
  STICKER,
  HTML,
]

export default TYPES

export {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  LIST_ITEM,
  LIST_BULLETED,
  LIST_NUMBERED,
  BOLD,
  ITALIC,
  UNDERLINE,
  STRIKETHROUGH,
  HORIZONAL,
  LINK,
  QUOTE,
  CODE,
  UNDO,
  REDO,
  ALIGNT_LEFT,
  ALIGNT_CENTER,
  ALIGNT_RIGHT,
  ALIGNT_JUSTIFY,
  LINK_CREATE,
  LINK_REMOVE,
  IMAGE,
  YOUTUBE,
  EMOJI,
  STICKER,
  HTML,


  EMBEDED_TYPES,
}
