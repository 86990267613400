import moment from 'moment'

const getOnlineStatusText = (t, lastVisit, online) => {
  if (lastVisit === undefined) {
    return t('ladies.search.result.online.unknown')
  }

  return lastVisit && online
    ? t('ladies.search.result.online.online')
    : t('ladies.search.result.online.lastVisit', { date: moment(lastVisit).format('DD.MM.yyyy') })
}

export default getOnlineStatusText
