import { Grid, MenuItem, TextField } from '@mui/material'
import Section from 'components/Section'
import React from 'react'
import { useTranslation } from 'react-i18next'
import sx from './sx'


const AccountInfoSection = ({ general, countriesOptions }) => {
  const { t } = useTranslation('text')

  const renderTextInput = (name, label, value, required = false) => (
    <Grid key={name} item xs={12} md={6}>
      <TextField
        name={name}
        label={label}
        defaultValue={value}
        required={required}
        fullWidth
      />
    </Grid>
  )

  const renderSelectInput = (name, label, value, options, required = false) => (
    <Grid key={name} item xs={12} md={6}>
      <TextField
        name={name}
        label={label}
        defaultValue={value}
        required={required}
        fullWidth
        select
      >
        {options.map(option => (
          <MenuItem
            key={option.value}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  )

  return (
    <Section title="My account information">
      <Grid container spacing={4} sx={sx.infoSection}>
        {renderTextInput('firstName', t('profile.info.firstName'), general.firstName, true)}
        {renderTextInput('lastName', t('profile.info.lastName'), general.lastName, true)}
        {renderTextInput('email', t('profile.info.email'), general.email, true)}
        {renderTextInput('phone', t('profile.info.phone'), general.phone)}
        {renderTextInput('streetAddres', t('profile.info.street'), general.streetAddres)}
        {renderSelectInput('countryId', t('profile.info.country'), general.countryId, countriesOptions)}
      </Grid>
    </Section>
  )
}

export default AccountInfoSection
