import React, { useCallback } from 'react'

import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { appURLs } from 'navigation/routes'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import HomeLayout from 'views/Home/HomeLayout'

import Address from './Address'
import Socials from './Socials'
import WriteLetterCard from './WriteLetterCard'

const ContactUsView = () => {
  const { t } = useTranslation('home')
  const navigate = useNavigate()

  const handleOnClickLogin = useCallback(() => navigate(appURLs.HOME), [navigate])

  return (
    <HomeLayout header="Contact Us" noCard>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Stack rowGap={2}>
              <Card>
                <Stack rowGap={4}>
                  <Typography variant="h5" textAlign="center">
                    {t('pages.contact.login.text')}
                  </Typography>
                  <Button onClick={handleOnClickLogin} fullWidth={false}>
                    {t('pages.contact.login.action')}
                  </Button>
                </Stack>
              </Card>
              <Card>
                <Socials />
              </Card>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <WriteLetterCard />
            </Card>
          </Grid>
          <Grid item xs={12} md={12}>
            <Card>
              <Address />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </HomeLayout>
  )
}

export default ContactUsView
