import React from 'react'
import ChangePasswordSection from 'components/Settings/ChangePasswordSection'
import ManEmailNotifications from 'components/Settings/EmailNotifications'

class ManSettings extends React.Component {
  render() {
    const { t } = this.props

    return [
      <div key="settings-title" className="settings-title">{t('settings.title')}</div>,
      <ChangePasswordSection key="settings-change-password-section" />,
      <ManEmailNotifications key="settings-change-email-notifications-section" />,
    ]
  }
}


export default ManSettings
