export default {
  header: {
    p: 4,
  },
  content: {
    px: 4,
    py: 2,
  },
  image: {
    margin: '0 -24px',
    width: '105%',
  },
}
