import React from 'react'

import MetaTags from 'react-meta-tags'

import LoginSection from 'components/LoginSection'

import FeaturedServices from './Sections/FeaturedAndServices'
import Feedback from './Sections/Feedback'
// import StoriesAndFeedback from 'components/StoriesAndFeedback'
// import CreateAccount from 'components/CreateAccount'
import FollowUs from './Sections/FollowUs'
import News from './Sections/News'
import Stories from './Sections/Stories'

const meta = {
  title: 'Dream One Love | Meet thousand of European singles online',
  keywords: 'Dating free, russian women marriage, dating ukraine, russian girl dating, ukraine dating free, russian dating, dating, single women dating, dating ukranian, online dating ukraine, dating russian, dating beautiful women, dating ukraine women, free ukraine dating, ukraine dating agency, ukraine women for dating, ukraine dating service, free ukraine dating websites, ukraine dating cupid, ukraine girl for dating, dating sites free, dating site free, free online dating, us free dating site, free dating sites for marriage, ukrainian date, best ukrainian dating site, ukrainian online dating, ukraine women online, free dating sites uk, uk dating, dating ukraine ladies, ukraine lady, ukraine single lady, usa dating sites, dating site for men, dating site australia, free dating site australia, free dating sites in europe, dating site europe, free dating site in canada, girls dating site, girls date for free, ukraine models, ukraine brides agency, free dating services, dating singles, free dating chat, free love dating site, meet women free',
  description: 'Date thousand of European singles online. Dream One Love is the best free dating service provider where you can meet European single women for dating.',
}

class Home extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className="home-position">
        <LoginSection />
        <MetaTags>
          <title>{meta.title}</title>
          <meta name="title" value={meta.title} />
          <meta name="keywords" content={meta.keywords} />
          <meta name="description" content={meta.description} />
        </MetaTags>
        <FeaturedServices />
        <Feedback />
        <News />
        <Stories />
        <FollowUs />
        {/* <CreateAccount /> */}

      </div>
    )
  }
}

export default Home
