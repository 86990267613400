import React from 'react'
import { Grid, MenuItem, TextField } from '@mui/material'
import { agesRange } from 'consts/age'
import { useTranslation } from 'react-i18next'

const LookingAges = ({ labelFrom, labelTo, ageFrom, ageTo }) => {
  const { t } = useTranslation('text')

  const getOptions = agesRange(t).map(option => (
    <MenuItem
      key={option.value}
      value={option.value}
    >
      {option.label}
    </MenuItem>
  ))

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextField
          name="lookingAgeFrom"
          label={labelFrom}
          defaultValue={ageFrom}
          fullWidth
          select
        >
          {getOptions}
        </TextField>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          name="lookingAgeTo"
          label={labelTo}
          defaultValue={ageTo}
          fullWidth
          select
        >
          {getOptions}
        </TextField>
      </Grid>
    </Grid>
  )
}

export default LookingAges
