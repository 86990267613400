const LS_TOKEN_KEY = 'token'

const hasToken = () => Boolean(window.localStorage.get(LS_TOKEN_KEY))

const storeToken = token => window.localStorage.setItem(LS_TOKEN_KEY, token)

const removeToken = token => window.localStorage.removeItem(LS_TOKEN_KEY)

const getToken = token => window.localStorage.getItem(LS_TOKEN_KEY)

export {
  hasToken,
  storeToken,
  removeToken,
  getToken,
}
