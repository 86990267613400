import React, { useCallback, useEffect, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import MenuIcon from '@mui/icons-material/Menu'
import AppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { appURLs } from 'navigation/routes'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'

import HeaderButton from 'components/HomeNavigationButton'
import logo from 'assets/logo.png'
import subHeaderImage from 'assets/subheader.jpg'
import { getAuthUserInfo } from 'service/auth/selectors'
import { getManMeInfo } from 'service/man/selectors'

import ListMenuItem from './ListMenuItem'
import SettingsButton from './SettingsButton'
import sx from './sx'

const informationItems = [
  {
    link: appURLs.HOME_INFO_TERMS,
    text: 'Terms of Use Agreement',
  },
  {
    link: appURLs.HOME_INFO_PRIVACY,
    text: 'Dreamonelove and IMBRA',
  },
  {
    link: appURLs.HOME_INFO_PARTNERS,
    text: 'For partners',
  },
  {
    link: appURLs.HOME_INFO_POLICY,
    text: 'Anti-scam policy',
  },
  {
    link: appURLs.HOME_INFO_CUSTOMER,
    text: 'Customer service',
  },
  {
    link: appURLs.HOME_INFO_FAQ,
    text: 'FAQ',
  },
]


const Header = ({ profileInfo, authInfo }) => {
  const { t } = useTranslation('headerFooter')
  const navigate = useNavigate()

  const [mobileMenuState, setMobileMenuState] = useState(false)
  const [mobileInfoState, setMobileInfoState] = useState(false)

  const upMD = useMediaQuery(theme => theme.breakpoints.up('md'))
  const downMD = useMediaQuery(theme => theme.breakpoints.down('md'))

  useEffect(() => {
    if (upMD && mobileMenuState) {
      setMobileMenuState(false)
    }
  }, [upMD, mobileMenuState])

  const toggleMobileMenu = useCallback(() => {
    setMobileMenuState(!mobileMenuState)
    if (mobileInfoState) {
      setMobileInfoState(false)
    }
  }, [mobileInfoState, mobileMenuState])

  const handleOnLogoClick = useCallback(() => navigate(authInfo.logged ? appURLs.PROFILE : ''), [authInfo.logged, navigate])

  const handleCloseMobileMenuOnAction = useCallback(() => {
    if (mobileMenuState) {
      setMobileMenuState(false)
      setMobileInfoState(false)
    }
  }, [mobileMenuState])

  const handleToggleInfoMenu = useCallback(() => setMobileInfoState(!mobileInfoState), [mobileInfoState])

  const renderMobileMenu = () => (
    <Collapse
      in={mobileMenuState && downMD}
      sx={sx.mobileMenuCollapse}
    >
      <Stack sx={sx.mobileMenu} justifyContent="space-between">
        <List>
          <Divider />
          <ListMenuItem
            label={
              <Stack direction="row" alignContent="baseline" justifyContent="center">
                {t('information.title')}
                {mobileInfoState ? <ArrowUpIcon /> : <ArrowDownIcon /> }
              </Stack>
            }
            onClick={handleToggleInfoMenu}
          />
          <Collapse in={mobileInfoState}>
            <List disablePadding>
              {informationItems.map(item => (
                <ListMenuItem
                  key={item.text}
                  label={item.text}
                  itemSx={sx.mobileInfoMenuItem}
                />
              ))}
            </List>
          </Collapse>
          <ListMenuItem
            label={t('navigation.contact')}
            url={appURLs.HOME_CONTACT_US}
            actionCallback={handleCloseMobileMenuOnAction}
          />
          <ListMenuItem
            label={t('navigation.about')}
            url={appURLs.HOME_ABOUT}
            actionCallback={handleCloseMobileMenuOnAction}
          />
          <ListMenuItem
            label={t('navigation.blog')}
            url={appURLs.HOME_BLOG}
            openNewTab
            actionCallback={handleCloseMobileMenuOnAction}
          />
          <ListMenuItem
            label={t('navigation.signUp')}
            itemSx={sx.mobileSignUp}
            url={appURLs.SIGN_UP}
            actionCallback={handleCloseMobileMenuOnAction}
          />
        </List>
        <div>
          <Stack direction="row" spacing={1} justifyContent="center" pb={2}>
            <Typography component="a" href={appURLs.HOME}>
              dreamonelove.com
            </Typography>
            <Typography>
              {` 2012-${new Date().getFullYear()}`}
            </Typography>
          </Stack>
        </div>
      </Stack>
    </Collapse>
  )

  const renderDesktopMenu = () => (
    <Grid container columnGap={2} width="max-content" height="min-content">
      <HeaderButton
        label={t('information.title')}
        options={informationItems}
      />
      <HeaderButton
        label={t('navigation.contact')}
        url={appURLs.HOME_CONTACT_US}
      />
      <HeaderButton
        label={t('navigation.about')}
        url={appURLs.HOME_ABOUT}
      />
      <HeaderButton
        label={t('navigation.blog')}
        url={appURLs.HOME_BLOG}
        openNewTab
      />
      <HeaderButton
        label={t('navigation.signUp')}
        accent
        url={appURLs.SIGN_UP}
      />
    </Grid>
  )

  const renderDefaultContent = () => (upMD ? renderDesktopMenu() : (
    <IconButton size="large" onClick={toggleMobileMenu}>
      {mobileMenuState ? <CloseIcon /> : <MenuIcon />}
    </IconButton>
  ))

  const renderLoggerUserContent = () => (
    <Stack direction="row" spacing={2} sx={sx.userInfoContainer}>
      <Stack justifyContent="center" width="max-content">
        <Typography variant="subtitle2">
          {`${profileInfo.general.firstName} ${profileInfo.general.lastName}`}
        </Typography>
        <Typography variant="body2">
          {t('information.balance', { count: 0 })}
        </Typography>
      </Stack>
      <Divider sx={sx.divider} orientation="vertical" />
      <Avatar
        alt={`${profileInfo.general.firstName} ${profileInfo.general.lastName}`}
        src={profileInfo.general.photoLnk}
        sx={sx.avatar}
      />
      <SettingsButton />
    </Stack>
  )


  return (
    <div>
      <AppBar sx={sx.appBar}>
        <Toolbar sx={sx.toolbar}>
          <Container maxWidth="lg" disableGutters={downMD}>
            <Stack direction="row" justifyContent="space-between" height="100%">
              <Grid container alignSelf="center">
                <Avatar
                  src={logo}
                  onClick={handleOnLogoClick}
                  sx={sx.logo}
                />
              </Grid>
              <Stack direction="row" alignSelf="center">
                {authInfo.logged ? renderLoggerUserContent() : renderDefaultContent()}
              </Stack>
            </Stack>
          </Container>
        </Toolbar>
      </AppBar>
      <Box sx={sx.subHeaderImgContainer}>
        {authInfo.logged &&
        <img
          src={subHeaderImage}
          alt="sub-header-img"
          style={{ width: '100%' }}
        />}
      </Box>
      {renderMobileMenu()}
    </div>
  )
}

const mapStateToProps = state => ({
  profileInfo: getManMeInfo(state),
  authInfo: getAuthUserInfo(state),
})

export default connect(mapStateToProps)(Header)
