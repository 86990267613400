import { createAction, handleActions } from 'redux-actions'

const initialState = {
  fetching: false,
  info: {},
}

// actions
const userGetIpInfoStart = createAction('USER_IP_INFO_GET_START')
const userGetIpInfoFail = createAction('USER_IP_INFO_GET_FAIL')
const userGetIpInfoDone = createAction('USER_IP_INFO_GET_DONE')

// reducer
const getIpInfoStart = state => ({ ...state, fetching: true })
const getIpInfoFail = state => ({ ...state, fetching: false })
const getIpInfoDone = (state, { payload }) => ({ ...state, fetching: false, info: payload })

const reducers = handleActions({
  USER_IP_INFO_GET_START: getIpInfoStart,
  USER_IP_INFO_GET_FAIL: getIpInfoFail,
  USER_IP_INFO_GET_DONE: getIpInfoDone,
}, initialState)

export {
  userGetIpInfoStart,
  userGetIpInfoFail,
  userGetIpInfoDone,
}

export default reducers
