export default {
  navigation: {
    contact: 'Contact Us',
    about: 'About Us',
    blog: 'Blog',
    signUp: 'Sign Up',
  },
  information: {
    title: 'Information',
    balance: 'Balance: {{count}}',
  },
  footer: {
    sub: {
      text: "Looking to date gorgeous Ukrainian women? It's easier than you think. Dream One Love is a top online dating site for men seeking Ukrainian women. Whether you're interested in Ukrainian women for relationship or casual dating, Dream One Love has you covered. Established by a Ukrainian dating agency, Dream One Love offers a quick and convenient way to find beautiful single ladies in Ukraine. The site serves as a one-stop source to search for, connect and communicate with single Ukrainian women every day. Dream One Love offers multiple services, including video chat, voice chat and gift and flower delivery services. Register today without having to pay anything. Registration is free and takes only a few seconds! Check out unlimited users profiles anywhere, anytime. You can make the first move or wait for a stunning girl to approach you - the choice is yours! Meeting single Ukrainian women has never been simpler. Find your match today.",
    },
  },
}
