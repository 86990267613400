import React from 'react'
import {
  Avatar,
  Box,
  Typography,
} from '@mui/material'
import { PHOTO } from 'consts/thubnails'
import { useTranslation } from 'react-i18next'
import sx from './sx'

const CommonLadyInfo = ({ item, showOnline, onClick }) => {
  const { t } = useTranslation('text')

  return (
    <Box sx={sx.infoContainer}>
      <Box sx={sx.avatarContainer}>
        <Avatar
          alt={item.name}
          src={`${item.avatar}/${PHOTO.SMALL}`}
          sx={sx.avatar}
          onClick={onClick}
        />
        {
          showOnline &&
          <Box sx={sx.onlineStatusContainer}>
            <Box sx={sx.onlineStatus(item.online)} />
          </Box>
        }
      </Box>
      <Box sx={sx.ladyInfoContainer}>
        <Box onClick={onClick} sx={sx.ladyInfoContainer}>
          <Typography sx={sx.ladyName}>{item.name}</Typography>
          <Typography sx={sx.ladyAge}>{item.age}</Typography>
        </Box>
        <Box onClick={onClick}>
          <Typography sx={sx.ladyID}>
            {t('ladies.search.result.id', { value: item.id })}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default CommonLadyInfo
