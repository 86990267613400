export default {
  container: {
    display: 'grid',
    gridTemplateColumns: '112px auto 112px',
  },
  photosContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  childrenContainer: {
    px: 8,
    pt: 4,
  },
  card: {
    p: 0,
    mt: 12,
  },
}
