export default theme => ({
  container: {
    padding: '16px 0',
  },
  title: props => ({
    'display': 'grid',
    'gridTemplateColumns': 'auto min-content auto',

    '& > span': {
      fontWeight: props.tight ? 300 : 700,
      whiteSpace: 'nowrap',
      textTransform: !props.caseRespect && 'uppercase',
      padding: '0 16px',
      display: 'inline-block',
    },
  }),
  content: {
    wordBreak: 'break-word',
    paddingTop: '16px',
  },
})
