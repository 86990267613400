import React from 'react'
import {
  Box,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import Section from 'components/Section'
import styles from './styles'

const RestInfoSection = ({ additional }) => {
  const { t } = useTranslation('text')
  const sx = styles()

  return (
    <Box sx={sx.infoSection}>
      <Section key="profile-section-character" title={t('profile.section.character')}>
        <Typography>{additional.character}</Typography>
      </Section>
      <Section key="profile-section-hobbies" title={t('profile.section.hobbies')}>
        <Typography>{additional.hobbies}</Typography>
      </Section>
      <Section key="profile-section-lookingType" title={t('profile.section.lookingType')}>
        <Typography>{additional.lookingFor}</Typography>
      </Section>
    </Box>
  )
}

export default RestInfoSection
