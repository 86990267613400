export default {
  container: {
    alignItems: 'center',
    display: 'flex',
    height: 400,
    margin: '0 auto',
    maxWidth: 1100,
    position: 'relative',
  },
  transition: {
    transition: 'all .3s ease-in-out',
    position: 'absolute',
  },
  items: {
    0: {
      left: '9%',
      transform: 'translateX(-50%)',
      height: 150,
      opacity: '.4',
      width: 240,
      zIndex: 0,
    },
    1: {
      left: '24%',
      transform: 'translateX(-50%)',
      height: 230,
      opacity: 1,
      width: 325,
      zIndex: 1,
    },
    2: {
      height: 350,
      opacity: 1,
      left: '50%',
      transform: 'translateX(-50%)',
      width: 550,
      zIndex: 2,
    },
    3: {
      left: '76%',
      transform: 'translateX(-50%)',
      height: 230,
      opacity: 1,
      width: 325,
      zIndex: 1,
    },
    4: {
      left: '91%',
      transform: 'translateX(-50%)',
      height: 150,
      opacity: '.4',
      width: 240,
      zIndex: 0,
    },
  },
  titleContainer: {
    display: 'grid',
    gridTemplateColumns: 'min-content auto min-content',
  },
}
