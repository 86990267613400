import { grey } from '@mui/material/colors'

export default () => ({
  name: {
    fontWeight: 700,
  },
  id: {
    ml: 2,
    fontWeight: 700,
    color: grey[400],
  },

  infoSection: {
    mt: 2,
  },

  fieldContainer: {
    borderBottom: '1px dashed #d2d2d2',
    p: '5px 0',
  },
  fieldLabel: {
    fontWeight: 500,
    alignSelf: 'center',
  },
  fieldValue: {
    fontWeight: 700,
  },

  mainPhoto: link => ({
    width: '100%',
    backgroundSize: 'cover !important',
    backgroundPositionY: '50% !important',
    height: '100%',
    minHeight: 480,
    borderRadius: 1,
    background: link,
  }),
})
