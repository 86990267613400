import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import {
  Button,
  Grid,
  Pagination,
  TextField,
  Typography,
  MenuItem,
} from '@mui/material'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import styles from './styles'

const sortTypes = [
  {
    label: 'ASC',
    value: 'ASC',
  },
  {
    label: 'DESC',
    value: 'DESC',
  },
]

class FilterSection extends React.PureComponent {
  constructor(props) {
    super()

    this.state = {
      sortField: props?.sortOptions?.length > 0 ? props.sortOptions[0].value : '',
      sortType: 'ASC',
    }
  }

  handlePageOnChanged = (event, number) => {
    if (Number.isInteger(number)) {
      this.props.onPageChanged(number)
    }
  }

  handleOnSortChanged = event => {
    const { sortField, sortType } = this.state

    const resp = {
      sortField,
      sortType,
      [event.target.name]: event.target.value,
    }

    this.props.onSort(resp)
  }

  renderNoData = (classes, noDataMessage) => (
    <div className={classes.noOneFound}>
      <Typography>{noDataMessage}</Typography>
    </div>
  )

  renderPagination = () => {
    const { classes, page, count } = this.props

    if (!page && !count) {
      return null
    }

    return (
      <Pagination
        classes={{
          root: classes.paginationRoot,
        }}
        page={page}
        count={count}
        onChange={this.handlePageOnChanged}
        hideNextButton
        hidePrevButton
      />
    )
  }

  renderSortSelect = (label, name, options) => (
    <Grid item xs={6}>
      <TextField
        label={label}
        name={name}
        defaultValue={options[0].value}
        onChange={this.handleOnSortChanged}
        fullWidth
        select
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  )

  render() {
    const { t, classes, items, sortOptions, itemsCount, noDataMessage, renderComponent } = this.props
    const { labelItemsCount, renderPrimaryFilter } = this.props

    return (
      <Grid container spacing={2}>
        {renderPrimaryFilter &&
        <Grid key="filter-section" item>
          {renderPrimaryFilter}
        </Grid>}

        <Grid
          key="data-filter-section"
          item
          container
          justifyContent="center"
        >
          <Grid item xs={4} alignSelf="center">
            <Typography>
              {`${labelItemsCount}: `}
              <span className={classes.counter}>{itemsCount}</span>
            </Typography>
          </Grid>
          <Grid item container xs={4} justifyContent="center">
            {renderPrimaryFilter &&
              <Button size="small" onClick={this.props.onSearch}>
                {t('ladies.search.actions.search')}
              </Button>}
          </Grid>
          <Grid item container xs={4} spacing={2}>
            {sortOptions && [
              this.renderSortSelect('Sort', 'sortField', sortOptions),
              this.renderSortSelect('Type', 'sortType', sortTypes),
            ]}
          </Grid>
        </Grid>

        {items.length === 0
          ? this.renderNoData(classes, noDataMessage)
          : (
            <Grid item container spacing={2} key="data-container">
              {items.map(item => (
                <Grid item lg={6} md={6} sm={6} xs={12} key={`asset-${item.id}`}>
                  {renderComponent(item)}
                </Grid>
              ))}
            </Grid>
          )}

        <Grid key="pagination-section" item container justifyContent="center">
          {this.renderPagination()}
        </Grid>
      </Grid>
    )
  }
}

FilterSection.propTypes = {
  /** Array of data for view. */
  items: PropTypes.array,
  /** All items total count. */
  itemsCount: PropTypes.number,
  /** Page count, if page & count are '==' false, then no pagination would be shown. */
  count: PropTypes.number,
  /** Sort options, if not set then it will be hidden. */
  sortOptions: PropTypes.array,
  /** Callback with new page number. */
  onPageChanged: PropTypes.func,
  /** Callback for sort change action. */
  onSort: PropTypes.func,
  /** Message to show if items are empty. */
  noDataMessage: PropTypes.string,
  /** Function to render component of child. */
  renderComponent: PropTypes.func,
  /** Function to render primary filter. */
  renderPrimaryFilter: PropTypes.func,
  /** Label of items count. */
  labelItemsCount: PropTypes.string,
}

FilterSection.defaulProps = {
  items: [],
  sortOptions: [],
  itemProps: {},
  renderComponent: () => {},
  labelItemsCount: 'Items found',
}


export default compose(
  withStyles(styles),
  withTranslation('text'),
)(FilterSection)
