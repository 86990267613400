import React from 'react'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

const sx = {
  send: {
    mx: 12,
  },
  header: {
    my: 2,
    textAlign: 'center',
  },
  message: {

  },
}

const WriteLetterCard = () => {
  const { t } = useTranslation('home')

  return (
    <Stack rowGap={4}>
      <Typography
        variant="h5"
        sx={sx.header}
      >
        {t('pages.contact.write.title')}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <TextField
            label={t('pages.contact.write.field.name')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <TextField
            label={t('pages.contact.write.field.email')}
            fullWidth
          />
        </Grid>
      </Grid>
      <TextField
        label={t('pages.contact.write.field.message')}
        rows={6}
        multiline
        fullWidth
      />
      <Button sx={sx.send}>
        {t('pages.contact.write.action.send')}
      </Button>
    </Stack>
  )
}

export default WriteLetterCard
