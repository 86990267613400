export default theme => ({
  filterContainer: {
    padding: '24px 0 0 0',
  },
  accordionRoot: {
    'boxShadow': 'unset',
    '&:before': {
      backgroundColor: 'unset',
    },
  },
  accordionDetailsRoot: {
    padding: 0,
    marginBottom: 16,
  },
  expandText: {
    color: theme.palette.link.main,
  },
  counter: {
    fontWeight: 700,
  },
})
