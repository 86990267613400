import React, { useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import headerImage from 'assets/home/gifts_header.png'
import { useTranslation } from 'react-i18next'
import HomeLayout from 'views/Home/HomeLayout'
import { getStoryByID } from 'service/home/actions/stories'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getStoriesSingle } from 'service/home/selectors/stories'
import Image from 'components/Image'
import sx from './sx'

const DetailsStoryView = ({ item, getStoryByID }) => {
  const { id } = useParams()

  const { t } = useTranslation('home')

  useEffect(() => {
    getStoryByID(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <HomeLayout
      image={headerImage}
      header={t('news.title')}
    >
      <Grid container rowGap={4}>
        <Box sx={sx.header}>
          <Grid item container rowGap={4}>
            <Grid item xs={12}>
              <Typography variant="h4">
                {item.author}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={sx.image}>
          <Image src={item.headerPhoto} fullWidth />
        </Box>
        <Box sx={sx.content}>
          <Grid sx={sx.content} item xs={12}>
            <Typography variant="h6">
              {`${item.header} | Dream One Love`}
            </Typography>
            <Typography>
              <div dangerouslySetInnerHTML={{ __html: item.details }} />
            </Typography>
          </Grid>
        </Box>
      </Grid>
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  item: getStoriesSingle(state),
})

const mapDispatchToProps = {
  getStoryByID,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(DetailsStoryView)
