import {
  MoreHoriz,
  Person,
  Settings,
} from '@mui/icons-material'
import Menu from '@mui/material/Menu'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import { appURLs } from 'navigation/routes'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import updateHistory from 'utils/history'
import sx from './sx'

const Item = ({ onClick, label, Icon }) => (
  <MenuItem onClick={onClick}>
    <Icon sx={sx.menuActionsIcon} fontSize="small" />
    {label}
  </MenuItem>
)

const SettingsButton = () => {
  const { t } = useTranslation('text')

  const [menuState, setMenuState] = useState(null)

  function onClick(event) {
    setMenuState(event.target)
  }

  function onClose(event) {
    setMenuState(null)
  }

  function handleOnProfileClick() {
    updateHistory('push', appURLs.PROFILE)
  }

  function handleOnSettingsClick() {
    updateHistory('push', appURLs.SETTINGS)
  }


  return (
    <>
      <Button
        sx={sx.settingsButton}
        variant="outlined"
        onClick={onClick}
      >
        <MoreHoriz />
      </Button>
      <Menu
        disableAutoFocusItem
        anchorEl={menuState}
        open={Boolean(menuState)}
        onClose={onClose}
      >
        <Item
          key="header-profile"
          label={t('header.actions.profile')}
          Icon={Person}
          onClick={handleOnProfileClick}
        />
        <Item
          key="header-settings"
          label={t('header.actions.settings')}
          Icon={Settings}
          onClick={handleOnSettingsClick}
        />
        {/* <Item
          key="header-logout"
          label={t('header.actions.logout')}
          Icon={LogoutIcon}
        /> */}
      </Menu>
    </>
  )
}

export default SettingsButton
