import React from 'react'
import { connect } from 'react-redux'
import { getMe } from 'service/man/actions/me'
import { getManMeInfo } from 'service/man/selectors'
import ProfileView from 'views/Profile/ProfileView'
import { manPhotoURL, manVideoURL } from 'navigation/routes'
import ManProfileEdit from './ManProfileEdit'


class ManProfile extends React.Component {
  constructor() {
    super()

    this.state = {
      edit: false,
    }
  }

  componentDidMount() {
    this.props.getMe()
  }

  handleOnEditClick = () => this.setState({ edit: true })

  handleOnEditCancel = () => this.setState({ edit: false })

  render() {
    const { edit } = this.state
    const { t, profileInfo } = this.props

    return edit ? (
      <ManProfileEdit
        t={t}
        profileInfo={profileInfo}
        onCancel={this.handleOnEditCancel}
      />
    ) : (
      <ProfileView
        profileInfo={profileInfo}
        photoURL={manPhotoURL}
        videoURL={manVideoURL}
        onEdit={this.handleOnEditClick}
      />
    )
  }
}

const mapStateToProps = state => ({
  profileInfo: getManMeInfo(state),
})

const mapDispatchToProps = {
  getMe,
}

export default connect(mapStateToProps, mapDispatchToProps)(ManProfile)
