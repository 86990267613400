import React, { useMemo } from 'react'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import headerImage from 'assets/home/services_header.png'
import { useTranslation } from 'react-i18next'
import HomeLayout from 'views/Home/HomeLayout'
import Image from 'components/Image'
import chatImg from 'assets/home/date/chat.png'
import voteImg from 'assets/home/date/vote.png'
import commentImg from 'assets/home/date/comment.png'
import giftImg from 'assets/home/date/gift.png'
import photoImg from 'assets/home/date/photo.png'
import topImg from 'assets/home/date/top.png'
import onlineImg from 'assets/home/date/online.png'

const GiftsView = () => {
  const { t } = useTranslation('home')

  const pointsImgs = useMemo(() => [
    chatImg,
    voteImg,
    commentImg,
    onlineImg,
    topImg,
    photoImg,
    giftImg,
  ], [])

  return (
    <HomeLayout
      image={headerImage}
      header={t('sections.services.item.additional')}
      title={t('features.services.title')}
      margin
      useTitle
    >
      <Grid container rowGap={2}>
        {t('features.services.content', { returnObjects: true }).map((item, index) => (
          <>
            <Grid item container>
              <Grid item xs={4}>
                <Image src={pointsImgs[index]} />
              </Grid>
              <Grid xs={8} item>
                <Stack rowGap={2}>
                  <Typography variant="h6">
                    {item.title}
                  </Typography>
                  {item.points.map(point => (
                    <>
                      <Typography>
                        {point}
                      </Typography>
                    </>
                  ))}
                </Stack>
              </Grid>
            </Grid>
          </>
        ))}
      </Grid>
    </HomeLayout>
  )
}

export default GiftsView
