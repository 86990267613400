export default theme => ({
  MuiPagination: {
    styleOverrides: {
      ul: {
        justifyContent: 'center',
      },
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: {
        'fontSize': 16,
        'color': theme.palette.link.main,
        'textDecoration': 'underline',
        'margin': '0 16px',
        'padding': '12px',
        '&.Mui-selected': {
          color: theme.palette.font.main,
          textDecoration: 'unset',
          background: 'unset',
          fontWeight: 700,
        },
      },
      ellipsis: {
        textDecoration: 'unset',
      },
    },
  },
})
