export default theme => ({
  rounded: {
    'minHeight': 80,
    '&:last-of-type': {
      borderRadius: 24,
      boxShadow: '0 0 55px 0 #00217726',
      backgroundColor: '#fff',
    },
  },
  clickable: {
    height: 80,
  },
  cardRoot: {
    border: 'none',
    color: '#0081f1',
    padding: '20px 30px',
    fontWeight: 700,

    width: '100%',
    minHeight: 100,
  },
  header: {
    'display': 'grid',
    'gridTemplateColumns': '30px auto',
    'gap': 16,
    'paddingLeft': 12,
    'alignItems': 'center',
    '& > p': {
      fontWeight: 700,
      fontSize: '1rem',
    },
  },
  content: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  item: {
    paddingLeft: 24,
  },
  icon: {
    minWidth: 48,
  },

  selected: {
    backgroundColor: theme.palette.grey[200],
  },
})
