import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import Layout from './Layout'

const AntiScamView = () => {
  const { t } = useTranslation('home')

  const content = useMemo(() => t('pages.scam.content', { returnObjects: true }).map(item => (
    <>
      <Typography>
        {item}
      </Typography>
      <br />
    </>
  )), [t])

  return (
    <Layout header={t('pages.scam.header')}>
      {content}
    </Layout>
  )
}

export default AntiScamView
