export default theme => ({
  paginationRoot: {
    padding: '12px 0 0 0',
  },
  noOneFound: {
    textAlign: 'center',
    margin: '64px 0',
  },

  counter: {
    fontWeight: 700,
  },
})
