import { link } from 'theme'

export default {
  photo: {
    'height': 200,
    'overflow': 'hidden',
    'borderRadius': 1,
    'cursor': 'pointer',
    ':hover': {
      opacity: 0.85,
    },
  },
  title: {
    height: 48,
    cursor: 'pointer',
    color: link.main,
  },
  itemContainer: {
    width: '100%',
  },
}
