import React from 'react'
import PropTypes from 'prop-types'
import { VIDEO, PHOTO } from 'consts/thubnails'
import { TYPE } from 'components/ViewMedia/ViewMedia'
import {
  Box,
  Grid,
  ButtonBase,
} from '@mui/material'
import { Clear, FiberManualRecord, PlayCircle } from '@mui/icons-material'
import MediaInfo from 'components/MediaInfo'
import sx from './sx'

const ProfileMediaCard = ({ classes, item, visitor, type, index, onClick, onMain, onDelete, onFavorite }) => {
  const favoriteAvailable = type === TYPE.PHOTO && !onDelete && !onMain

  const preview = type === TYPE.VIDEO
    ? `url(${item.videoUrl}${VIDEO.NORMAL})`
    : `url(${item.photoUrl}${PHOTO.MEDIUM})`

  function handleOnMain() {
    onMain(index, item)
  }

  function handleOnDelete() {
    onDelete(index, item)
  }

  function handleOnClick() {
    onClick(index, item)
  }

  function handleOnFavorite(item) {
    onFavorite(item)
  }

  const renderActions = () => {
    if (type === TYPE.VIDEO && onDelete) {
      return (
        <>
          <ButtonBase
            sx={sx.actionButton}
            type="button"
            onClick={handleOnDelete}
          >
            <Clear sx={sx.actionButtonIcon} />
          </ButtonBase>
        </>
      )
    }

    if (type === TYPE.PHOTO && onDelete && onMain) {
      return (
        <Grid
          container
          justifyContent="center"
          sx={{
            columnGap: '4px',
            padding: '4px',
          }}
        >
          <ButtonBase
            sx={sx.actionButton}
            type="button"
            onClick={handleOnDelete}
          >
            <Clear sx={sx.actionButtonIcon} />
          </ButtonBase>
          <ButtonBase
            sx={sx.actionButton}
            type="button"
            onClick={handleOnMain}
          >
            {item.main && <FiberManualRecord sx={sx.actionButtonIcon} />}
          </ButtonBase>
        </Grid>
      )
    }

    return null
  }

  return (
    <>
      <ButtonBase onClick={handleOnClick}>
        <Box sx={sx.preview(preview, type === TYPE.VIDEO)}>
          {type === TYPE.VIDEO && <PlayCircle sx={sx.videoIcon} />}
          {favoriteAvailable && <MediaInfo
            item={item}
            visitor={visitor}
            onFavorite={handleOnFavorite}
          />}
        </Box>
      </ButtonBase>
      {renderActions()}
    </>
  )
}

ProfileMediaCard.propTypes = {
  /** Callback for card action. If not set no action will appear. */
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
}

ProfileMediaCard.defaultProps = {

}

export default ProfileMediaCard
