import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import Section from 'components/Section'
import { Box, Card, Grid, Pagination, Typography } from '@mui/material'
import Container from 'components/Container'
import Image from 'components/Image'
import sx from './sx'


const HomeLayout = ({
  image, header, title, noCard, margin, useTitle, pagination, pageCounts, currentPage, children,
  onPageChanged,
}) => {
  const handlePageOnChanged = useCallback((event, number) => {
    if (Number.isInteger(number)) {
      onPageChanged(number)
    }
  }, [onPageChanged])

  const content = useMemo(() => (
    <>
      {useTitle &&
      <Box sx={sx.header}>
        <Image src={image} fullWidth />
        {title &&
        <Grid container sx={sx.headerText} alignItems="center" justifyContent="center">
          <Typography variant="h4">
            {title}
          </Typography>
        </Grid>}
      </Box>}
      <Box sx={{ padding: margin && 8 }}>
        {children}
      </Box>
    </>
  ), [])

  return (
    <Section
      TitleProps={{
        sx: { my: 8 },
      }}
      title={header}
      variant="h3"
      tight
      caseRespect
    >
      <Container>
        {noCard ? content : <Card>{content}</Card>}
        {pagination &&
        <Card sx={sx.paginationCard}>
          <Pagination
            page={currentPage}
            count={pageCounts}
            onChange={handlePageOnChanged}
            hideNextButton
            hidePrevButton
          />
        </Card>}
      </Container>
    </Section>
  )
}

HomeLayout.propTypes = {
  /** URL for title image. */
  image: PropTypes.string,
  /** Header text. Used in top of view. */
  header: PropTypes.string,
  /** Title text. Used over title image. */
  title: PropTypes.string,
  /** Define use a card as the container for a content. */
  noCard: PropTypes.bool,
  /** Flag to define to use or not default margin for child component. */
  margin: PropTypes.bool,
  /** Flag to define to use or not title component. */
  useTitle: PropTypes.bool,
  /** Flag to define to use or not pagination component. Required pageCounts & currentPage & onPageChanged for proper work. */
  pagination: PropTypes.bool,
  /** Define pages count for pagination. */
  pageCounts: PropTypes.number,
  /** Define current page number for pagination. */
  currentPage: PropTypes.number,
  /** Callback for page change. */
  onPageChanged: PropTypes.func,
}

HomeLayout.defaulProps = {

}

export default HomeLayout
