import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import Paragraph from 'components/Paragraph'
import Layout from './Layout'


const PointSubtextLink = ({ items }) => {
  if (!items || items.length === 0) {
    return null
  }

  return (
    <>
      {items.map(item => (
        <>
          <Paragraph component="a" href={item.link}>
            {item.text}
          </Paragraph>
          <br />
        </>
      ))}
      <br />
    </>
  )
}

const PointSubtext = ({ items }) => {
  if (!items || items.length === 0) {
    return null
  }

  return (
    <>
      {items.map(item => (
        <>
          <Paragraph>
            {item}
          </Paragraph>
        </>
      ))}
      <br />
    </>
  )
}

const PointText = ({ items }) => {
  if (!items || items.length === 0) {
    return null
  }

  return items.map(item => (
    <>
      <Typography variant={item.subtitle ? 'h6' : undefined}>
        {item.point}
      </Typography>
      <br />
      <PointSubtext items={item?.subpoints} />
      <PointSubtextLink items={item?.linkpoints} />
    </>
  ))
}

const Point = ({ content, useBottomBR = true }) => (
  <>
    <Typography variant="h6">
      {content.header}
    </Typography>
    <br />
    <PointText items={content?.text} />
    {useBottomBR ? <br /> : null}
  </>
)

const PrivacyPolicyView = () => {
  const { t } = useTranslation('home')

  const content = useMemo(() => t('pages.privacy.points', { returnObjects: true }).map(item => (
    <>
      <Point content={item} />
    </>
  )), [t])

  return (
    <Layout header={t('pages.privacy.header')}>
      <Typography>
        {t('pages.privacy.start')}
      </Typography>
      <br />
      <Typography variant="h6">
        {t('pages.privacy.content')}
      </Typography>
      <br />
      {content}
    </Layout>
  )
}

export default PrivacyPolicyView
