import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Fab,
  Typography,
} from '@mui/material'
import NotFavoriteIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import sx from './sx'

const MediaInfo = ({ item, visitor, onFavorite }) => {
  function handleOnFavoriteClick(event) {
    event.stopPropagation()
    onFavorite(item)
  }

  return (
    <Box sx={sx.container}>
      <Typography sx={sx.totalVotes}>
        {item.totalVotes}
      </Typography>
      {visitor &&
      <Fab
        sx={sx.like}
        disabled={!item.possibleToLike}
        onClick={handleOnFavoriteClick}
      >
        {item.favorite ? <FavoriteIcon /> : <NotFavoriteIcon />}
      </Fab>}
    </Box>
  )
}

MediaInfo.propTypes = {
  /** Votes counter. */
  totalVotes: PropTypes.number,
  /** Determine whether a user is able to like a media. */
  visitor: PropTypes.bool,
  /** Callback for favorite button click. */
  onFavorite: PropTypes.func,
}

MediaInfo.defaultProps = {
  visitor: true,
  onFavorite: () => {},
}

export default MediaInfo
