import React from 'react'
import { Button, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

import sx from './sx'

const ToolbarButton = ({ type, active, icon, onClick }) => {
  const { t } = useTranslation('mailEditor')

  function handleOnClick() {
    onClick(type)
  }

  return (
    <Tooltip title={t(`toolbar.${type}`)}>
      <Button variant="toolbar" sx={sx.toolbarState(active)} onClick={handleOnClick}>
        {icon}
      </Button>
    </Tooltip>
  )
}


export default ToolbarButton
