// all errors are aligned with translations by path errors.profile.media.<error>

const VALIDATION_SIZE_ERR = 'size'
const VALIDATION_EXT_WRONG_ERR = 'ext.wrong'
const VALIDATION_EXT_UNKNOWN_ERR = 'ext.unknown'

export {
  VALIDATION_SIZE_ERR,
  VALIDATION_EXT_WRONG_ERR,
  VALIDATION_EXT_UNKNOWN_ERR,
}
