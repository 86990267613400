import React, { useState, useCallback, useMemo } from 'react'
import { jsx } from 'slate-hyperscript'
import { Editor, Transforms } from 'slate'
import { HistoryEditor } from 'slate-history'
import { Box, Button, Divider, Grid } from '@mui/material'
import {
  HorizontalRule,
  LooksOne,
  LooksTwo,
  Looks3,
  Looks4,
  Looks5,
  Looks6,
  Redo,
  Undo,
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  FormatStrikethrough,
  FormatAlignLeft,
  FormatAlignCenter,
  FormatAlignRight,
  FormatAlignJustify,
  InsertLink,
  LinkOff,
  Image,
  YouTube,
  FormatListBulleted,
  FormatListNumbered,
  EmojiEmotions,
  FaceRetouchingNatural,
  Html,
} from '@mui/icons-material'
import ToolbarButton from './ToolbarButton'
import {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  LIST_ITEM,
  LIST_BULLETED,
  LIST_NUMBERED,
  BOLD,
  ITALIC,
  UNDERLINE,
  STRIKETHROUGH,
  HORIZONAL,
  LINK,
  QUOTE,
  UNDO,
  REDO,
  ALIGNT_LEFT,
  ALIGNT_CENTER,
  ALIGNT_RIGHT,
  ALIGNT_JUSTIFY,
  LINK_CREATE,
  LINK_REMOVE,
  IMAGE,
  YOUTUBE,
  EMOJI,
  STICKER,
  HTML,
} from './types'
import YoutubeDialog from './Dialogs/YoutubeDialog'

import sx from './sx'
import ImageDialog from './Dialogs/ImageDialog'


const Toolbar = ({ editor }) => {
  const [youtubeDialog, setYoutubeDialog] = useState(false)
  const [imageDialog, setImageDialog] = useState(false)

  const isBlockActive = type => {
    const [match] = Editor.nodes(editor, {
      match: n => n.type === type,
    })

    return !!match
  }

  const isActive = type => {
    const marks = Editor.marks(editor)

    return marks ? marks[type] === true : false
  }

  function handleOnInsertTag(type) {
    editor.insertNode({
      type, children: [{ text: '' }],
    })
  }

  function handleOnUndo() {
    HistoryEditor.undo(editor)
  }

  function handleOnRedo() {
    HistoryEditor.redo(editor)
  }

  function handleOnFormatStyle(type) {
    if (isActive(type)) {
      Editor.removeMark(editor, type)
    } else {
      Editor.addMark(editor, type, true)
    }
  }

  function handleOnYoutubeDialogOpen() {
    setYoutubeDialog(true)
  }

  function handleOnYoutubeDialogClose() {
    setYoutubeDialog(false)
  }

  function handleOnYoutubeDialogAccept(videoID) {
    editor.insertNode({ type: YOUTUBE, videoID, children: [{ text: '' }] })

    handleOnYoutubeDialogClose()
  }

  function handleOnImageDialogOpen() {
    setImageDialog(true)
  }

  function handleOnImageDialogClose() {
    setImageDialog(false)
  }

  function handleOnImageDialogAccept(url) {
    editor.insertNode({ type: IMAGE, url, children: [{ text: '' }] })

    handleOnImageDialogClose()
  }

  return (
    <>
      <Box sx={sx.toolbarContainer}>
        <ToolbarButton type={H1} active={isActive(H1)} onClick={handleOnFormatStyle} icon={<LooksOne />} />
        <ToolbarButton type={H2} active={isActive(H2)} onClick={handleOnFormatStyle} icon={<LooksTwo />} />
        <ToolbarButton type={H3} active={isActive(H3)} onClick={handleOnFormatStyle} icon={<Looks3 />} />
        <ToolbarButton type={H4} active={isActive(H4)} onClick={handleOnFormatStyle} icon={<Looks4 />} />
        <ToolbarButton type={H5} active={isActive(H5)} onClick={handleOnFormatStyle} icon={<Looks5 />} />
        <ToolbarButton type={H6} active={isActive(H6)} onClick={handleOnFormatStyle} icon={<Looks6 />} />
        <ToolbarButton type={BOLD} active={isActive(BOLD)} onClick={handleOnFormatStyle} icon={<FormatBold />} />
        <ToolbarButton type={ITALIC} active={isActive(ITALIC)} onClick={handleOnFormatStyle} icon={<FormatItalic />} />
        <ToolbarButton type={UNDERLINE} active={isActive(UNDERLINE)} onClick={handleOnFormatStyle} icon={<FormatUnderlined />} />
        <ToolbarButton type={STRIKETHROUGH} active={isActive(STRIKETHROUGH)} onClick={handleOnFormatStyle} icon={<FormatStrikethrough />} />
        <ToolbarButton type={HORIZONAL} active={isActive(HORIZONAL)} onClick={handleOnInsertTag} icon={<HorizontalRule />} />
        <ToolbarButton type={UNDO} active={isActive(UNDO)} onClick={handleOnUndo} icon={<Undo />} />
        <ToolbarButton type={REDO} active={isActive(REDO)} onClick={handleOnRedo} icon={<Redo />} />
        <ToolbarButton type={ALIGNT_LEFT} active={isActive(ALIGNT_LEFT)} onClick={handleOnFormatStyle} icon={<FormatAlignLeft />} />
        <ToolbarButton type={ALIGNT_CENTER} active={isActive(ALIGNT_CENTER)} onClick={handleOnFormatStyle} icon={<FormatAlignCenter />} />
        <ToolbarButton type={ALIGNT_RIGHT} active={isActive(ALIGNT_RIGHT)} onClick={handleOnFormatStyle} icon={<FormatAlignRight />} />
        <ToolbarButton type={ALIGNT_JUSTIFY} active={isActive(ALIGNT_JUSTIFY)} onClick={handleOnFormatStyle} icon={<FormatAlignJustify />} />
        <ToolbarButton type={LIST_NUMBERED} active={isActive(LIST_NUMBERED)} onClick={handleOnFormatStyle} icon={<FormatListBulleted />} />
        <ToolbarButton type={LIST_BULLETED} active={isActive(LIST_BULLETED)} onClick={handleOnFormatStyle} icon={<FormatListNumbered />} />
        <ToolbarButton type={LINK_CREATE} active={isActive(LINK_CREATE)} onClick={handleOnFormatStyle} icon={<InsertLink />} />
        <ToolbarButton type={LINK_REMOVE} active={isActive(LINK_REMOVE)} onClick={handleOnFormatStyle} icon={<LinkOff />} />
        <ToolbarButton type={IMAGE} active={isActive(IMAGE)} onClick={handleOnImageDialogOpen} icon={<Image />} />
        <ToolbarButton type={YOUTUBE} active={isActive(YOUTUBE)} onClick={handleOnYoutubeDialogOpen} icon={<YouTube />} />

        <ToolbarButton type={EMOJI} active={isActive(EMOJI)} onClick={handleOnFormatStyle} icon={<EmojiEmotions />} />
        <ToolbarButton type={STICKER} active={isActive(STICKER)} onClick={handleOnFormatStyle} icon={<FaceRetouchingNatural />} />
        <ToolbarButton type={HTML} active={isActive(HTML)} onClick={handleOnFormatStyle} icon={<Html />} />
      </Box>
      <YoutubeDialog open={youtubeDialog} onClose={handleOnYoutubeDialogClose} onAccept={handleOnYoutubeDialogAccept} />
      <ImageDialog open={imageDialog} onClose={handleOnImageDialogClose} onAccept={handleOnImageDialogAccept} />
    </>
  )
}

export default Toolbar
