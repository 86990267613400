export default {
  error: {
    password: {
      update: {
        general: 'Error while updating password.',
        match: 'Passwords are not matching.',
        oldMatch: 'New password is matching old.',
      },
    },
    profile: {
      date: {
        invalide: 'Invalide date.',
        young: 'You have to be older than 18 years old.',
        old: 'Are you really older than 100 years? Check the date you\'ve entered.',
        height: 'Height format must be 0\'00\'\'.',
        lookingAge: 'Looking age range is invalide. From cannot be greater than to.',
      },
      email: 'Wrong email format.',
    },
  },
  success: {
    profile: {
      password: 'Password updated.',
      notification: 'Notifications updated.',
    },
    favorite: {
      photo: {
        add: 'Photo was added to favorite',
        delete: 'Photo was deleted from favorite',
      },
    },
  },
  header: {
    actions: {
      profile: 'Profile',
      settings: 'Settings',
      logout: 'Log out',
    },
  },
  general: {
    na: 'n/a',
    singOut: 'Sing out',
    confirm: 'Confirm',
    save: 'Save',
    cancel: 'Cancel',
    update: 'Update',
    loading: 'Loading...',
    video: 'Video(s)',
    photo: 'Photo(s)',
    search: 'Search',
    noPeople: 'No one found',
    noItems: 'Nothing found',
    any: 'Any',
    more: 'More',
    balance: 'Balance: {{count}}',
  },
  component: {
    fileUpload: {
      fileLabel: 'No file selected',
      browseLabel: 'Select file',
    },
  },
  errors: {
    profile: {
      media: {
        size: 'File is too big. 5MB is allowed.',
        ext: {
          wrong: 'Unsupported file extension.',
          unknown: 'Unknown file extension.',
        },
      },
    },
  },
  zodiac: {
    capricorn: 'Capricorn',
    aquarius: 'Aquarius',
    pisces: 'Pisces',
    aries: 'Aries',
    taurus: 'Taurus',
    gemini: 'Gemini',
    cancer: 'Cancer',
    leo: 'Leo',
    virgo: 'Virgo',
    libra: 'Libra',
    scorpio: 'Scorpio',
    sagittarius: 'Sagittarius',
    unknown: 'Unknown',
  },
  person: {
    field: {
      firstName: 'First name',
      lastName: 'Last name',
      country: 'Country',
      eye: 'Eye color',
      hair: 'Hair color',
      zodiac: 'Zodiac sign',
      smoking: 'Smoking',
      alchohol: 'Alchohol',
      religion: 'Religion',
      children: 'Children',
      maritalStatus: 'Marital Status',
      englishSkill: 'English level',
      age: {
        from: 'Age from',
        to: 'Age to',
      },
      height: {
        from: 'Height from',
        to: 'Height to',
      },
      weight: {
        from: 'Weight from',
        to: 'Weight to',
      },
    },
  },
  favorite: {
    photo: {
      title: 'Favorite photos',
      delete: 'Delete photo',
    },
    video: {
      title: 'Favorite videos',
      delete: 'Delete video',
    },
    result: {
      foundLabel: 'Items found',
    },
  },
  empty: {
    photo: '',
  },
  login: {
    header: 'Meet Thousand\nof European Singles',
    input: {
      email: 'EMAIL / ID',
      pswd: 'PASSWORD',
      rmbrMe: 'Remember me',
    },
    action: {
      login: 'LOGIN',
      forgotPswd: ' Forgot your password?',
    },
  },
  layout: {
    express: {
      onlineLadies: 'Online ladies',
      newLadies: 'Newest ladies',
      top100: 'Top 100 Ladies',
      liveChat: 'Live Chat',
    },
    sidebar: {
      search: 'Search ladies',
      mail: {
        title: 'Mail',
        inbox: 'Inbox',
        outbox: 'Outbox',
        admin: 'Service support',
        spam: 'Spam',
        trash: 'Trash',
        writeNew: 'Write new letter',
        createNewFolder: 'Create new folder',
      },
      ladies: {
        title: 'Ladies',
        visitors: 'Visitors',
        favorite: {
          ladies: 'Favorite',
          photos: 'Favorite photos',
          videos: 'Favorite videos',
        },
        contact: 'Contacts',
      },
      features: {
        title: 'Featured services',
      },
      gifts: {
        title: 'Virtual gifts',
      },
      finances: {
        title: 'Finance',
        purchase: 'Purchase coins',
        history: {
          expenses: 'Expenses history',
          purchase: 'Purchases history',
        },
      },
    },
  },
  registration: {
    title: 'Sign up',
    info: {
      restricted: 'Sorry, but you are not allowed to registrate',
    },
    field: {
      firstName: 'First name',
      lastName: 'Last name',
      email: 'E-mail',
      birthday: 'Birthday',
      password: 'Password',
      repeatPassword: 'Repeat',
      country: 'Country',
      emailNotifications: 'Email notifications',
    },
    action: {
      signUp: 'Sign up',
    },
  },
  ladies: {
    title: 'Ladies',
    search: {
      title: {
        general: 'Search',
        online: 'Online',
        newest: 'Newest',
        favorite: 'Favorite',
        top: 'Top beautiful',
        visitors: 'Visitors',
      },
      field: {
        online: 'Online status',
        birthdaySoon: 'Birthday soon',
      },
      actions: {
        search: 'Search',
      },
      sort: {
        label: 'Sort by',
        options: {
          id: 'ID',
          name: 'Name',
          from: 'From',
        },
      },
      filter: {
        expand: 'Expand options',
        hide: 'Hide options',
      },
      result: {
        id: 'ID: {{value}}',
        foundLabel: 'Ladies found',
        online: {
          lastVisit: 'Last visit {{date}}',
          online: 'Online now',
          unknown: 'Last visit recenlty',
        },
      },
    },
  },
  settings: {
    title: 'Settings',
    password: {
      title: 'Update password',
      old: 'Old password',
      new: 'New password',
      repeat: 'Repeat new password',
      action: {
        update: 'Update password',
      },
    },
    emailNotifications: {
      title: 'Email notifications',
      description: 'Select events you wish to be notified about.',
      manChecks: {
        newLadies: 'New ladies at the site.',
        visitor: 'Lady watched my profile.',
        vote: 'Lady voted for my picture.',
        letter: 'Lady sent me a letter.',
        gift: 'Lady sent me a virtual gift.',
        birthday: 'Lady has a birthday today.',
        profileUpdate: 'Lady updated her profile.',
        profileUpdateFavorite: 'Favorite lady updated her profile.',
        videoUpload: 'Lady uploaded new video.',
        videoUploadFavorite: 'Favorite lady uploaded new video.',
      },
      action: {
        update: 'Update notifications',
      },
    },
  },
  profile: {
    title: 'My profile',
    actions: {
      edit: 'Edit profile',
      settings: 'Settings',
      upload: {
        photo: 'Upload photo',
        video: 'Upload video',
      },
    },
    general: {
      offline: 'Last visit on the {{date}} at {{time}}',
      id: 'ID: {{value}}',
      age: '{{value}} AGE',
    },
    fields: {
      id: 'ID',
      firstName: 'First name',
      from: 'From',
      mail: 'View mail',
      letter: 'Write a letter',
    },
    info: {
      id: 'ID',
      email: 'E-mail',
      street: 'Street address',
      country: 'Country',
      firstName: 'First name',
      lastName: 'Last name',
      phone: 'Phone',
      nativeLang: 'Native Lang',
      secondLang: 'Second Lang',
      job: 'My work',
      religion: 'Religion',
      maritalStatus: 'Marital Status',
      children: 'Childrens',
      childrenSex: 'Children Gender',
      childrenAge: 'Children Age',
      birthday: 'Date of birth',
      height: 'Height',
      heightView: 'Height cm/ft',
      heightType: 'Height type',
      weight: 'Weight',
      weightView: 'Weight kg/lbs',
      weightType: 'Weight type',
      hair: 'Hair color',
      eyes: 'Eyes Color',
      drinking: 'Drinking',
      smoking: 'Smoking',
      education: 'Education',
      lookingAge: 'Looking ages',
      ageFrom: 'Age from',
      ageTo: 'Age to',
    },
    section: {
      photos: 'Photos',
      photosUpload: 'Upload your photos',
      videos: 'Videos',
      videosUpload: 'Upload your videos',
      about: 'About me',
      more: 'More information',
      character: 'Character',
      hobbies: 'Hobbies',
      lookingType: 'Looking for',
    },
  },
}
