import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { getAuthUserInfo } from 'service/auth/selectors'
import { ladiesAddFavorite, ladiesRemoveFavorite } from 'service/ladies/actions/favorite'
import { filterVisitors as filterLadies } from 'service/ladies/actions/filter'
import {
  getVisitors,
  getVisitorsCount,
  getVisitorsFilter,
  getVisitorsPagination,
} from 'service/ladies/selectors'

import LadiesResultSection from './LadiesResultSection/LadiesResultSection'

const LadiesVisitors = ({ items, itemsCount, pagination, filterLadies, ladiesFilterObject, ladiesRemoveFavorite, ladiesAddFavorite }) => {
  const [filterObj, setFilterObj] = useState({
    from: 0,
    size: 6,
  })

  useEffect(() => {
    filterLadies(filterObj)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { t } = useTranslation('text')

  function handleOnFavoriteClick(item) {
    const resultFunc = item.favorite
      ? ladiesRemoveFavorite
      : ladiesAddFavorite

    resultFunc(item.id).then(filterLadies)
  }

  function handleOnPageChanged(pageNumber) {
    filterLadies({
      from: (pageNumber - 1) * ladiesFilterObject.size,
    })
  }

  function handleOnSort(value) {
    filterLadies({
      sort: {
        fldName: value.sortField,
        sortType: value.sortType,
      },
    })
  }

  function handleOnSearch() {
    filterLadies(filterObj)
  }

  return (
    <LadiesResultSection
      data={items}
      page={pagination.currentPage}
      count={pagination.pagesCount}
      itemsCount={itemsCount}
      title={t('ladies.search.title.visitors')}
      onSort={handleOnSort}
      onSearch={handleOnSearch}
      onPageChanged={handleOnPageChanged}
      onFavoriteClick={handleOnFavoriteClick}
    />
  )
}


const mapStateToProps = state => ({
  userInfo: getAuthUserInfo(state),
  items: getVisitors(state),
  itemsCount: getVisitorsCount(state),
  pagination: getVisitorsPagination(state),
  ladiesFilterObject: getVisitorsFilter(state),
})

const mapDispatchToProps = {
  filterLadies,
  ladiesAddFavorite,
  ladiesRemoveFavorite,
}


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(LadiesVisitors)
