import moment from 'moment'
import { validateEmail } from 'utils/validationHelper'

const validateSignUp = stateData => {
  const momentBirthday = moment(stateData.birthday, 'DD.MM.YYYY', true)

  if (stateData.password !== stateData.repeatPassword) {
    return 'error.password.update.match'
  }

  if (!momentBirthday.isValid()) {
    return 'error.profile.date.invalide'
  }

  // inverted comparing
  if (momentBirthday.diff(new Date(), 'years') > -18) {
    return 'error.profile.date.young'
  }

  // inverted comparing
  if (momentBirthday.diff(new Date(), 'years') < -100) {
    return 'error.profile.date.old'
  }

  if (stateData.email && !validateEmail(stateData.email)) {
    return 'error.profile.email'
  }

  return null
}

export {
  validateSignUp,
}
