import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

const YoutubeDialog = ({ open, onClose, onAccept }) => {
  const { t } = useTranslation('mailEditor')

  const [error, setError] = useState(false)

  function handleOnAdd(event) {
    event.preventDefault()

    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
    const match = event.target.link.value.match(regExp)

    if (match && match[2].length === 11) {
      setError(false)

      onAccept(match[2])
    } else {
      setError(true)
    }
  }

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={onClose}
      fullWidth
    >
      <form onSubmit={handleOnAdd}>
        <DialogTitle>{t('dialog.youtube.title')}</DialogTitle>
        <DialogContent dividers>
          <TextField
            placeholder="Paste link - https://youtu.be/"
            name="link"
            helperText={error && t('dialog.youtube.error')}
            error={error}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={onClose}>{t('dialog.youtube.action.cancel')}</Button>
          <Button type="submit">{t('dialog.youtube.action.accept')}</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default YoutubeDialog
