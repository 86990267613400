const WEIGHT_KG = {
  label: 'kg',
  value: 'KG',
}
const WEIGHT_LBS = {
  label: 'lbs',
  value: 'LBS',
}

const weightOptions = [
  WEIGHT_KG,
  WEIGHT_LBS,
]

export {
  WEIGHT_KG,
  WEIGHT_LBS,
  weightOptions,
}
