import React from 'react'
import PropTypes from 'prop-types'
import {
  Avatar,
  Divider,
  Box,
  Grid,
  Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import updateHistory from 'utils/history'
import { appURLs } from 'navigation/routes'
import styles from './styles'
import sx from './sx'
import CommonLadyActions from './CommonLadyActions'

const LadiesFirstTopResultItem = ({ t, classes, item, quickMessage, onFavorite }) => {
  function handleOnLadyClick() {
    updateHistory('push', `${appURLs.LADY_PROFILE_VIEW_VISITOR_BASE}${item.id}`)
  }

  function handleOnFavoriteClick() {
    onFavorite(item)
  }

  return (
    <>
      <div className={classes.ladyTopOneContainer}>
        <Grid container spacing={2}>
          <Grid xs={12} item container justifyContent="center">
            <Typography className={classes.ladyTopNumber} variant="h1">{`${item.index}.`}</Typography>
            <Avatar
              alt={item.name}
              src={item.avatar}
              sx={sx.topItemAvatar}
              onClick={handleOnLadyClick}
            />
          </Grid>
          <Grid xs={12} item container justifyContent="center">
            <div className={classes.ladyTopTextInfoContainer}>
              <Box onClick={handleOnLadyClick} sx={sx.topItemInfoContainer}>
                <Grid justifyContent="center">
                  <Typography className={classes.ladyNameInfo} variant="h5">{item.name}</Typography>
                  <Typography className={classes.ladyAgeInfo} variant="h5">{item.age}</Typography>
                </Grid>
                <Grid justifyContent="center">
                  <Typography className={classes.ladyTopOneIDInfo}>
                    {t('ladies.search.result.id', { value: item.id })}
                  </Typography>
                </Grid>
              </Box>
            </div>
          </Grid>
          <Grid xs={12} item container justifyContent="center">
            <CommonLadyActions
              item={item}
              onFavorite={handleOnFavoriteClick}
              mail
            />
          </Grid>
        </Grid>
      </div>
      <Divider />
    </>
  )
}

LadiesFirstTopResultItem.propTypes = {
  /** Array of rows data for table. */
  item: PropTypes.object,
  /** On favorite button click. */
  onFavorite: PropTypes.func,
  /** Flag to show quick message button. */
  quickMessage: PropTypes.func,
}

LadiesFirstTopResultItem.defaultProps = {
  item: {},
  onFavorite: () => {},
  quickMessage: false,
}

export default compose(
  withTranslation('text'),
  withStyles(styles),
)(LadiesFirstTopResultItem)
