import React, { useState } from 'react'

import {
  useSelected,
  useFocused,
} from 'slate-react'

const Image = ({ attributes, children, element }) => {
  const [width, setWidth] = useState(350)
  const [height, setHeight] = useState(400)

  const selected = useSelected()
  const focused = useFocused()

  return (
    <div {...attributes} style={{ width, height, display: 'relative' }}>
      {children}
      <img
        alt={element.url}
        src={element.url}
        style={{
          boxShadow: selected && focused ? '0 0 0 2px blue;' : 'none',
          width,
          height,
        }}
      />
    </div>
  )
}

export default Image
