import { appURLs } from 'navigation/routes'
import { hist } from 'index'
import { getToken, removeToken } from 'utils/auth'

const sendRequest = (path, body) => fetch(path, body)
  .then(response => {
    if (response.status === 401) {
      removeToken()
      hist('push', appURLs.HOME)
    }

    return response
  }).catch(error => {
    throw error
  })

const Api = {
  basePath: window._env_.API_ADDRESS,
  prodPath: 'https://dreamonelove.com/gloriabrides/services/',

  requestHeader: () => ({
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': getToken(),
  }),

  requestFormDataHeader: () => ({
    'Authorization': getToken(),
  }),

  put: (path, data, options = {}) => {
    const request = {
      method: 'PUT',
      body: JSON.stringify(data),
      mode: 'cors',
      headers: Api.requestHeader(),
      ...options,
    }

    return sendRequest(path, request)
  },

  post: (path, data, options = {}) => {
    const request = {
      method: 'POST',
      body: JSON.stringify(data),
      mode: 'cors',
      headers: Api.requestHeader(),
      ...options,
    }

    return sendRequest(path, request)
  },

  postFormData: (path, data, options = {}) => {
    const request = {
      method: 'POST',
      body: data,
      mode: 'cors',
      headers: Api.requestFormDataHeader(),
      ...options,
    }

    return sendRequest(path, request)
  },

  get: (path, options = {}) => {
    const request = {
      method: 'GET',
      mode: 'cors',
      headers: Api.requestHeader(),
      ...options,
    }

    return sendRequest(path, request)
  },

  delete: (path, options = {}) => {
    const request = {
      method: 'DELETE',
      mode: 'cors',
      headers: Api.requestHeader(),
      ...options,
    }

    return sendRequest(path, request)
  },

  fileDownload: (url, filename) => sendRequest(url)
    .then(data => data.blob())
    .then(blob => {
      const url = URL.createObjectURL(blob)
      const tmpLink = document.createElement('a')
      tmpLink.href = url
      tmpLink.download = filename
      tmpLink.target = '_blank'
      tmpLink.click()
    }),
}

export default Api
