export default {
  card: {
    margin: '120px 0',
  },
  form: {
    pt: 4,
    px: 16,
  },
  sendButton: {
    p: 4,
  },
}
