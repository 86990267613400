import React, { useState } from 'react'
import { Settings } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'

const Youtube = ({ attributes, children, element }) => {
  const [width, setWidth] = useState('100%')
  const [height, setHeight] = useState(400)

  return (
    <div {...attributes}>
      <div contentEditable={false}>
        <div style={{ width, height, display: 'relative' }}>
          <iframe
            style={{ width, height }}
            src={`https://www.youtube.com/embed/${element.videoID}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
      {children}
    </div>
  )
}

export default Youtube
