/**
 * Contains lists of locations that are restricted for users are from to be registered.
 */

const asianCountries = [
  'AB', 'ABH',
  'AZ', 'AZE',
  'AM', 'ARM',
  'AF', 'AFG',
  'BD', 'BGD',
  'BH', 'BHR',
  'BN', 'BRN',
  'BT', 'BTN',
  'VN', 'VNM',
  'HK', 'HKG',
  'GE', 'GEO',
  'IL', 'ISR',
  'IN', 'IND',
  'ID', 'IDN',
  'JO', 'JOR',
  'IQ', 'IRQ',
  'IR', 'IRN',
  'YE', 'YEM',
  'KZ', 'KAZ',
  'KH', 'KHM',
  'QA', 'QAT',
  'CY', 'CYP',
  'KG', 'KGZ',
  'CN', 'CHN',
  'KP', 'PRK',
  'KR', 'KOR',
  'KW', 'KWT',
  'LA', 'LAO',
  'LB', 'LBN',
  'MO', 'MAC',
  'MY', 'MYS',
  'MV', 'MDV',
  'MN', 'MNG',
  'MM', 'MMR',
  'NP', 'NPL',
  'AE', 'ARE',
  'OM', 'OMN',
  'CX', 'CXR',
  'PK', 'PAK',
  'PS', 'PSE',
  'SA', 'SAU',
  'SG', 'SGP',
  'SY', 'SYR',
  'TJ', 'TJK',
  'TH', 'THA',
  'TW', 'TWN',
  'TL', 'TLS',
  'TM', 'TKM',
  'TR', 'TUR',
  'UZ', 'UZB',
  'PH', 'PHL',
  'LK', 'LKA',
  'OS', 'OST',
  'JP', 'JPN',
]

// specific
const countries = ['UA', 'UKR', 'RU', 'RUS']

const restrictedCounties = countries.concat(asianCountries)

export {
  asianCountries,
  countries,
  restrictedCounties,
}
