import { HEIGHT_FT } from 'consts/heightOptions'
import moment from 'moment'

const feetRegex = new RegExp(/^([0-9]{1,})(\')([0-9]{1,})('')$/)

const validateProfileInfo = stateData => {
  const momentBirthday = moment(stateData.birthday, 'DD.MM.YYYY', true)

  if (!momentBirthday.isValid()) {
    return 'error.profile.date.invalide'
  }

  // inverted comparing
  if (momentBirthday.diff(new Date(), 'years') > -18) {
    return 'error.profile.date.young'
  }

  if (stateData.heightType === HEIGHT_FT.value && !feetRegex.test(stateData.height)) {
    return 'error.profile.date.height'
  }

  if (Number(stateData.lookingAgeFrom) > Number(stateData.lookingAgeTo)) {
    return 'error.profile.date.lookingAge'
  }

  return null
}

const validatePasswordInfo = stateData => {
  if (stateData.newPassword !== stateData.repeatedPassword) {
    return 'error.password.update.match'
  }

  if (stateData.newPassword === stateData.oldPassword) {
    return 'error.password.update.oldMatch'
  }

  return null
}

export {
  validateProfileInfo,
  validatePasswordInfo,
}
