import React from 'react'
import { withTranslation } from 'react-i18next'
import { filterFavorite as ladiesFavoriteFilter } from 'service/ladies/actions/filter'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getAuthUserInfo } from 'service/auth/selectors'
import { ladiesAddFavorite, ladiesRemoveFavorite } from 'service/ladies/actions/favorite'
import { getFavoriteFilterLadies, getFavoriteFilterLadiesFilterObj, getFavoriteFilterLadiesPaginationInfo, getFavoriteFilterLadiesResultCount } from 'service/ladies/selectors'
import LadiesResultSection from './LadiesResultSection/LadiesResultSection'

class LadiesFavorite extends React.PureComponent {
  componentDidMount() {
    this.props.ladiesFavoriteFilter({ from: 0, size: 6 })
  }

  handleOnFavoriteClick = item => {
    const resultFunc = item.favorite
      ? this.props.ladiesRemoveFavorite
      : this.props.ladiesAddFavorite

    resultFunc(item.id).then(() => this.props.ladiesFavoriteFilter({}))
  }

  handleOnChange = event => this.setState({ [event.target.name]: event.target.value })

  handleOnPageChanged = pageNumber => this.props.ladiesFavoriteFilter({
    from: (pageNumber - 1) * this.props.ladiesFilterObject.size,
  })

  handleOnSort = value => this.props.ladiesFavoriteFilter({
    sort: {
      fldName: value.sortField,
      sortType: value.sortType,
    },
  })

  render() {
    const { t, items, itemsCount, pagination } = this.props

    return (
      <LadiesResultSection
        data={items}
        page={pagination.currentPage}
        count={pagination.pagesCount}
        itemsCount={itemsCount}
        title={t('ladies.search.title.favorite')}
        onSort={this.handleOnSort}
        onPageChanged={this.handleOnPageChanged}
        onFavoriteClick={this.handleOnFavoriteClick}
      />
    )
  }
}


const mapStateToProps = state => ({
  userInfo: getAuthUserInfo(state),
  items: getFavoriteFilterLadies(state),
  itemsCount: getFavoriteFilterLadiesResultCount(state),
  pagination: getFavoriteFilterLadiesPaginationInfo(state),
  ladiesFilterObject: getFavoriteFilterLadiesFilterObj(state),
})

const mapDispatchToProps = {
  ladiesFavoriteFilter,
  ladiesAddFavorite,
  ladiesRemoveFavorite,
}


export default compose(
  withTranslation('text'),
  connect(mapStateToProps, mapDispatchToProps),
)(LadiesFavorite)
