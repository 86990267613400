import { connect } from 'react-redux'
import { getAuthUserInfo } from 'service/auth/selectors'
import Profile from './Profile'

const mapStateToProps = state => ({
  userInfo: getAuthUserInfo(state),
})

const mapDispatchToProps = null

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
