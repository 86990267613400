const mapUpdateStateToApi = stateData => ({
  firstName: stateData.firstName.trim(),
  lastName: stateData.lastName.trim(),
  email: stateData.email,
  countryId: stateData.countryId,
  streetAddres: stateData.streetAddres.trim(),
  phone: stateData.phone.trim(),
  birthday: stateData.birthday,
  heightType: stateData.heightType,
  weightType: stateData.weightType,
  weight: stateData.weight.trim(),
  height: stateData.height.trim(),
  hairColor: stateData.hairColor,
  eyeColor: stateData.eyeColor,
  maritalStatus: stateData.maritalStatus,
  religion: stateData.religion,
  education: stateData.education,
  smoker: stateData.smoker,
  drinker: stateData.drinker,
  nativeLang: stateData.nativeLanguage.trim(),
  secondLang: stateData.secondLanguage.trim(),
  job: stateData.job.trim(),
  childrenCount: stateData.childrenCount,
  childrenSex: stateData.childrenSex.trim(),
  childrenYear: stateData.childrenAge.trim(),
  character: stateData.character.trim(),
  hobbies: stateData.hobbies.trim(),
  lookingFor: stateData.lookingFor.trim(),
  lookingAge: `${stateData.lookingAgeFrom}-${stateData.lookingAgeTo}`,
  mainPhotoId: stateData.userPhotos.find(item => item.main).id,
  profilePhotoId: stateData.userPhotos.find(item => item.main).id, // TODO delete when BE ready
  mediaInDTO: {
    newStoredPhotoIds: stateData.userPhotos.filter(item => item.notSaved).map(photo => photo.id),
    removedPhotoIds: stateData.removedPhotoIDs,
    newStoredVideoIds: stateData.userVideos.filter(item => item.notSaved).map(video => video.id),
    removedVideoIds: stateData.removedVideoIDs,
  },
})

const mapUpdatePasswordStateToApi = stateData => ({
  oldPassword: stateData.oldPassword.trim(),
  newPassword: stateData.newPassword.trim(),
  repeatedPassword: stateData.repeatedPassword.trim(),
})

const mapUpdateNotificationsStateToApi = stateData => ({
  newLadyMailSubscriber: stateData.newLadies,
  watchMailSubscriber: stateData.visitor,
  voteMailSubscriber: stateData.vote,
  newLetterMailSubscriber: stateData.letter,
  imgGiftMailSubscriber: stateData.gift,
  updateLadyMailSubscriber: stateData.profileUpdate,
  updateLadyFromFavMailSubscriber: stateData.profileUpdateFavorite,
  newVideoMailSubscriber: stateData.videoUpload,
  newVideoFromFavMailSubscriber: stateData.videoUploadFavorite,
  birthdayMailSubscriber: stateData.birthday,
})

const mapClearUploadedMediaToApi = stateData => ({
  newStoredPhotoIds: stateData.userPhotos.filter(item => item.notSaved).map(video => video.id),
  newStoredVideoIds: stateData.userVideos.filter(item => item.notSaved).map(video => video.id),
})

export {
  mapUpdateStateToApi,
  mapUpdatePasswordStateToApi,
  mapClearUploadedMediaToApi,
  mapUpdateNotificationsStateToApi,
}
