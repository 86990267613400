import React from 'react'
import Profile from 'views/Profile'
import Settings from 'views/Settings'
import LadiesSearch from 'views/LadiesSearch/LadiesSearch'
import LadiesNewestSearch from 'views/LadiesSearch/LadiesNewestSearch'
import LadiesFavorite from 'views/LadiesSearch/LadiesFavorite'
import LadiesTopSearch from 'views/LadiesSearch/LadiesTopSearch'

import ManFavoritePhotos from 'views/Favorites/ManFavoritesMedia/ManFavoritePhotos'
import ManFavoritesVideos from 'views/Favorites/ManFavoritesMedia/ManFavoriteVideos'
import LadyVisitorView from 'views/Profile/LadyVisitorView'
import LadiesVisitors from 'views/LadiesSearch/LadiesVisitors'
import LadiesContacts from 'views/LadiesSearch/LadiesContacts'
import PurchaseCoins from 'views/Finanses/PurchaseCoins'
import PurchaseHistory from 'views/Finanses/PurchaseHistory/PurchaseHistory'
import WriteNewMail from 'views/Mail/WriteNewMail'
import { appURLs } from 'navigation/routes'

const SettingsRoute = {
  key: 'settings',
  path: appURLs.SETTINGS,
  component: <Settings />,
}

const ProfileRoute = {
  key: 'profile',
  path: appURLs.PROFILE,
  component: <Profile />,
}

const LadiesSearchRoute = {
  key: 'ladiesSearch',
  path: appURLs.LAIDES_SEARCH,
  component: <LadiesSearch />,
}

const LadiesNewestSearchRoute = {
  key: 'ladiesNewestSearch',
  path: appURLs.LAIDES_NEWEST_SEARCH,
  component: <LadiesNewestSearch />,
}

const LadiesFavoriteRoute = {
  key: 'ladiesFavorite',
  path: appURLs.LAIDES_FAVORITE,
  component: <LadiesFavorite />,
}

const LadiesTopSearchRoute = {
  key: 'ladiesTopSearch',
  path: appURLs.LAIDES_TOP_SEARCH,
  component: <LadiesTopSearch />,
}

const LadiesVisitorsRoute = {
  key: 'ladiesVisitorsSearch',
  path: appURLs.LAIDES_VISITORS,
  component: <LadiesVisitors />,
}

const LadiesContactsRoute = {
  key: 'ladiesContactsSearch',
  path: appURLs.LAIDES_CONTACTS,
  component: <LadiesContacts />,
}

const LadyProfileViewVisitorRoute = {
  key: 'ladyProfileViewVisitor',
  path: appURLs.LADY_PROFILE_VIEW_VISITOR,
  component: <LadyVisitorView />,
}

const ManFavoritePhotosRoute = {
  key: 'manFavoritePhotos',
  path: appURLs.MAN_FAVORITE_PHOTOS,
  component: <ManFavoritePhotos />,
}

const ManFavoriteVideosRoute = {
  key: 'manFavoriteVideos',
  path: appURLs.MAN_FAVORITE_VIDEOS,
  component: <ManFavoritesVideos />,
}

const PurchasCoinsRoute = {
  key: 'purchaseCoins',
  path: appURLs.PURCHASES_COINS,
  component: <PurchaseCoins />,
}

const PurchasHistoryRoute = {
  key: 'purchaseHistory',
  path: appURLs.PURCHASES_HISTORY,
  component: <PurchaseHistory />,
}

const WriteNewMailRoute = {
  key: 'mailWriteNew',
  path: appURLs.MAIL_WRITE_NEW,
  component: <WriteNewMail />,
}

const manRoutes = [
  ProfileRoute,
  SettingsRoute,
  LadiesSearchRoute,
  LadiesNewestSearchRoute,
  LadiesTopSearchRoute,
  LadiesFavoriteRoute,
  LadiesVisitorsRoute,
  LadiesContactsRoute,
  LadyProfileViewVisitorRoute,
  ManFavoritePhotosRoute,
  ManFavoriteVideosRoute,
  PurchasCoinsRoute,
  PurchasHistoryRoute,
  WriteNewMailRoute,
]

export {
  manRoutes,
}
