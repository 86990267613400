const PHOTO = {
  NORMAL: '/photo.jpg',
  MEDIUM: '/thumbnailMedium.jpg',
  SMALL: '/thumbnailSmall.jpg',
}

const VIDEO = {
  NORMAL: '/thumbnail.jpg',
}

export {
  PHOTO,
  VIDEO,
}
