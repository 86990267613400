import Container from '@mui/material/Container'
import React from 'react'
import PropTypes from 'prop-types'

const CustomContainer = ({ py, children }) => (
  <Container maxWidth="lg">
    {children}
  </Container>
)

CustomContainer.propTypes = {
  py: PropTypes.bool,
}

CustomContainer.defaultProps = {
  py: false,
}

export default CustomContainer
