import { createSelectorCreator, defaultMemoize } from 'reselect'
import isEqual from 'lodash/isEqual'
import { eyeOptions, hairOptions } from 'consts/profileEditOptions'
import { getAge } from 'consts/age'

const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual,
)

const getManMeInfo = createDeepEqualSelector(
  state => state.man.me,
  me => {
    const lookingAges = RegExp(/^\d*-\d*$/).test(me.manAdditionalDTO?.lookingAge)
      ? me.manAdditionalDTO.lookingAge?.split('-')
      : ['18', '18']
    let mainMediaName = ''


    const mediaURLSplit = me?.manGeneralDTO?.photoLnk.split('/') || []
    if (mediaURLSplit.length > 0) {
      mainMediaName = mediaURLSplit[mediaURLSplit.length - 2]
    }

    return {
      general: me?.manGeneralDTO ? {
        ...me.manGeneralDTO,
        mediaName: mainMediaName,
        // countryName: me.manGeneralDTO.countryName.trim(), // TODO remove when country name will be fomratted at DB
      } : {},
      additional: me?.manAdditionalDTO ? {
        ...me.manAdditionalDTO,
        education: me.manAdditionalDTO.education.toUpperCase(), // TODO when enum fixed
        maritalStatus: me.manAdditionalDTO.maritalStatus.toUpperCase(), // TODO when enum fixed
        smoker: me.manAdditionalDTO.smoker.toUpperCase(), // TODO when enum fixed
        religion: me.manAdditionalDTO.religion.toUpperCase(), // TODO when enum fixed
        drinker: me.manAdditionalDTO.drinker.toUpperCase(), // TODO when enum fixed
        birthday: me.manAdditionalDTO.birthday.split(' ')[0], // TODO remove when date will be timestampt
        hairColor: (hairOptions.find(item => item.label === me.manAdditionalDTO.hairColor) || hairOptions[0]).value, // TODO remove when hair will be an object
        eyeColor: (eyeOptions.find(item => item.label === me.manAdditionalDTO.eyeColor) || eyeOptions[0]).value, // TODO remove when eye will be an object
        lookingAgeFrom: getAge(lookingAges[0]),
        lookingAgeTo: getAge(lookingAges[1]),
        profilePhoto: {
          totalVotes: me.manGeneralDTO.totalVotes,
        },
      } : {},
      photos: me?.manPhotoDTOs?.map(item => (item.id === me?.manGeneralDTO.mainPhotoId ? {
        ...item,
        main: true,
      } : item)) || [],
      videos: me?.manVideoDTOs || [],
    }
  },
)

const getNotificationsSettings = createDeepEqualSelector(
  state => state.man.me,
  me => ({
    newLadies: me.manNotificationDTO.newLadyMailSubscriber,
    visitor: me.manNotificationDTO.watchMailSubscriber,
    vote: me.manNotificationDTO.voteMailSubscriber,
    letter: me.manNotificationDTO.newLetterMailSubscriber,
    gift: me.manNotificationDTO.imgGiftMailSubscriber,
    profileUpdate: me.manNotificationDTO.updateLadyMailSubscriber,
    profileUpdateFavorite: me.manNotificationDTO.updateLadyFromFavMailSubscriber,
    videoUpload: me.manNotificationDTO.newVideoMailSubscriber,
    videoUploadFavorite: me.manNotificationDTO.newVideoFromFavMailSubscriber,
    birthday: me.manNotificationDTO.birthdayMailSubscriber,
  }),
)

export {
  getManMeInfo,
  getNotificationsSettings,
}
