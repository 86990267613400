import {
  Typography,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Button,
} from '@mui/material'
import Section from 'components/Section'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CustomTable from 'components/Table'
import sx from './sx'

const PurchaseHistory = ({ }) => {
  const { t } = useTranslation('finanses')

  const headers = [
    {
      name: t('history.purchase.table.header.date'),
      align: 'left',
    },
    {
      name: t('history.purchase.table.header.amount'),
      align: 'right',
    },
    {
      name: t('history.purchase.table.header.status'),
      align: 'right',
    },
    {
      name: t('history.purchase.table.header.pendgin'),
      align: 'right',
    },
  ]

  return (
    <Section title={t('purchase.label')}>
      <Grid sx={sx.part}>
        <CustomTable
          header={headers}
          // data={items}
          // page={pagination.currentPage}
          // count={pagination.pagesCount}
          // renderRow={renderTableRow}
          // onPageChanged={handleOnPageChanged}
        />
      </Grid>
      <Grid container justifyContent="center">
        <Typography>
          {t('history.purchase.total', { USD: 0, EUR: 0 })}
        </Typography>
      </Grid>
    </Section>
  )
}

export default PurchaseHistory
