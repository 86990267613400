import React from 'react'
import Section from 'components/Section'
import Container from '@mui/material/Container'

const HomeSection = ({ title, children }) => (
  <Section
    TitleProps={{
      sx: { my: 6 },
    }}
    title={title}
    variant="h4"
    tight
  >
    <Container>
      {children}
    </Container>
  </Section>
)

export default HomeSection
