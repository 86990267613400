export default theme => ({
  title: {
    textAlign: 'center',
    fontWeight: 700,
    padding: '6px 0 12px',
    textTransform: 'uppercase',
  },

  cardRoot: {
    paddingLeft: 32,
    borderRadius: 16,
  },
  ladyNameInfo: {
    display: 'inline-block',
    fontWeight: 700,
  },
  ladyAgeInfo: {
    display: 'inline-block',
    fontWeight: 700,
    paddingLeft: 4,
    color: theme.palette.grey[400],
  },
  ladyIDInfo: {
    fontWeight: 700,
    color: theme.palette.grey[400],
  },


  mediaCard: {
    'width': '100%',
    'height': 135,
    'backgroundPositionY': '50%',
    'backgroundSize': 'cover',
    'cursor': 'pointer',

    '& > svg': {
      'width': '100%',
      'height': '100%',
      'padding': 24,
      'fill': 'white',

      '&:hover': {
        fill: '#ffffffad',
      },
    },
  },

  cardContent: {
    position: 'relative',
  },

  cardDeleteAction: {
    position: 'absolute',
    bottom: 0,
  },
})
