import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import MobileStepper from '@mui/material/MobileStepper'
import useMediaQuery from '@mui/material/useMediaQuery'
import { connect } from 'react-redux'
import {
  getShortNews,
  clearNews,
} from 'service/home/actions/news'
import { getNews } from 'service/home/selectors/news'
import Image from 'components/Image'
import GoButton from 'components/GoButton'
import { appURLs } from 'navigation/routes'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import { useNavigate } from 'react-router'
import sx from './sx'
import HomeSection from '../HomeSection'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const NewLarge = ({ item, onClick }) => {
  const handleOnClick = useCallback(() => onClick(item), [item, onClick])

  return (
    <Grid
      container
      rowGap={2}
      sx={sx.clickable}
      onClick={handleOnClick}
    >
      <Box sx={sx.photoContainer(300)}>
        <Box
          sx={sx.photo}
          src={item.headerPhoto}
          component={Image}
        />
      </Box>
      <Typography variant="title">
        {item.header}
      </Typography>
    </Grid>
  )
}

const NewMinor = ({ item, onClick }) => {
  const handleOnClick = useCallback(() => onClick(item), [item, onClick])

  return (
    <Grid
      container
      spacing={2}
      sx={sx.clickable}
      onClick={handleOnClick}
    >
      <Grid item xs={5}>
        <Box sx={sx.photoContainer(150)}>
          <Image
            sx={sx.photo}
            src={item.headerPhoto}
          />
        </Box>
      </Grid>
      <Grid item container xs={7} rowGap={2}>
        <Typography variant="title">
          {item.header}
        </Typography>
        <Typography sx={sx.description}>
          {item.description}
        </Typography>
      </Grid>
    </Grid>
  )
}

const NewCarousel = ({ item, onClick }) => {
  const handleOnClick = useCallback(() => onClick(item), [item, onClick])

  return (
    <Grid
      container
      rowGap={1}
      sx={sx.clickable}
      onClick={handleOnClick}
    >
      <Box sx={sx.photoContainerCarousel}>
        <Image
          src={item.headerPhoto}
          borderRadius={1}
          fullWidth
        />
      </Box>
      <Grid container rowGap={2}>
        <Grid item container xs={12}>
          <Typography variant="title">
            {item.header}
          </Typography>
        </Grid>
        <Grid item container xs={12}>
          <Typography sx={sx.description}>
            {item.description}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

const News = ({ news, getShortNews, clearNews }) => {
  const [currentNew, setCurrentNew] = useState(0)

  const { t } = useTranslation(['home', 'common'])
  const navigate = useNavigate()

  const upMD = useMediaQuery(theme => theme.breakpoints.up('md'))
  const downMD = useMediaQuery(theme => theme.breakpoints.down('md'))

  useEffect(() => {
    clearNews().then(getShortNews)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnClick = useCallback(item => navigate(`${appURLs.HOME_NEWS_DETAILS_BASE}${item.id}`), [navigate])
  const handleOnMoreClick = useCallback(() => navigate(`${appURLs.HOME_NEWS_OVERVIEW}`), [navigate])

  if (news.length === 0) {
    return null
  }

  const renderMD = () => (
    <>
      <Grid container spacing={2}>
        <Grid item sm={12} md={6}>
          <NewLarge item={news[0]} onClick={handleOnClick} />
        </Grid>
        <Grid item container sm={12} md={6} rowGap={2}>
          <NewMinor item={news[1]} onClick={handleOnClick} />
          <NewMinor item={news[2]} onClick={handleOnClick} />
        </Grid>
      </Grid>
    </>
  )

  const renderXS = () => (
    <>
      <Box>
        <Box sx={sx.playerContainer}>
          <AutoPlaySwipeableViews
            index={currentNew}
            onChangeIndex={setCurrentNew}
            enableMouseEvents
          >
            {news.map(item => (
              <NewCarousel
                key={item.header}
                item={item}
                onClick={handleOnClick}
              />
            ))}
          </AutoPlaySwipeableViews>
        </Box>
        <Grid container justifyContent="center">
          <MobileStepper
            steps={3}
            activeStep={currentNew}
            position="static"
          />
        </Grid>
      </Box>
    </>
  )

  return (
    <HomeSection title={t('sections.news.title')}>
      {upMD && renderMD()}
      {downMD && renderXS()}
      <Grid container justifyContent="center">
        <GoButton mt onClick={handleOnMoreClick}>{t('general.more', { ns: 'common' })}</GoButton>
      </Grid>
    </HomeSection>
  )
}

const mapStateToProps = state => ({
  news: getNews(state),
})

const mapDispatchToProps = {
  getShortNews,
  clearNews,
}

export default connect(mapStateToProps, mapDispatchToProps)(News)
