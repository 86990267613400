import React from 'react'

const Search = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="none">
    <g fill="#f5ca00">
      <rect x="4" width="11" height="11" rx="3" />
      <rect x="19" width="11" height="11" rx="3" />
      <rect x="34" width="11" height="11" rx="3" />
      <rect x="4" y="15" width="11" height="11" rx="3" />
    </g>
    <rect x="16" y="12" width="17" height="17" rx="3" fill="url(#A)" />
    <g fill="#f5ca00">
      <rect x="34" y="15" width="11" height="11" rx="3" />
      <rect x="4" y="30" width="11" height="11" rx="3" />
      <rect x="19" y="30" width="11" height="11" rx="3" />
      <rect x="34" y="30" width="11" height="11" rx="3" />
    </g>
    <path d="M36.549 17.315l-.963.268.963-.268a12.55 12.55 0 0 0-1.281-3.029c-.552-.936-1.218-1.789-1.997-2.557a12.48 12.48 0 0 0-2.557-1.997c-.941-.555-1.952-.982-3.029-1.281C26.598 8.15 25.473 8 24.313 8s-2.286.15-3.372.452c-1.078.299-2.088.726-3.029 1.281s-1.795 1.219-2.573 1.997a12.79 12.79 0 0 0-1.98 2.557 12.55 12.55 0 0 0-1.281 3.029c-.302 1.087-.451 2.212-.451 3.372 0 1.498.252 2.942.758 4.327l.001.003a12.87 12.87 0 0 0 1.62 3.054L1.608 40.483A2.02 2.02 0 0 0 1 41.938a2.02 2.02 0 0 0 .608 1.454A2.02 2.02 0 0 0 3.063 44a2.02 2.02 0 0 0 1.454-.608l12.412-12.396c.948.679 1.966 1.22 3.054 1.62l.003.001c1.385.506 2.829.758 4.327.758 1.16 0 2.286-.15 3.372-.452a12.55 12.55 0 0 0 3.029-1.281 12.79 12.79 0 0 0 2.557-1.98c.778-.778 1.444-1.636 1.997-2.573a12.55 12.55 0 0 0 1.281-3.029l-.963-.268.963.268c.302-1.087.452-2.212.452-3.372s-.15-2.286-.452-3.372zm-4.353 6.69h0l-.003.007c-.45 1.049-1.06 1.955-1.83 2.725s-1.678 1.387-2.729 1.848a8.34 8.34 0 0 1-3.321.665 8.34 8.34 0 0 1-3.321-.665c-1.051-.461-1.958-1.077-2.729-1.848s-1.387-1.678-1.848-2.729c-.441-1.031-.665-2.135-.665-3.321a8.34 8.34 0 0 1 .665-3.321c.461-1.051 1.077-1.958 1.848-2.729s1.676-1.38 2.725-1.83h0l.007-.003c1.031-.452 2.133-.68 3.317-.68s2.287.228 3.317.68h0l.007.003c1.049.45 1.955 1.06 2.725 1.83s1.38 1.676 1.83 2.725h0l.003.007c.452 1.031.68 2.133.68 3.317s-.228 2.287-.68 3.317z" fill="#01124f" stroke="#01124f" strokeWidth="2" />
    <defs>
      <linearGradient id="A" x1="24.5" y1="12" x2="24.5" y2="29" gradientUnits="userSpaceOnUse">
        <stop stopColor="#f5ca00" stopOpacity="0" />
        <stop offset="1" stopColor="#f5ca00" />
      </linearGradient>
    </defs>
  </svg>
)

export default Search
