import Api from './Api'

class HomeApi {
  static filterNews = data => {
    const path = `${Api.prodPath}newsAPI`
    return Api.post(path, data).then(response => response.json())
  }

  static getNewsByID = data => {
    const path = `${Api.prodPath}newsAPI`
    return Api.post(path, data).then(response => response.json())
  }

  static filterStories = data => {
    const path = `${Api.prodPath}storyAPI`
    return Api.post(path, data).then(response => response.json())
  }

  static getStoryByID = data => {
    const path = `${Api.prodPath}storyAPI`
    return Api.post(path, data).then(response => response.json())
  }
}

export default HomeApi
