import React, { useCallback, useEffect } from 'react'
import HomeLayout from 'views/Home/HomeLayout'
import { useTranslation } from 'react-i18next'
import {
  filterVideoStories,
  clearVideoStories,
} from 'service/home/actions/stories'
import { connect } from 'react-redux'
import {
  Box,
  Grid,
  Typography,
} from '@mui/material'
import Image from 'components/Image'
import { appURLs } from 'navigation/routes'
import {
  getVideoStories,
  getVideoStoriesFilter,
  getVideoStoriesPagination,
} from 'service/home/selectors/stories'
import { useNavigate } from 'react-router'
import sx from './sx'

const OverviewItem = ({ item, onClick }) => {
  const handleOnClick = useCallback(() => onClick(item), [item, onClick])

  return (
    <Grid
      item
      onClick={handleOnClick}
      md={4}
      sm={6}
      xs={12}
      rowGap={1}
      container
    >
      <Grid item xs={12}>
        <Box sx={sx.photo}>
          <Image
            src={item.thumbUrl}
            useBackground
            fullHeight
          />
        </Box>
      </Grid>
      <Grid item container xs={12} alignItems="flex-start">
        <Typography sx={sx.title}>
          {item.author}
        </Typography>
      </Grid>
    </Grid>
  )
}

const FeedbackOverview = ({ stories, filter, pagination, filterVideoStories, clearVideoStories }) => {
  const { t } = useTranslation('home')
  const navigate = useNavigate()

  useEffect(() => {
    // if user from home view
    if (stories.length <= 5) {
      clearVideoStories().then(filterVideoStories)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnClick = useCallback(item => navigate(`${appURLs.HOME_STORY_DETAILS_BASE}${item.id}`), [navigate])

  const handleOnPageChanged = useCallback(pageNumber => filterVideoStories({
    from: (pageNumber - 1) * filter.size,
  }), [filter.size, filterVideoStories])

  return (
    <HomeLayout
      header={t('sections.stories.video.title')}
      pageCounts={pagination.count}
      currentPage={pagination.page}
      onPageChanged={handleOnPageChanged}
      pagination
      margin
    >
      <Grid container spacing={4}>
        {stories.map(item => <OverviewItem key={item.id} item={item} onClick={handleOnClick} />)}
      </Grid>
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  stories: getVideoStories(state),
  pagination: getVideoStoriesPagination(state),
  filter: getVideoStoriesFilter(state),
})

const mapDispatchToProps = {
  filterVideoStories,
  clearVideoStories,
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackOverview)
