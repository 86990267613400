import React, { useCallback, useEffect } from 'react'
import HomeLayout from 'views/Home/HomeLayout'
import { useTranslation } from 'react-i18next'
import { clearNews, filterNews } from 'service/home/actions/news'
import { connect } from 'react-redux'
import { getNews, getNewsFilter, getNewsPagination } from 'service/home/selectors/news'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Image from 'components/Image'
import { appURLs } from 'navigation/routes'
import moment from 'moment'
import { useNavigate } from 'react-router'
import sx from './sx'

const OverviewItem = ({ item, onClick }) => {
  const handleOnClick = useCallback(() => onClick(item), [item, onClick])

  return (
    <Grid
      item
      container
      md={4}
      sm={6}
      xs={12}
      onClick={handleOnClick}
    >
      <Stack rowGap={1} sx={sx.itemContainer}>
        <Box sx={sx.photo}>
          <Image
            src={item.headerPhoto}
            useBackground
            fullHeight
          />
        </Box>
        <Typography sx={sx.date}>
          {moment(item.date).format('DD-mm-YYYY')}
        </Typography>
        <Typography sx={sx.title}>
          {item.header}
        </Typography>
      </Stack>
    </Grid>
  )
}

const NewsOverview = ({ news, filter, pagination, filterNews, clearNews }) => {
  const { t } = useTranslation('home')
  const navigate = useNavigate()

  useEffect(() => {
    // if user from home view
    if (news.length <= 3) {
      clearNews().then(filterNews)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnClick = useCallback(item => navigate(`${appURLs.HOME_NEWS_DETAILS_BASE}${item.id}`), [navigate])

  const handleOnPageChanged = useCallback(pageNumber => filterNews({
    from: (pageNumber - 1) * filter.size,
  }), [filter.size, filterNews])

  return (
    <HomeLayout
      header={t('sections.news.title')}
      pageCounts={pagination.count}
      currentPage={pagination.page}
      onPageChanged={handleOnPageChanged}
      pagination
      margin
    >
      <Grid container spacing={4} rowGap={2}>
        {news.map(item => <OverviewItem key={item.id} item={item} onClick={handleOnClick} />)}
      </Grid>
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  news: getNews(state),
  pagination: getNewsPagination(state),
  filter: getNewsFilter(state),
})

const mapDispatchToProps = {
  filterNews,
  clearNews,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsOverview)
