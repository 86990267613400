import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'

const Map = ({ marker, zoom }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBvD_VyxVI-13fPQxIX4wCkh85963rY29Y',
  })

  const [map, setMap] = React.useState(null)

  const onLoad = useCallback(map => {
    const bounds = new window.google.maps.LatLngBounds(marker)
    map.fitBounds(bounds)
    setMap(map)
  }, [marker])

  const onUnmount = React.useCallback(map => setMap(null), [])

  useEffect(() => {
    map?.setCenter(new window.google.maps.LatLng(marker))
  }, [marker])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        width: '100%',
        height: '400px',
        borderRadius: 16,
      }}
      zoom={zoom}
      options={{
        disableDefaultUI: true,
      }}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <Marker position={marker} />
    </GoogleMap>
  ) : null
}

Map.propTypes = {
  /** Define a marker on the map. Also it will center map on it. */
  marker: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
  /** Define a current map zoom. */
  zoom: PropTypes.number,
}

Map.defaultProps = {
  marker: {
    lat: -3.745,
    lng: -38.523,
  },
  zoom: 10,
}

export default React.memo(Map)
