import React from 'react'
import Box from '@mui/material/Box'
import HomeLayout from 'views/Home/HomeLayout'

const sx = {
  container: {
    maxHeight: 600,
    overflow: 'hidden',
    overflowY: 'auto',
  },
}

const Layout = ({ header, children }) => (
  <HomeLayout header={header}>
    <Box sx={sx.container}>
      {children}
    </Box>
  </HomeLayout>
)

export default Layout
