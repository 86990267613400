import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { compose } from 'redux'
import { getByID } from 'service/ladies/actions/get'
import { getSelectedLadyProfile } from 'service/ladies/selectors'
import { ladyPhotoURL, ladyVideoURL } from 'navigation/routes'
import { photoAddFavorite, photoRemoveFavorite } from 'service/favoriteMedia/actions/photo'
import ProfileView from './ProfileView'

const LadyVisitorView = ({ ladyProfile, getByID, photoAddFavorite, photoRemoveFavorite }) => {
  const { ladyID } = useParams()

  useEffect(() => {}, [ladyProfile])

  useEffect(() => {
    getByID(ladyID)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleOnFavoritePhoto(item) {
    if (item.favorite) {
      photoRemoveFavorite(item.id, true)
    } else {
      photoAddFavorite(item.id, true)
    }
  }

  return (
    <ProfileView
      profileInfo={ladyProfile}
      photoURL={ladyPhotoURL}
      videoURL={ladyVideoURL}
      onPhotoFavorite={handleOnFavoritePhoto}
      visitor
    />
  )
}


const mapStateToProps = state => ({
  ladyProfile: getSelectedLadyProfile(state),
})

const mapDispatchToProps = {
  getByID,
  photoAddFavorite,
  photoRemoveFavorite,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(LadyVisitorView)
