const styles = theme => ({
  success: {
    backgroundColor: theme.palette.success.main,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  info: {
    backgroundColor: theme.palette.info.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  icon: {
    fontSize: 13,
  },
  closeButton: {
    color: 'inherit',
    opacity: 0.9,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
  },
})

export default styles
