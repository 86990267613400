import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect'
import isEqual from 'lodash/isEqual'
import { ladyPhotoURL } from 'navigation/routes'
import createGeneralPagination from 'utils/pagination'

const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual,
)

const createGeneralInfo = item => ({
  id: item.id,
  age: item.age,
  name: item.firstName,
  from: item.countryName,
  avatar: `${ladyPhotoURL}${item.mediaName}`,
  favorite: item.favorite,
  online: item.online,
  lastVisit: item.lastLogin,
})

const getSelectedLadyInfoShort = createDeepEqualSelector(
  state => state.ladies.profile?.ladyGeneralDTO,
  info => (info ? ({
    ...info,
    label: info.firstName,
    value: info.id,
  }) : {}),
)

const getSelectedLadyProfile = createSelector(
  state => state.ladies.profile,
  profile => ({
    general: profile?.ladyGeneralDTO || {},
    additional: {
      ...profile?.ladyAdditionalDTO,
      lookingFor: profile?.ladyAdditionalDTO?.desiredDescription,
    } || {},
    photos: (profile?.ladyPhotoDTOs || []).map(photo => ({
      ...photo,
      photoUrl: `${ladyPhotoURL}${photo.mediaName}`,
    })),
    videos: (profile?.ladyVideoDTOs || []).map(video => ({
      ...video,
      videoUrl: `${ladyPhotoURL}${video.mediaName}`,
    })),
  }),
)

// general
const getLadies = createDeepEqualSelector(
  state => state.ladies.filterData,
  payload => payload?.map(createGeneralInfo) || [],
)

const getLadiesCount = createSelector(
  state => state.ladies.filterDataCount,
  count => count,
)

const getLadiesFilter = createDeepEqualSelector(
  state => state.ladies.filter,
  filter => filter || {},
)

// favorite
const getFavoriteFilterLadies = createDeepEqualSelector(
  state => state.ladies.filterFavoriteData,
  payload => payload?.map(createGeneralInfo) || [],
)

const getFavoriteFilterLadiesResultCount = createSelector(
  state => state.ladies.filterFavoriteDataCount,
  count => count,
)

const getFavoriteFilterLadiesPaginationInfo = createDeepEqualSelector(
  state => state.ladies.filterFavoriteDataCount,
  state => state.ladies.filterFavorite,
  (count, filter) => createGeneralPagination(count, filter),
)

const getFavoriteFilterLadiesFilterObj = createDeepEqualSelector(
  state => state.ladies.filterFavorite,
  filter => filter,
)

// visitors
const getVisitors = createDeepEqualSelector(
  state => state.ladies.filterVisitorsData,
  payload => payload?.map(createGeneralInfo) || [],
)

const getVisitorsCount = createSelector(
  state => state.ladies.filterVisitorsDataCount,
  count => count,
)

const getVisitorsPagination = createDeepEqualSelector(
  state => state.ladies.filterVisitorsDataCount,
  state => state.ladies.filterVisitors,
  (count, filter) => createGeneralPagination(count, filter),
)

const getVisitorsFilter = createDeepEqualSelector(
  state => state.ladies.filterVisitors,
  filter => filter,
)

// contacts
const getContacts = createDeepEqualSelector(
  state => state.ladies.filterContactsData,
  payload => payload?.map(createGeneralInfo) || [],
)

const getContactsCount = createSelector(
  state => state.ladies.filterContactsDataCount,
  count => count,
)

const getContactsPagination = createDeepEqualSelector(
  state => state.ladies.filterContactsDataCount,
  state => state.ladies.filterContacts,
  (count, filter) => createGeneralPagination(count, filter),
)

const getContactsFilter = createDeepEqualSelector(
  state => state.ladies.filterContacts,
  filter => filter,
)

// top
const getTopFilterLadies = createDeepEqualSelector(
  state => state.ladies.filterTopData,
  state => state.ladies.filterTop,
  (data, filter) => data?.map((item, index) => ({
    ...createGeneralInfo(item),
    index: filter.from + index + 1,
  })) || [],
)

const getTopFilterLadiesResultCount = createSelector(
  state => state.ladies.filterTopDataCount,
  state => state.ladies.topLimit,
  (count, limit) => (count > limit ? limit : count), // request for top 100
)

const getTopFilterLadiesPaginationInfo = createDeepEqualSelector(
  state => state.ladies.filterTopDataCount,
  state => state.ladies.topLimit,
  state => state.ladies.filterTop,
  (count, limit, filter) => ({
    pagesCount: Math.ceil((count > limit ? limit : count) / filter?.size),
    currentPage: filter.from / filter.size + 1,
  }),
)

const getTopFilterLadiesFilterObj = createDeepEqualSelector(
  state => state.ladies.filterTop,
  filter => filter,
)


export {
  getSelectedLadyProfile,
  getSelectedLadyInfoShort,

  getLadies,
  getLadiesCount,
  getLadiesFilter,

  getFavoriteFilterLadies,
  getFavoriteFilterLadiesResultCount,
  getFavoriteFilterLadiesPaginationInfo,
  getFavoriteFilterLadiesFilterObj,

  getTopFilterLadies,
  getTopFilterLadiesResultCount,
  getTopFilterLadiesPaginationInfo,
  getTopFilterLadiesFilterObj,

  getVisitors,
  getVisitorsCount,
  getVisitorsPagination,
  getVisitorsFilter,

  getContacts,
  getContactsCount,
  getContactsPagination,
  getContactsFilter,
}
