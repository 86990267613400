import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { useNavigate } from 'react-router'

import sx from './sx'

const ListMenuItem = ({ label, disableRipple, itemSx, onClick, url, openNewTab, actionCallback }) => {
  const navigate = useNavigate()

  const handleOnClick = useCallback(() => {
    if (openNewTab) {
      actionCallback()

      return
    }

    if (onClick) {
      onClick()

      return
    }

    navigate(url)
    actionCallback()
  }, [actionCallback, navigate, onClick, openNewTab, url])

  return (
    <ListItem
      divider
      sx={openNewTab ? { ...itemSx, color: 'black' } : itemSx}
      {...openNewTab && {
        component: 'a',
        href: url,
        target: '_blank',
      }}
    >
      <ListItemButton
        onClick={handleOnClick}
        disableRipple={disableRipple}
      >
        <ListItemText
          primary={label}
          sx={sx.mobileMenuItemText}
        />
      </ListItemButton>
    </ListItem>
  )
}

ListMenuItem.propTypes = {
  /** Define item label. */
  label: PropTypes.any.isRequired,
  /** Define root component sx. */
  itemSx: PropTypes.object,
  /** Define click action on item. Overrided with [openNewTab] */
  onClick: PropTypes.func,
  /** Define a target page url. */
  url: PropTypes.string.isRequired,
  /** Define if page should be opened in next tab. */
  openNewTab: PropTypes.bool,
  /** Disabe on click ripplie effect. */
  disableRipple: PropTypes.bool,
  /** Pass a function that will be called after action. */
  actionCallback: PropTypes.func,
}

ListMenuItem.defaultProps = {
  itemSx: {},
  openNewTab: false,
  disableRipple: false,
  onClick: undefined,
  actionCallback: () => {},
}

export default ListMenuItem
