import { createAction, handleActions } from 'redux-actions'

const initialState = {
  fetching: false,
  filterBase: {
    from: 0,
    size: 12,
  },
  filter: {},
  data: [],
  videoData: [],
  item: null,
  videoItem: null,
}

// actions
const storiesGetStart = createAction('STORIES_GET_INFO_START')
const storiesGetFail = createAction('STORIES_GET_INFO_FAIL')
const storiesGetDone = createAction('STORIES_GET_INFO_DONE')

const storyGetStart = createAction('STORIES_GET_SINGLE_START')
const storyGetFail = createAction('STORIES_GET_SINGLE_FAIL')
const storyGetDone = createAction('STORIES_GET_SINGLE_DONE')

const videoStoriesGetStart = createAction('VIDEO_STORIES_GET_INFO_START')
const videoStoriesGetFail = createAction('VIDEO_STORIES_GET_INFO_FAIL')
const videoStoriesGetDone = createAction('VIDEO_STORIES_GET_INFO_DONE')

const videoStoryGetStart = createAction('VIDEO_STORIES_GET_SINGLE_START')
const videoStoryGetFail = createAction('VIDEO_STORIES_GET_SINGLE_FAIL')
const videoStoryGetDone = createAction('VIDEO_STORIES_GET_SINGLE_DONE')

const storiesUpdateFilter = createAction('STORIES_FILTER_UPDATE')
const storiesClear = createAction('STORIES_CLEAR')

const videoStoriesUpdateFilter = createAction('VIDEO_STORIES_FILTER_UPDATE')
const videoStoriesClear = createAction('VIDEO_STORIES_CLEAR')

// reducer
const getStoriesStart = state => ({ ...state, fetching: true })
const getStoriesFail = state => ({ ...state, fetching: false })
const getStoriesDone = (state, { payload }) => ({ ...state, fetching: false, data: payload })

const getVideoStoriesStart = state => ({ ...state, fetching: true })
const getVideoStoriesFail = state => ({ ...state, fetching: false })
const getVideoStoriesDone = (state, { payload }) => ({ ...state, fetching: false, videoData: payload })

const getStoryStart = state => ({ ...state, fetching: true })
const getStoryFail = state => ({ ...state, fetching: false })
const getStoryDone = (state, { payload }) => ({ ...state, fetching: false, item: payload })

const getVideoStoryStart = state => ({ ...state, fetching: true })
const getVideoStoryFail = state => ({ ...state, fetching: false })
const getVideoStoryDone = (state, { payload }) => ({ ...state, fetching: false, videoItem: payload })

const updateStoriesFilter = (state, { payload }) => ({
  ...state,
  fetching: false,
  filter: {
    ...state.filter,
    common: payload,
  },
})
const clearStoriesInfo = state => ({ ...state, fetching: false, data: [] })

const updateVideoStoriesFilter = (state, { payload }) => ({
  ...state,
  fetching: false,
  filter: {
    ...state.filter,
    video: payload,
  },
})
const clearVideoStoriesInfo = state => ({ ...state, fetching: false, data: [] })

const reducers = handleActions({
  STORIES_GET_INFO_START: getStoriesStart,
  STORIES_GET_INFO_FAIL: getStoriesFail,
  STORIES_GET_INFO_DONE: getStoriesDone,

  STORIES_GET_SINGLE_START: getStoryStart,
  STORIES_GET_SINGLE_FAIL: getStoryFail,
  STORIES_GET_SINGLE_DONE: getStoryDone,

  VIDEO_STORIES_GET_INFO_START: getVideoStoriesStart,
  VIDEO_STORIES_GET_INFO_FAIL: getVideoStoriesFail,
  VIDEO_STORIES_GET_INFO_DONE: getVideoStoriesDone,

  VIDEO_STORIES_GET_SINGLE_START: getVideoStoryStart,
  VIDEO_STORIES_GET_SINGLE_FAIL: getVideoStoryFail,
  VIDEO_STORIES_GET_SINGLE_DONE: getVideoStoryDone,

  STORIES_FILTER_UPDATE: updateStoriesFilter,
  STORIES_CLEAR: clearStoriesInfo,

  VIDEO_STORIES_FILTER_UPDATE: updateVideoStoriesFilter,
  VIDEO_STORIES_CLEAR: clearVideoStoriesInfo,
}, initialState)

export {
  storiesGetStart,
  storiesGetFail,
  storiesGetDone,

  storyGetStart,
  storyGetFail,
  storyGetDone,

  videoStoriesGetStart,
  videoStoriesGetFail,
  videoStoriesGetDone,

  videoStoryGetStart,
  videoStoryGetFail,
  videoStoryGetDone,

  storiesUpdateFilter,
  storiesClear,

  videoStoriesUpdateFilter,
  videoStoriesClear,
}

export default reducers
