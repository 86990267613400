import { createAction, handleActions } from 'redux-actions'

const initialState = {
  queue: [],
  current: null,
}

const addToQueue = (state, notification) => {
  const queue = [...state.queue]

  // nothing in queue, add directly to current
  if (queue.length === 0 && !state.current) {
    return {
      current: notification,
      queue: [],
    }
  }

  const notInQueue = !queue.some(queuedNotification => queuedNotification.message === notification.message)
  const notCurrent = state.current.message !== notification.message

  // something in current, add new to queue
  if (notInQueue && notCurrent) {
    queue.push(notification)
  }

  return {
    current: state.current,
    queue,
  }
}

const removeFirstFromQueue = state => {
  const queue = [...state.queue]

  // nothing in queue, no action required
  if (queue.length === 0) {
    return {
      current: undefined,
      queue,
    }
  }

  const current = queue.shift()

  return {
    current: current || state.current,
    queue,
  }
}

// actions
const enqueue = createAction('ENQUEUE_NOTIFICATION')
const dequeue = createAction('DEQUEUE_NOTIFICATION')

// reducer
const enqueueNotification = (state, { payload }) => ({ ...state, ...addToQueue(state, payload) })
const dequeueNotification = state => ({ ...state, ...removeFirstFromQueue(state) })

const reducers = handleActions({
  ENQUEUE_NOTIFICATION: enqueueNotification,
  DEQUEUE_NOTIFICATION: dequeueNotification,
}, initialState)

export {
  enqueue,
  dequeue,
}

export default reducers
