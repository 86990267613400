/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/display-name */
import { Button } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

import sx from './sx'

const GoButton = ({ mt, ...props }) => (
  <Button {...props} size="large" sx={sx.root(mt)} />
)

GoButton.propTypes = {
  pt: PropTypes.bool,
}

GoButton.defaultProps = {
  mt: false,
}

export default GoButton
