import React from 'react'

const Services = () => (
  <svg width="25" height="30" viewBox="0 0 35 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.952 28.901v6.0313l11.3714 5.1266M7.952 28.901l-2.305-1.0554 2.305-6.3329m0 7.3883l9.0664 4.0711 2.305-6.3328m0 13.4196V26.6393m0 13.4196l11.5252-5.1266V28.901m-11.5252-2.2617L7.952 21.5127m11.3714 5.1266l2.3051 6.3328 9.2201-4.0711m-11.5252-2.2617l11.5252-5.1266m-22.8966 0l11.3714-5.2773 11.5252 5.2773m0 7.3883l2.1513-1.0554-2.1513-6.3329" stroke="#01124F" strokeWidth="2" />
    <path d="M30.7158 11.9944h0l-.0021-.0061c-.1647-.4896-.4146-.9321-.7519-1.3166a3.6239 3.6239 0 00-1.2116-.9048 3.6231 3.6231 0 00-1.474-.3378l-.0158.9766.0158-.9766a3.784 3.784 0 00-1.1801.1695 3.7848 3.7848 0 00-.6346-1.0092 3.6228 3.6228 0 00-1.2116-.9049 3.6231 3.6231 0 00-1.474-.3378c-.5114-.0082-1.0104.0877-1.4903.2791h0l-.006.0024a4.017 4.017 0 00-.9128.5175l-.0001-.0001-.0091.0071a4.2698 4.2698 0 00-.7208.7075h0l-.008.0102a4.3656 4.3656 0 00-.5217.856 4.346 4.346 0 00-.3124.9466l-.0016.008-.0016.0081a4.3005 4.3005 0 00-.0698.9989h0l.0006.0116c.0201.3512.0861.6954.1965 1.0308l.0002.0005 2.0493 6.2108.335 1.0151.9905-.4018 6.0606-2.4582.0005-.0002a3.8762 3.8762 0 00.9127-.5231 3.973 3.973 0 00.7261-.7099 4.1576 4.1576 0 00.5291-.8551 4.1906 4.1906 0 00.3114-.9602 3.9673 3.9673 0 00.0737-1.0196 3.882 3.882 0 00-.1921-1.0343z" fill="#fff" stroke="#01124F" strokeWidth="2" />
    <path d="M12.8227 3.2156h0l-.0058-.0028c-.4684-.218-.9612-.3418-1.4723-.3622a3.6222 3.6222 0 00-1.4906.2546 3.6225 3.6225 0 00-1.2604.8354l.6969.6845-.697-.6845a3.784 3.784 0 00-.6902.972 3.7845 3.7845 0 00-1.1687-.2354 3.6228 3.6228 0 00-1.4906.2545 3.6227 3.6227 0 00-1.2605.8355c-.3584.365-.6326.7927-.8246 1.2723h0l-.0024.006a4.0178 4.0178 0 00-.254 1.0181h0l-.0012.0115a4.272 4.272 0 00.0161 1.0099h0l.0018.0128c.0494.3316.1367.6546.2609.9679a4.341 4.341 0 00.4708.8786l.0046.0068.0049.0067c.1951.2715.4211.5179.6758.7389h0l.0088.0075c.2683.2274.5632.4168.8824.5679l.0005.0002 5.9131 2.7944.9665.4567.3913-.9947 2.3946-6.086.0002-.0005a3.8807 3.8807 0 00.2498-1.022 3.978 3.978 0 00-.0141-1.0152 4.163 4.163 0 00-.2552-.9727 4.1919 4.1919 0 00-.4813-.8873 3.9653 3.9653 0 00-.6881-.756 3.88 3.88 0 00-.882-.5734z" stroke="#01124F" strokeWidth="2" />
  </svg>
)

export default Services
