import React from 'react'
import {
  TableRow,
  TableCell,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

const LadiesTableRow = ({ item }) => {
  const { t } = useTranslation('text')
  return (
    <TableRow>
      <TableCell>
        {item.id}
      </TableCell>
      <TableCell>
        {item.name}
      </TableCell>
      <TableCell align="right">
        {item.from}
      </TableCell>
      <TableCell align="right">{t('profile.fields.mail')}</TableCell>
      <TableCell align="right">{t('profile.fields.letter')}</TableCell>
    </TableRow>
  )
}

export default LadiesTableRow
