import React from 'react'
import Section from 'components/Section'
import { withTranslation } from 'react-i18next'
import { getNotificationsSettings } from 'service/man/selectors'
import { updateNotificaitons } from 'service/man/actions/me'
import { connect } from 'react-redux'
import { Button, FormControlLabel, Typography } from '@mui/material'
import Checkbox from 'components/Checkbox'
import { compose } from 'redux'
import { withStyles } from '@mui/styles'
import styles from './styles'

const checkList = [
  {
    field: 'newLadies',
    label: 'settings.emailNotifications.manChecks.newLadies',
  },
  {
    field: 'visitor',
    label: 'settings.emailNotifications.manChecks.visitor',
  },
  {
    field: 'vote',
    label: 'settings.emailNotifications.manChecks.vote',
  },
  {
    field: 'letter',
    label: 'settings.emailNotifications.manChecks.letter',
  },
  {
    field: 'gift',
    label: 'settings.emailNotifications.manChecks.gift',
  },
  {
    field: 'birthday',
    label: 'settings.emailNotifications.manChecks.birthday',
  },
  {
    field: 'profileUpdate',
    label: 'settings.emailNotifications.manChecks.profileUpdate',
  },
  {
    field: 'profileUpdateFavorite',
    label: 'settings.emailNotifications.manChecks.profileUpdateFavorite',
  },
  {
    field: 'videoUpload',
    label: 'settings.emailNotifications.manChecks.videoUpload',
  },
  {
    field: 'videoUploadFavorite',
    label: 'settings.emailNotifications.manChecks.videoUploadFavorite',
  },
]

class ManEmailNotifications extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      newLadies: props.notifications.newLadies,
      visitor: props.notifications.visitor,
      vote: props.notifications.vote,
      letter: props.notifications.letter,
      gift: props.notifications.gift,
      birthday: props.notifications.birthday,
      profileUpdate: props.notifications.profileUpdate,
      profileUpdateFavorite: props.notifications.profileUpdateFavorite,
      videoUpload: props.notifications.videoUpload,
      videoUploadFavorite: props.notifications.videoUploadFavorite,
    }
  }

  handleOnChange = event => this.setState({ [event.target.name]: !this.state[event.target.name] })

  handleOnSave = () => this.props.updateNotificaitons(this.state)

  renderCheckbox = item => (
    <FormControlLabel
      key={item.field}
      name={item.field}
      label={this.props.t(item.label)}
      control={<Checkbox />}
      checked={this.state[item.field]}
      onChange={this.handleOnChange}
    />
  )

  render() {
    const { t, classes } = this.props

    return (
      <Section title={t('settings.emailNotifications.title')}>
        <Typography className={classes.description}>
          {t('settings.emailNotifications.description')}
        </Typography>
        <div className={classes.checkboxContainer}>
          {checkList.map(this.renderCheckbox)}
        </div>
        <div className={classes.actions}>
          <Button onClick={this.handleOnSave}>
            {t('settings.emailNotifications.action.update')}
          </Button>
        </div>
      </Section>
    )
  }
}

const mapStateToProps = state => ({
  notifications: getNotificationsSettings(state),
})

const mapDispatchToProps = {
  updateNotificaitons,
}


export default compose(
  withStyles(styles),
  withTranslation('text'),
  connect(mapStateToProps, mapDispatchToProps),
)(ManEmailNotifications)
