import React from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import './styles.scss'
import { PHOTO } from 'consts/thubnails'
import Api from 'api/Api'
import {
  Grid,
  Dialog,
  Typography,
  Button,
  Box,
} from '@mui/material'
import ContentHorizontalScroll from 'components/ContentHorizontalScroll'
import { ArrowLeft, ArrowRight, Close, FileDownload } from '@mui/icons-material'
import sx from './sx'

const TYPE = {
  VIDEO: 'video',
  PHOTO: 'photo',
}

const closableClassnames = ['view-media-content', 'view-media-container']

const getURL = (type, item) => (type === TYPE.PHOTO
  ? `${item.photoUrl}${PHOTO.NORMAL}`
  : `${item?.videoUrl}/video.${item?.extension}`)

/**
 * ViewMedia. Works only with ProfilePhotoCard.jsx and ProfileVideoCard.jsx
 */
// const ViewMedia = ({ selectedItem, selectedIndex, type, items, title, show, onClose }) => {
class ViewMedia extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedItemIndex: -1,
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.show !== this.props.show && nextProps.show) {
      this.setState({
        selectedItemIndex: nextProps.selectedIndex,
      })

      return false
    }

    return true
  }

  handleOnClose = () => this.props.onClose()


  handleOnContainerClick = event => {
    if (closableClassnames.indexOf(event.target.className) !== -1) {
      this.handleOnClose()
    }
  }

  handleSelecteNext = () => {
    const { items } = this.props
    const { selectedItemIndex } = this.state

    const itemsLength = items.length
    const nextIndex = itemsLength === selectedItemIndex + 1 ? 0 : selectedItemIndex + 1

    this.setState({ selectedItemIndex: nextIndex })
  }

  handleSelectePrev = () => {
    const { items } = this.props
    const { selectedItemIndex } = this.state

    const itemsLength = items.length
    const nextIndex = selectedItemIndex - 1 === -1 ? itemsLength - 1 : selectedItemIndex - 1

    this.setState({ selectedItemIndex: nextIndex })
  }

  handleOnDownload = () => {
    const { type, items } = this.props
    const { selectedItemIndex } = this.state

    const filename = type === TYPE.PHOTO ? 'photo.jpg' : 'video.mp4'

    Api.fileDownload(getURL(type, items[selectedItemIndex]), filename)
  }

  handleOnSelect = index => this.setState({ selectedItemIndex: index })

  renderContent = (type, item, url) => (
    <Box sx={sx.mediaView}>
      {type === TYPE.VIDEO ?
        (
          <ReactPlayer
            style={sx.photo}
            height="100%"
            width="auto"
            url={url}
            controls
          />
        ) : (

          <img
            src={url}
            alt={url}
            style={sx.photo}
          />
        )}
    </Box>
  )


  render() {
    const { show, type, title, items } = this.props
    const { selectedItemIndex } = this.state

    return (
      <Dialog
        open={show}
        onClick={this.handleOnContainerClick}
        PaperProps={{
          sx: sx.paper,
        }}
        fullScreen
      >
        <Grid container sx={sx.header}>
          <Grid item xs={4} />
          <Grid item container justifyContent="center" xs={4}>
            <Typography variant="h4" sx={sx.title}>
              {title}
            </Typography>
          </Grid>
          <Grid item container justifyContent="end" xs={4}>
            <Button
              variant="text"
              sx={sx.headerAction}
              onClick={this.handleOnDownload}
            >
              <FileDownload fontSize="large" />
            </Button>
            <Button
              variant="text"
              sx={sx.headerAction}
              onClick={this.handleOnClose}
            >
              <Close fontSize="large" />
            </Button>
          </Grid>
        </Grid>

        <Grid container sx={sx.mediaViewContainer}>
          <Grid item>
            <Button
              variant="text"
              sx={sx.mediaViewActions}
              onClick={this.handleSelectePrev}
            >
              <ArrowLeft fontSize="large" />
            </Button>
          </Grid>
          <Grid item container justifyContent="center">
            {this.renderContent(type, items[selectedItemIndex], getURL(type, items[selectedItemIndex]))}
          </Grid>
          <Grid item>
            <Button
              variant="text"
              sx={sx.mediaViewActions}
              onClick={this.handleSelecteNext}
            >
              <ArrowRight fontSize="large" />
            </Button>
          </Grid>
        </Grid>

        <Grid sx={sx.carousell}>
          {items.length > 1 && <ContentHorizontalScroll
            type={type}
            items={items}
            onSelect={this.handleOnSelect}
            centerItems
          />}
        </Grid>
      </Dialog>
    )
  }
}

ViewMedia.propTypes = {
  /** Content type to loaded. */
  type: PropTypes.string,
  /** Index of media that must be opened. */
  selectedIndex: PropTypes.number,
  /** List of items to switch between. */
  items: PropTypes.any.isRequired,
  /** View title. */
  title: PropTypes.string.isRequired,
  /** State of the view. */
  show: PropTypes.bool.isRequired,
  /** Callback when view ready to close. */
  onClose: PropTypes.func.isRequired,
}

ViewMedia.defaultProps = {
  type: TYPE.VIDEO,
  url: '',
}

export default ViewMedia

export {
  TYPE,
}
