import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Paragraph from 'components/Paragraph'
import Typography from '@mui/material/Typography'
import Layout from './Layout'

const PointText = ({ items }) => {
  if (!items || items.length === 0) {
    return null
  }

  return items.map(item => (
    <>
      <Paragraph>{item}</Paragraph>
      <br />
    </>
  ))
}

const Point = ({ content, useBottomBR = true }) => (
  <>
    <Typography variant="h6">{content.header}</Typography>
    <br />
    <PointText items={content?.subpoints} />
    {useBottomBR ? <br /> : null}
  </>
)

const QuestionsView = () => {
  const { t } = useTranslation('home')

  const content = useMemo(() => t('pages.faq.content', { returnObjects: true }).map(item => (
    <>
      <Point content={item} />
    </>
  )), [t])

  return (
    <Layout header={t('pages.faq.header')}>
      {content}
      <Paragraph component="a" href="http://dreamonelove.com/#!NEWAGENCY">
        {t('pages.faq.footer')}
      </Paragraph>
    </Layout>

  )
}

export default QuestionsView
