import React from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, Typography } from '@mui/material'
import getOnlineStatusText from 'utils/onlineStatus'
import { useTranslation } from 'react-i18next'
import Edit from '@mui/icons-material/EditOutlined'
import styles from './styles'
import GeneralInfoSection from './GeneralInfoSection'
import AdditionalInfoSection from './AdditionalInfoSection'
import RestInfoSection from './RestInfoSection'
import MediaSection from './MediaSection'

const ProfileView = ({ profileInfo, visitor, photoURL, videoURL, onPhotoFavorite, onEdit }) => {
  const { t } = useTranslation('text')
  const sx = styles()

  const { general, additional, photos, videos } = profileInfo

  return (
    <Grid>
      {/* Header */}
      <Grid container>
        <Grid item container xs={6}>
          <Grid container direction="row">
            <Typography variant="h6" sx={sx.name}>{`${general.firstName} ${general.lastName || ''}`}</Typography>
            <Typography variant="h6" sx={sx.id}>{general.id}</Typography>
          </Grid>
          {visitor &&
          <Typography>
            {getOnlineStatusText(t, general.lastLogin, general.online)}
          </Typography>}
        </Grid>
        <Grid item container xs={6} justifyContent="flex-end">
          {
            !visitor &&
            <Button size="small" onClick={onEdit} startIcon={(<Edit />)}>
              {t('profile.actions.edit')}
            </Button>
          }
        </Grid>
      </Grid>

      <GeneralInfoSection
        general={general}
        additional={additional}
        photoURL={photoURL}
        visitor={visitor}
      />

      <MediaSection
        photos={photos}
        videos={videos}
        photoURL={photoURL}
        videoURL={videoURL}
        visitor={visitor}
        onPhotoFavorite={onPhotoFavorite}
      />

      <AdditionalInfoSection
        additional={additional}
      />

      <RestInfoSection
        additional={additional}
      />
    </Grid>
  )
}

ProfileView.propTypes = {
  profileInfo: PropTypes.object,
  visitor: PropTypes.bool,
  photoURL: PropTypes.string,
  videoURL: PropTypes.string,
}

ProfileView.defaultProps = {
  visitor: false,
  photoURL: '',
  videoURL: '',
  onEdit: () => {},
}

export default ProfileView
