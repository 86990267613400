export default theme => ({
  closeButton: {
    fontWeight: 700,
    padding: 6,
    borderRadius: 6,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
  },
  updateMessageContainer: {
    backgroundColor: '#4fa745',
  },
})
