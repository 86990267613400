import { createSelectorCreator, defaultMemoize, createSelector } from 'reselect'
import isEqual from 'lodash/isEqual'

const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual,
)

const getCountriesCount = createSelector(
  state => state.country.list.count,
  count => count,
)

const getCountriesOptions = createDeepEqualSelector(
  state => state.country.list.data,
  data => data?.map(item => ({
    value: item.id,
    label: item.name.trim(),
  })) || [],
)

export {
  getCountriesCount,
  getCountriesOptions,
}
