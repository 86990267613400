import AuthApi from 'api/AuthApi'
import { userSignInDone, userSignInFail, userSignInStart, userSignUpDone, userSignUpFail, userSignUpStart } from 'redux/reducers/auth'
import enqueueMessage from 'service/notification/enqueue'
import { removeToken, storeToken } from 'utils/auth'
import newPromise from 'utils/newPromise'

import { mapSignInToApi, mapSignUpToApi } from '../mapper'

import { validateSignUp } from './validation'

const singIn = data => dispatch => {
  dispatch(userSignInStart())

  return AuthApi.signIn(mapSignInToApi(data))
    .then(data => {
      if (data.errMsg) {
        throw Error(data.message || data.errMsg)
      }

      storeToken(data.token)

      return dispatch(userSignInDone({
        logged: true,
        loginAsMan: data.loginAsMan,
      }))
    })
    .catch(error => {
      dispatch(userSignInFail())

      return Promise.reject()
    })
}

const signUp = data => dispatch => {
  dispatch(userSignUpStart())

  return newPromise(validateSignUp, data)
    .then(() => AuthApi.signUp(mapSignUpToApi(data)))
    .then(data => {
      if (data.errMsg) {
        throw Error(data.message || data.errMsg)
      }

      storeToken(data.token)

      return dispatch(userSignUpDone({
        logged: true,
        loginAsMan: true,
      }))
    })
    .catch(error => {
      dispatch(enqueueMessage(error.message, 'danger'))

      dispatch(userSignUpFail())

      return Promise.reject()
    })
}

const signOut = () => dispatch => {
  dispatch(userSignInStart())

  return AuthApi.signIn()
    .then(data => {
      if (data.errMsg) {
        throw Error(data.message || data.errMsg)
      }

      removeToken()

      return dispatch(userSignInDone())
    })
    .catch(error => {
      dispatch(userSignInFail())

      return Promise.reject()
    })
}

export {
  singIn,
  signUp,
  signOut,
}
