import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { connect } from 'react-redux'
import Image from 'components/Image'
import {
  getShortVideoStories,
  clearVideoStories,
} from 'service/home/actions/stories'
import { getVideoStories } from 'service/home/selectors/stories'
import Carousel from 'components/Caruosel'
import GoButton from 'components/GoButton'
import { YouTube } from '@mui/icons-material'
import { appURLs } from 'navigation/routes'
import { useNavigate } from 'react-router'
import sx from './sx'
import HomeSection from '../HomeSection'

const Item = ({ item, primary, onClick }) => {
  const handleOnClick = useCallback(() => {
    if (primary) {
      onClick(item)
    }
  }, [item, onClick, primary])

  return (
    <Box sx={sx.container(primary)} onClick={handleOnClick}>
      {primary &&
      <Box sx={sx.hover}>
        <YouTube sx={sx.icon} />
      </Box>}
      <Image
        src={item.thumbUrl}
        fullHeight
        fullWidth
        useBackground
      />
    </Box>
  )
}


const Feedback = ({ stories, getShortVideoStories, clearVideoStories }) => {
  const { t } = useTranslation(['home', 'common'])
  const navigate = useNavigate()

  useEffect(() => {
    clearVideoStories().then(getShortVideoStories)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnClick = useCallback(item => navigate(`${appURLs.HOME_FEEDBACK_DETAILS_BASE}${item.id}`), [navigate])
  const handleOnMoreClick = useCallback(() => navigate(`${appURLs.HOME_FEEDBACKS_OVERVIEW}`), [navigate])

  const renderItem = useCallback((item, primary, onClick) => (
    <Item
      item={item}
      primary={primary}
      onClick={onClick}
    />
  ), [])

  if (stories.length === 0) {
    return null
  }

  return (
    <HomeSection title={t('sections.stories.video.title')}>
      <Carousel
        items={stories}
        itemKey="id"
        labelKey="author"
        onClick={handleOnClick}
        renderItem={renderItem}
      />
      <Grid container justifyContent="center">
        <GoButton mt onClick={handleOnMoreClick}>{t('general.more', { ns: 'common' })}</GoButton>
      </Grid>
    </HomeSection>
  )
}

const mapStateToProps = state => ({
  stories: getVideoStories(state),
})

const mapDispatchToProps = {
  getShortVideoStories,
  clearVideoStories,
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback)
