import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  filterNewest as filterLadies,
  updateFilter,
  clearData,
} from 'service/ladies/actions/filter'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getAuthUserInfo } from 'service/auth/selectors'
import { ladiesAddFavorite, ladiesRemoveFavorite } from 'service/ladies/actions/favorite'
import {
  getLadies,
  getLadiesCount,
  getLadiesFilter,
} from 'service/ladies/selectors'
import LadiesResultSection from './LadiesResultSection/LadiesResultSection'

const baseFilter = {
  from: 0,
  size: 6,
}

const LadiesNewestSearch = ({ items, count, filterLadies, filter,
  ladiesRemoveFavorite, ladiesAddFavorite, updateFilter, clearData }) => {
  const { t } = useTranslation('text')

  useEffect(() => {
    clearData()
      .then(() => updateFilter(baseFilter, 'newest'))
      .then(() => filterLadies('newest'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleOnFavoriteClick(item) {
    const resultFunc = item.favorite
      ? ladiesRemoveFavorite
      : ladiesAddFavorite

    resultFunc(item.id).then(() => filterLadies('newest'))
  }

  // filter

  function handleOnFilterChanged(data, run = false) {
    updateFilter(data, 'newest')
      .then(() => {
        if (run) {
          filterLadies('newest')
        }
      })
  }

  return (
    <LadiesResultSection
      data={items}
      filter={filter}
      itemsCount={count}
      title={t('ladies.search.title.newest')}
      onFilterChange={handleOnFilterChanged}
      onFavorite={handleOnFavoriteClick}
    />
  )
}


const mapStateToProps = state => ({
  userInfo: getAuthUserInfo(state),
  items: getLadies(state),
  count: getLadiesFilter(state),
  filter: getLadiesCount(state).newest,
})

const mapDispatchToProps = {
  updateFilter,
  filterLadies,
  clearData,
  ladiesAddFavorite,
  ladiesRemoveFavorite,
}


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(LadiesNewestSearch)
