import React from 'react'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

import headerImage from 'assets/home/partners.png'

import HomeLayout from '../HomeLayout'

const ForPartnersView = () => {
  const { t } = useTranslation('home')

  return (
    <HomeLayout
      image={headerImage}
      header={t('pages.partners.header')}
      title={t('pages.partners.title')}
      margin
      useTitle
    >
      <Stack rowGap={8}>
        <Typography textAlign="center" whiteSpace="pre-line">
          {t('pages.partners.text')}
          <a href="mailto:dreamonelove.official@gmail.com">dreamonelove.official@gmail.com</a>
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <TextField
              label={t('pages.partners.field.phone')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={t('pages.partners.field.email')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              label={t('pages.partners.field.content')}
              rows={6}
              multiline
              fullWidth
            />
          </Grid>
        </Grid>
        <Button>
          {t('pages.partners.action.send')}
        </Button>
      </Stack>
    </HomeLayout>
  )
}

export default ForPartnersView
