import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import IconButton from '@mui/material/IconButton'
import Snackbar from '@mui/material/Snackbar'
import SnackbarContent from '@mui/material/SnackbarContent'
import { withStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import Close from '@mui/icons-material/CloseOutlined'
import dequeueMessage from 'service/notification/dequeue'
import styles from './styles'

const duration = 3000

class Notification extends React.PureComponent {
  closeTimeout = null

  constructor() {
    super()

    this.state = {
      isOpen: false,
      isHidden: true,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.current && prevState.isHidden) {
      return {
        isOpen: true,
        isHidden: false,
      }
    }
    return null
  }

  handleOnEntered = () => {
    this.closeTimeout = setTimeout(() => this.handleOnClose(), duration)
  }

  handleOnClose = () => {
    clearTimeout(this.closeTimeout)
    this.setState({ isOpen: false })
  }

  handleOnExited = () => this.setState({ isHidden: true }, this.props.dequeueMessage)

  renderSnackbarActions = () => (
    <IconButton
      className={this.props.classes.closeButton}
      key="close"
      onClick={this.handleOnClose}
    >
      <Close className={this.props.classes.icon} />
    </IconButton>
  )

  renderSnackbarMessage = message => {
    const { t, classes } = this.props

    return (
      <Typography className={classes.message} variant="body1">
        {t(message)}
      </Typography>
    )
  }

  renderSnackbarContent = message => (
    <SnackbarContent
      classes={{ root: this.props.classes[message.type === 'danger' ? 'error' : message.type] }}
      message={this.renderSnackbarMessage(message.message)}
      action={this.renderSnackbarActions()}
    />
  )

  render() {
    const { current } = this.props
    const { isOpen } = this.state

    if (!current) {
      return null
    }

    return (
      <Snackbar
        id="snackbar-content"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={isOpen}
        TransitionProps={{
          onClose: this.handleOnClose,
          onEntered: this.handleOnEntered,
          onExited: this.handleOnExited,
        }}
      >
        {this.renderSnackbarContent(current)}
      </Snackbar>
    )
  }
}

const mapStateToProps = state => ({
  current: state.notification.current,
})

const mapDispatchToProps = {
  dequeueMessage,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('text'),
  withStyles(styles),
)(Notification)
