import React, { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import socials from 'consts/socials'
import { Grid, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { appURLs } from 'navigation/routes'
import Container from 'components/Container'
import { useTranslation } from 'react-i18next'
import { compose } from 'redux'
import { getAuthUserInfo } from 'service/auth/selectors'
import { connect } from 'react-redux'
import HomeNavigationButton from 'components/HomeNavigationButton'
import styles from './styles'


const Footer = ({ classes, authInfo }) => {
  const { t } = useTranslation('headerFooter')

  const navItems = useMemo(() => [
    {
      link: appURLs.HOME_INFO_TERMS,
      label: 'Terms',
    },
    {
      link: appURLs.HOME_CONTACT_US,
      label: t('navigation.contact'),
    },
    {
      link: appURLs.HOME_ABOUT,
      label: t('navigation.about'),
    },
    {
      link: appURLs.HOME_BLOG,
      label: t('navigation.blog'),
      openNewTab: true,
    },
  ], [t])


  const renderGrid = (content, lg = 4) => (
    <Grid className={classes.item} textAlign="center" item lg={lg} md={6} xs={12}>
      {content}
    </Grid>
  )

  const renderSubFooter = () => (
    <Container>
      <Typography variant="subtitle1" className={classes.unsignedContent}>
        {t('footer.sub.text')}
      </Typography>
    </Container>
  )

  return (
    <footer>
      {!authInfo.logged && renderSubFooter()}
      <div className={classes.content}>
        <Container>
          <Grid container rowSpacing={2}>
            {renderGrid(navItems.map(item => (
              <HomeNavigationButton
                key={item.name}
                label={item.label}
                url={item.link}
                openNewTab={item.openNewTab}
                size="small"
              />
            )))}
            {renderGrid(socials.map(item => (
              <a
                key={item.type}
                className={classes.socialNetworkItem}
                href={item.link}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={['fab', item.type]} size="2x" fixedWidth />
              </a>
            )))}
            {renderGrid(
              <Typography>
                <a href={appURLs.HOME} className={classes.dreamOneLoveLink}>
                  <span className={classes.wordDream}>dream</span>
                  <span className={classes.wordOne}>one</span>
                  <span className={classes.wordLove}>love</span>
                  <span>.com</span>
                </a>
              </Typography>,
              2, // grid lg size
            )}
            {renderGrid(
              <Typography>
                {`2012-${new Date().getFullYear()}`}
              </Typography>,
              2, // grid lg size
            )}
          </Grid>
        </Container>
      </div>
    </footer>
  )
}

const mapStateToProps = state => ({
  authInfo: getAuthUserInfo(state),
})

export default compose(
  withStyles(styles),
  connect(mapStateToProps, null),
)(Footer)
