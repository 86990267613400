import { createAction, handleActions } from 'redux-actions'

// TODO user type (man, woman) is required in future
const initialState = {
  fetching: false,
  list: {},
  filter: {
    from: 0,
    size: 0,
    sortName: '',
    sortType: '',
  },
}

// actions
const countryGetListStart = createAction('COUNTRY_GET_LIST_START')
const countryGetListFail = createAction('COUNTRY_GET_LIST_FAIL')
const countryGetListDone = createAction('COUNTRY_GET_LIST_DONE')

const countryListFilterUpdate = createAction('COUNTRY_LIST_FILTER_UPDATE')


// reducer
const getListStart = state => ({ ...state, fetching: true })
const getListFail = state => ({ ...state, fetching: false })
const getListDone = (state, { payload }) => ({ ...state, fetching: false, list: payload })

const listFilterUpdate = (state, { payload }) => ({ ...state, filter: payload })

const reducers = handleActions({
  COUNTRY_GET_LIST_START: getListStart,
  COUNTRY_GET_LIST_FAIL: getListFail,
  COUNTRY_GET_LIST_DONE: getListDone,

  COUNTRY_LIST_FILTER_UPDATE: listFilterUpdate,
}, initialState)

export {
  countryGetListStart,
  countryGetListFail,
  countryGetListDone,

  countryListFilterUpdate,
}

export default reducers
