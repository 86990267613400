import Api from './Api'

class MediaApi {
  static filterFavoritePhotos = filter => {
    const path = `${Api.basePath}favorite/photos`
    return Api.post(path, filter).then(response => response.json())
  }

  static filterFavoriteVideos = filter => {
    const path = `${Api.basePath}favorite/videos`
    return Api.post(path, filter).then(response => response.json())
  }

  static addPhotoFavorite = id => {
    const path = `${Api.basePath}favorite/photo/${id}`
    return Api.post(path).then(response => (`${response.status}`[0] !== '2' ? response.json() : response.text()))
  }

  static removePhotoFavorite = id => {
    const path = `${Api.basePath}favorite/photo/${id}`
    return Api.delete(path).then(response => (`${response.status}`[0] !== '2' ? response.json() : response.text()))
  }
}

export default MediaApi
