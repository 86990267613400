import { grey } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'
import MuiPagination from './MuiPagination'

const primary = {
  main: '#ff0000',
  light: '#ff0000ad',
  darken: '#d20000',
}

const link = {
  main: '#2f80ed',
}

const font = {
  main: '#01124f',
}

const onlineStatus = {
  online: 'green',
  offline: 'red',
}

const applicationTheme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          padding: 24,
          boxShadow: '0 0 20px 0 #00217726',
        },
      },
    },
    MuiAccordion: {
      variants: [
        {
          props: { variant: 'sidebar' },
          style: {
            boxShadow: '0 0 20px 0 #00217726',
          },
        },
      ],
    },

    MuiSelect: {
      defaultProps: {
        MenuProps: {
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        },
      },
      styleOverrides: {
        icon: {
          top: 3,
          right: 8,
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          'paddingTop': 8,
          'paddingBottom': 8,
          '&:hover': {
            backgroundColor: '#ff0000',
            color: 'white',
          },
          '&.Mui-selected': {
            'backgroundColor': '#ff0000ad',
            'color': 'white',

            '&:hover': {
              backgroundColor: '#ff0000',
              color: 'white',
            },
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          maxHeight: 300,
          padding: 0,
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: '-13px',
          left: 0,
          transform: 'top',
          padding: '0 2px',
          margin: 0,
        },
        shrink: {
          fontSize: '1.4rem',
          top: '-20px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          'borderRadius': '20px 20px 20px 20px',
        },
        // TODO something strange, invesigation required
        notchedOutline: {
          '& > *': {
            display: 'none',
          },
          '& > legend': {
            display: 'none',
          },
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        input: {
          paddingLeft: 18,
          fontWeight: 500,
          // // TODO workaround
          paddingTop: 9,
          marginTop: '-6px',
          paddingBottom: 8,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'online' },
          style: {
            color: grey[600],
            fontWeight: 500,
            // fontSize: '1.25rem ',
          },
        },
        {
          props: { variant: 'title' },
          style: {
            fontWeight: 300,
            fontSize: '1.5rem ',
            fontFamily: 'Montserrat,sans-serif',
          },
        },
      ],
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          borderRadius: 16,
          wordBreak: 'normal',
          fontWeight: 700,
        },
        iconSizeSmall: {
          marginRight: 4,
          marginLeft: 0,
        },
      },
      variants: [
        {
          props: { variant: 'toolbar' },
          style: {
            padding: 0,
            width: 32,
            height: 32,
            minWidth: 0,
            minHeight: 0,
            borderRadius: 8,
            color: grey[700],
          },
        },
        {
          props: { variant: 'large' },
          style: {
            'padding': '16px 32px',
            'background': primary.main,
            'color': 'white',
            ':hover': {
              background: primary.darken,
            },
          },
        },
      ],
    },

    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          padding: '4px 0',
          maxHeight: 284,
        },
        input: {
          paddingLeft: '12px !important', // Didn't found any other way
          fontWeight: 500,
          // // TODO workaround
          paddingTop: 9,
          marginTop: '-6px',
          paddingBottom: 8,
        },
        option: {
          'paddingTop': 8,
          'paddingBottom': 8,
          '&:hover': {
            backgroundColor: '#ff0000',
            color: 'white',
          },
          '&.Mui-selected': {
            'backgroundColor': '#ff0000ad',
            'color': 'white',

            '&:hover': {
              backgroundColor: '#ff0000',
              color: 'white',
            },
          },
        },
        endAdornment: {
          top: 1,
        },
      },
    },
  },

  shape: {
    borderRadius: 16,
  },

  palette: {
    primary,
    link,
    font: {
      main: '#01124f',
    },
    status: {
      online: 'green',
      offline: 'red',
    },
  },
  typography: {
    fontFamily: 'Montserrat,sans-serif',
    // htmlFontSize: 12,
    // fontSize: 18,
  },
})

applicationTheme.components = {
  ...applicationTheme.components,
  ...MuiPagination(applicationTheme),
}

export default applicationTheme

export {
  primary,
  link,
  onlineStatus,
}
