export default {
  about: {
    header: 'About us',
    content: [
      'DreamOneLove was founded in December 2011 by a group of private investors from Ukraine and Great Britain. This is an online dating service that provides an opportunity for men from all over the world to meet beautiful girls from Ukraine.',
      'Looking Today for gorgeous Ukrainian women? It is easier than you think. Dream One Love is the best dating site for men seeking Ukrainian women.',
      'The site serves as a universal source for searching, connecting and communicating with unmarried Ukrainians every day. Dream One Love offers many services, including video chat, voice chat, and gift and flower delivery services.',
      'Register today without paying anything. Registration is free and takes only a few seconds! Check unlimited user profiles anywhere, anytime. You can take the first step or wait until the amazing girl comes up; the choice is yours!',
    ],
    link: 'Watch us at ',
    footer: 'Meeting single Ukrainian women has never been easier. Find your couple today.',
    dreamone: 'All the best, DreamOneLove Team',
  },
  partners: {
    header: 'For partners',
    title: 'If you are dating/marriage agency we can offer you to become our partner',
    text: 'Please provide more information about your agency with your phone number to\n contact you. Or contact us at ',
    field: {
      phone: 'Phone number',
      email: 'E-mail',
      content: 'Information about your agency',
    },
    action: {
      send: 'Send',
    },
  },
  contact: {
    login: {
      text: 'If you have some suggestions or complaints, sign in the site and compose a letter to site Administration',
      action: 'Login',
    },
    write: {
      title: 'Or write us a letter',
      field: {
        name: 'Your name',
        email: 'E-mail',
        message: 'Message',
      },
      action: {
        send: 'Send message',
      },
    },
    info: {
      field: {
        email: 'Email',
        address: 'Address',
        info: 'Info',
        phone: 'Phone',
      },
    },
  },
  faq: {
    header: 'Frequently Asked Questions',
    content: [
      {
        header: 'Why would I want to use your service?',
        subpoints: [
          "Our site was made for men who wish to explore all of their options when choosing a beloved or close friend. Due to today's technology we are able to bring people from different cultures and backgrounds together on our site. By viewing each woman's photos, and reading their profiles, you can decide whom you would like to correspond with, to have phone conversations and to communicate live (using our service Chat-on-line). Also, you can make gifts to your  special lady (using our service gifts and flowers).We continuously contact and update our database of women in order to offer you the best possible chance for a successful relationship! If you would like to have a personal meeting with a lady, our site will gladly help you to arrange a meeting in her hometown.",
        ],
      },
      {
        header: 'Are the women on your site really interested in me, or just leaving their homeland?',
        subpoints: [
          'There are a variety of reasons as to why the women choose to join our site. It has been our experience that the primary objective of these women is to find a loyal, dependable, stable man with whom they can build a happy and loving family. These women are now searching outside of their country for this because for whatever reason or reasons they have not yet been able to find that one special person. A family is very important to the vast majority of these women, thus they are forced to expand their search for their beloved. The Internet has also opened up a whole new world of opportunities to these women. They are no longer confined to their own cities and towns when searching for a beloved. They can now search the world over for the right person.',
        ],
      },
      {
        header: 'Are the women only looking for wealthy men?',
        subpoints: [
          'Due to the culture and harsh realities of everyday life, the expectations and wants of most women are extremely practical and realistic. They view strong family and traditional values as being important factors when choosing a life mate. Of course, like women everywhere, they want to fall in love. The facts in reference to marriage are that women seek mature, older, healthy, intelligent, successful, stable men with the ability to protect and provide for the family, and in all circumstances each of us seek a comfortable and happy home life.',
        ],
      },
      {
        header: 'Is there a charge for the women who use your service?',
        subpoints: [
          'No. The service is free of charge for the women. By not charging them to be on our service we are able to bring you a greater number of people from which to choose. For the most part however, the women on our service could easily afford to be there. We have people from all walks of life including doctors, teachers, scientists, business people, farmers, etc.',
        ],
      },
      {
        header: 'Is age an important factor?',
        subpoints: [
          'Studies show men and women seek different goals in marital partners. Men seek youth and beauty. Women seek mature, older, healthy, intelligent, successful, stable men who will love and respect them, with the ability to protect and provide for the family. It is a fact of life. In many other cultures, such as the Ukrainian culture, the women tend to have much more reasonable expectations. Many Ukrainian men lack a certain maturity towards family and relationships. Most Ukrainian women are extremely mature for their years.\n Our site attempts to be as diverse as possible. We have women on our database from all walks of life, different cultures, and varying age groups. Age does become a factor when choosing whom you will be most compatible with; however, do not let it become a barrier. For many women an older person represents stability, security and wisdom.',
        ],
      },
      {
        header: 'How does your site Work?',
        subpoints: [
          'Our site helps Western men liaison with the best in Eastern European women through a network of dating agency partners located in cities across Ukraine & CIS. Our site brings romantic couples together in a number of ways (correspondence, live chat, phone translation, etc.)',
        ],
      },
      {
        header: 'How do we protect our clients from scammers?',
        subpoints: [
          "Any agency may have problems because of scammers: when a lady comes to register at the agency, all what managers see is her passport (it is obligatory for all our agencies to check lady's information: name, age, date of birth, marital status and whether she has children). Further on, when a lady starts writing letters and receives responses from men, the agency (translators and operators) follow ANY requests about money. If we see such a request (exception: covering her travel expenses) - the lady's profile is removed from our database.",
          'We are concerned about those women who make asking money from Western men their practice, we are trying to fight the business of scamming men. Be aware that this fraud can be run in the Internet by individuals or agencies. You should know that we are serious agency and understand that only HONEST work will ensure that we stay in the business. We support anti-scam program and warn all Western men - do not send money to Ukrainian women you do not know well, do not mention your income and possessions during correspondence, avoid money topic at all. People in Ukraine think that life in the West is easy and people there have so much money that they do not know what to spend it for. Do not make Ukrainian women think it is true. Our dating agency tries to save you from scammers and give you possibility to meet an honest Ukrainian woman who would become your wife.',
          'Use dating agencies where you pay for corresponding and letter forwarding: dating net-works care about their reputation, the agency checks their women-clients identity (so, you avoid possibility to write to a phantom woman), the agency translates and delivers letters to women and do not charge them (so, you avoid possibility to be scammed by need-money-for-internet-cafe-and-translation scenario); besides, you can always ask for advice, help and support.',
          'It’s impossible for any dating agency to anticipate and prevent all instances of internet dating fraud or web scam. Our site has made significant progress by screening and monitoring all ladies who register on our site.',
          'The head office of our site is in USA. We cooperate with many agencies in Ukraine and CIS nations. We carefully check all users of our site (passport, id).',
          'We value our reputation and try to avoid and retrace scammers.',
          'If you’ll have any complaints or suspicions, please feel free to contact us and we’ll do everything possible for you to feel secure using our service.',
        ],
      },
    ],
    footer: 'Accept new agence',
  },
  scam: {
    header: 'Anti-scam policy',
    content: [
      "Any agency may have problems because of scammers: when a lady comes to register at the agency, all what managers see is her passport (it is obligatory for all our agencies to check lady's information: name, age, date of birth, marital status and whether she has children). Further on, when a lady starts writing letters and receives responses from men, follow ANY requests about money. If we see such a request- the lady's profile is removed from our database.",
      'We are concerned about those women who make money by asking from Western men their practice, we are trying to fight the business of scamming men. Be aware that this fraud can be run in the Internet by individuals or agencies. You should know that we are serious agency and understand that only HONEST work will ensure that we stay in the business. We support anti-scam program and warn all Western men - do not send money to Russian girls you do not know well, do not mention your income and possessions during correspondence, avoid money topic at all. People in Ukraine think that life in the West is easy and people there have so much money that they do not know what to spend it for. Do not make Russian girls think it is true. Our site tries to save you from scammers and give you possibility to meet an honest Russian girl.',
      'The head office of our site is in GB.  We carefully check all users of our site (passport, id).',
      'We value our reputation and try to avoid and retrace scammers.',
      'If you’ll have any complaints or suspicions, please feel free to contact us and we’ll do everything possible for you to feel secure using our service.',
      "Depending on what you do when you visit any Web site, you are providing information about your visit to the site's owners. This includes our site. We value privacy and think you do too, so we want you to have the information you need to make your own decisions about your personal privacy. When you visit or supply information to any Web site, we think it's a good idea for you to first understand what that site's privacy policies are. If the site doesn't tell you, don't hesitate to ask.",
      'We do not use your personal information for any other purpose; we never sell it to any third party; and we never share it with any third party.',
    ],
  },
  privacy: {
    header: 'DreamOneLove.com: Privacy Policy',
    start: 'DreamOneLove.com (hereinafter “Website” or “We”) values the privacy of all of our users and has established this Privacy Policy to show commitment to these rights and to safeguard your privacy. If you use our website or application or services, then we urge you to read this Privacy Policy and become fully informed about how it affects to your personal privacy. Please refer to this Privacy Policy to find out how to contact us if you have any questions about the contents herein. By using our website you consent to the contents of this Privacy Policy.',
    content: 'Content:',
    contentList: [
      'I.     PRIVACY POLICY',
      'II.    TYPES OF PERSONAL DATA COLLECTED',
      'III.   THE USE AND SHARE OF THE COLLECTED PERSONAL DATA',
      'IV.    AGE RESTRICTION',
      'V.     THE RIGHTS OF ACCESS AND OPT-OUT',
      'VI.    MODE AND PLACE OF PROCESSING THE PERSONAL DATA',
      'VII.   RETENTION TIME AND DELETION OF PERSONAL DATA',
      'VIII.  CHANGES TO THIS PRIVACY POLICY',
      'IX.    DATA CONTROLLER CONTACT INFORMATION',
      'X.     CHANGES TO THIS PRIVACY POLICY',
      'XI.    ACCEPTANCE OF THIS PRIVACY POLICY',
    ],
    points: [
      {
        header: 'I. Privacy policy',
        text: [
          {
            point: 'When you visit any website, you are providing a range of personal data about your visit to the operators of the website, depending on the actions you take. This is also the case with DreamOneLove.com. You should always understand the privacy policies on any website when supplying personal data during your use of the website. You should always ask for a copy of the privacy policy if the website does not make such available to you.',
          },
          {
            point: 'We will only use the personal data you send to us during your visits for the purposes of internal tracking. This includes the activities of making DreamOneLove.com and other our websites more effective for you to use our website’s features and services and responding appropriately to your requests for information. Unless specifically mentioned, we do not use your personal data for any other reason. We will never sell your personal data to a third party, and we will never give a third party access to your personal data, except as may be provided in our Terms of Use Agreement or Privacy Policy or unless required to do so by law.',
          },
        ],
      },
      {
        header: 'II. TYPES OF PERSONAL DATA COLLECTED',
        text: [
          {
            point: 'Personal data requests and user registration',
            subpoints: [
              'When you use or register on the DreamOneLove.com website, we only collect personal data that you provide of your own accord. It is possible to browse our website without registering, but you will need to register for the purpose of using certain features and services that we offer. By registering on our website you will provide and share your personal data with the public and with other users. Something that is public can be seen by anyone. For example, the personal data you provide in section “About Me”, your name, gender, username, user ID, profile picture, photos and videos. This personal data is made available to the public to help connect you with other website users. Please be advised that public personal data can show up when someone does a search on AnastasiaDate.com or on another search engine. In some cases, people you share and communicate with may download or copy the content you provided to them or make it public. Use caution about the personal data you share with others.',
            ],
          },
          {
            point: 'Personal data you provide us when you use our services or register for an account with us:',
            subpoints: [
              '- Identifying personal data such as your gender, email and billing address when you register for an account with us. It is your voluntary decision whether to provide us with any such personal information, but if you refuse to provide such information we may not be able to register you to receive our services;',
              '- Other content that you generate, or that is connected to your account ( username, user ID, profile picture, photos and videos, interests, favorites);',
              '- Phone number and financial personal data (such as credit card number) if you use the relevant services;',
              '- You may also provide us other personal data through your participation in user-to-user communications, chats, or when you otherwise communicate with us regarding our services;',
              '- Additional personal data we are required or authorized by applicable laws to collect and process in order to authenticate or identify you or to verify the personal data we have collected.',
            ],
          },
          {
            point: 'Personal data we collect automatically when you use our services or register for an account with us:',
            subpoints: [
              '- We collect data about your interaction with our services and your communications with us. This is personal data we receive from devices (including mobile devices) you use when you access our services. This personal data could include the following: device number, device ID or unique identifier, device type, and unique device token, operating system and applications used, application crash-data, IP address, User agent, platform, Software Development Kit version, anonymous User ID, time stamp Developer Key, application version, device identifiers such as: IDFA (Identifier For Advertisers), Android ID (Android device), referrer url (Playstore), Google Advertiser ID, device model, manufacture, OS version, in-app events, and network status (WiFi/3G), Login source, application use, age, gender, if profile is linked to Facebook/Google, number of photos in profile, interests, notification status, registration date, credits bought, date of last payment and use of services;',
              '- Location personal data, including location personal data from your mobile device including specific geographic locations through IP, GPS, Bluetooth, or WiFi signals;',
              '- Computer and connection personal data such as statistics on your page views, traffic to and from the websites, referral URL, ad data, your IP address, your browsing history, and your web log personal data.',
            ],
          },
          {
            point: 'Personal data we collect using cookies and similar technologies:',
            subpoints: [
              '- We use cookie technology on DreamOneLove.com as part of the statistical reporting on our website. It contains personal data used by a website to personalize your experience (e.g. rotating profiles on the DreamOneLove.com home page) and to gather statistical data, such as which pages are visited, what is downloaded during your experience, the domain name and country of the internet provider that you have come from (e.g. ‘aol.com’, or ‘umich.edu’), and addresses of those websites you have visited just before and after DreamOneLove.com. Nevertheless, we do not associate any of this personal data with you as an individual. Only an aggregate measure is taken. Cookie personal data allows us to follow your “click stream” activity (i.e., how visitors navigate our website as they move from page to page). It is important to note that cookies never capture your individual email address or any of your private personal data.',
              "- 'We also operate standard web server log files to assist in counting visitor numbers and analyzing our website’s technical capability. This personal data is used to establish how many people visit DreamOneLove.com, enable us to organize the pages for the best usability, make the website suitable for your browser, and ensure that our pages are working at their full potential. Although we collect personal data on website traffic we do not record personal data on particular visitors to our website, which means that no personal data about you as an individual is retained or used. You are able to browse the website without cookies by configuring your browser to reject all cookies, or to alert you when a cookie is sent. Since each browser is different, you should refer to the “Help” menu of your browser to learn how to change your cookie preferences.'",
            ],
          },
          {
            point: 'Personal data we collect from other social media:',
            subpoints: [
              '- We allow you to create your account with the respective social media site (e.g. Facebook, Google or other). Those social media sites may give us automatic access to certain personal data retained by them about you. You control the personal data you share with us through the respective social media you used for registering account with us privacy settings on the applicable social media site and the permissions you give us when you grant us access to the personal data retained by the respective social media site about you. By associating an account managed by a social media site with your account on our Website and authorizing us to have access to your personal data, you agree that we can collect, use and retain the personal data provided by these social media sites in accordance with this Privacy Policy and privacy policy of respective social media site in part of personal data transfer to us.',
            ],
          },
          {
            point: 'Third-party Cookies',
            subtitle: true,
          },
          {
            point: 'We use analytics packages from trusted third parties in order to constantly improve your browsing experience on our websites. Our trusted partners help us serve advertising and analytics and may place cookies on your device. You can find their privacy policies in the list below. Please read their privacy policies to ensure that you are comfortable with the manner in which they use cookies.',
            linkpoints: [
              {
                text: ' - Use of Google Analytics, Google DoubleClick are subject to Google Privacy Policy;',
                link: 'https://policies.google.com/privacy',
              },
              {
                text: ' - Use of Facebook Pixel is subject to Facebook Privacy Policy;',
                link: 'https://www.facebook.com/about/privacy/',
              },
              {
                text: ' - Use of Adroll is subject to Adroll Privacy Notice;',
                link: 'https://www.adrollgroup.com/privacy',
              },
              {
                text: ' - Use of Criteo is subject to Criteo Privacy Policy;',
                link: 'https://www.criteo.com/privacy/',
              },
              {
                text: ' - Use of Yahoo is subject to Yahoo Privacy Policy;',
                link: 'https://policies.yahoo.com/xa/en/yahoo/privacy/index.htm',
              },
              {
                text: ' - Use of Microsoft Bing Ads is subject to Microsoft Privacy Statement;',
                link: 'https://privacy.microsoft.com/en-US/privacystatement',
              },
              {
                text: ' - Use of MGID is subject to MGID Privacy Policy.',
                link: 'https://www.mgid.com/privacy-policy',
              },
            ],
          },
          {
            point: 'While we do our best to keep this list updated, please note that third parties who place cookies on your device may change from time to time, and there may be a slight delay updating the list.',
          },
          {
            point: 'Also, you can generally activate or later deactivate the use of cookies through a functionality built into your web browser or mobile device.',
          },
          {
            point: 'To learn more about how to control cookie settings through your browser:',
            linkpoints: [
              {
                text: 'Firefox',
                link: 'https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectlocale=en-US&redirectslug=enable-and-disable-cookies-website-preferences',
              },
              {
                text: 'Chrome',
                link: 'https://support.google.com/chrome/answer/95647?hl=en',
              },
              {
                text: 'Internet Explorer',
                link: 'https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies',
              },
              {
                text: 'Safari (Desktop)',
                link: 'https://support.apple.com/ru-ru/guide/safari/sfri11471/mac',
              },
              {
                text: 'Safari (Mobile)',
                link: 'https://support.apple.com/en-us/HT201265',
              },
              {
                text: 'Android Browser',
                link: 'https://support.google.com/nexus/answer/54068?visit_id=637290227101479610-4038841504&hl=en&rd=1',
              },
            ],
          },
          {
            point: 'For other browsers, please consult the documentation that your browser manufacturer provides. You can opt out of interest-based targeting provided by participating ad servers through the DigitalAdvertising Alliance',
            linkpoints: [
              {
                text: 'youradchoices.com',
                link: 'http://youradchoices.com/',
              },
            ],
          },
        ],
      },
      {
        header: 'III. THE USE AND SHARE OF THE COLLECTED PERSONAL DATA',
        text: [
          {
            point: 'We use your personal data to fulfill the provisions of DreamOneLove.com Terms of Use Agreement with you and provide you with our services, to comply with our legal obligations and to protect your vital interest. This includes:',
            subpoints: [
              '- Management of your account and preferences, operate, measure and improve our services, keep our services safe, secure and operational, and customize website content that includes services that you may like in response to actions that you take;',
              '- Provision of customer support, to contact you regarding your account, to resolve any dispute and answer your questions and enquires;',
              '- Provision of marketing information and special offers, different kind of notifications about our services (you have the right to withdraw, opt-out, your consent at any time;',
              '- Provision of analytics, improve services, monitor and develop the personal data security of our website and mobile applications, measure the performance of our marketing and advertising campaigns;',
              '- Detection and protection against fraudulent transactions and other suspicious activities;',
              '- Co-registration on DreamOneLove.com partner websites/applications;',
              '- To create Agregated data for internal use or for improving services/websites and creating new features. Agregated data is anonymous and is not linked to any personal data. Aggregate Information helps understand trends and customer needs so that new products and services can be considered and so existing products and services can be tailored to customer desires. We may share such aggregated data with our partners, without restriction, on commercial terms that we can determine in our sole discretion.',
            ],
          },
          {
            // TODO check it
            points: 'Following links to other websites',
          },
          {
            point: 'If you click on a link that takes you away from DreamOneLove.com to another website, you should remember that the Terms of Use Agreement and Privacy Policy of DreamOneLove.com no longer apply to the new website you visit. All your activity on any other website, including any website with a link on DreamOneLove.com, is subject to the rules and regulations of that website. Therefore, we recommend that you read all the policies of new websites you visit to fully understand their rules and regulations for collecting, using, and sharing your personal data.',
          },
          {
            point: 'We may share your personal data you provide to us with specially selected service providers service providers who assist us in delivering the products and services we offer for the sole purpose of providing the services or when we consider their services or products to be of interest to you. These service providers must adhere to strict confidentiality obligations in a way that is consistent with this Privacy Policy and the agreements we enter into with them. We may cooperate with external service providers to:',
            subpoints: [
              '- Oversee a customer personal data database;',
              '- Help us in sending out emails, sms (message and data rates may apply) and presents;',
              '- Help us with direct marketing;',
              '- Assist us with storage and data analysis;',
              '- Prevent from fraudulent activities;',
              '- Help us in maintaining, developing and improving our systems/websites/applications/services;',
              '- Collect payments.',
            ],
          },
          {
            point: 'We may transfer or share a copy of your personal data in cases where DreamOneLove.com or one of its properties, affiliates, or subsidiaries is part of a business transition, such as a merger, being acquired by another company, or selling part of its assets.',
          },
          {
            point: 'The Personal data may be used for legal purposes by us or the Data controller and any of its affiliates, subsidiaries, directors and etc., in court or to protect against the contravention or unauthorized use of our website, to defend or protect our rights.',
          },
          {
            point: 'We may share personal data internally within our family of companies or with third parties for purposes described in this Privacy Policy. Personal data collected within the European Economic Area (“EEA”) may, for example, be transferred to countries outside of the EEA for the purposes as described in this Privacy Policy. We utilize standard contract clauses approved by the European Commission, adopt other means under European Union law, and obtain your consent to legitimize data transfers from the EEA to the United States and other countries.',
          },
          {
            point: 'By posting any content, messages, photos and/or videos to any public area of the website/ application You automatically grant, represent and warrant that You have the right to grant to Website an irrevocable, perpetual, non-exclusive, fully paid, worldwide license to use, copy, perform, display, and distribute such personal data and content and to prepare derivative works of, or incorporate into other works, such personal data and content, and to grant and authorize sublicenses of the foregoing.',
          },
          {
            point: 'For content that is covered by intellectual property rights, like photos and videos (IP content), you specifically give us the following permission, subject to your privacy and application settings: you grant us a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use any IP content that you post on or in connection with Website / application (IP License). This IP License ends when you terminate your membership with the Website / application and we according this Privacy Policy and Terms and Conditions delete your IP content unless your content has been shared with others, and they have not deleted it.',
          },
          {
            point: 'You can contact us using the contact information provided below with questions or concerns. We also may resolve disputes you have with us in connection with our Privacy Policy.',
          },
        ],
      },
      {
        header: 'IV. AGE RESTRICTION',
        text: [
          {
            point: 'Children under 18 years of age are not allowed to use our website and our services are not targeted at children under the age of 18. In accordance with the Children’s Online Privacy Protection Act, any personal data we receive from users we believe to be aged 18 or under will be removed from our database.',
          },
        ],
      },
      {
        header: 'V. THE RIGHTS OF ACCESS AND OPT-OUT',
        text: [
          {
            point: 'You have the right, at any time, to change or delete your Personal data, please carry out these instructions:',
            subpoints: [
              '- Log into your account',
              '- Click the profile thumbnail at the top right hand corner of the AnastasiaDate.com main page',
              '- Select My Profile;',
              '- Click the pencil thumbnail in section You would like to edit;',
              '- Edit your personal data;',
              '- Click Save.',
            ],
          },
          {
            point: 'You may also send a request to our Support Team and they will guide you throught the process.',
          },
          {
            point: 'You can decide which correspondence to receive from us in accordance with our policy on freedom of choice and opting out. To stop receiving correspondence you can click the unsubscribe link found at the bottom of each email you receive or by emailing your requrest to us at unsubscribe@dreamonelove.com or by changing the settings in your profile.',
          },
        ],
      },
      {
        header: 'VI. MODE AND PLACE OF PROCESSING THE PERSONAL DATA',
        text: [
          {
            point: 'The Data controller processes the personal data of users of DreamOneLove.com in a proper manner and shall take appropriate security measures to prevent unauthorized access, disclosure, modification or unauthorized destruction of the personal data.',
          },
          {
            point: 'The personal data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Data controller, in some cases, the personal data may be accessible to certain types of persons in charge, involved with the operation of the website (administration, sales, marketing, legal, system administration) or external parties (such as third party technical service providers, mail carriers, hosting providers, IT companies, communication agencies) appointed, if necessary, as Data Processors by the Data controller.',
          },
          {
            point: 'The Data controller has secure certificates from VeriSign and McAfee that show the website is approved, fully tested, and certified by these two expert security systems. This means that you are safe from any viruses, identity theft, spyware, fraudulent credit card activity, spam, and internet scams.',
          },
          {
            point: "The Personal data is processed at the Data controller's operating offices and in any other place where the parties involved with the processing are located.",
          },
        ],
      },
      {
        header: 'VII. RETENTION TIME AND DELETION OF PERSONAL DATA',
        text: [
          {
            point: 'The Personal data is kept for the time necessary to provide the services to you and you can always request that the Data controller suspend or delete your personal data. If You wish to delete personal data we have collected during your use of Website / application, You should send a request to our Support Team. Processing of your request will take some time to take effect. Thank you for your patience. Please note that changing or deleting your personal data will only change or delete the data in our database for purposes of future activities and for managing future communications. These changes and deletions will not change or delete emails or personal data website / application may have already forwarded to other users or credit card companies or any other third parties, all as provided above in this Privacy Policy. Further, if you delete your personal data from the Website / application, but continue to use other our websites/applications/services, you may receive emails from us regarding those websites/applications/services. However, we may elect to keep your personal data, subject to the requirements of the law and legitimate interests of the Data controller.',
          },
          {
            point: 'Please note that, even after termination of membership and deletion of any personal data, termination of any license granted hereunder, some content may continue to exist because of cached internet pages beyond the control of AnastasiaDate.com.',
          },
        ],
      },
      {
        header: 'VIII. CHANGES TO THIS PRIVACY POLICY',
        text: [
          {
            point: 'In addition to rights set out in the section V of this Privacy Policy, if you are located in the European Union, as a data subject (a person whose personal information is collected, stored and processed) you have several rights under GDPR:',
            subpoints: [
              '- You have the right to obtain confirmation if your personal information is being processed by us. If that is the case, you can access your personal information and the following information: (a) thepurposes of the processing; (b) the categories of personal information; (c) to whom the personal information have been or will be disclosed; (d) the envisaged period for which the personal information will be stored, or the criteria used to determine that period. If you would like to have a copy of your personal information from us, we will provide it based on(1) you will prove your identity, (2) it will not adversely affect the rights and freedoms of others.',
              '- You have the right to demand that we erase your personal information, and we shall erase it without undue delay where one of the following grounds applies: (a) this personal information is no longer necessary in relation to the purposes for which they were processed; (b) you withdrawconsent if the processing was based on your consent, and where there is no other legal ground for the processing; (c) you object to the processing and there are no overriding legitimate grounds; (d) your personal information have been unlawfully processed; (e) your personal information have to be erased for compliance with a legal obligation.',
              '- You have the right to receive your personal information which you provided us in a structured, commonly used and machine-readable format and have the right to transmit those data to another company, where: (a) the processing is based on your consent or on a contract; and (b)the processing is carried out by automated means.',
              '- You have the right to withdraw you consent for processing of your personal information at any time. The withdrawal of consent shall not affect the lawfulness of processing based on consentbefore its withdrawal.',
              '- You have the right to lodge a complaint with a supervisory authority, in particular in the Member State of your habitual residence, place of work or place of the alleged infringement if you consider that the processing of your personal information infringes GDPR.',
            ],
          },
        ],
      },
      {
        header: 'IX. DATA CONTROLLER CONTACT INFORMATION',
        text: [
          {
            point: 'If You live in the USA, Canada:',
            subpoints: [
              'Data controller of the website is 3923 Hull St, Skokie, IL 60607, US +17085154753',
            ],
          },
          {
            point: 'If You live in the European Union or anywhere else:',
            subpoints: [
              'Data controller of the website is 3rd Floor, 207 Regent Street, W1B 3HH DreamOneLove ltd',
              'As we do not have an establishment in the European Union (“EU”), we have appointed a representativebased in Latvia, who you may address if you are located in the EU to raise any issues or queries you may have relating to our processing of your personal data and/or this Privacy Policy more generally.',
            ],
          },
        ],
      },
      {
        header: 'X. CHANGES TO THIS PRIVACY POLICY',
        text: [
          {
            point: 'We reserve the right to replace, modify or amend this Privacy Policy at its own discretion. It is yourresponsibility to review this page and check for any Privacy Policy updates. Please note the latest modification date at the bottom of the Privacy Policy.',
          },
        ],
      },
      {
        header: 'XI. ACCEPTANCE OF THIS PRIVACY POLICY',
        text: [
          {
            point: 'Our website and your use of it, including any issue concerning privacy, is subject to this Privacy Policy and the related Terms & Conditions. When you use our website, you accept the conditions set out in thisPrivacy Policy and the related Terms of Use Agreement. You signify and guarantee that you will never generate any databases, websites, software, legal entities and services that compete with DreamOneLove.com. Such behavior will be fully investigated, and necessary legal action will be carried out, including, without limitation, civil, criminal, and injunctive redress.',
          },
          {
            point: 'The acceptance of this Privacy Policy and applicable Terms of Use Agreement are considered as a pre-condition for the use of our website/Application and our services.',
          },
        ],
      },
    ],
  },
  service: {
    header: 'Customer service',
    content: [
      {
        point: 'Registration ',
        subpoints: [
          'is FREE on our site with no obligation. Browsing Ladies profiles is also free.\nCoins are virtual, standard units that enable you to read and write letters to ladies registered on our site. They also allow you to view ladies videos.',
          'As we do not charge a membership fee, this means that every time you view a ladies video, read a message from a lady and every time you send a message to a lady, you pay 10 coins(except when she first wrote to you, to open and read this letter you will be free). Live chat costs just 1 coin per minute. Coins are purchased and appear on your account. As you use them your inventory of coin is reduced accordingly.',
          'Coins are purchased by selecting"Purchase Coins" in your account page. Complete the order form, use  Credit Cards, coins will be added to your account immediately upon successful completion.',
        ],
      },
      {
        point: 'How can I make a payment?',
        subpoints: [
          'Simply select pay Commercegate at the time of checkout for any purchase and follow the prompts and pay by Credit Cards.',
        ],
      },
      {
        point: 'What information can be exchanged?',
        subpoints: [
          'As an American company, our site adheres to the International Marriage Brokers Act (IMBRA) which among other provisions restricts the exchange of personal information, email addresses, telephone numbers and postal addresses except under certain controlled conditions. Typically members are able to exchange this information only between themselves in a note included in a <a href="{{links.gift}}">Gifts & flowers delievery</a>. All have proven to be most effective and are highly recommended.',
          "For more see our site’s <a href='{{links.terms}}'> Terms and conditions. </a>",
        ],
      },
      {
        point: 'How can I get the contact details of a Lady?',
        subpoints: [
          '<a href="{{links.imbra}}"> The International Marriage Brokers Act 2006 </a> forbids us from transferring anything but postal addresses through our email correspondence system. However, there are various ways to still exchange contact information with your lady.',
          'As well as simply passing on your contact information, you could put a beautiful smile on your lady’s face by sending her some fresh flowers or perfume through our <a href="{{links.gift}}">Gift & flowers service. </a>',
        ],
      },
      {
        point: 'How much does the whole process cost?',
        subpoints: [
          'The actual cost of the whole process of finding your partner via our system varies, and depends upon how many ladies you contact and develop a relationship with. It is certainly cheaper and more effective to use our system than to fly to Ukraine every few weeks to try to meet someone by yourself, but ultimately, we do recommend that you visit your lady in her country in order to cement your relationship.',
          "Our Correspondence system costs 10 coins per letter, which includes the opportunity to send or receive two photos for free. These coins cover the translation of the letter into or from English. Coins also support our verification team, which checks the women's personal and contact information before they are registered on our site. This team also regularly monitors correspondence to maintain the legitimacy and reputation of our site.",
          'Live chat is your way to chat real time with any of the ladies online on our site. It is priced at only 1 coin per minute, with an additional coin charged for Web and Voice chat.',
          'View the girl’s video is only 10 coins. Already viewed video marked the inscription "Watched" and you can watch them for free.',
          'To use all services you must first <a href="{{links.scam}}">Purchase Coins.</a>',
        ],
      },
      {
        point: 'Member’s Verification - Anti scam policy',
        subpoints: [
          'Our site Verification Team works full time, carefully checking the personal and contact information of ladies before they are registered on our site.',
          'For more see our site <a href="{{links.scam}}">Anti-scam policy.</a>',
        ],
      },
    ],
  },
  terms: {
    header: 'DreamOneLove.com Terms of Use Agreement',
    update: 'Last updated April, 2018',
    subpoints: [
      {
        point: 'This Terms of Use Agreement (this «Agreement») is made between Dream One Love ltd, registered office address at 589 3rd Floor 207 Regent Street, London, W1B 3HH (hereinafter "The Company", or the "Website", or the "Application (including applications for tablets and other smart devices)) and the user of Dreamonelove.com and its affiliate services («You» «Registered Non-Member» or «Member») with respect to communications with other members and other online services provided by Dreamonelove.com (the «Service»). By clicking «Sign Up»  on the registration forms, You indicate Your acceptance of all the terms and conditions of this Agreement and your registration on Dreamonelove.com.',
      },
      {
        point: 'IF YOU DO NOT AGREE WITH THE TERMS AND CONDITIONS OF THIS AGREEMENT, DO NOT CLICK ON «SIGN UP» BUTTON, ONCE THE BUTTON IS CLICKED YOU ARE BOUND BY THE TERMS OF THIS AGREEMENT REGARDLESS OF WHETHER OR NOT YOU REGISTER FOR OR PURCHASE THE SERVICE. THE TERMS AND CONDITIONS OF THIS AGREEMENT ARE SUBJECT TO CHANGE BY Dreamonelove.com AT ANY TIME, EFFECTIVE UPON NOTICE TO YOU, WHICH NOTICE SHALL BE DEEMED TO HAVE BEEN PROVIDED UPON OUR POSTING OF THE CURRENT VERSION OF THIS AGREEMENT ON THE WEBSITE/ UPON OUR UPDATING THE APPLICATION WITH THE CURRENT VERSION OF THIS AGREEMENT.',
        subpoints: [
          {
            style: 'circle',
            point: 'Eligibility. By registering on Dreamonelove.com, You represent and warrant that You are at least 18 years old. Membership in the Service and registration on Dreamonelove.com is void where prohibited. By using Dreamonelove.com, You represent and warrant that You have the right, authority, and capacity to enter into this Agreement and to abide by all of the terms and conditions of this Agreement. You may not authorize others to use Your membership, and You may not assign or otherwise transfer Your account to any other person or entity.',
          },
          {
            style: 'circle',
            point: 'Unsubscribe. By clicking the "SIGN UP" button at the Dreamonelove.com you are agree to get email notification from the site about updates and events from Dreamonelove.com. If you do not wish to get emails you can go to the folder "EMAIL NOTIFICATION" in the left bar and remove the tick or put the tick opposite the definite event of email notification which you wish to receive.',
          },
          {
            style: 'circle',
            point: 'Term. This Agreement will remain in full force and effect while You use Dreamonelove.com and/or are a Member of Dreamonelove.com. You may terminate Your membership at any time, for any reason, effective upon receipt of Your written notice of termination by Dreamonelove.com customer support staff.',
          },
          {
            style: 'circle',
            point: 'Dreamonelove.com may terminate Your membership for any reason, with or without explanation, effective upon sending a notice to Your email address provided at registration, or to any other email address provided by You to Dreamonelove.com. Upon termination by Dreamonelove.com without cause, You shall receive a refund, pro rata, of any paid fees that Dreamonelove.com has received from You. If Your membership with Dreamonelove.com is terminated by reason of violation of this Agreement, or at Your request, You will not be entitled to, nor shall Dreamonelove.com be liable to You, for any refund of unused fees for using Dreamonelove.com or its additional Services. Unused credits expire 365 days after their purchase date. Notwithstanding Your membership being terminated, this Agreement will remain in full effect.',
          },
          {
            style: 'circle',
            point: 'Non-Commercial Use by Members. Dreamonelove.com is for the personal use of individual Members and/or Registered Non-Members only and may not be used in connection with any commercial endeavors. Organizations, companies, businesses, and/or other entities may not become Members and are not entitled to use the Service or the Website/ Application for any purpose. Illegal and/or unauthorized use of the Website/ Application, including collecting usernames and/or email addresses of members by electronic or other means for the purpose of sending unsolicited email and unauthorized framing of or linking to the Website/ Application will be investigated, and appropriate legal action may be taken, including without limitation, civil, criminal, and injunctive redress. You hereby grant, represent and warrant that You will not create any databases, websites, applications, software, legal entities and services competitive with Dreamonelove.com. You further guarantee and warrant that You will not use the Website/Application for any commercial purposes, and/or send or receive funds thorough the Website/Application, and/or create accounts with fake usernames. You further guarantee and warrant that You will not be paid for using the Website/Application and services, provided on the Website/Application. Such activity will be investigated, Your membership may be terminated and appropriate legal action may be taken, including without limitation, civil, criminal, and injunctive redress.',
          },
          {
            style: 'circle',
            point: 'Proprietary Rights in Content. Dreamonelove.com owns licenses or otherwise retains all intellectual property rights in the Website/ Application and the Service, absolutely. Those rights include, but are not limited to, database rights, copyright, design rights (whether registered or unregistered), patents, trademarks (whether registered or unregistered) and other similar rights, wherever existing in the world, together with the right to apply for protection of the same of Dreamonelove.com and its licensors. Except for that information which is in the public domain or for which You have been given written permission, You may not copy, modify, publish, transmit, distribute, perform, display, or sell any such proprietary information.',
          },
          {
            style: 'circle',
            point: 'Content Posted at Dreamonelove.com:',
            subpoints: [
              {
                style: 'disc',
                point: 'You understand and agree that Dreamonelove.com may at its sole discretion store for review and delete any content, messages, photos, videos, audio and video calls or profiles (collectively, «Content») that in the sole judgment of Dreamonelove.com violate this Agreement or which might be offensive, illegal, or that might violate the rights of, harm, or threaten the safety of other Members or any third parties.              You are solely responsible for the Content that You publish or display (hereinafter, «post») via the Website/ Application, or transmit to other Members.',
              },
              {
                style: 'disc',
                point: 'By posting Content to any public area of the Website/ Application You automatically grant, represent and warrant that You have the right to grant to Dreamonelove.com an irrevocable, perpetual, non-exclusive, fully paid, worldwide license to use, copy, perform, display, and distribute such information and Content and to prepare derivative works of, or incorporate into other works, such information and content, and to grant and authorize sublicenses of the foregoing.',
              },
              {
                style: 'disc',
                point: 'The following is a partial list of Content that is illegal or prohibited on the Website/ Application. Dreamonelove.com reserves the right to investigate and take appropriate legal action at its sole discretion against anyone who violates this provision including without limitation removing the offending communication from the Service and terminating the membership of such violators. Prohibited Content includes, but is not limited to, Content that:',
              },
            ],
          },
        ],
      },
      {
        point: "Limitation of Liability. Except in jurisdictions where such provisions are restricted, in no event will Dreamonelove.com be liable to You or any third person for any indirect, consequential, exemplary, incidental, special or punitive damages, including also lost profits arising from Your use of the Website/ Application or the Service, as well as damages for loss of date, loss of programs, cost of procurement of substitute Services or Service interruptions, even if Dreamonelove.com knows or has been advised of the possibility of such damages. Dreamonelove.com is not responsible for any expenses that you may have related to communicating with Members and Non-Members on our site but outside of using our services our services (including, but not limited to expenses related to travel, deliveries, meetings etc.). Those expenses are your own responsibility. Notwithstanding anything to the contrary contained herein, Dreamonelove.com's liability to You for any cause whatsoever, and regardless of the form of the action, will at all times be limited to the amount paid, if any, by You to Dreamonelove.com for the Service during the term of membership.",
      },
      {
        point: "U. S. Export Controls. Software from the Website/ Application (the «Software») is further subject to United States export controls. No Software may be downloaded from the Website/ Application or otherwise exported or re-exported (i) into (or to a national or resident of) Cuba, Iraq, Libya, North Korea, Iran, Syria, or any other Country to which the U.S. has embargoed goods; or (ii) to anyone who is listed on the U. S. Treasury Department's list of Specially Designated Nationals or on the U. S. Commerce Department's Table of Deny Orders. By downloading or using the Software, You represent and warrant that You are not located in, under the control of, or a national or resident of any such country or on any such list.",
      },
      {
        point: 'Disputes. You acknowledge that the transactions involving your use of the Website and your purchase of products or services took place in the country of incorporation of the Company. Any dispute or claim relating in any way to your visit to the Website or to products or services sold or distributed by Dreamonelove.com or through Dreamonelove.com will be resolved by binding arbitration under the LCIA Rules, rather than in court. The number of arbitrators shall be three. The arbitration shall take place in London, UK. The language to be used in the arbitral proceedings shall be English. The governing law of this Agreement shall be the substantive law of the country of incorporation of the Company. There is no judge or jury in arbitration, and court review of an arbitration award is limited. Arbitrators, however, can award you the same damages and relief, and must honor the same terms in this Agreement, as a court would.',
      },
      {
        point: "The LCIA’s Rules are available at www.lcia.org/Dispute_Resolution_Services/lcia-arbitration-rules-2014. Payment of all filing, administration and arbitrator fees will be governed by the LCIA's rules.",
      },
      {
        point: "We each agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated or representative action. THE ARBITRATOR MAY AWARD MONEY OR INJUNCTIVE RELIEF ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF WARRANTED BY THAT PARTY'S INDIVIDUAL CLAIM. NO CLASS OR REPRESENTATIVE OR PRIVATE ATTORNEY GENERAL THEORIES OF LIABILITY OR PRAYERS FOR RELIEF MAY BE MAINTAINED IN ANY ARBITRATION HELD UNDER THIS AGREEMENT. If for any reason a claim proceeds in court rather than in arbitration we each waive any right to a jury trial. We also both agree that you or we may bring suit in court to enjoin infringement or other misuse of intellectual property rights.",
      },
      {
        point: "You will attempt to resolve all disputes with respect to the Services through communication with Dreamonelove.com's customer service. If parties are unable to resolve the dispute, You must use the dispute resolution mechanism discussed above in this Section 14.Please also note should you chargeback any transactions billed to your account, Dreamonelove.com's may be required to disclose any and/or all private communications sent/received by You through our Service in order to prove the validity of the charges billed to Your account and Dreamonelove.com's privacy policy will therefore not restrain any such disclosures.",
      },
      {
        point: "Indemnity. You agree to indemnify and hold Dreamonelove.com, its subsidiaries, affiliates, officers, agents, and other partners and employees, harmless from any loss, liability, claim, or demand, including reasonable attorney's fees, made by any third party due to or arising out of Your use of the Service in violation of this Agreement, and/or any allegations that any Content that You submit to Dreamonelove.com or transmit to the Website/ Application infringe or otherwise violate the copyright, trademark, trade secret or other intellectual property or other rights of any third party; and/or arising from a breach of this Agreement and/or any breach of Your representations and warranties set forth above. This indemnity shall be applicable without regard to the negligence of any party, including any indemnified person.",
      },
      {
        point: 'Other. This Agreement, accepted upon use of the Website/ Application and further affirmed by becoming a Member and/or Registered Non-Member of the Service, contains the entire Agreement between You and Dreamonelove.com regarding the use of the Website/ Application and/or the Service. If any provision of this Agreement is held invalid, the remainder of this Agreement shall continue in full force and effect.',
      },
    ],
  },

}
