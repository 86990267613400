import LadiesApi from 'api/LadiesApi'
import {
  ladiesAddFavoriteDone,
  ladiesAddFavoriteFail,
  ladiesAddFavoriteStart,
  ladiesRemoveFavoriteDone,
  ladiesRemoveFavoriteFail,
  ladiesRemoveFavoriteStart,
} from 'redux/reducers/ladies'
import enqueueMessage from 'service/notification/enqueue'

const ladiesAddFavorite = id => dispatch => {
  dispatch(ladiesAddFavoriteStart())

  return LadiesApi.addFavorite(id)
    .then(data => {
      if (data.errMsg) {
        throw Error(data.message || data.errMsg)
      }

      return dispatch(ladiesAddFavoriteDone())
    })
    .catch(error => {
      dispatch(enqueueMessage(error.message, 'danger'))

      dispatch(ladiesAddFavoriteFail())

      return Promise.reject()
    })
}

const ladiesRemoveFavorite = id => dispatch => {
  dispatch(ladiesRemoveFavoriteStart())

  return LadiesApi.removeFavorite(id)
    .then(data => {
      if (data.errMsg) {
        throw Error(data.message || data.errMsg)
      }

      return dispatch(ladiesRemoveFavoriteDone())
    })
    .catch(error => {
      dispatch(enqueueMessage(error.message, 'danger'))

      dispatch(ladiesRemoveFavoriteFail())

      return Promise.reject()
    })
}

export {
  ladiesAddFavorite,
  ladiesRemoveFavorite,
}
