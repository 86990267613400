import { restrictedCounties } from 'consts/restrictedLocations'
import { createSelector } from 'reselect'

const registrationIsRestricted = createSelector(
  state => state.location.info,
  info => Boolean(restrictedCounties.find(country => info.country_code === country)),
)

const locationLoading = createSelector(
  state => state.location.fetching,
  fetching => fetching,
)

export {
  locationLoading,
  registrationIsRestricted,
}
