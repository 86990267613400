export default {
  infoSection: {
    mt: 2,
  },

  form: {
    width: '100%',
  },

  fileLine: {
    margin: '22px 0',
  },
}
