import React from 'react'
import {
  Grid,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import Section from 'components/Section'
import { getLabel, martialStatusOptions, religionOptions } from 'consts/profileEditOptions'
import styles from './styles'

const AdditionalInfoSection = ({ additional }) => {
  const { t } = useTranslation('text')
  const sx = styles()

  const renderInfo = (main, label, value) => {
    return (
      <Grid container sx={sx.fieldContainer}>
        <Grid item md={5} xs={7}>
          <Typography sx={sx.fieldLabel}>
            {`${label}:`}
          </Typography>
        </Grid>
        <Grid item md={7} xs={5}>
          <Typography sx={sx.fieldValue}>
            {value || t('general.na')}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Section key="profile-section-more" title={t('profile.section.more')} sx={sx.infoSection}>
      <Grid container columnSpacing={4}>
        <Grid item sm={6} xs={12}>
          {renderInfo(false, t('profile.info.nativeLang'), additional.nativeLanguage)}
          {renderInfo(false, t('profile.info.secondLang'), additional.secondLanguage)}
          {renderInfo(false, t('profile.info.job'), additional.job)}
          {renderInfo(false, t('profile.info.religion'), getLabel(religionOptions, additional.religion))}
        </Grid>
        <Grid item sm={6} xs={12}>
          {renderInfo(false, t('profile.info.maritalStatus'), getLabel(martialStatusOptions, additional.maritalStatus))}
          {renderInfo(false, t('profile.info.children'), additional.childrenCount)}
          {renderInfo(false, t('profile.info.childrenSex'), additional.childrenSex)}
          {renderInfo(false, t('profile.info.childrenAge'), additional.childrenAge)}
        </Grid>
      </Grid>
    </Section>
  )
}

export default AdditionalInfoSection
