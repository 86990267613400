import React from 'react'

import Home from 'views/Home'
import About from 'views/Home/About'
import ContactUsView from 'views/Home/ContactUs'
import DetailsFeedbackView from 'views/Home/Details/Feedback'
import DetailsNewsView from 'views/Home/Details/News'
import DetailsStoryView from 'views/Home/Details/Story'
import AntiScamView from 'views/Home/InfoViews/AntiScamView'
import CustomerServiceView from 'views/Home/InfoViews/CustomerServiceView'
import ForPartnersView from 'views/Home/InfoViews/ForPartnersView'
import PrivacyPolicyView from 'views/Home/InfoViews/PrivacyPolicyView'
import QuestionsView from 'views/Home/InfoViews/QuestionsView'
import TermsView from 'views/Home/InfoViews/TermsView'
import FeedbackOverview from 'views/Home/Overviews/Feedback'
import NewsOverview from 'views/Home/Overviews/News'
import StoriesOverview from 'views/Home/Overviews/Stories'
import AdditionalServicesView from 'views/Home/Services/AdditionalServicesView'
import DateView from 'views/Home/Services/DateView'
import GiftsView from 'views/Home/Services/GiftsView'
import LettersView from 'views/Home/Services/LettersView'
import LiveChatView from 'views/Home/Services/LiveChatView'
import VideoChat from 'views/Home/Services/VideoChatView'
import VoiceChatView from 'views/Home/Services/VoiceChatView'
import RecoverPassword from 'views/RecoverPassword'
import Registration from 'views/Registration'

import { appURLs } from './routes'


const HomeViewRoute = {
  key: 'home',
  path: appURLs.HOME,
  component: <Home />,
}

const HomeAboutRoute = {
  key: 'about',
  path: appURLs.HOME_ABOUT,
  component: <About />,
}

const HomeInfoPolicyRoute = {
  key: 'info-policy',
  path: appURLs.HOME_INFO_POLICY,
  component: <AntiScamView />,
}

const HomeInfoPartnersRoute = {
  key: 'info-partners',
  path: appURLs.HOME_INFO_PARTNERS,
  component: <ForPartnersView />,
}

const HomeInfoFAQRoute = {
  key: 'info-policy',
  path: appURLs.HOME_INFO_FAQ,
  component: <QuestionsView />,
}

const HomeInfoPrivacyPolicyRoute = {
  key: 'privacy-policy',
  path: appURLs.HOME_INFO_PRIVACY,
  component: <PrivacyPolicyView />,
}

const HomeInfoCustomerServiceRoute = {
  key: 'customer-service',
  path: appURLs.HOME_INFO_CUSTOMER,
  component: <CustomerServiceView />,
}

const HomeInfoTermsAndAgreementRoute = {
  key: 'terms-and-agreement',
  path: appURLs.HOME_INFO_TERMS,
  component: <TermsView />,
}

const HomeContactUsRoute = {
  key: 'contact-us-route',
  path: appURLs.HOME_CONTACT_US,
  component: <ContactUsView />,
}


/**
 * HOME - FEATURE & SERVICES PAGES
 */

const HomeServiceAdditionalServicesRoute = {
  key: 'service_additional',
  path: appURLs.HOME_SERVICE_ADDITIONAL,
  component: <AdditionalServicesView />,
}

const HomeServiceVideoChatRoute = {
  key: 'service_video_chat',
  path: appURLs.HOME_SERVICE_VIDEO_CHAT,
  component: <VideoChat />,
}

const HomeServiceGiftsRoute = {
  key: 'service_gifts',
  path: appURLs.HOME_SERVICE_GIFTS,
  component: <GiftsView />,
}

const HomeServiceLettersRoute = {
  key: 'service_letters',
  path: appURLs.HOME_SERVICE_LETTERS,
  component: <LettersView />,
}

const HomeServiceDateRoute = {
  key: 'service_date',
  path: appURLs.HOME_SERVICE_DATE,
  component: <DateView />,
}

const HomeServiceLiveChatRoute = {
  key: 'live_chat',
  path: appURLs.HOME_SERVICE_LIVE_CHAT,
  component: <LiveChatView />,
}

const HomeServiceVoiceChatRoute = {
  key: 'voice_chat',
  path: appURLs.HOME_SERVICE_VOICE_CHAT,
  component: <VoiceChatView />,
}

/**
 * HOME - NEWS PAGES
 */

const HomeNewsDetailsRoute = {
  key: 'news_single',
  path: appURLs.HOME_NEWS_DETAILS,
  component: <DetailsNewsView />,
}

const HomeNewsOverviewRoute = {
  key: 'news_overview',
  path: appURLs.HOME_NEWS_OVERVIEW,
  component: <NewsOverview />,
}

/**
 * HOME - STORIES PAGES
 */

const HomeStoryDetailsRoute = {
  key: 'story_single',
  path: appURLs.HOME_STORY_DETAILS,
  component: <DetailsStoryView />,
}

const HomeStoriesOverviewRoute = {
  key: 'stories_overview',
  path: appURLs.HOME_STORIES_OVERVIEW,
  component: <StoriesOverview />,
}

/**
 * HOME - FEEDBACK PAGES
 */

const HomeFeedbackDetailsRoute = {
  key: 'feedback_single',
  path: appURLs.HOME_FEEDBACK_DETAILS,
  component: <DetailsFeedbackView />,
}

const HomeFeedbackOverviewRoute = {
  key: 'feedbacks_overview',
  path: appURLs.HOME_FEEDBACKS_OVERVIEW,
  component: <FeedbackOverview />,
}

const SignInRoute = {
  key: 'sign-in',
  path: appURLs.SIGN_UP,
  component: <Registration />,
}

const RecoverPasswordRoute = {
  key: 'recover-password',
  path: appURLs.RECOVER_PASSWORD,
  component: <RecoverPassword />,
}

export default [
  HomeViewRoute,
  HomeAboutRoute,
  HomeContactUsRoute,

  HomeInfoPolicyRoute,
  HomeInfoFAQRoute,
  HomeInfoPrivacyPolicyRoute,
  HomeInfoCustomerServiceRoute,
  HomeInfoTermsAndAgreementRoute,
  HomeInfoPartnersRoute,

  HomeServiceVideoChatRoute,
  HomeServiceGiftsRoute,
  HomeServiceLettersRoute,
  HomeServiceDateRoute,
  HomeServiceLiveChatRoute,
  HomeServiceVoiceChatRoute,
  HomeServiceAdditionalServicesRoute,

  HomeNewsDetailsRoute,
  HomeNewsOverviewRoute,

  HomeStoriesOverviewRoute,
  HomeStoryDetailsRoute,

  HomeFeedbackDetailsRoute,
  HomeFeedbackOverviewRoute,

  SignInRoute,
  RecoverPasswordRoute,
]
