export default {
  listIcon: {
    width: '0.5em',
    position: 'absolute',
    top: 4,
  },
  listIconContainer: {
    width: 24,
    minWidth: 24,
  },
  selectionContainer: {
    width: '100%',
  },
  label: {
    marginBottom: 0,
  },
  part: {
    py: 4,
  },
}
