import { grey } from '@mui/material/colors'
import { onlineStatus } from 'theme'

export default {
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 3,
  },

  avatarContainer: {
    position: 'relative',
  },
  avatar: {
    width: 78,
    height: 78,
    cursor: 'pointer',
  },

  onlineStatus: online => ({
    backgroundColor: online ? onlineStatus.online : onlineStatus.offline,
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  }),
  onlineStatusContainer: {
    right: '-2px',
    width: 18,
    bottom: '-2px',
    height: 18,
    padding: '3px',
    position: 'absolute',
    background: 'white',
    borderRadius: '50%',
  },

  ladyInfoContainer: {
    pt: 1,
  },
  ladyName: {
    display: 'inline-block',
    fontWeight: 700,
    cursor: 'pointer',
  },
  ladyAge: {
    display: 'inline-block',
    fontWeight: 700,
    paddingLeft: 1,
    color: grey[400],
    cursor: 'pointer',
  },
  ladyID: {
    fontWeight: 700,
    color: grey[400],
    cursor: 'pointer',
  },
}
