import React from 'react'

const Ladies = () => (
  <svg width="27" height="30" viewBox="0 0 30 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.5813 19.4913c1.1773-.3343 3.4535-1.875 3.1395-5.3634-.654-.7849-2.1802-3.8808-.218-4.404-.0078-.0739-.0178-.1604-.0291-.2584C8.2304 7.3641 7.3777 0 15.0435 0c8.5443.0425 7.9302 6.5497 7.6515 9.5027l-.0207.2211c.9157 0 .9157 1.3518.9157 1.875 0 1.2646-.9593 2.1803-1.5261 2.5291-.1599 1.5262.4273 4.7355 3.3575 5.3634-1.9622.7849-5.5814 1.2209-6.6715-.4361-.1328.083-.2545.1755-.3796.2705-.5322.4043-1.1255.855-2.8907.8197-1.7442-.0349-2.9361-.9157-3.2268-1.3518-.843.7994-3.218 2.0581-6.6715.6977zm4.3605-7.8052L9.5057 9.157c.0145-.3052.2006-.9419.8285-1.0465.1142-.019.3512-.0233.6783-.0292 1.9203-.0345 6.9447-.1249 8.4351-3.59 1.3953.5232 1.9186 1.1773 2.093 3.0523.0145 1.0901-.2616 3.0087-.4797 4.1425.3925-1.1338.5233-1.3082.9157-1.3518.3925-.0436.7979.1758.7413 1.3081-.038.7609-.7399 1.3116-1.0631 1.5652a3.262 3.262 0 00-.1142.0918c-.7994 2.0059-3.0872 6.0175-5.843 6.0175-2.7559 0-5.2762-3.8663-6.1919-5.7994-.5233-.1745-.9767-1.003-1.1773-2.0059-.218-1.0901.3924-1.2209.6104-1.2209.2617 0 .7413.218 1.003 1.3954z" fill="#01124F" />
    <path d="M1.3954 32.3108h27.2093c1.0465 0 1.3662-.9302 1.3953-1.3953v-3.8808c0-1.7093-1.8023-3.096-2.7035-3.5756l-6.4535-2.0494c.0872 3.343-1.3953 5.1453-5.7122 7.7616-4.7791-1.814-5.8575-5.9302-5.7994-7.7616-.8721.3343-3.279.7587-5.7558 1.875C1.0988 24.4009.1744 26.6858 0 27.4271v3.4884c0 .8372.9302 1.2791 1.3954 1.3953z" fill="#01124F" />
  </svg>
)

export default Ladies
