import React, { useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import headerImage from 'assets/home/gifts_header.png'
import { useTranslation } from 'react-i18next'
import HomeLayout from 'views/Home/HomeLayout'
import { getNewsByID } from 'service/home/actions/news'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getNewsSingle } from 'service/home/selectors/news'
import moment from 'moment'
import Image from 'components/Image'
import sx from './sx'

const DetailsNewsView = ({ item, getNewsByID }) => {
  const { id } = useParams()

  const { t } = useTranslation('home')

  useEffect(() => {
    getNewsByID(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <HomeLayout
      image={headerImage}
      header={t('news.title')}
    >
      <Grid container rowGap={4}>
        <Box sx={sx.header}>
          <Grid item container rowGap={4}>
            <Grid item xs={12}>
              <Typography variant="h4">
                {item.header}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {moment(item.date).format('DD-mm-YYYY HH:mm')}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={sx.image}>
          <Image src={item.headerPhoto} fullWidth />
        </Box>
        <Box sx={sx.content}>
          <Grid sx={sx.content} item xs={12}>
            <Typography>
              <div dangerouslySetInnerHTML={{ __html: item.details }} />
            </Typography>
          </Grid>
        </Box>
      </Grid>
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  item: getNewsSingle(state),
})

const mapDispatchToProps = {
  getNewsByID,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(DetailsNewsView)
