import React from 'react'
import PropTypes from 'prop-types'
import {
  Divider,
  Grid,
  Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { withTranslation } from 'react-i18next'
import updateHistory from 'utils/history'
import { compose } from 'redux'
import { appURLs } from 'navigation/routes'
import CommonLadyInfo from './CommonLadyInfo'
import styles from './styles'
import sx from './sx'
import CommonLadyActions from './CommonLadyActions'

const LadiesTopResultItem = ({ t, classes, item, quickMessage, onFavorite }) => {
  function handleOnFavoriteClick() {
    onFavorite(item)
  }

  function handleOnLadyClick() {
    updateHistory('push', `${appURLs.LADY_PROFILE_VIEW_VISITOR_BASE}${item.id}`)
  }

  return (
    <>
      <Grid container justifyContent="center" sx={sx.topItemContainer}>
        <Grid xs={2} className={classes.ladyTopNumberContainer}>
          <Typography className={classes.ladyTopNumber} variant="h3">{`${item.index}.`}</Typography>
        </Grid>
        <Grid xs={4}>
          <CommonLadyInfo
            item={item}
            onClick={handleOnLadyClick}
          />
        </Grid>
        <Grid xs={4}>
          <CommonLadyActions
            item={item}
            onFavorite={handleOnFavoriteClick}
            mail
          />
        </Grid>
      </Grid>
      <Divider />
    </>
  )
}

LadiesTopResultItem.propTypes = {
  /** Array of rows data for table. */
  item: PropTypes.object,
  /** On favorite button click. */
  onFavorite: PropTypes.func,
  /** Flag to show quick message button. */
  quickMessage: PropTypes.func,
}

LadiesTopResultItem.defaultProps = {
  item: {},
  onFavorite: () => {},
  quickMessage: false,
}

export default compose(
  withTranslation('text'),
  withStyles(styles),
)(LadiesTopResultItem)
