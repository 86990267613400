import React, { useCallback, useState } from 'react'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Container from '@mui/material/Container'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { withStyles } from '@mui/styles'
import { appURLs } from 'navigation/routes'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { compose } from 'redux'

import Checkbox from 'components/Checkbox'
import backgroundImage from 'assets/banner.png'
import { singIn } from 'service/auth/actions'

import styles from './styles'
import sx from './sx'


const LoginSection = ({ classes, singIn }) => {
  const { t } = useTranslation('text')
  const navigate = useNavigate()

  const [login] = useState('')
  const [password] = useState('')
  const [passwordVisiable, setPasswordVisiable] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)

  const handleOnSubmitLogin = useCallback(event => {
    event.preventDefault()

    singIn({
      login: event.target.login.value,
      password: event.target.password.value,
    }).then(() => navigate(appURLs.PROFILE))
  }, [navigate, singIn])

  // singIn({ login, password }).then(() => updateHistory('push', `${appURLs.MAIL_WRITE_NEW_BASE}736248`))

  // useEffect(() => {
  //   singIn({
  //     login,
  //     password,
  //   }).then(() => updateHistory('push', appURLs.PROFILE))
  // }, [])

  const handleOnChangeRememberMe = useCallback(() => setRememberMe(!rememberMe), [])

  const handleToggleVisibility = useCallback(() => setPasswordVisiable(!passwordVisiable), [])

  const renderLoginForm = () => (
    <Card sx={sx.card}>
      <Typography sx={sx.title} variant="h5">
        {t('login.header')}
      </Typography>
      <FormControl
        component="form"
        onSubmit={handleOnSubmitLogin}
        fullWidth
      >
        <TextField
          name="login"
          defaultValue={login}
          InputProps={{
            sx: sx.textField,
            inputProps: { sx: sx.input },
          }}
          placeholder={t('login.input.email')}
          type="text"
          fullWidth
        />
        <TextField
          name="password"
          defaultValue={password}
          InputProps={{
            sx: sx.textField,
            inputProps: { sx: sx.input },
            endAdornment: (
              <IconButton
                className={classes.pswdButton}
                aria-label="toggle password visibility"
                onClick={handleToggleVisibility}
              >
                {passwordVisiable ? <VisibilityOff /> : <Visibility />}
              </IconButton>),
          }}
          placeholder={t('login.input.pswd')}
          type={passwordVisiable ? 'text' : 'password'}
          fullWidth
        />
        <FormControlLabel
          label={t('login.input.rmbrMe')}
          classes={{
            root: classes.checkboxLabel,
          }}
          control={<Checkbox classes={{ root: classes.checkbox }} />}
          checked={rememberMe}
          onChange={handleOnChangeRememberMe}
        />
        <Button
          className={classes.loginButton}
          size="large"
          fullWidth
          type="submit"
        >
          {t('login.action.login')}
        </Button>
      </FormControl>

      <Link to="/recovering" style={sx.recoverLink}>
        <Typography sx={sx.recoverText}>
          {t('login.action.forgotPswd')}
        </Typography>
      </Link>
    </Card>
  )

  const backgroundStyle = {
    background: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPositionY: '20%',
    backgroundPosition: 'center',
  }

  return (
    <Box className={classes.backgroundContainer} sx={backgroundStyle}>
      <Container>
        <Grid container justifyContent="flex-start">
          {renderLoginForm()}
        </Grid>
      </Container>
    </Box>
  )
}

const mapDispatchToProps = {
  singIn,
}


export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles),
)(LoginSection)
