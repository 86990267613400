import React, { useMemo } from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import HomeLayout from 'views/Home/HomeLayout'


const About = () => {
  const { t } = useTranslation('home')

  const content = useMemo(() => t('pages.about.content', { returnObjects: true }).map(item => (
    <>
      <Typography>
        {item}
      </Typography>
      <br />
    </>
  )), [t])

  return (
    <HomeLayout header={t('pages.about.header')}>
      {content}
      <Typography>
        {t('pages.about.link')}
        <a href="https://www.youtube.com/watch?v=erL_pxj3rwk" target="_blank" rel="noreferrer">
          Youtube
        </a>
      </Typography>
      <br />
      <Typography>
        {t('pages.about.footer')}
      </Typography>
      <br />
      <Typography>
        {t('pages.about.dreamone')}
      </Typography>
    </HomeLayout>
  )
}

export default About
