const agesRange = t => [{
  value: 0,
  label: t('general.any'),
}].concat(new Array(60).fill(0).map((age, idx) => ({
  label: 18 + idx,
  value: 18 + idx,
})))

const getAge = age => {
  const ageNum = Number(age)

  if (ageNum === 0) {
    return 0
  }

  return ageNum < 18 ? 18 : ageNum
}

export {
  getAge,
  agesRange,
}
