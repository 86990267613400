export default {
  recovering: {
    title: 'Password Recovering',
    description: "If you've forgot your password, please, specify the email you used for registration. Your password will be sent there.",
    error: {
      email: 'Invalid mail',
    },
    input: {
      email: 'Email',
    },
    action: {
      send: 'Send recovering email',
    },
  },
}
