export default {
  topItemContainer: {
    py: 3,
  },

  topItemAvatar: {
    width: 102,
    height: 102,
    cursor: 'pointer',
  },
  topItemInfoContainer: {
    cursor: 'pointer',
  },

  ladyInfoContainer: {
    mt: 2,
  },
}
