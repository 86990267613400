import React, { useCallback, useEffect, useState } from 'react'

import { Pagination, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'

import Section from 'components/Section'
import { getAuthUserInfo } from 'service/auth/selectors'
import { ladiesAddFavorite, ladiesRemoveFavorite } from 'service/ladies/actions/favorite'
import { filterTop as filterLadies } from 'service/ladies/actions/filter'
import {
  getTopFilterLadies,
  getTopFilterLadiesFilterObj,
  getTopFilterLadiesPaginationInfo,
  getTopFilterLadiesResultCount,
} from 'service/ladies/selectors'

import LadiesFirstTopResultItem from './LadiesResultSection/LadiesFirstTopResultItem'
import LadiesTopResultItem from './LadiesResultSection/LadiesTopResultItem'
import styles from './styles'

const LadiesTopSearch = ({ items, classes, itemsCount, pagination, filterLadies, ladiesFilterObject, ladiesRemoveFavorite, ladiesAddFavorite }) => {
  const [filterObj, setFilterObj] = useState({
    from: 0,
    size: 6,
  })

  useEffect(() => {
    filterLadies(filterObj, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { t } = useTranslation('text')

  const handleOnFavoriteClick = useCallback(item => {
    const resultFunc = item.favorite
      ? ladiesRemoveFavorite
      : ladiesAddFavorite

    resultFunc(item.id).then(filterLadies)
  }, [])

  const handleOnPageChanged = useCallback((event, pageNumber) => {
    if (!Number.isInteger(pageNumber)) {
      return
    }

    filterLadies({
      from: (pageNumber - 1) * ladiesFilterObject.size,
    })
  }, [])

  const renderPagination = () => {
    if (!pagination && !itemsCount) {
      return null
    }

    return (
      <Pagination
        classes={{
          root: classes.paginationRoot,
        }}
        page={pagination.currentPage}
        count={pagination.pagesCount}
        onChange={handleOnPageChanged}
        hideNextButton
        hidePrevButton
      />
    )
  }

  return (
    <div>
      <Typography
        key="settings-title"
        className={classes.title}
        variant="h4"
      >
        {t('ladies.title')}
      </Typography>
      <Section title={t('ladies.search.title.top')}>
        <div>
          {items.map(item => (item.index === 1
            ? <LadiesFirstTopResultItem key={item.id} item={item} onFavorite={handleOnFavoriteClick} />
            : <LadiesTopResultItem key={item.id} item={item} onFavorite={handleOnFavoriteClick} />))}
        </div>
        {renderPagination()}
      </Section>
    </div>
  )
}


const mapStateToProps = state => ({
  userInfo: getAuthUserInfo(state),
  items: getTopFilterLadies(state),
  itemsCount: getTopFilterLadiesResultCount(state, 100),
  pagination: getTopFilterLadiesPaginationInfo(state),
  ladiesFilterObject: getTopFilterLadiesFilterObj(state),
})

const mapDispatchToProps = {
  filterLadies,
  ladiesAddFavorite,
  ladiesRemoveFavorite,
}


export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(LadiesTopSearch)
