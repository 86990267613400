export default theme => ({
  description: {
    padding: '16px 0 24px',
    textAlign: 'center',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[300],
    marginBottom: 16,
  },
  checkboxContainer: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
  },
  actions: {
    marginTop: 12,
    textAlign: 'center',
  },
})
