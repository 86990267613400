import { grey } from '@mui/material/colors'

export default {
  preview: (url, isVideo) => ({
    background: url,
    borderRadius: 1,
    height: 120,
    width: isVideo ? 170 : 120,
    backgroundPositionY: '50%',
    backgroundSize: 'cover',
  }),
  card: {
    '::selection': {
      color: 'unset',
    },
    'borderRadius': 1,
    'margin': 1,
    'padding': 0,
  },

  indicator: {
    display: 'none',
  },

  videoIcon: {
    'width': '100%',
    'height': '100%',
    'padding': 4,
    'cursor': 'pointer',
    'fill': 'white',
    'boxSizing': 'border-box',

    ':hover': {
      fill: '#ffffffad',
    },
  },

  actionButton: {
    padding: 1,
    borderRadius: 1,
    width: 20,
    height: 20,
    border: '1px solid',
    borderColor: grey[500],
  },

  actionButtonIcon: {
    width: 16,
    height: 16,
    fill: grey[500],
  },
}
