import { Grid, MenuItem, TextField } from '@mui/material'
import Section from 'components/Section'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  religionOptions,
  drinkingOptions,
  smokingOptions,
  educationOptions,
  martialStatusOptions,
  hairOptions,
  eyeOptions,
} from 'consts/profileEditOptions'
import { convertCmToFeet, convertFeetToCm, convertKgToLbs, convertLbsToKg } from 'utils/converters'

import { weightOptions, WEIGHT_KG, WEIGHT_LBS } from 'consts/weightOptions'
import { heightOptions, HEIGHT_CM, HEIGHT_FT } from 'consts/heightOptions'
import LookingAges from '../components/LookingAges'
import sx from './sx'

const AboutMeSection = ({ general, additional }) => {
  const [weight, setWeight] = useState(additional.weightKg)
  const [weightType, setWeightType] = useState(WEIGHT_KG.value)
  const [height, setHeight] = useState(additional.heightCm)
  const [heightType, setHeightType] = useState(HEIGHT_CM.value)

  const { t } = useTranslation('text')

  function handleOnWeightTypeChanged(event) {
    const value = event.target.value

    let newWeight = weight

    if (weightType !== value) {
      newWeight = value === WEIGHT_LBS.value ? convertKgToLbs(weight) : convertLbsToKg(weight)
    }

    setWeight(newWeight)
    setWeightType(value)
  }

  function handleOnWeightChanged(event) {
    setWeight(event.target.value)
  }

  function handleOnHeightTypeChanged(event) {
    const value = event.target.value

    let newHeight = height

    if (heightType !== value) {
      newHeight = value === HEIGHT_FT.value ? convertCmToFeet(height) : convertFeetToCm(height)
    }

    setHeight(newHeight)
    setHeightType(value)
  }

  function handleOnHeightChanged(event) {
    setHeight(event.target.value)
  }


  const renderTextInput = (name, label, value, required = false) => (
    <Grid key={name} item xs={12} md={6}>
      <TextField
        name={name}
        label={label}
        defaultValue={value}
        required={required}
        fullWidth
      />
    </Grid>
  )

  const renderTextAreaInput = (name, label, value, required = false) => (
    <Grid key={name} item xs={12} md={12}>
      <TextField
        name={name}
        label={label}
        defaultValue={value}
        required={required}
        multiline
        fullWidth
      />
    </Grid>
  )

  const renderSelectInput = (name, label, value, options, required = false) => (
    <Grid key={name} item xs={12} md={6}>
      <TextField
        name={name}
        label={label}
        defaultValue={value}
        required={required}
        fullWidth
        select
      >
        {options.map(option => (
          <MenuItem
            key={option.value}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  )

  const renderWeightInput = () => {
    return (
      <Grid container item xs={12} md={6} spacing={2}>
        <Grid item xs={8}>
          <TextField
            label={t('profile.info.weight')}
            name="weight"
            value={weight}
            onChange={handleOnWeightChanged}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={t('profile.info.weightType')}
            name="weightType"
            value={weightType}
            onChange={handleOnWeightTypeChanged}
            fullWidth
            select
          >
            {weightOptions.map(option => (
              <MenuItem
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    )
  }

  const renderHeightInput = () => {
    return (
      <Grid container item xs={12} md={6} spacing={2}>
        <Grid item xs={8}>
          <TextField
            label={t('profile.info.height')}
            name="height"
            value={height}
            onChange={handleOnHeightChanged}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={t('profile.info.heightType')}
            name="heightType"
            value={heightType}
            onChange={handleOnHeightTypeChanged}
            fullWidth
            select
          >
            {heightOptions.map(option => (
              <MenuItem
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    )
  }

  return (
    <Section title={t('profile.section.about')}>
      <Grid container spacing={4} sx={sx.infoSection}>
        {renderTextInput('birthday', t('profile.info.birthday'), additional.birthday, true)}
        {renderSelectInput('religion', t('profile.info.religion'), additional.religion, religionOptions)}
        {renderWeightInput()}
        {renderSelectInput('drinker', t('profile.info.drinking'), additional.drinker, drinkingOptions)}
        {renderHeightInput()}
        {renderSelectInput('smoker', t('profile.info.smoking'), additional.smoker, smokingOptions)}
        {renderSelectInput('hairColor', t('profile.info.hair'), additional.hairColor, hairOptions)}
        {renderSelectInput('eyeColor', t('profile.info.eyes'), additional.eyeColor, eyeOptions)}
        {renderSelectInput('maritalStatus', t('profile.info.maritalStatus'), additional.maritalStatus, martialStatusOptions)}
        {renderTextInput('job', t('profile.info.job'), additional.job)}
        {renderTextInput('nativeLanguage', t('profile.info.nativeLang'), additional.nativeLanguage)}
        {renderTextInput('secondLanguage', t('profile.info.secondLang'), additional.secondLanguage)}
        {renderSelectInput('education', t('profile.info.education'), additional.education, educationOptions)}
        {renderTextInput('childrenCount', t('profile.info.children'), additional.childrenCount)}
        {renderTextInput('childrenAge', t('profile.info.childrenAge'), additional.childrenAge)}
        {renderTextInput('childrenSex', t('profile.info.childrenSex'), additional.childrenSex)}
        <Grid key="lookingAge" item xs={12} md={6}>
          <LookingAges
            labelFrom={t('profile.info.ageFrom')}
            labelTo={t('profile.info.ageTo')}
            ageFrom={additional.lookingAgeFrom}
            ageTo={additional.lookingAgeTo}
          />
        </Grid>
        {/* Text areas */}
        {renderTextAreaInput('character', t('profile.section.character'), additional.character)}
        {renderTextAreaInput('hobbies', t('profile.section.hobbies'), additional.hobbies)}
        {renderTextAreaInput('lookingFor', t('profile.section.lookingType'), additional.lookingFor)}
      </Grid>
    </Section>
  )
}

export default AboutMeSection
