import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enLoc from 'locale/en'

i18n
  .use(initReactI18next)
  .init({
    languages: ['en-US'],
    lng: 'en-US',
    debug: false,
    resources: {
      en: enLoc,
    },
    interpolation: {
      escapeValue: false,
    },
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
  })

export default i18n
