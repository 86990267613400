const childrenOptions = [
  {
    label: 'Does not matter',
    value: 0,
  },
  {
    label: 'Has children',
    value: 1,
  },
  {
    label: 'No children',
    value: 2,
  },
]

const onlineOptions = [
  {
    label: 'Does not matter',
    value: 0,
  },
  {
    label: 'Online now',
    value: 1,
  },
  {
    label: 'Offline now',
    value: 2,
  },
]

const birthdayOptions = [
  {
    label: 'Does not matter',
    value: 0,
  },
  {
    label: 'Birthday soon',
    value: 1,
  },
  {
    label: 'Birthday isn\'t soon',
    value: 2,
  },
]

export {
  childrenOptions,
  onlineOptions,
  birthdayOptions,
}
