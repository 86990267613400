import React from 'react'
import {
  Box,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import Section from 'components/Section'
import ContentHorizontalScroll from 'components/ContentHorizontalScroll'
import { TYPE } from 'components/ViewMedia/ViewMedia'
import ViewMedia from 'components/ViewMedia'
import ProfileMediaCard from 'components/ContentHorizontalScroll/ProfileMediaCard'
import styles from './styles'

const MediaSection = ({ photos, videos, visitor, onPhotoFavorite }) => {
  const { t } = useTranslation('text')
  const sx = styles()

  const [mediaToView, setMediaToView] = React.useState(null)

  function handleOnPhotoFavorite(item) {
    onPhotoFavorite(item)
  }

  function handleOnPhotoClick(index) {
    setMediaToView({ index, type: TYPE.PHOTO })
  }

  function handleOnVideoClick(index) {
    setMediaToView({ index, type: TYPE.VIDEO })
  }

  function handleOnCloseMediaView() {
    setMediaToView(null)
  }

  function renderItem(props) {
    return (
      <ProfileMediaCard
        visitor={visitor}
        onFavorite={handleOnPhotoFavorite}
        {...props}
      />
    )
  }

  const photosMediaView = (
    <ContentHorizontalScroll
      type={TYPE.PHOTO}
      items={photos}
      onSelect={handleOnPhotoClick}
      renderItem={renderItem}
    />
  )


  const videoMediaView = (
    <ContentHorizontalScroll
      type={TYPE.VIDEO}
      items={videos}
      onSelect={handleOnVideoClick}
      renderItem={renderItem}
    />
  )


  return (
    <Box>
      <Section key="photo-section-container" title={t('profile.section.photos')} sx={sx.infoSection}>
        <div className="file-section-container">
          {photosMediaView}
        </div>
      </Section>
      <Section key="video-section-container" title={t('profile.section.videos')} sx={sx.infoSection}>
        <div className="file-section-container">
          {videoMediaView}
        </div>
      </Section>
      <ViewMedia
        key="profile-view-media-dialog"
        type={mediaToView?.type}
        selectedIndex={mediaToView?.index}
        title={mediaToView?.type === TYPE.VIDEO ? t('general.video') : t('general.photo')}
        items={mediaToView?.type === TYPE.VIDEO ? videos : photos}
        show={Boolean(mediaToView)}
        onClose={handleOnCloseMediaView}
      />
    </Box>
  )
}

export default MediaSection
