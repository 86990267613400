import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'utils/i18n'
import mimeType from 'mime-db'
import { Box, Typography } from '@mui/material'
import { VALIDATION_EXT_UNKNOWN_ERR, VALIDATION_EXT_WRONG_ERR, VALIDATION_SIZE_ERR } from './const'
import sx from './sx'

i18n.setDefaultNamespace('text')

const FileInput = ({ name, onChange, onValidationFailed, filesLabel, label, extensions, sizeLimit, strict }) => {
  const checkExtensions = file => {
    const fileExt = mimeType[file.type]

    if (fileExt.extensions.length === 0) {
      return VALIDATION_EXT_UNKNOWN_ERR
    }

    const extension = `.${fileExt.extensions[0]}`

    return extensions.includes(extension) ? null : VALIDATION_EXT_WRONG_ERR
  }

  function handleOnChange(event) {
    const files = event.target.files

    for (let idx = 0; idx < files.length; idx++) {
      if (sizeLimit !== 0 && files[idx].size > sizeLimit) {
        onValidationFailed(VALIDATION_SIZE_ERR)
        return
      }

      if (strict) {
        const result = checkExtensions(event.target.files[idx])
        if (result) {
          onValidationFailed(result)
          return
        }
      }
    }

    onChange(event)
  }

  function renderLabel(props) {
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    return <label {...props} htmlFor={`file-upload-button-${name}`} />
  }

  function renderInput(props) {
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    return <input {...props} />
  }

  return (
    <Box sx={sx.root}>
      <Box
        component={renderInput}
        id={`file-upload-button-${name}`}
        name={name}
        type="file"
        onChange={handleOnChange}
        accept={extensions.join(',')}
        style={sx.input}
      />
      <Typography sx={sx.text}>{filesLabel}</Typography>
      <Typography component={renderLabel} sx={sx.label}>
        {label}
      </Typography>
    </Box>
  )
}
FileInput.propTypes = {
  /** Field name. */
  name: PropTypes.string.isRequired,
  /** Uploaded file(s) label. */
  filesLabel: PropTypes.string,
  /** Input label. */
  label: PropTypes.string,
  /** Allowed to upload extensions. */
  extensions: PropTypes.arrayOf(PropTypes.string),
  /** File(s) size limit in bytes. 0 mean unlimit size. */
  sizeLimit: PropTypes.number,
  /** Extention would be additionally valudated. */
  strict: PropTypes.bool,
  /** Return uploaded file(s). */
  onChange: PropTypes.func,
  /** Return uploaded file(s). */
  onValidationFailed: PropTypes.func.isRequired,
}

FileInput.defaultProps = {
  filesLabel: i18n.t('component.fileUpload.fileLabel'),
  label: i18n.t('component.fileUpload.browseLabel'),
  extensions: [],
  sizeLimit: 0,
  strict: false,
  onChange: '',
}

export default FileInput
