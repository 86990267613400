import React, { useCallback } from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import socials from 'consts/socials'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const Socials = () => {
  const renderIcon = useCallback(item => (
    <a
      key={item.type}
      href={item.link}
      target="_blank"
      rel="noreferrer"
    >
      <Avatar sx={{ ...item.sx, width: 64, height: 64 }}>
        <FontAwesomeIcon icon={['fab', item.type]} size="2x" fixedWidth />
      </Avatar>
    </a>
  ), [])

  // TODO make a single render function for socials.
  return (
    <Stack rowGap={4}>
      <Typography variant="h5" textAlign="center">
        We are in social networks
      </Typography>
      <Stack direction="row" justifyContent="space-between">
        {socials.map(renderIcon)}
      </Stack>
    </Stack>
  )
}

export default Socials
