import Api from './Api'

class LadiesApi {
  static getByID = id => {
    const path = `${Api.basePath}person/lady/${id}`
    return Api.get(path).then(response => response.json())
  }

  static filter = filter => {
    const path = `${Api.basePath}person/lady/filter`
    return Api.post(path, filter).then(response => response.json())
  }

  static filterNewest = filter => {
    const path = `${Api.basePath}person/lady/filter/newest`
    return Api.post(path, filter).then(response => response.json())
  }

  static filterTop = filter => {
    const path = `${Api.basePath}person/lady/filter/top`
    return Api.post(path, filter).then(response => response.json())
  }

  static findFavorite = filter => {
    const path = `${Api.basePath}favorite/ladies`
    return Api.post(path, filter).then(response => response.json())
  }

  static findVisitors = filter => {
    const path = `${Api.basePath}person/lady/filter/viewed`
    return Api.post(path, filter).then(response => response.json())
  }

  static findContacts = filter => {
    const path = `${Api.basePath}person/lady/filter/contacts`
    return Api.post(path, filter).then(response => response.json())
  }

  static addFavorite = id => {
    const path = `${Api.basePath}favorite/lady/${id}`
    return Api.post(path).then(response => (`${response.status}`[0] !== '2' ? response.json() : response.text()))
  }

  static removeFavorite = id => {
    const path = `${Api.basePath}favorite/lady/${id}`
    return Api.delete(path).then(response => (`${response.status}`[0] !== '2' ? response.json() : response.text()))
  }
}

export default LadiesApi
