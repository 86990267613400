import React from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  Box,
  Typography,
} from '@mui/material'

import { withStyles } from '@mui/styles'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import moment from 'moment'
import updateHistory from 'utils/history'
import { appURLs } from 'navigation/routes'
import styles from './styles'
import CommonLadyInfo from './CommonLadyInfo'
import sx from './sx'
import CommonLadyActions from './CommonLadyActions'

const LadiesResulItem = ({ t, classes, item, quickMessage, onFavoriteClick }) => {
  const getOnlineStatusText = () => {
    if (item.lastVisit === undefined) {
      return t('ladies.search.result.online.unknown')
    }

    return item.lastVisit && item.online
      ? t('ladies.search.result.online.online')
      : t('ladies.search.result.online.lastVisit', { date: moment(item.lastVisit).format('DD.MM.yyyy') })
  }

  function handleOnFavoriteClick() {
    onFavoriteClick(item)
  }

  function handleOnLadyClick() {
    updateHistory('push', `${appURLs.LADY_PROFILE_VIEW_VISITOR_BASE}${item.id}`)
  }

  return (
    <Card variant="outlined" className={classes.cardRoot}>
      <Typography className={classes.onlineStatusText}>{getOnlineStatusText()}</Typography>
      <Box sx={sx.ladyInfoContainer}>
        <CommonLadyInfo
          item={item}
          onClick={handleOnLadyClick}
          showOnline
        />
      </Box>
      <CommonLadyActions
        item={item}
        onFavorite={handleOnFavoriteClick}
        mail
      />
    </Card>
  )
}

LadiesResulItem.propTypes = {
  /** Array of rows data for table. */
  item: PropTypes.object,
  /** On favorite button click. */
  onFavoriteClick: PropTypes.func,
  /** Flag to show quick message button. */
  quickMessage: PropTypes.func,
}

LadiesResulItem.defaultProps = {
  item: {},
  onFavoriteClick: () => {},
  quickMessage: false,
}

export default compose(
  withTranslation('text'),
  withStyles(styles),
)(LadiesResulItem)
