import { createAction, handleActions } from 'redux-actions'

// TODO user type (man, woman) is required in future
const initialState = {
  fetching: false,
  logged: false,
  loginAsMan: false,
}

// actions
const userSignInStart = createAction('USER_SIGN_IN_START')
const userSignInFail = createAction('USER_SIGN_IN_FAIL')
const userSignInDone = createAction('USER_SIGN_IN_DONE')

const userSignUpStart = createAction('USER_SIGN_UP_START')
const userSignUpFail = createAction('USER_SIGN_UP_FAIL')
const userSignUpDone = createAction('USER_SIGN_UP_DONE')

const userSignOutStart = createAction('USER_SIGN_OUT_START')
const userSignOutFail = createAction('USER_SIGN_OUT_FAIL')
const userSignOutDone = createAction('USER_SIGN_OUT_DONE')

// reducer
const signInStart = state => ({ ...state, fetching: true })
const signInFail = state => ({ ...state, fetching: false })
const signInDone = (state, { payload }) => ({ ...state, ...payload, fetching: false })

const signUpStart = state => ({ ...state, fetching: true })
const signUpFail = state => ({ ...state, fetching: false })
const signUpDone = (state, { payload }) => ({ ...state, ...payload, fetching: false })

const signOutStart = state => ({ ...state, fetching: true })
const signOutFail = state => ({ ...state, fetching: false, ...initialState })
const signOutDone = state => ({ ...state, fetching: false, ...initialState })

const reducers = handleActions({
  USER_SIGN_IN_START: signInStart,
  USER_SIGN_IN_FAIL: signInFail,
  USER_SIGN_IN_DONE: signInDone,

  USER_SIGN_UP_START: signUpStart,
  USER_SIGN_UP_FAIL: signUpFail,
  USER_SIGN_UP_DONE: signUpDone,

  USER_SIGN_OUT_START: signOutStart,
  USER_SIGN_OUT_FAIL: signOutFail,
  USER_SIGN_OUT_DONE: signOutDone,
}, initialState)

export {
  userSignInStart,
  userSignInFail,
  userSignInDone,

  userSignUpStart,
  userSignUpFail,
  userSignUpDone,

  userSignOutStart,
  userSignOutFail,
  userSignOutDone,
}

export default reducers
