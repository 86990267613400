/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/display-name */
import { Box } from '@mui/material'
import React from 'react'

const Image = ({ fullHeight, fullWidth, borderRadius, useBackground, ...rest }) => {
  function renderComponent(boxProps) {
    return <img {...boxProps} />
  }

  return (
    <Box
      sx={{
        borderRadius,
        height: fullHeight ? '100%' : undefined,
        width: fullWidth ? '100%' : undefined,
        ...useBackground && {
          background: `url(${rest.src}) center center / cover`,
        },
      }}
      {...rest}
      {...!useBackground && {
        component: renderComponent,
      }}
    />
  )
}

export default Image
