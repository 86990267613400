import React, { useCallback, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { connect } from 'react-redux'
import Image from 'components/Image'
import {
  clearStories,
  getShortStories,
} from 'service/home/actions/stories'
import GoButton from 'components/GoButton'
import { getStories } from 'service/home/selectors/stories'
import { appURLs } from 'navigation/routes'
import { useNavigate } from 'react-router'
import sx from './sx'
import HomeSection from '../HomeSection'

const Item = ({ size, item, onClick, downMD = false }) => {
  const { t } = useTranslation(['home', 'common'])

  const handleOnClick = useCallback(() => onClick(item), [item, onClick])

  return (
    <Grid item xs={size}>
      <Box sx={sx.item(downMD, size)}>
        <Box sx={sx.hoverItem}>
          <Typography
            sx={sx.itemHeader}
            variant="h6"
          >
            {item.author}
          </Typography>
          <Button
            sx={sx.itemButton}
            onClick={handleOnClick}
          >
            {t('general.more', { ns: 'common' })}
          </Button>
        </Box>
        <Image
          src={item.headerPhoto}
          useBackground
          fullHeight
        />
      </Box>
    </Grid>
  )
}

const Stories = ({ stories, clearStories, getShortStories }) => {
  const { t } = useTranslation('home')
  const navigate = useNavigate()

  const upMD = useMediaQuery(theme => theme.breakpoints.up('md'))

  const topItems = useMemo(() => new Array(upMD ? 4 : 0).fill(null), [upMD])
  const bottomItems = useMemo(() => new Array(upMD ? 3 : 6).fill(null), [upMD])

  useEffect(() => {
    clearStories().then(getShortStories)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnClick = useCallback(item => navigate(`${appURLs.HOME_STORY_DETAILS_BASE}${item.id}`), [navigate])
  const handleOnMoreClick = useCallback(() => navigate(`${appURLs.HOME_STORIES_OVERVIEW}`), [navigate])

  if (stories.length === 0) {
    return null
  }

  return (
    <HomeSection title={t('sections.stories.text.title')}>
      <Grid container>
        {topItems.map((_, index) => (
          <Item
            key={stories[index].id}
            item={stories[index]}
            size={3}
            onClick={handleOnClick}
          />
        ))}
        {bottomItems.map((_, index) => (
          <Item
            key={stories[index + topItems.length].id}
            item={stories[index + topItems.length]}
            size={4}
            onClick={handleOnClick}
            downMD={!upMD}
          />
        ))}
      </Grid>
      <Grid container justifyContent="center">
        <GoButton mt onClick={handleOnMoreClick}>
          {t('general.more', { ns: 'common' })}
        </GoButton>
      </Grid>
    </HomeSection>
  )
}

const mapStateToProps = state => ({
  stories: getStories(state),
})

const mapDispatchToProps = {
  clearStories,
  getShortStories,
}

export default connect(mapStateToProps, mapDispatchToProps)(Stories)
