import { createSelectorCreator, defaultMemoize } from 'reselect'
import isEqual from 'lodash/isEqual'
import createGeneralPagination from 'utils/pagination'

const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual,
)

const getStories = createDeepEqualSelector(
  state => state.stories.data.storyList,
  list => list || [],
)

const getStoriesSingle = createDeepEqualSelector(
  state => state.stories.item,
  item => item || {},
)

const getStoriesPagination = createDeepEqualSelector(
  state => state.stories.data.totalCount,
  state => state.stories.filterBase,
  state => state.stories.filter.common,
  (count, filterBase, filter) => createGeneralPagination(count, filter || filterBase),
)

const getStoriesFilter = createDeepEqualSelector(
  state => state.stories.filter.common,
  filter => filter || {},
)


const getVideoStories = createDeepEqualSelector(
  state => state.stories.videoData.storyList,
  list => list || [],
)

const getVideoStoriesSingle = createDeepEqualSelector(
  state => state.stories.videoItem,
  item => item || {},
)

const getVideoStoriesPagination = createDeepEqualSelector(
  state => state.stories.videoData.totalCount,
  state => state.stories.filterBase,
  state => state.stories.filter.video,
  (count, filterBase, filter) => createGeneralPagination(count, filter || filterBase),
)

const getVideoStoriesFilter = createDeepEqualSelector(
  state => state.stories.filter.video,
  filter => filter || {},
)


export {
  getStories,
  getStoriesSingle,
  getStoriesFilter,
  getStoriesPagination,

  getVideoStories,
  getVideoStoriesSingle,
  getVideoStoriesPagination,
  getVideoStoriesFilter,
}
