import LadiesApi from 'api/LadiesApi'
import { ladiesGetByIDFavoriteDone, ladiesGetByIDFavoriteFail, ladiesGetByIDFavoriteStart } from 'redux/reducers/ladies'
import enqueueMessage from 'service/notification/enqueue'

const getByID = id => (dispatch, getState) => {
  dispatch(ladiesGetByIDFavoriteStart())

  return LadiesApi.getByID(id)
    .then(data => {
      if (data.errMsg) {
        throw Error(data.message || data.errMsg)
      }

      dispatch(ladiesGetByIDFavoriteDone(data))

      return Promise.resolve()
    })
    .catch(error => {
      dispatch(enqueueMessage(error.message))

      dispatch(ladiesGetByIDFavoriteFail())

      return Promise.reject()
    })
}

export {
  getByID,
}
