import HomeApi from 'api/HomeApi'
import {
  storiesClear,
  storiesGetDone,
  storiesGetFail,
  storiesGetStart,
  storiesUpdateFilter,
  storyGetDone,
  storyGetFail,
  storyGetStart,
  videoStoriesClear,
  videoStoriesGetDone,
  videoStoriesGetFail,
  videoStoriesGetStart,
  videoStoriesUpdateFilter,
  videoStoryGetDone,
  videoStoryGetFail,
  videoStoryGetStart,
} from 'redux/reducers/stories'

const clearStories = () => dispatch => {
  dispatch(storiesClear())

  return Promise.resolve()
}

const clearVideoStories = () => dispatch => {
  dispatch(videoStoriesClear())

  return Promise.resolve()
}

const getFilter = (key, filter, getState) => ({
  ...getState().stories.filterBase,
  ...getState().stories.filter[key],
  ...filter,
})

const getShortStories = () => dispatch => {
  dispatch(storiesGetStart())

  const request = {
    cmd: 'CMD_GET_ALL_STORIES',
    count: '7',
    retrieveCount: 'true',
    start: '0',
  }

  return HomeApi.filterStories(request)
    .then(data => {
      if (!data.succes) {
        throw Error(data.message || data.errMsg)
      }

      dispatch(storiesGetDone(data.result))

      return Promise.resolve()
    })
    .catch(error => {
      dispatch(storiesGetFail())

      return Promise.reject()
    })
}

const filterStories = (filter = {}) => (dispatch, getState) => {
  dispatch(storiesGetStart())

  const newFilter = getFilter('common', filter, getState)

  dispatch(storiesUpdateFilter(newFilter))

  const request = {
    cmd: 'CMD_GET_ALL_STORIES',
    count: newFilter.size,
    retrieveCount: 'true',
    start: newFilter.from,
  }

  return HomeApi.filterStories(request)
    .then(data => {
      if (!data.succes) {
        throw Error(data.message || data.errMsg)
      }

      dispatch(storiesGetDone(data.result))

      return Promise.resolve()
    })
    .catch(error => {
      dispatch(storiesGetFail())

      return Promise.reject()
    })
}

const getStoryByID = id => dispatch => {
  dispatch(storyGetStart())

  const request = {
    cmd: 'CMD_GET_DETAIL_STORY',
    storyId: `${id}`,
  }

  return HomeApi.getStoryByID(request)
    .then(data => {
      if (!data.succes) {
        throw Error(data.message || data.errMsg)
      }

      dispatch(storyGetDone(data.result))

      return Promise.resolve()
    })
    .catch(error => {
      dispatch(storyGetFail())

      return Promise.reject()
    })
}

const filterVideoStories = (filter = {}) => (dispatch, getState) => {
  dispatch(videoStoriesGetStart())

  const newFilter = getFilter('video', filter, getState)

  dispatch(videoStoriesUpdateFilter(newFilter))

  const request = {
    cmd: 'CMD_GET_VIDEO_ALL_STORIES',
    count: newFilter.size,
    retrieveCount: 'true',
    start: newFilter.from,
  }

  return HomeApi.filterStories(request)
    .then(data => {
      if (!data.succes) {
        throw Error(data.message || data.errMsg)
      }

      dispatch(videoStoriesGetDone(data.result))

      return Promise.resolve()
    })
    .catch(error => {
      dispatch(videoStoriesGetFail())

      return Promise.reject()
    })
}

const getShortVideoStories = () => dispatch => {
  dispatch(videoStoriesGetStart())

  const request = {
    cmd: 'CMD_GET_VIDEO_ALL_STORIES',
    count: '5',
    retrieveCount: 'true',
    start: '0',
  }

  return HomeApi.filterStories(request)
    .then(data => {
      if (!data.succes) {
        throw Error(data.message || data.errMsg)
      }

      dispatch(videoStoriesGetDone(data.result))

      return Promise.resolve()
    })
    .catch(error => {
      dispatch(videoStoriesGetFail())

      return Promise.reject()
    })
}

const getVideoStoryByID = id => dispatch => {
  dispatch(videoStoryGetStart())

  const request = {
    cmd: 'CMD_GET_VIDEO_DETAIL_STORY',
    storyId: `${id}`,
  }

  return HomeApi.getStoryByID(request)
    .then(data => {
      if (!data.succes) {
        throw Error(data.message || data.errMsg)
      }

      dispatch(videoStoryGetDone(data.result))

      return Promise.resolve()
    })
    .catch(error => {
      dispatch(videoStoryGetFail())

      return Promise.reject()
    })
}

export {
  getShortStories,
  filterStories,
  getStoryByID,
  clearStories,

  getShortVideoStories,
  filterVideoStories,
  getVideoStoryByID,
  clearVideoStories,
}
