import React from 'react'
import { connect } from 'react-redux'
import { getCountriesCount, getCountriesOptions } from 'service/country/selectors'
import { getList as getCountryList } from 'service/country/actions/getList'
import { signUp } from 'service/auth/actions'
import { getUserIpInfo } from 'service/location/actions'
import { locationLoading, registrationIsRestricted } from 'service/location/selectors'
import {
  Grid,
  Button,
  TextField,
  Typography,
  FormControl,
} from '@mui/material'
import { compose } from 'redux'
import { withStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import Layout from 'views/Home/UserView/Layout'
import styles from './styles'
import sx from './sx'

const RecoverPassword = ({}) => {
  const { t } = useTranslation('auth')

  function handleOnSubmitRecovering(event) {
    event.preventDefault()

    alert('Not implemented yet.')
  }

  return (
    <Layout>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item>
          <Typography variant="h5">
            {t('recovering.title')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            {t('recovering.description')}
          </Typography>
        </Grid>
      </Grid>

      <Grid sx={sx.form}>
        <FormControl
          component="form"
          onSubmit={handleOnSubmitRecovering}
          fullWidth
        >
          <TextField
            name="password"
            InputProps={{
              sx: sx.textField,
              inputProps: { sx: sx.input },
            }}
            placeholder={t('recovering.input.email')}
            fullWidth
          />
          <Grid container justifyContent="center" sx={sx.sendButton}>
            <Button type="submit">
              {t('recovering.action.send')}
            </Button>
          </Grid>
        </FormControl>
      </Grid>
    </Layout>
  )
}


const mapStateToProps = state => ({
  countriesCount: getCountriesCount(state),
  countriesOptions: getCountriesOptions(state),
  registrationIsRestricted: registrationIsRestricted(state),
  locationInfoLoadgin: locationLoading(state),
})

const mapDispatchToProps = {
  signUp,
  getCountryList,
  getUserIpInfo,
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(RecoverPassword)
