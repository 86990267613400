import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import Layout from './Layout'

const Point = ({ point, subpoints, style }) => {
  return (
    <>
      {point ? (
        <>
          <Typography component={style ? 'ul' : undefined}>{point}</Typography>

        </>
      ) : null}
      {subpoints?.length > 0 ? subpoints.map(item => (
        <>
          <Point point={item.point} subpoints={item.subpoints} style={item.style} />
        </>
      )) : null}
      {point || subpoints?.length > 0 ? <br /> : null}
    </>
  )
}

const TermsView = () => {
  const { t } = useTranslation('home')

  const content = useMemo(() => t('pages.terms.subpoints', { returnObjects: true }).map(item => (
    <>
      <Point point={item.point} subpoints={item.subpoints} style={item.style} />
    </>
  )), [t])

  return (
    <Layout header={t('pages.terms.header')}>
      <Typography variant="body2">
        {t('pages.terms.update')}
      </Typography>
      <br />
      {content}
    </Layout>
  )
}

export default TermsView
