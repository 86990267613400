import React from 'react'

import { ThemeProvider } from '@mui/material/styles'
import { createBrowserHistory } from 'history'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'

import i18n from 'utils/i18n'

import store from './redux/store'
// import 'bootstrap/dist/css/bootstrap.css'
// import SingUp from './components/SingUp'
import Application from './views/Application'
import applicationTheme from './theme'
// import DetailNews from './components/DetailNews'
// import ErrorPage from './components/ErrorPage'
// import AllNews from './components/AllNews'
// import ForgotPassword from './components/ForgotPassword'
// import DetailStory from './components/DetailStory'
// import DetailVideoStory from './components/DetailVideoStory'
// import DetailFeaturedAndServices from './components/DetailFeaturedAndServices'
// import Page from './components/Page'
// import AllStoriesAndFeedback from './components/AllStoriesAndFeedback'

const hist = createBrowserHistory()

ReactDOM.render((
  <ThemeProvider theme={applicationTheme}>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <Application history={hist} />
      </Provider>
    </I18nextProvider>
  </ThemeProvider>
), document.getElementById('root'))

export { hist }
