import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TYPE } from 'components/ViewMedia/ViewMedia'
import ViewMedia from 'components/ViewMedia'
import {
  Typography,
} from '@mui/material'
import Section from 'components/Section'
import { compose } from 'redux'
import { useTranslation } from 'react-i18next'
import { withStyles } from '@mui/styles'
import FilterSection from 'components/FilterSection'
import styles from './styles'
import FavoriteMediaItem from './FavoriteMediaItem'


const ManFavoritesMedia = ({ classes, type, items, itemsCount, pagination, onDelete }) => {
  const { t } = useTranslation('text')

  const [mediaToView, setMediaToView] = useState(null)

  function handleOnClick(item) {
    setMediaToView({ index: 0, item, type })
  }

  function handleOnDelete(item) {
    onDelete(item)
  }

  function handleOnCloseMediaView() {
    setMediaToView(null)
  }

  const prepareTitle = (t, type) => (type === TYPE.PHOTO
    ? t('favorite.photo.title')
    : t('favorite.video.title'))

  function renderItem(item) {
    return (
      <FavoriteMediaItem
        t={t}
        classes={classes}
        type={type}
        item={item}
        onClick={handleOnClick}
        onDelete={handleOnDelete}
      />
    )
  }

  const renderData = () => {
    return (
      <FilterSection
        items={items}
        page={pagination.currentPage}
        count={pagination.pagesCount}
        itemsCount={itemsCount}
        labelItemsCount={t('favorite.result.foundLabel')}
        noDataMessage={t('general.noItems')}
        // onPageChanged={this.props.onPageChanged}
        renderComponent={renderItem}
        // renderPrimaryFilter={filter}
      />
    )
  }

  return (
    <div>
      <Typography
        key="settings-title"
        className={classes.title}
        variant="h4"
      >
        {t('ladies.title')}
      </Typography>
      <Section title={prepareTitle(t, type)}>
        {renderData()}
      </Section>
      <ViewMedia
        key="profile-view-media-dialog"
        type={mediaToView?.type}
        selectedIndex={0}
        title={mediaToView?.type === TYPE.VIDEO ? t('general.video') : t('general.photo')}
        items={mediaToView?.item ? [mediaToView.item] : []}
        show={Boolean(mediaToView)}
        onClose={handleOnCloseMediaView}
      />
    </div>
  )
}

ManFavoritesMedia.propTypes = {
  /** Type of card to show content. */
  type: PropTypes.oneOf([TYPE.PHOTO, TYPE.VIDEO]),
  /** Array of data for view. */
  items: PropTypes.array,
  /** All items total count. */
  itemsCount: PropTypes.number,
  /** Pagination info object. Holds current page and whole pages count info. */
  pagination: PropTypes.shape({
    pagesCount: PropTypes.number,
    currentPage: PropTypes.number,
  }),
}

ManFavoritesMedia.defaultProps = {
  type: TYPE.PHOTO,
  items: [],
  itemsCount: 0,
  pagination: {
    pagesCount: 0,
    currentPage: 0,
  },
}

export default compose(
  withStyles(styles),
)(ManFavoritesMedia)
