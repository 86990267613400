import React, { useEffect } from 'react'
import { getSelectedLadyInfoShort } from 'service/ladies/selectors'

import Section from 'components/Section'
import {
  Button,
  Grid,
  Typography,
} from '@mui/material'
import { getByID } from 'service/ladies/actions/get'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { compose } from 'redux'
import updateHistory from 'utils/history'
import { appURLs, ladyPhotoURL } from 'navigation/routes'
import MailEditor from 'components/MailEditor'
import Autocomplete from 'components/Autocomplete'
import sx from './sx'
import PersonInfo from './PersonInfo'

const WriteNewMail = ({ personInfo, getByID }) => {
  const { personID } = useParams()

  useEffect(() => {
    if (!isNaN(personID)) {
      if (personInfo.id !== Number(personID)) {
        getByID(personID)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleGoToSearch() {
    updateHistory('push', `${appURLs.LAIDES_SEARCH}`)
  }

  return (
    <Section title="Write a mail">
      <Grid container sx={sx.container}>
        <Grid item container justifyContent="center" xs={12} md={6}>
          <Grid item xs={12}>
            <Autocomplete
              value={personInfo}
              TextFieldProps={{
                label: 'Search a person by name',
                variant: 'outlined',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button sx={sx.goToSearch} variant="outlined" onClick={handleGoToSearch}>
              Or select one from a search
            </Button>
          </Grid>

        </Grid>
        <Grid item container justifyContent="center" xs={12} md={6}>
          {personInfo ? <PersonInfo item={personInfo} photoURL={ladyPhotoURL} />
            : <Typography> Select a person first</Typography>}


        </Grid>
      </Grid>
      <Grid>
        <MailEditor />
      </Grid>
      <Grid container justifyContent="center" sx={sx.container}>
        <Button disabled={!personInfo}>
          Send
        </Button>
      </Grid>
    </Section>

  )
}

const mapStateToProps = state => ({
  // lady
  personInfo: getSelectedLadyInfoShort(state),
})

const mapDispatchToProps = {
  getByID,
}


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(WriteNewMail)
