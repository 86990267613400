import { createAction, handleActions } from 'redux-actions'

const initialState = {
  fetching: false,
  // photos
  photos: [],
  photosCount: 0,
  filterPhoto: {
    from: 0,
    size: 6,
  },
  // videos
  videos: [],
  videosCount: 0,
  filterVideo: {
    from: 0,
    size: 6,
  },
}

// actions
const filterFavoritePhotosStart = createAction('FILTER_FAVORITE_PHOTOS_START')
const filterFavoritePhotosFail = createAction('FILTER_FAVORITE_PHOTOS_FAIL')
const filterFavoritePhotosDone = createAction('FILTER_FAVORITE_PHOTOS_DONE')

const filterFavoriteVideosStart = createAction('FILTER_FAVORITE_VIDEOS_START')
const filterFavoriteVideosFail = createAction('FILTER_FAVORITE_VIDEOS_FAIL')
const filterFavoriteVideosDone = createAction('FILTER_FAVORITE_VIDEOS_DONE')

const addFavoritePhotoStart = createAction('FILTER_ADD_FAVORITE_PHOTOS_START')
const addFavoritePhotoFail = createAction('FILTER_ADD_FAVORITE_PHOTOS_FAIL')
const addFavoritePhotoDone = createAction('FILTER_ADD_FAVORITE_PHOTOS_DONE')

const removeFavoritePhotoStart = createAction('FILTER_REMOVE_FAVORITE_PHOTOS_START')
const removeFavoritePhotoFail = createAction('FILTER_REMOVE_FAVORITE_PHOTOS_FAIL')
const removeFavoritePhotoDone = createAction('FILTER_REMOVE_FAVORITE_PHOTOS_DONE')


// reducer
const filterPhotosStart = (state, { payload }) => ({ ...state, filterPhoto: payload, fetching: true })
const filterPhotosFail = state => ({ ...state, fetching: false })
const filterPhotosDone = (state, { payload }) => ({
  ...state,
  fetching: false,
  photos: payload?.photos || [],
  photosCount: payload?.count || 0,
})

const filterVideosStart = (state, { payload }) => ({ ...state, filterVideo: payload, fetching: true })
const filterVideosFail = state => ({ ...state, fetching: false })
const filterVideosDone = (state, { payload }) => ({
  ...state,
  fetching: false,
  videos: payload?.videos || [],
  videosCount: payload?.count || 0,
})

const favoritePhotoStart = state => ({ ...state, fetching: true })
const favoritePhotoFail = state => ({ ...state, fetching: false })
const favoritePhotoDone = state => ({ ...state, fetching: false })

const unfavoriteStart = state => ({ ...state, fetching: true })
const unfavoriteFail = state => ({ ...state, fetching: false })
const unfavoriteDone = state => ({ ...state, fetching: false })


const reducers = handleActions({
  FILTER_FAVORITE_PHOTOS_START: filterPhotosStart,
  FILTER_FAVORITE_PHOTOS_FAIL: filterPhotosFail,
  FILTER_FAVORITE_PHOTOS_DONE: filterPhotosDone,

  FILTER_FAVORITE_VIDEOS_START: filterVideosStart,
  FILTER_FAVORITE_VIDEOS_FAIL: filterVideosFail,
  FILTER_FAVORITE_VIDEOS_DONE: filterVideosDone,

  FILTER_ADD_FAVORITE_PHOTOS_START: favoritePhotoStart,
  FILTER_ADD_FAVORITE_PHOTOS_FAIL: favoritePhotoFail,
  FILTER_ADD_FAVORITE_PHOTOS_DONE: favoritePhotoDone,

  FILTER_REMOVE_FAVORITE_PHOTOS_START: unfavoriteStart,
  FILTER_REMOVE_FAVORITE_PHOTOS_FAIL: unfavoriteFail,
  FILTER_REMOVE_FAVORITE_PHOTOS_DONE: unfavoriteDone,
}, initialState)

export {
  filterFavoritePhotosStart,
  filterFavoritePhotosFail,
  filterFavoritePhotosDone,

  filterFavoriteVideosStart,
  filterFavoriteVideosFail,
  filterFavoriteVideosDone,

  addFavoritePhotoStart,
  addFavoritePhotoFail,
  addFavoritePhotoDone,

  removeFavoritePhotoStart,
  removeFavoritePhotoFail,
  removeFavoritePhotoDone,
}

export default reducers
