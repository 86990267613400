import React from 'react'
import Image from './Components/Image'

import Youtube from './Components/Youtube'

import {
  LIST_ITEM,
  LIST_BULLETED,
  LIST_NUMBERED,
  HORIZONAL,
  LINK,
  QUOTE,
  CODE,
  ALIGNT_LEFT,
  ALIGNT_CENTER,
  ALIGNT_RIGHT,
  ALIGNT_JUSTIFY,
  IMAGE,
  YOUTUBE,
} from './types'


const Element = props => {
  const { attributes, children, element } = props


  switch (element.type) {
    default:
      return <p {...attributes}>{children}</p>
    case QUOTE:
      return <blockquote {...attributes}>{children}</blockquote>
    case CODE:
      return (
        <pre>
          <code {...attributes}>{children}</code>
        </pre>
      )

    case LIST_ITEM:
      return <li {...attributes}>{children}</li>
    case LIST_NUMBERED:
      return <ol {...attributes}>{children}</ol>
    case LIST_BULLETED:
      return <ul {...attributes}>{children}</ul>
    case HORIZONAL:
      return <hr />

    case ALIGNT_LEFT:
      return <p {...attributes}>{children}</p>
    case ALIGNT_CENTER:
      return <p {...attributes} style={{ textAlign: 'center' }}>{children}</p>
    case ALIGNT_RIGHT:
      return <p {...attributes} style={{ textAlign: 'right' }}>{children}</p>
    case ALIGNT_JUSTIFY:
      return <p {...attributes} style={{ textAlign: 'justify' }}>{children}</p>

    case LINK:
      return (
        <a href={element.url} {...attributes}>
          {children}
        </a>
      )
    case YOUTUBE:
      return <Youtube {...props} />
    case IMAGE:
      return <Image {...props} />
  }
}

export default Element
