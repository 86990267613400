const religionOptions = [
  {
    label: 'Buddhism',
    value: 'BUDDHISM',
  },
  {
    label: 'Paganism',
    value: 'PAGANISM',
  },
  {
    label: 'Muslim',
    value: 'MUSLIM',
  },
  {
    label: 'Jewish',
    value: 'JEWISH',
  },
  {
    label: 'Russian Ortodox',
    value: 'RUSSIAN_ORTODOX',
  },
  {
    label: 'Catholic',
    value: 'CATHOLIC',
  },
  {
    label: 'Baptist',
    value: 'BAPTIST',
  },
  {
    label: 'Christian',
    value: 'CHRISTIAN',
  },
  {
    label: 'Protestant',
    value: 'PROTESTANT',
  },
  {
    label: 'Lutheran',
    value: 'LUTHERAN',
  },
  {
    label: 'Mormon',
    value: 'MORMON',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
  {
    label: 'NotReligious',
    value: 'NOT_RELIGIOUS',
  },
]

const drinkingOptions = [
  {
    label: 'Never',
    value: 'NEVER',
  },
  {
    label: 'Socially',
    value: 'SOCIALLY',
  },
  {
    label: 'Occasionally',
    value: 'OCCASIONALLY',
  },
]

const smokingOptions = [
  {
    label: 'Yes',
    value: 'YES',
  },
  {
    label: 'Never',
    value: 'NEVER',
  },
]

const educationOptions = [
  {
    label: 'University degree',
    value: 'UNIVERSITY_DEGREE',
  },
  {
    label: 'University unfinished',
    value: 'UNIVERSITY_UNFINISHED',
  },
  {
    label: 'Medical degree',
    value: 'MEDICAL_DEGREE',
  },
  {
    label: 'Student',
    value: 'STUDENT',
  },
  {
    label: 'College',
    value: 'COLLEGE',
  },
  {
    label: 'High school',
    value: 'HIGH_SCHOOL',
  },
]

const martialStatusOptions = [
  {
    label: 'Never',
    value: 'NEVER',
  },
  {
    label: 'Divorced',
    value: 'DIVORCED',
  },
  {
    label: 'Widow',
    value: 'WIDOW',
  },
  {
    label: 'Widower',
    value: 'WIDOWER',
  },
]

const hairOptions = [
  {
    label: 'Blond',
    value: 'BLOND',
  },
  {
    label: 'Brown',
    value: 'BROWN',
  },
  {
    label: 'Black',
    value: 'BLACK',
  },
  {
    label: 'Fair',
    value: 'FAIR',
  },
  {
    label: 'Chestnut',
    value: 'CHESTNUT',
  },
  {
    label: 'Red',
    value: 'RED',
  },
]

const eyeOptions = [
  {
    label: 'Blue',
    value: 'BLUE',
  },
  {
    label: 'Brown',
    value: 'BROWN',
  },
  {
    label: 'Green',
    value: 'GREEN',
  },
  {
    label: 'Gray',
    value: 'GRAY',
  },
  {
    label: 'Hazel',
    value: 'HAZEL',
  },
]

const getLabel = (options, val) => options.find(o => o.value === val)?.label || val

export {
  religionOptions,
  drinkingOptions,
  smokingOptions,
  educationOptions,
  martialStatusOptions,
  hairOptions,
  eyeOptions,

  getLabel,
}
