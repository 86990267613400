const mapFilterToApi = filter => ({
  ...filter.id && { id: Number(filter.id) },

  from: filter.from,
  size: filter.size,

  ...filter.sort && { sort: filter.sort },
})

export {
  mapFilterToApi,
}
