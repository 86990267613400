import { TYPE } from 'components/ViewMedia/ViewMedia'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { filterPhotos } from 'service/favoriteMedia/actions/filter'
import {
  getLoading,
  getPhotos,
  getPhotosCount,
  getPhotosFilter,
  getPhotosPagination,
} from 'service/favoriteMedia/selectors'
import { photoRemoveFavorite } from 'service/favoriteMedia/actions/photo'
import ManFavoritesMedia from './ManFavoritesMedia'

const ManFavoritePhotos = ({ loading, photos, count, filter, pagination, filterPhotos, photoRemoveFavorite }) => {
  useEffect(() => {
    filterPhotos({ from: 0, size: 6 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleOnDelete(item) {
    photoRemoveFavorite(item.id).then(filterPhotos)
  }

  return (
    <ManFavoritesMedia
      type={TYPE.PHOTO}
      loading={loading}
      items={photos}
      itemsCount={count}
      filter={filter}
      pagination={pagination}
      onDelete={handleOnDelete}
    />
  )
}

const mapStateToProps = state => ({
  loading: getLoading(state),

  photos: getPhotos(state),
  count: getPhotosCount(state),
  filter: getPhotosFilter(state),
  pagination: getPhotosPagination(state),
})

const mapDispatchToProps = {
  filterPhotos,
  photoRemoveFavorite,
}


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ManFavoritePhotos)
