export default {
  photoContainer: height => ({
    width: '100%',
    height,
    borderRadius: 2,
    overflow: 'hidden',
  }),
  photo: {
    width: '100%',
    borderRadius: 2,
  },
  description: {
    height: 100,
    overflow: 'hidden',
    WebkitLineClamp: 4,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
  },
  photoContainerCarousel: {
    borderRadius: 1,
    width: '100%',
    height: 350,
    overflow: 'hidden',
  },
  clickable: {
    cursor: 'pointer',
  },
  playerContainer: {
    borderRadius: 1,
    overflow: 'hidden',
  },
}
