import React from 'react'
import PropTypes from 'prop-types'
import Section from 'components/Section'
import { Button, FormControl, Grid } from '@mui/material'
import { connect } from 'react-redux'
import { getList as getCountryList } from 'service/country/actions/getList'
import { getCountriesCount, getCountriesOptions } from 'service/country/selectors'
import ContentHorizontalScroll from 'components/ContentHorizontalScroll'
import { uploadManPhoto, uploadManVideo } from 'service/upload/actions/uploadManMedia'
import FileInput from 'components/FileInput'
import { updateMe, clearUploadedMedia } from 'service/man/actions/me'
import ViewMedia from 'components/ViewMedia'
import { TYPE } from 'components/ViewMedia/ViewMedia'
import enqueueMessage from 'service/notification/enqueue'
import ProfileMediaCard from 'components/ContentHorizontalScroll/ProfileMediaCard'
import { Box } from '@mui/system'
import AccountInfoSection from './AccountInfoSection'
import AboutMeSection from './AboutMeSection'
import sx from './sx'

const photoExt = ['.jpg', '.jpeg', '.png']
const videoExt = ['.mp4', '.ogv', '.webm', '.flv']

class ManProfileEdit extends React.Component {
  constructor(props) {
    super(props)

    const { profileInfo } = props

    this.state = {
      userPhotos: profileInfo.photos,
      removedPhotoIDs: [],
      uploadedPhotosIDs: [],
      selectedPhoto: null,

      userVideos: profileInfo.videos,
      removedVideoIDs: [],
      uploadedVideosIDs: [],
      selectedVideo: null,

      mediaToView: null,
    }
  }

  componentDidMount() {
    const { countriesCount, getCountryList } = this.props

    if (!countriesCount) {
      getCountryList()
    }

    window.addEventListener('beforeunload', () => this.props.clearUploadedMedia(this.state))
  }

  getProfilePhotos = () => this.state.userPhotos
    .filter(photo => this.state.removedPhotoIDs.findIndex(id => id === photo.id) === -1)

  getProfileVideos = () => this.state.userVideos
    .filter(video => this.state.removedVideoIDs.findIndex(id => id === video.id) === -1)


  hanldeOnPhotoDelete = (index, item) => {
    const removedPhotoIDs = [...this.state.removedPhotoIDs]

    removedPhotoIDs.push(item.id)

    this.setState({ removedPhotoIDs })

    if (item.main) {
      this.handleOnSelectPhotoAsMain()
    }
  }

  hanldeOnVideoDelete = (index, item) => {
    const removedVideoIDs = [...this.state.removedVideoIDs]

    removedVideoIDs.push(item.id)

    this.setState({ removedVideoIDs })
  }

  handleOnSelectPhotoAsMain = (index = 0, item) => {
    let userPhotos = [...this.state.userPhotos]

    let indexOfNew = index

    // if removed
    if (indexOfNew === 0 || item === undefined) {
      const photos = this.getProfilePhotos()

      for (let i = 0; i < photos.length; i++) {
        if (!photos[i].main) {
          indexOfNew = userPhotos.findIndex(photo => photo.id === photos[i].id)
          break
        }
      }
    } else {
      indexOfNew = userPhotos.findIndex(photo => photo.id === item.id)
    }

    userPhotos = userPhotos.map((item, arrIndex) => ({
      ...item,
      main: arrIndex === indexOfNew,
    }))

    this.setState({ userPhotos })
  }

  handleOnPhotoClick = (index, item) => this.setState({ mediaToView: { index, item, type: TYPE.PHOTO } })
  handleOnVideoClick = (index, item) => this.setState({ mediaToView: { index, item, type: TYPE.VIDEO } })

  handleOnCloseMediaView = () => this.setState({ mediaToView: null })

  handleCancelUpdateProfileInfo = () => {
    this.props.clearUploadedMedia(this.state)
    this.props.onCancel()
  }


  handleOnChangeFiles = event => this.setState({ [event.target.name]: event.target.files[0] })

  handleOnFileValidationFailed = type => enqueueMessage(this.props.t(`errors.profile.media.${type}`), 'error')

  handleOnChange = event => this.setState({ [event.target.name]: event.target.value })


  handleUpdateProfileInfo = () => this.props.updateMe(this.state)
    .then(() => this.props.onCancel())

  handleUploadSelectedPhoto = () => this.props.uploadManPhoto(this.state.selectedPhoto)
    .then(uploadedPhotoData => this.setState({
      selectedPhoto: null,
      userPhotos: this.state.userPhotos.concat({
        ...uploadedPhotoData,
        notSaved: true,
      }),
      uploadedPhotosIDs: uploadedPhotoData.id,
    }))
    .catch(() => {}) // TODO show some pop-up

  handleUploadSelectedVideo = () => this.props.uploadManVideo(this.state.selectedVideo)
    .then(uploadedVideoData => this.setState({
      selectedVideo: null,
      userVideos: this.state.userVideos.concat({
        ...uploadedVideoData,
        notSaved: true,
      }),
      uploadedVideosIDs: uploadedVideoData.id,
    }))
    .catch(() => {}) // TODO show some pop-up

  handleOnSubmit = event => {
    event.preventDefault()

    this.props.updateMe({
      ...this.state,
      firstName: event.target.firstName.value,
      lastName: event.target.lastName.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      streetAddres: event.target.streetAddres.value,
      countryId: event.target.countryId.value,

      birthday: event.target.birthday.value,
      religion: event.target.religion.value,

      drinker: event.target.drinker.value,

      smoker: event.target.smoker.value,
      hairColor: event.target.hairColor.value,
      eyeColor: event.target.eyeColor.value,
      maritalStatus: event.target.maritalStatus.value,
      job: event.target.job.value,

      nativeLanguage: event.target.nativeLanguage.value,
      secondLanguage: event.target.secondLanguage.value,
      education: event.target.education.value,
      childrenCount: event.target.childrenCount.value,
      childrenAge: event.target.childrenAge.value,
      childrenSex: event.target.childrenSex.value,
      character: event.target.character.value,
      hobbies: event.target.hobbies.value,
      lookingFor: event.target.lookingFor.value,
      lookingAgeFrom: event.target.lookingAgeFrom.value,
      lookingAgeTo: event.target.lookingAgeTo.value,

      heightType: event.target.heightType.value,
      weightType: event.target.weightType.value,
      weight: event.target.weight.value,
      height: event.target.height.value,
    }).then(this.props.onCancel)
  }


  renderFileInput = (fieldName, buttonLabel, extensions, onClick) => (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <FileInput
          name={fieldName}
          filesLabel={this.state[fieldName]?.name}
          extensions={extensions}
          onChange={this.handleOnChangeFiles}
          onValidationFailed={this.handleOnFileValidationFailed}
          sizeLimit={5242880} // 5MB
          strict
        />
      </Grid>
      <Grid item xs={3}>
        <Button disabled={!this.state[fieldName]} onClick={onClick}>
          {buttonLabel}
        </Button>
      </Grid>
    </Grid>
  )

  renderPhotoItem = props => {
    return (
      <ProfileMediaCard
        onDelete={this.hanldeOnPhotoDelete}
        onMain={this.handleOnSelectPhotoAsMain}
        {...props}
      />
    )
  }

  renderVideoItem = props => {
    return (
      <ProfileMediaCard
        onDelete={this.hanldeOnVideoDelete}
        {...props}
      />
    )
  }

  renderPhotoMediaContent = () => (
    <ContentHorizontalScroll
      key="upload-photo-section-container"
      type={TYPE.PHOTO}
      items={this.getProfilePhotos()}
      onSelect={this.handleOnPhotoClick}
      renderItem={this.renderPhotoItem}
    />
  )

  renderVideoMediaContent = () => (
    <ContentHorizontalScroll
      key="upload-video-section-container"
      type={TYPE.VIDEO}
      items={this.getProfileVideos()}
      onSelect={this.handleOnVideoClick}
      renderItem={this.renderVideoItem}
    />
  )

  render() {
    const { t, profileInfo, countriesOptions } = this.props
    const { mediaToView } = this.state

    const videoMediaView = this.renderVideoMediaContent()
    const photosMediaView = this.renderPhotoMediaContent()

    return (
      <FormControl
        component="form"
        sx={sx.form}
        onSubmit={this.handleOnSubmit}
      >
        <AccountInfoSection
          general={profileInfo.general}
          countriesOptions={countriesOptions}
        />
        <AboutMeSection
          general={profileInfo.general}
          additional={profileInfo.additional}
        />
        <Section title={t('profile.section.photosUpload')}>
          <>
            <Box sx={sx.fileLine}>
              {photosMediaView}
            </Box>
            {this.renderFileInput('selectedPhoto', t('profile.actions.upload.photo'), photoExt, this.handleUploadSelectedPhoto)}
          </>
        </Section>
        <Section title={t('profile.section.videosUpload')}>
          <>
            <Box sx={sx.fileLine}>
              {videoMediaView}
            </Box>
            {this.renderFileInput('selectedVideo', t('profile.actions.upload.video'), videoExt, this.handleUploadSelectedVideo)}
          </>
        </Section>
        <Grid container justifyContent="center">
          <Button type="submit">
            {t('general.save')}
          </Button>
          <Button variant="text" onClick={this.handleCancelUpdateProfileInfo}>
            {t('general.cancel')}
          </Button>
        </Grid>
        <ViewMedia
          key="profile-view-media-dialog"
          type={mediaToView?.type}
          selectedIndex={mediaToView?.index}
          title={mediaToView?.type === TYPE.VIDEO ? t('general.video') : t('general.photo')}
          items={mediaToView?.type === TYPE.VIDEO ? this.getProfileVideos() : this.getProfilePhotos()}
          show={Boolean(mediaToView)}
          onClose={this.handleOnCloseMediaView}
        />
      </FormControl>
    )
  }
}

ManProfileEdit.defaultProps = {
  t: PropTypes.func.isRequired,
  profileInfo: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  countriesCount: getCountriesCount(state),
  countriesOptions: getCountriesOptions(state),
})

const mapDispatchToProps = {
  getCountryList,
  uploadManPhoto,
  uploadManVideo,
  updateMe,
  clearUploadedMedia,
}


export default connect(mapStateToProps, mapDispatchToProps)(ManProfileEdit)
