import Api from './Api'

class CountryApi {
  static getList = filter => {
    const path = `${Api.basePath}geo/countries/filter`
    return Api.post(path, filter).then(response => response.json())
  }
}

export default CountryApi
