import { link } from 'theme'

export default {
  container: {
    pt: 2,
    columnGap: 1,
    display: 'flex',
  },
  action: {
    backgroundColor: 'white',
    color: link.main,
    boxShadow: '0px 4px 12px 4px #00217726',
  },
}
