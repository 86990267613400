import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Fab,
} from '@mui/material'

import NotFavoriteIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import GiftIcon from '@mui/icons-material/CardGiftcardOutlined'
import MailIcon from '@mui/icons-material/MailOutline'

import updateHistory from 'utils/history'
import { appURLs } from 'navigation/routes'
import sx from './sx'

const CommonLadyActions = ({ item, onFavorite, onQuickMessage, onGift, onVirtualGift, mail }) => {
  // handlers

  function handleOnMailClick() {
    updateHistory('push', `${appURLs.MAIL_WRITE_NEW_BASE}${item.id}`)
  }

  // renderers

  const renderFAB = (icon, onClick) => {
    if (!onClick) {
      return null
    }

    return (
      <Fab sx={sx.action} size="small" onClick={onClick}>
        {icon}
      </Fab>
    )
  }

  return (
    <Box sx={sx.container}>
      {renderFAB(item.favorite ? <FavoriteIcon /> : <NotFavoriteIcon />, onFavorite)}
      {renderFAB(<ChatBubbleOutlineIcon />, onQuickMessage)}
      {renderFAB(<GiftIcon />, onGift)}
      {renderFAB(<GiftIcon />, onVirtualGift)}
      {renderFAB(<MailIcon />, mail && handleOnMailClick)}
    </Box>
  )
}

CommonLadyActions.propTypes = {
  /** Lady item with neccessary info. */
  item: PropTypes.object.isRequired,
  /** Callback when clicking on favorite button. */
  onFavorite: PropTypes.func,
  /** Callback when clicking on quick message button. */
  onQuickMessage: PropTypes.func,
  /** Callback when clicking on gift button. */
  onGift: PropTypes.func,
  /** Callback when clicking on virtual gift button. */
  onVirtualGift: PropTypes.func,
  /** Define show mail button or not (Common action for each view). */
  mail: PropTypes.bool,
}

export default CommonLadyActions
