import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import headerImage from 'assets/home/voiceChat_header.png'
import { Trans, useTranslation } from 'react-i18next'
import HomeLayout from 'views/Home/HomeLayout'

const VideoChat = () => {
  const { t } = useTranslation('home')

  return (
    <HomeLayout
      image={headerImage}
      header={t('sections.services.item.voiceChat')}
      margin
      useTitle
    >
      <Stack container rowGap={4}>
        {t('features.chat.content', { returnObjects: true }).map(item => (
          <>
            <Stack rowGap={2}>
              <Typography variant="h6">
                {item.title}
              </Typography>
              {item.points.map(point => (
                <>
                  <Typography>
                    <Trans i18nKey={point} />
                  </Typography>
                </>
              ))}
            </Stack>
          </>
        ))}
      </Stack>
    </HomeLayout>
  )
}

export default VideoChat
