import { createSelectorCreator, defaultMemoize } from 'reselect'
import isEqual from 'lodash/isEqual'
import createGeneralPagination from 'utils/pagination'

const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual,
)

const getNews = createDeepEqualSelector(
  state => state.news.data.newsList,
  list => list || [],
)

const getNewsSingle = createDeepEqualSelector(
  state => state.news.item,
  item => item || {},
)

const getNewsPagination = createDeepEqualSelector(
  state => state.news.data.totalCount,
  state => state.news.filter,
  (count, filter) => createGeneralPagination(count, filter),
)

const getNewsFilter = createDeepEqualSelector(
  state => state.news.filter,
  filter => filter || {},
)

export {
  getNews,
  getNewsSingle,
  getNewsFilter,
  getNewsPagination,
}
