import {
  FiberManualRecord,
} from '@mui/icons-material'
import {
  Typography,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Button,
} from '@mui/material'
import Section from 'components/Section'
import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import sx from './sx'


const quide = [
  {
    header: 'Select amount of coins.',
  },
  {
    header: 'Press "Pay" button.',
  },
  {
    header: 'Fill card information.',
  },
  {
    header: 'Put tick against terms and conditions.',
  },
  {
    header: 'Click on the button "Pay now"',
  },
  {
    header: 'After you see the message "Redirectering to merchant" click the button "Redirect"',
  },
  {
    header: 'Then you will be redirected at site to that page https://dreamonelove.com/gloriabrides/success  and you will see the message "Success". ',
  },
  {
    header: 'You may close the window. Reload page on the site and coins are on your account.',
  },
]

const payments = [
  {
    count: 1450, price: 500, per: 35,
  },
  {
    count: 1000, price: 350, per: 35,
  },
  {
    count: 572, price: 200, per: 35,
  },
  {
    count: 286, price: 100, per: 35,
  },
  {
    count: 200, price: 70, per: 35,
  },
  {
    count: 158, price: 55, per: 35,
  },
  {
    count: 100, price: 35, per: 35,
  },
  {
    count: 40, price: 14, per: 35,
  },
  {
    count: 20, price: 7, per: 35,
  },
]

const PurchaseCoins = ({ }) => {
  const { t } = useTranslation('finanses')

  const [selectedPayment, setSelectedPayment] = useState(payments[0])


  function handleOnSelectPayment(event) {
    setSelectedPayment(payments.find(item => item.count === Number(event.target.value)))
  }

  const renderItem = item => (
    <Grid container>
      <Grid item container alignItems="center" xs={4}>
        <Typography>
          {t('purchase.item.coins', { count: item.count })}
        </Typography>
      </Grid>
      <Grid item container alignItems="center" xs={4}>
        <Typography>
          {t('purchase.item.euroPer', { count: item.per })}
        </Typography>
      </Grid>
      <Grid item container alignItems="center" xs={4}>
        <Typography>
          {t('purchase.item.euroTotal', { count: item.price })}
        </Typography>
      </Grid>
    </Grid>
  )

  return (
    <Section title={t('purchase.label')}>
      <Grid sx={sx.part}>
        <Typography>
          {t('purchase.description')}
        </Typography>
      </Grid>
      <Divider />
      <Grid sx={sx.part}>
        <FormControl component="fieldset" fullWidth>
          <RadioGroup
            aria-label="gender"
            defaultValue={selectedPayment.count}
            name="radio-buttons-group"
            onChange={handleOnSelectPayment}
            sx={{
              width: '100%',
            }}
          >
            {payments.map(payment => (
              <FormControlLabel
                key={payment.count}
                sx={sx.label}
                value={payment.count}
                label={renderItem(payment)}
                control={<Radio />}
                disableTypography
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid container justifyContent="center">
        <Button>
          {t('purchase.buy', { count: selectedPayment.count, price: selectedPayment.price })}
        </Button>
      </Grid>
      {/* <Grid container spacing={2}>
        <Grid item md={7}>
          <List>
            {quide.map(item => (
              <ListItem key={item.header}>
                <ListItemIcon sx={sx.listIconContainer}>
                  <FiberManualRecord sx={sx.listIcon} />
                </ListItemIcon>
                <ListItemText primary={item.header} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item md={5}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              defaultValue={payments[0].count}
              name="radio-buttons-group"
            >
              {payments.map(payment => (
                <FormControlLabel
                  key={payment.count}
                  value={payment.count}
                  label={`${payment.count} coins`}
                  control={<Radio />}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid> */}


    </Section>
  )
}

export default PurchaseCoins
