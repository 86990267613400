const getHeight = (downMD, size) => {
  if (downMD) {
    return 200
  }

  return size === 3 ? 280 : 320
}

export default {
  item: (downMD, size) => ({
    height: getHeight(downMD, size),
    overflow: 'hidden',
    position: 'relative',
  }),
  hoverItem: {
    'position': 'absolute',
    'opacity': 0,
    'height': '100%',
    'width': '100%',
    ':hover': {
      opacity: 1,
      position: 'absolute',
      background: '#00000099',
      color: 'white',
    },
  },
  itemButton: {
    position: 'absolute',
    bottom: 24,
    left: 32,
    right: 32,
  },
  itemHeader: {
    textAlign: 'center',
    bottom: 72,
    position: 'absolute',
    left: 0,
    right: 0,
  },
}
