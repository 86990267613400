import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import auth from './auth'
import man from './man'
import country from './country'
import upload from './upload'
import notification from './notification'
import location from './location'
import ladies from './ladies'
import news from './news'
import stories from './stories'
import favoriteMedia from './favoriteMedia'

export default combineReducers({
  routing: routerReducer,
  auth,
  man,
  country,
  upload,
  notification,
  location,
  ladies,
  favoriteMedia,
  news,
  stories,
})
