import { TYPE } from 'components/ViewMedia/ViewMedia'
import React, { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { filterVideos } from 'service/favoriteMedia/actions/filter'
import { getLoading,
  getVideos,
  getVideosCount,
  getVideosFilter,
  getVideosPagination } from 'service/favoriteMedia/selectors'
import ManFavoritesMedia from './ManFavoritesMedia'

const ManFavoriteVideos = ({ loading, photos, count, filter, pagination, filterVideos }) => {
  useEffect(() => {
    filterVideos({ from: 0, size: 6 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ManFavoritesMedia
      type={TYPE.VIDEO}
      loading={loading}
      items={photos}
      itemsCount={count}
      filter={filter}
      pagination={pagination}
    />
  )
}


const mapStateToProps = state => ({
  loading: getLoading(state),

  photos: getVideos(state),
  count: getVideosCount(state),
  filter: getVideosFilter(state),
  pagination: getVideosPagination(state),
})

const mapDispatchToProps = {
  filterVideos,
}


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ManFavoriteVideos)
