import MediaApi from 'api/FavoriteMediaApi'
import {
  addFavoritePhotoDone,
  addFavoritePhotoFail,
  addFavoritePhotoStart,
  removeFavoritePhotoDone,
  removeFavoritePhotoFail,
  removeFavoritePhotoStart,
} from 'redux/reducers/favoriteMedia'
import { updateLadyProfile } from 'redux/reducers/ladies'
import enqueueMessage from 'service/notification/enqueue'

const photoAddFavorite = (id, updateProfile = false) => (dispatch, getState) => {
  dispatch(addFavoritePhotoStart())

  return MediaApi.addPhotoFavorite(id)
    .then(data => {
      if (data.errMsg) {
        throw Error(data.message || data.errMsg)
      }

      // #DIRECT_UPDATE - ladies.profile
      if (updateProfile) {
        const photoIndex = getState().ladies.profile.ladyPhotoDTOs.findIndex(photo => photo.id === id)

        dispatch(updateLadyProfile({ path: `ladyPhotoDTOs[${photoIndex}].favorite`, value: true }))
      }

      dispatch(enqueueMessage('success.favorite.photo.add', 'success'))

      return dispatch(addFavoritePhotoDone())
    })
    .catch(error => {
      dispatch(enqueueMessage(error.message, 'danger'))

      dispatch(addFavoritePhotoFail())

      return Promise.reject()
    })
}

const photoRemoveFavorite = (id, updateProfile = false) => (dispatch, getState) => {
  dispatch(removeFavoritePhotoStart())

  return MediaApi.removePhotoFavorite(id)
    .then(data => {
      if (data.errMsg) {
        throw Error(data.message || data.errMsg)
      }

      // #DIRECT_UPDATE - ladies.profile
      if (updateProfile) {
        const photoIndex = getState().ladies.profile.ladyPhotoDTOs.findIndex(photo => photo.id === id)

        dispatch(updateLadyProfile({ path: `ladyPhotoDTOs[${photoIndex}].favorite`, value: false }))
      }

      dispatch(enqueueMessage('success.favorite.photo.delete', 'success'))

      return dispatch(removeFavoritePhotoDone())
    })
    .catch(error => {
      dispatch(enqueueMessage(error.message, 'danger'))

      dispatch(removeFavoritePhotoFail())

      return Promise.reject()
    })
}

export {
  photoAddFavorite,
  photoRemoveFavorite,
}
