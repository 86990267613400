import LadiesApi from 'api/LadiesApi'
import { ladiesContactsFilterDone, ladiesContactsFilterFail, ladiesContactsFilterStart, ladiesDataClear, ladiesFavoriteFilterDone, ladiesFavoriteFilterFail, ladiesFavoriteFilterStart, ladiesFilterDone, ladiesFilterFail, ladiesFilterStart, ladiesFilterUpdate, ladiesNewestFilterDone, ladiesNewestFilterFail, ladiesNewestFilterStart, ladiesTopFilterDone, ladiesTopFilterFail, ladiesTopFilterStart, ladiesVistorsFilterDone, ladiesVistorsFilterFail, ladiesVistorsFilterStart } from 'redux/reducers/ladies'
import { mapFilterToApi } from 'service/ladies/mapper'
import enqueueMessage from 'service/notification/enqueue'

const handleResponse = (data, dispatch) => {
  if (data.errMsg) {
    throw Error(data.message || data.errMsg)
  }

  dispatch(ladiesFilterDone(data))

  return Promise.resolve()
}

const handleError = (error, dispatch) => {
  dispatch(enqueueMessage(error.message))

  dispatch(ladiesFilterFail())

  return Promise.reject()
}

const getFilter = (key, getState) => ({
  ...getState().ladies.filterBase,
  ...getState().ladies.filter[key],
})

const updateFilter = (filter = {}, key = 'default') => (dispatch, getState) => {
  const newFilter = {
    ...getState().ladies.filterBase,
    ...getState().ladies.filter[key],
    ...filter,
  }

  dispatch(ladiesFilterUpdate({ filter: newFilter, key }))

  return Promise.resolve(newFilter)
}

const clearData = () => dispatch => {
  dispatch(ladiesDataClear())

  return Promise.resolve()
}

const filter = (key = 'default') => (dispatch, getState) => {
  dispatch(ladiesFilterStart())

  return LadiesApi.filter(mapFilterToApi(getFilter(key, getState)))
    .then(data => handleResponse(data, dispatch))
    .catch(error => handleError(error, dispatch))
}


const filterNewest = (key = 'newest') => (dispatch, getState) => {
  dispatch(ladiesFilterStart())

  return LadiesApi.filter(mapFilterToApi(getFilter(key, getState)))
    .then(data => handleResponse(data, dispatch))
    .catch(error => handleError(error, dispatch))
}

const filterTop = (filter = {}, limit) => (dispatch, getState) => {
  const newFilter = {
    ...getState().ladies.filterTop,
    ...filter,
  }

  if (newFilter.from + newFilter.size > 100) {
    newFilter.size = 100 - newFilter.from
  }

  const newLimit = limit || getState().ladies.topLimit

  dispatch(ladiesTopFilterStart({ filter: newFilter, limit: newLimit }))

  return LadiesApi.filterTop(mapFilterToApi(newFilter))
    .then(data => {
      if (data.errMsg) {
        throw Error(data.message || data.errMsg)
      }

      return dispatch(ladiesTopFilterDone(data))
    })
    .catch(error => {
      dispatch(enqueueMessage(error.message))

      dispatch(ladiesTopFilterFail())

      return Promise.reject()
    })
}

const filterFavorite = (key = 'favorite') => (dispatch, getState) => {
  const newFilter = {
    ...getState().ladies.filterFavorite,
    ...filter,
  }

  dispatch(ladiesFavoriteFilterStart(newFilter))

  return LadiesApi.findFavorite(mapFilterToApi(newFilter))
    .then(data => handleResponse(data, dispatch))
    .catch(error => handleError(error, dispatch))
}

const filterVisitors = (filter = {}) => (dispatch, getState) => {
  const newFilter = {
    ...getState().ladies.filterVisitors,
    ...filter,
  }

  dispatch(ladiesVistorsFilterStart(newFilter))

  return LadiesApi.findVisitors(mapFilterToApi(newFilter))
    .then(data => {
      if (data.errMsg) {
        throw Error(data.message || data.errMsg)
      }

      return dispatch(ladiesVistorsFilterDone(data))
    })
    .catch(error => {
      dispatch(enqueueMessage(error.message))

      dispatch(ladiesVistorsFilterFail())

      return Promise.reject()
    })
}

const filterContacts = (filter = {}) => (dispatch, getState) => {
  const newFilter = {
    ...getState().ladies.filterContacts,
    ...filter,
  }

  dispatch(ladiesContactsFilterStart(newFilter))

  return LadiesApi.findContacts(mapFilterToApi(newFilter))
    .then(data => {
      if (data.errMsg) {
        throw Error(data.message || data.errMsg)
      }

      return dispatch(ladiesContactsFilterDone(data))
    })
    .catch(error => {
      dispatch(enqueueMessage(error.message))

      dispatch(ladiesContactsFilterFail())

      return Promise.reject()
    })
}

export {
  filter,
  updateFilter,
  clearData,

  filterVisitors,
  filterNewest,
  filterTop,
  filterFavorite,
  filterContacts,
}
