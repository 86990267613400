import { createAction, handleActions } from 'redux-actions'
import lodash from 'lodash'

const initialState = {
  fetching: false,
  profile: {},
  filterBase: {
    from: 0,
    size: 6,
  },
  // general filter
  filter: {},
  filterData: [],
  filterDataCount: 0,
  // newest filter
  filterNewest: {
    from: 0,
    size: 6,
  },
  filterNewestData: [],
  filterNewestDataCount: 0,
  // top filter
  filterTop: {
    from: 0,
    size: 6,
  },
  // favorite filter
  filterFavorite: {
    from: 0,
    size: 6,
  },
  filterFavoriteData: [],
  filterFavoriteDataCount: 0,
  // visitors filter
  filterVisitors: {
    from: 0,
    size: 6,
  },
  filterVisitorsData: [],
  filterVisitorsDataCount: 0,
  // contacts filter
  filterContacts: {
    from: 0,
    size: 6,
  },
  filterContactsData: [],
  filterContactsDataCount: 0,

  topLimit: 100,
}

// actions

const ladiesFilterStart = createAction('LADIES_FILTER_START')
const ladiesFilterFail = createAction('LADIES_FILTER_FAIL')
const ladiesFilterDone = createAction('LADIES_FILTER_DONE')

const ladiesFilterUpdate = createAction('LADIES_FILTER_UPDATE')
const ladiesDataClear = createAction('LADIES_DATA_CLEAR')


const ladiesNewestFilterStart = createAction('LADIES_NEWEST_FILTER_START')
const ladiesNewestFilterFail = createAction('LADIES_NEWEST_FILTER_FAIL')
const ladiesNewestFilterDone = createAction('LADIES_NEWEST_FILTER_DONE')

const ladiesTopFilterStart = createAction('LADIES_TOP_FILTER_START')
const ladiesTopFilterFail = createAction('LADIES_TOP_FILTER_FAIL')
const ladiesTopFilterDone = createAction('LADIES_TOP_FILTER_DONE')

const ladiesFavoriteFilterStart = createAction('LADIES_FAVORITE_FILTER_START')
const ladiesFavoriteFilterFail = createAction('LADIES_FAVORITE_FILTER_FAIL')
const ladiesFavoriteFilterDone = createAction('LADIES_FAVORITE_FILTER_DONE')

const ladiesVistorsFilterStart = createAction('LADIES_VISITORS_FILTER_START')
const ladiesVistorsFilterFail = createAction('LADIES_VISITORS_FILTER_FAIL')
const ladiesVistorsFilterDone = createAction('LADIES_VISITORS_FILTER_DONE')

const ladiesContactsFilterStart = createAction('LADIES_CONTACTS_FILTER_START')
const ladiesContactsFilterFail = createAction('LADIES_CONTACTS_FILTER_FAIL')
const ladiesContactsFilterDone = createAction('LADIES_CONTACTS_FILTER_DONE')

const ladiesAddFavoriteStart = createAction('LADIES_ADD_FAVORITE_START')
const ladiesAddFavoriteFail = createAction('LADIES_ADD_FAVORITE_FAIL')
const ladiesAddFavoriteDone = createAction('LADIES_ADD_FAVORITE_DONE')

const ladiesRemoveFavoriteStart = createAction('LADIES_REMOVE_FAVORITE_START')
const ladiesRemoveFavoriteFail = createAction('LADIES_REMOVE_FAVORITE_FAIL')
const ladiesRemoveFavoriteDone = createAction('LADIES_REMOVE_FAVORITE_DONE')

const ladiesGetByIDFavoriteStart = createAction('LADIES_GET_BY_ID_START')
const ladiesGetByIDFavoriteFail = createAction('LADIES_GET_BY_ID_FAIL')
const ladiesGetByIDFavoriteDone = createAction('LADIES_GET_BY_ID_DONE')

const updateLadyProfile = createAction('LADIES_UPDATE_PROFILE')

// reducer
const filterStart = state => ({ ...state, fetching: true })
const filterFail = state => ({ ...state, fetching: false })
const filterDone = (state, { payload }) => ({
  ...state,
  fetching: false,
  filterData: payload?.ladies || [],
  filterDataCount: payload?.count || 0,
})

const filterUpdate = (state, { payload }) => ({ ...state,
  filter: {
    ...state.filter,
    [payload.key]: payload.filter,
  },
  fetching: true })
const clearData = state => ({
  ...state,
  filterData: [],
  filterDataCount: 0,
})

const filterNewestStart = (state, { payload }) => ({ ...state, filterNewest: payload, fetching: true })
const filterNewestFail = state => ({ ...state, fetching: false })
const filterNewestDone = (state, { payload }) => ({
  ...state,
  fetching: false,
  filterNewestData: payload?.ladies || [],
  filterNewestDataCount: payload?.count || 0,
})

const filterFavoriteStart = (state, { payload }) => ({ ...state, filterFavorite: payload, fetching: true })
const filterFavoriteFail = state => ({ ...state, fetching: false })
const filterFavoriteDone = (state, { payload }) => ({
  ...state,
  fetching: false,
  filterFavoriteData: payload?.ladies || [],
  filterFavoriteDataCount: payload?.count || 0,
})

const filterVisitorsStart = (state, { payload }) => ({ ...state, filterVisitors: payload, fetching: true })
const filterVisitorsFail = state => ({ ...state, fetching: false })
const filterVisitorsDone = (state, { payload }) => ({
  ...state,
  fetching: false,
  filterVisitorsData: payload?.ladies || [],
  filterVisitorsDataCount: payload?.count || 0,
})

const filterContactsStart = (state, { payload }) => ({ ...state, filterContacts: payload, fetching: true })
const filterContactsFail = state => ({ ...state, fetching: false })
const filterContactsDone = (state, { payload }) => ({
  ...state,
  fetching: false,
  filterContactsData: payload?.ladies || [],
  filterContactsDataCount: payload?.count || 0,
})

const filterTopStart = (state, { payload }) => ({
  ...state,
  filterTop: payload.filter,
  topLimit: payload.limit,
  fetching: true,
})
const filterTopFail = state => ({ ...state, fetching: false })
const filterTopDone = (state, { payload }) => ({
  ...state,
  fetching: false,
  filterTopData: payload?.ladies || [],
  filterTopDataCount: payload?.count || 0,
})

const addFavoriteStart = state => ({ ...state, fetching: true })
const addFavoriteFail = state => ({ ...state, fetching: false })
const addFavoriteDone = state => ({ ...state, fetching: false })

const removeFavoriteStart = state => ({ ...state, fetching: true })
const removeFavoriteFail = state => ({ ...state, fetching: false })
const removeFavoriteDone = state => ({ ...state, fetching: false })

const getByIDStart = (state, { payload }) => ({ ...state, profile: payload, fetching: true })
const getByIDFail = state => ({ ...state, fetching: false })
const getByIDDone = (state, { payload }) => ({ ...state, fetching: false, profile: payload })

const updateProfile = (state, { payload }) => {
  const profile = { ...state.profile }
  lodash.set(profile, payload.path, payload.value)

  return { ...state, profile }
}


const reducers = handleActions({
  LADIES_FILTER_START: filterStart,
  LADIES_FILTER_FAIL: filterFail,
  LADIES_FILTER_DONE: filterDone,

  LADIES_NEWEST_FILTER_START: filterNewestStart,
  LADIES_NEWEST_FILTER_FAIL: filterNewestFail,
  LADIES_NEWEST_FILTER_DONE: filterNewestDone,

  LADIES_TOP_FILTER_START: filterTopStart,
  LADIES_TOP_FILTER_FAIL: filterTopFail,
  LADIES_TOP_FILTER_DONE: filterTopDone,

  LADIES_FAVORITE_FILTER_START: filterFavoriteStart,
  LADIES_FAVORITE_FILTER_FAIL: filterFavoriteFail,
  LADIES_FAVORITE_FILTER_DONE: filterFavoriteDone,

  LADIES_VISITORS_FILTER_START: filterVisitorsStart,
  LADIES_VISITORS_FILTER_FAIL: filterVisitorsFail,
  LADIES_VISITORS_FILTER_DONE: filterVisitorsDone,

  LADIES_CONTACTS_FILTER_START: filterContactsStart,
  LADIES_CONTACTS_FILTER_FAIL: filterContactsFail,
  LADIES_CONTACTS_FILTER_DONE: filterContactsDone,

  LADIES_ADD_FAVORITE_START: addFavoriteStart,
  LADIES_ADD_FAVORITE_FAIL: addFavoriteFail,
  LADIES_ADD_FAVORITE_DONE: addFavoriteDone,

  LADIES_REMOVE_FAVORITE_START: removeFavoriteStart,
  LADIES_REMOVE_FAVORITE_FAIL: removeFavoriteFail,
  LADIES_REMOVE_FAVORITE_DONE: removeFavoriteDone,

  LADIES_GET_BY_ID_START: getByIDStart,
  LADIES_GET_BY_ID_FAIL: getByIDFail,
  LADIES_GET_BY_ID_DONE: getByIDDone,

  LADIES_UPDATE_PROFILE: updateProfile,

  LADIES_FILTER_UPDATE: filterUpdate,
  LADIES_DATA_CLEAR: clearData,
}, initialState)

export {
  ladiesFilterStart,
  ladiesFilterFail,
  ladiesFilterDone,

  ladiesFilterUpdate,
  ladiesDataClear,

  ladiesNewestFilterStart,
  ladiesNewestFilterFail,
  ladiesNewestFilterDone,

  ladiesTopFilterStart,
  ladiesTopFilterFail,
  ladiesTopFilterDone,

  ladiesFavoriteFilterStart,
  ladiesFavoriteFilterFail,
  ladiesFavoriteFilterDone,

  ladiesVistorsFilterStart,
  ladiesVistorsFilterFail,
  ladiesVistorsFilterDone,

  ladiesContactsFilterStart,
  ladiesContactsFilterFail,
  ladiesContactsFilterDone,

  ladiesAddFavoriteStart,
  ladiesAddFavoriteFail,
  ladiesAddFavoriteDone,

  ladiesRemoveFavoriteStart,
  ladiesRemoveFavoriteFail,
  ladiesRemoveFavoriteDone,

  ladiesGetByIDFavoriteStart,
  ladiesGetByIDFavoriteFail,
  ladiesGetByIDFavoriteDone,

  updateLadyProfile,
}

export default reducers
