import React from 'react'
import { connect } from 'react-redux'
import { getAuthUserInfo } from 'service/auth/selectors'
import { getList as getCountryList } from 'service/country/actions/getList'
import { getCountriesCount, getCountriesOptions } from 'service/country/selectors'
import ManProfile from './Man'

class Profile extends React.Component {
  componentDidMount() {
    const { countriesCount, getCountryList } = this.props

    if (!countriesCount) {
      getCountryList()
    }
  }

  render() {
    const { userInfo } = this.props

    return (
      <div>
        {userInfo.loginAsMan && <ManProfile />}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  countriesCount: getCountriesCount(state),
  countriesOptions: getCountriesOptions(state),
  userInfo: getAuthUserInfo(state),
})

const mapDispatchToProps = {
  getCountryList,
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
