import ManApi from 'api/ManApi'
import { manGetMeStart, manGetMeFail, manGetMeDone, manUpdateMeStart, manUpdateMeDone, manUpdatePasswordStart, manUpdatePasswordDone, manUpdatePasswordFail, manClearUploadedMediaStart, manClearUploadedMediaDone, manClearUploadedMediaFail, manUpdateNotificationsDone, manUpdateNotificationsFail } from 'redux/reducers/man'
import { mapClearUploadedMediaToApi, mapUpdateNotificationsStateToApi, mapUpdatePasswordStateToApi, mapUpdateStateToApi } from 'service/man/mapper'
import enqueueMessage from 'service/notification/enqueue'
import newPromise from 'utils/newPromise'
import { validatePasswordInfo, validateProfileInfo } from './validation'

const getMe = () => dispatch => {
  dispatch(manGetMeStart())

  return ManApi.getMe()
    .then(data => {
      if (data.errMsg) {
        throw Error(data.message || data.errMsg)
      }

      return dispatch(manGetMeDone(data))
    })
    .catch(error => {
      dispatch(manGetMeFail())

      return Promise.reject()
    })
}

const updateMe = stateData => dispatch => {
  dispatch(manUpdateMeStart())

  return newPromise(validateProfileInfo, stateData)
    .then(() => ManApi.updateMe(mapUpdateStateToApi(stateData)))
    .then(data => {
      if (data.errMsg) {
        throw Error(data.message || data.errMsg)
      }

      return dispatch(manUpdateMeDone(data))
    })
    .catch(error => {
      dispatch(enqueueMessage(error.message, 'danger'))

      dispatch(manGetMeFail())

      return Promise.reject()
    })
}

const updatePassword = stateData => dispatch => {
  dispatch(manUpdatePasswordStart())

  return newPromise(validatePasswordInfo, stateData)
    .then(() => ManApi.updatePassword(mapUpdatePasswordStateToApi(stateData)))
    .then(data => {
      if (data.errMsg) {
        throw Error(data.message || data.errMsg)
      }

      dispatch(enqueueMessage('success.profile.password', 'success'))

      return dispatch(manUpdatePasswordDone())
    })
    .catch(error => {
      dispatch(enqueueMessage(error.message, 'danger'))

      dispatch(manUpdatePasswordFail())

      return Promise.reject()
    })
}

const updateNotificaitons = stateData => dispatch => {
  dispatch(manUpdatePasswordStart())

  return ManApi.updateNotificatioins(mapUpdateNotificationsStateToApi(stateData))
    .then(data => {
      if (data.errMsg) {
        throw Error(data.message || data.errMsg)
      }

      dispatch(enqueueMessage('success.profile.notification', 'success'))

      return dispatch(manUpdateNotificationsDone())
    })
    .catch(error => {
      dispatch(enqueueMessage(error.message, 'danger'))

      dispatch(manUpdateNotificationsFail())

      return Promise.reject()
    })
}

const clearUploadedMedia = stateData => dispatch => {
  dispatch(manClearUploadedMediaStart())

  return ManApi.clearUploadedMedia(mapClearUploadedMediaToApi(stateData))
    .then(data => {
      if (data.errMsg) {
        throw Error(data.message || data.errMsg)
      }

      return dispatch(manClearUploadedMediaDone())
    })
    .catch(error => {
      dispatch(manClearUploadedMediaFail())

      return Promise.reject()
    })
}


export {
  getMe,
  updateMe,
  updatePassword,
  updateNotificaitons,
  clearUploadedMedia,
}
