import React from 'react'
// section icons
import Ladies from 'icons/sidebar/Ladies'
import Services from 'icons/sidebar/Services'
import Mail from 'icons/sidebar/Mail'
import Finances from 'icons/sidebar/Finances'
import Gifts from 'icons/sidebar/Gifts'
// menu icons
import { CreateNewFolder, CreditCard, DeleteSweep, Email, FiberManualRecord, LocalFireDepartment, MoveToInbox, Outbox, ShoppingCart, SupportAgent } from '@mui/icons-material'
import { appURLs } from 'navigation/routes'

const sidebarMenu = t => [
  {
    icon: <Mail />,
    label: t('layout.sidebar.mail.title').toUpperCase(),
    items: [
      {
        icon: <MoveToInbox />,
        label: t('layout.sidebar.mail.inbox'),
        link: '/not/implemented',
        info: <span>(0)</span>,
      },
      {
        icon: <Outbox />,
        label: t('layout.sidebar.mail.outbox'),
        link: '/not/implemented',
      },
      {
        icon: <SupportAgent />,
        label: t('layout.sidebar.mail.admin'),
        link: '/not/implemented',
      },
      {
        icon: <LocalFireDepartment />,
        label: t('layout.sidebar.mail.spam'),
        link: '/not/implemented',
      },
      {
        icon: <DeleteSweep />,
        label: t('layout.sidebar.mail.trash'),
        link: '/not/implemented',
      },
      {
        icon: <Email />,
        label: t('layout.sidebar.mail.writeNew'),
        link: appURLs.MAIL_WRITE_NEW,
      },
      {
        icon: <CreateNewFolder />,
        label: t('layout.sidebar.mail.createNewFolder'),
        link: '/not/implemented',
      },
    ],
  },
  {
    icon: <Ladies />,
    label: t('layout.sidebar.ladies.title').toUpperCase(),
    items: [
      {
        icon: <FiberManualRecord fontSize="small" />,
        label: t('layout.sidebar.ladies.visitors'),
        link: appURLs.LAIDES_VISITORS,
      },
      {
        icon: <FiberManualRecord fontSize="small" />,
        label: t('layout.sidebar.ladies.contact'),
        link: appURLs.LAIDES_CONTACTS,
      },
      {
        icon: <FiberManualRecord fontSize="small" />,
        label: t('layout.sidebar.ladies.favorite.ladies'),
        link: appURLs.LAIDES_FAVORITE,
      },
      {
        icon: <FiberManualRecord fontSize="small" />,
        label: t('layout.sidebar.ladies.favorite.photos'),
        link: appURLs.MAN_FAVORITE_PHOTOS,
      },
      {
        icon: <FiberManualRecord fontSize="small" />,
        label: t('layout.sidebar.ladies.favorite.videos'),
        link: appURLs.MAN_FAVORITE_VIDEOS,
      },
    ],
  },
  {
    icon: <Services />,
    label: t('layout.sidebar.features.title').toUpperCase(),
    items: [],
  },
  {
    icon: <Gifts />,
    label: t('layout.sidebar.gifts.title').toUpperCase(),
    items: [],
  },
  {
    icon: <Finances />,
    label: t('layout.sidebar.finances.title').toUpperCase(),
    items: [
      {
        icon: <ShoppingCart fontSize="small" />,
        label: t('layout.sidebar.finances.purchase'),
        link: appURLs.PURCHASES_COINS,
      },
      {
        icon: <CreditCard fontSize="small" />,
        label: t('layout.sidebar.finances.history.purchase'),
        link: appURLs.PURCHASES_HISTORY,
      },
      {
        icon: <CreditCard fontSize="small" />,
        label: t('layout.sidebar.finances.history.expenses'),
        link: appURLs.MAN_FAVORITE_VIDEOS,
      },
    ],
  },
]

export default sidebarMenu
