import { ListItem, ListItemButton, ListItemIcon, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import cx from 'classnames'
import React from 'react'
import styles from './styles'

const CollapseListItem = ({ classes, item, selected, onClick }) => {
  function onItemClick() {
    onClick(item)
  }

  const className = cx(classes.item, { [classes.selected]: selected })

  return (
    <ListItem>
      <ListItemButton
        classes={{
          root: className,
        }}
        onClick={onItemClick}
      >
        <ListItemIcon classes={{ root: classes.icon }}>
          {item.icon}
        </ListItemIcon>
        <Typography>{item.label}</Typography>
      </ListItemButton>
    </ListItem>
  )
}

export default withStyles(styles)(CollapseListItem)
