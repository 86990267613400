const link = breakpoints => ({
  margin: '0 auto',
  [breakpoints.up('xs')]: {
    'width': 60,
    'height': 60,
    'fontSize': '2em',
  },
  [breakpoints.up('sm')]: {
    'width': 80,
    'height': 80,
    'fontSize': '3em',
  },
  [breakpoints.up('md')]: {
    'width': 120,
    'height': 120,
    'fontSize': '4em',
  },
  [breakpoints.up('lg')]: {
    'width': 165,
    'height': 165,
    'fontSize': '6em',
  },
})

export default theme => ({
  'container': {
    paddingTop: 24,
  },
  'facebook': {
    ...link(theme.breakpoints),
  },
  'youtube': {
    ...link(theme.breakpoints),
  },
  'instagram': {
    ...link(theme.breakpoints),
  },
  'twitter': {
    ...link(theme.breakpoints),
  },
  'linkedin': {
    ...link(theme.breakpoints),
  },
  'google-plus': {
    ...link(theme.breakpoints),
  },
})
