export default theme => ({
  pswdButton: {
    marginBottom: 4,
  },

  backgroundContainer: {
    height: 600,
    width: '100%',
    paddingTop: 66,
  },
  loginButton: {
    padding: '8px 16px',
  },
  loginForm: {
    width: '100%',
    minHeight: 420,
    padding: 32,
    maxWidth: 316,
  },
  checkboxLabel: {
    marginBottom: '20px !important',
    color: theme.palette.grey[500],
    paddingLeft: 20,
  },
  checkbox: {
    '& svg': {
      color: theme.palette.primary.light,
    },
  },
  recoverPassword: {
    'margin': 10,
    'display': 'block',
    'textAlign': 'center',
    'color': '#337ab7',
    'textDecoration': 'none',

    '&:hover': {
      color: '#23527c',
      textDecoration: 'underline',
    },
  },
})
