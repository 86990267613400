import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import Image from 'components/Image'
import { useNavigate } from 'react-router'
import HomeSection from '../HomeSection'
import items from './items'

import sx from './sx'


const FeatureItem = ({ item, fullHeight, fullWidth, xs, md }) => {
  const navigate = useNavigate()

  const onClick = useCallback(() => navigate(item.path), [item.path, navigate])

  return (
    <Grid key={item.text} item xs={xs} md={md}>
      <ButtonBase sx={sx.item} onClick={onClick}>
        <Image src={item.img} fullHeight={fullHeight} fullWidth={fullWidth} />
        <Box sx={sx.itemLabel}>
          <Typography variant="h6">
            {item.text}
          </Typography>
        </Box>
      </ButtonBase>
    </Grid>
  )
}

const FeaturedAndServices = () => {
  const { t } = useTranslation('home')

  const upMD = useMediaQuery(theme => theme.breakpoints.up('md'))

  const featureItems = items(t, upMD)

  return (
    <HomeSection title={t('sections.services.title')}>
      <Grid container rowGap={0.5} sx={sx.container}>
        <Grid container spacing={0.5}>
          {featureItems.top.map(item => (
            <FeatureItem
              key={item.text}
              xs={6}
              md={3}
              item={item}
              fullHeight
            />
          ))}
        </Grid>
        <Grid container spacing={0.5}>
          {featureItems.bottom.map(item => (
            <FeatureItem
              key={item.text}
              xs={12}
              md={4}
              item={item}
              fullHeight={upMD}
              fullWidth={!upMD}
            />
          ))}
        </Grid>
      </Grid>
    </HomeSection>
  )
}

export default FeaturedAndServices
