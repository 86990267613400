import React from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  Grid,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { compose } from 'redux'
import styles from './styles'
import sx from './sx'


const CustomTable = ({ header, data, classes, page, count, renderRow, onPageChanged }) => {
  function handleOnPageChanged(event, pageNumber) {
    if (!Number.isInteger(pageNumber)) {
      return
    }

    onPageChanged(pageNumber)
  }

  const formattedHeader = header.map(header => (
    <TableCell
      key={header.name}
      align={header.align}
    >
      {header.name}
    </TableCell>
  ))

  const formattedRows = data.map(renderRow)

  const renderPagination = () => {
    if (!page && !count) {
      return null
    }

    return (
      <Pagination
        classes={{
          ul: classes.paginationURL,
        }}
        page={page}
        count={count}
        onChange={handleOnPageChanged}
        hideNextButton
        hidePrevButton
      />
    )
  }

  return (
    <Grid container>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {formattedHeader}
            </TableRow>
          </TableHead>
          <TableBody>
            {formattedRows}
          </TableBody>
        </Table>
      </TableContainer>
      {renderPagination()}
    </Grid>
  )
}

CustomTable.propTypes = {
  /** Array of header columns for table. */
  header: PropTypes.array,
  /** Array of rows data for table. */
  data: PropTypes.arrayOf(PropTypes.array),
  /** Current pagination page, if page & count are '==' false, then no pagination would be shown. */
  page: PropTypes.number,
  /** Page count, if page & count are '==' false, then no pagination would be shown. */
  count: PropTypes.number,
  /** Callback with new page number. */
  onPageChanged: PropTypes.func,
  /** Function to render rows. */
  renderRow: PropTypes.func,
}

CustomTable.defaultProps = {
  data: [],
  header: [],
  striped: false,
  hover: false,
  onPageChanged: () => {},
  renderRow: () => {},
}

export default compose(
  withStyles(styles),
)(CustomTable)
