import { createAction, handleActions } from 'redux-actions'

const initialState = {
  fetching: false,
  data: [],
  filter: {
    from: 0,
    size: 12,
  },
  item: null,
}

// actions
const newsGetInfoStart = createAction('NEWS_GET_INFO_START')
const newsGetInfoFail = createAction('NEWS_GET_INFO_FAIL')
const newsGetInfoDone = createAction('NEWS_GET_INFO_DONE')

const newsGetSingleStart = createAction('NEWS_GET_SINGLE_START')
const newsGetSingleFail = createAction('NEWS_GET_SINGLE_FAIL')
const newsGetSingleDone = createAction('NEWS_GET_SINGLE_DONE')

const newsUpdateFilter = createAction('NEWS_FILTER_UPDATE')
const newsGetInfoClear = createAction('NEWS_GET_INFO_CLEAR')

// reducer
const getNewsInfoStart = state => ({ ...state, fetching: true })
const getNewsInfoFail = state => ({ ...state, fetching: false })
const getNewsInfoDone = (state, { payload }) => ({ ...state, fetching: false, data: payload })

const getNewsSingleStart = state => ({ ...state, fetching: true })
const getNewsSingleFail = state => ({ ...state, fetching: false })
const getNewsSingleDone = (state, { payload }) => ({ ...state, fetching: false, item: payload })

const updateFilter = (state, { payload }) => ({ ...state, fetching: false, filter: payload })
const clearNewsInfo = state => ({ ...state, fetching: false, data: [] })

const reducers = handleActions({
  NEWS_GET_INFO_START: getNewsInfoStart,
  NEWS_GET_INFO_FAIL: getNewsInfoFail,
  NEWS_GET_INFO_DONE: getNewsInfoDone,

  NEWS_GET_SINGLE_START: getNewsSingleStart,
  NEWS_GET_SINGLE_FAIL: getNewsSingleFail,
  NEWS_GET_SINGLE_DONE: getNewsSingleDone,

  NEWS_FILTER_UPDATE: updateFilter,
  NEWS_GET_INFO_CLEAR: clearNewsInfo,
}, initialState)

export {
  newsGetInfoStart,
  newsGetInfoFail,
  newsGetInfoDone,

  newsGetSingleStart,
  newsGetSingleFail,
  newsGetSingleDone,

  newsUpdateFilter,
  newsGetInfoClear,
}

export default reducers
