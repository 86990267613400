import React from 'react'

const Gifts = () => (
  <svg width="25" height="30" viewBox="0 0 32 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.8993 37.782L1 31.0846V13.5531M15.8993 37.782V20.2505m0 17.5315L31 31.0846V13.5531m-15.1007 6.6974L1 13.5531m14.8993 6.6974L31 13.5531m-30 0l14.8993-6.8944L31 13.553" stroke="#01124F" strokeWidth="2" />
    <path d="M6.468 17.0332l3.7725.9431v16.9764L6.468 33.538V17.0332zM25.3308 17.0332l-3.7725.9431v16.9764l3.7725-1.4147V17.0332z" fill="#01124F" />
    <path d="M10.7121 9.0162L6.9396 11.374l16.7443 8.0613 1.0461-3.8909-14.0179-6.5282z" fill="#01124F" />
    <path d="M6.4684 15.6189l2.8292 2.8293 16.0334-7.0734-3.5786-2.4682-15.284 6.7123z" fill="#01124F" />
    <path d="M4 9c3.992 1.4722 9.0001 4 11.8994 5.2038 0-4.3163-9.0549-17.7495-10.0091-11.6347V5C3.666 5.2398-.0177 7.5184 4 9zM28.2908 9.5278C24 11 19 13 15.8994 14.2038c0-4.3163 9.0549-17.7495 10.0091-11.6347L25.9086 5.5c2.2242.2398 6.4326 2.6381 2.3822 4.0278z" fill="#fff" stroke="#01124F" strokeWidth="2" strokeLinejoin="round" />
    <path d="M14.2039 7.602h3.7725l-.4716 3.301-2.8294.4716-.4715-3.7725z" fill="#01124F" />
    <path d="M15.6184 10.9028l-1.8862-4.7156 2.8294-1.4147 1.4147 1.8862-2.3579 4.2441z" fill="#fff" />
  </svg>
)

export default Gifts
