import { common, grey } from '@mui/material/colors'

export default {
  userInfoContainer: {
    color: common.black,
  },
  settingsButton: {
    minWidth: 32,
    height: 32,
    p: 0,
    borderRadius: 0.5,
    alignSelf: 'center',
  },
  menuActionsIcon: {
    margin: '0 12px 0px 0px',
  },
  avatar: {
    width: 50,
    height: 50,
  },
  logo: {
    borderRadius: 0,
    height: 65,
    width: 'max-content',
    cursor: 'pointer',
  },
  appBar: {
    backgroundColor: common.white,
    width: '100%',
    zIndex: 20,
    height: 80,
  },
  toolbar: {
    height: 80,
  },
  mobileMenuCollapse: {
    position: 'fixed',
    zIndex: 100000,
    background: 'white',
  },
  mobileMenu: {
    height: 'calc(100vh - 80px)',
    width: '100vw',
    background: 'white',
  },
  mobileMenuItemText: {
    textAlign: 'center',
  },
  mobileSignUp: {
    backgroundColor: 'primary.main',
    color: common.white,
  },
  mobileInfoMenuItem: {
    backgroundColor: grey[100],
  },

  subHeaderImgContainer: {
    width: '100%',
    pt: '74px',
  },
}
