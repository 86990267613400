const smallSize = 20

const checkedIcon = theme => ({
  border: '1px solid',
  borderColor: theme.palette.primary.main,
  borderRadius: '3px',
  boxSizing: 'border-box',
  backgroundColor: theme.palette.primary.main,
  color: 'white !important',
})

const uncheckedIcon = theme => ({
  border: '1px solid',
  borderRadius: '3px',
  boxSizing: 'border-box',
  color: theme.palette.primary.main,
  borderColor: theme.palette.grey[500],
})


const styles = theme => ({
  checkedIconSmall: {
    ...checkedIcon(theme),
    width: [smallSize, '!important'],
    height: [smallSize, '!important'],
  },
  uncheckedIconSmall: {
    ...uncheckedIcon(theme),
    width: [smallSize, '!important'],
    height: [smallSize, '!important'],
    padding: 8,
  },
  checkedIconMedium: {
    ...checkedIcon(theme),
    width: 18,
    height: 18,
  },
  uncheckedIconMedium: {
    ...uncheckedIcon(theme),
    padding: 8,
  },
  checkedIconLarge: {
    ...checkedIcon(theme),
    width: 20,
    height: 20,
  },
  uncheckedIconLarge: {
    ...uncheckedIcon(theme),
    padding: 9,
  },
})

export default styles
