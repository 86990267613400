const newPromise = (validationFunction = () => {}, ...args) => new Promise((resolve, reject) => {
  const err = validationFunction(...args)

  if (err) {
    reject(new Error(err))
  } else {
    resolve()
  }
})

export default newPromise
