import React, { useCallback, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { withStyles } from '@mui/styles'
import { agesRange } from 'consts/age'
import { getCountriesCount, getCountriesOptions } from 'service/country/selectors'
import { getList as getCountryList } from 'service/country/actions/getList'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { drinkingOptions, eyeOptions, hairOptions, martialStatusOptions, religionOptions, smokingOptions } from 'consts/profileEditOptions'
import { weightOptions } from 'consts/weightOptions'
import { heightOptions } from 'consts/heightOptions'
import { englishSkillOptions } from 'consts/englishOptions'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'
import styles from './styles'
import {
  childrenOptions,
  onlineOptions,
  birthdayOptions,
} from './options'

const grid = 36
const gridColumn = grid / 3

const LadiesFilter = ({ classes, countriesOptions, filter, onlineBlocked, onChange }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleOnChange = useCallback(debounce(onChange, 300), [])

  const [expanded, setExpanded] = useState(false)

  const { t } = useTranslation('text')

  if (!filter) {
    return null
  }

  function handleOnChange(event) {
    debouncedHandleOnChange({ [event.target.name]: event.target.value })
  }

  function handleChangeExpande() {
    setExpanded(!expanded)
  }

  const renderText = (label, name, grid = gridColumn) => (
    <Grid
      item
      {...grid && { xs: grid }}
    >
      <TextField
        label={label}
        name={name}
        defaultValue={filter[name]}
        onChange={handleOnChange}
        fullWidth
      />
    </Grid>
  )

  const renderSelect = (label, name, options, grid = gridColumn, disabled = false) => (
    <Grid
      item
      {...grid && { xs: grid }}
    >
      <TextField
        label={label}
        name={name}
        defaultValue={filter[name]}
        onChange={handleOnChange}
        disabled={disabled}
        fullWidth
        select
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {t(option.label)}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  )

  const renderAdditionalFilter = (t, onlineBlocked) => (
    <Grid container columnSpacing={2} rowSpacing={4} columns={grid}>
      {renderText(t('person.field.firstName'), 'firstName')}
      {renderText(t('person.field.lastName'), 'lastName')}
      {renderSelect(t('person.field.englishSkill'), 'englishSkill', englishSkillOptions)}

      {renderSelect(t('person.field.eye'), 'eyeColor', eyeOptions)}
      {renderSelect(t('person.field.hair'), 'hairColor', hairOptions)}
      {/* TODO not supported */}
      {renderSelect(t('person.field.maritalStatus'), 'maritalStatus', martialStatusOptions)}

      {renderSelect(t('person.field.smoking'), 'smoker', smokingOptions)}
      {renderSelect(t('person.field.alchohol'), 'drinker', drinkingOptions)}
      {renderSelect(t('person.field.religion'), 'religion', religionOptions)}

      {renderText(t('person.field.weight.from'), 'minWeight', 7)}
      {renderText(t('person.field.weight.to'), 'maxWeight', 7)}
      {renderSelect('', 'weightType', weightOptions, 4)}
      {renderText(t('person.field.height.from'), 'minHeight', 7)}
      {renderText(t('person.field.height.to'), 'maxHeight', 7)}
      {renderSelect('', 'heightType', heightOptions, 4)}

      {renderSelect(t('person.field.children'), 'children', childrenOptions)}
      {renderSelect(t('ladies.search.field.online'), 'online', onlineOptions, gridColumn, onlineBlocked)}
      {renderSelect(t('ladies.search.field.birthdaySoon'), 'birthdaySoon', birthdayOptions)}
    </Grid>
  )

  return (
    <div>
      <Grid container spacing={1} className={classes.filterContainer} columns={grid}>
        <Grid item xs={gridColumn}>
          <TextField
            label="Lady ID"
            name="id"
            defaultValue={filter.id}
            onChange={handleOnChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={gridColumn}>
          {renderSelect('Country', 'countryId', countriesOptions, 0)}
        </Grid>
        <Grid item xs={gridColumn / 2}>
          {renderSelect('Age from', 'minAge', agesRange(t), 0)}
        </Grid>
        <Grid item xs={gridColumn / 2}>
          {renderSelect('Age to', 'maxAge', agesRange(t), 0)}
        </Grid>
      </Grid>
      <Accordion
        classes={{
          root: classes.accordionRoot,
        }}
        expanded={expanded}
        onChange={handleChangeExpande}
        disableGutters
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center" justifyContent="end">
            <Typography className={classes.expandText}>
              {expanded ? t('ladies.search.filter.hide') : t('ladies.search.filter.expand')}
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
          {renderAdditionalFilter(t, onlineBlocked)}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

LadiesFilter.propTypes = {
  /** If view "Search online", then this status will be selected as true and disabled. */
  onlineBlocked: PropTypes.bool,
  /** On filter changed callback. */
  onChange: PropTypes.func,
}

LadiesFilter.defaultProps = {
  onlineBlocked: false,
  onChange: () => {},
}

const mapStateToProps = state => ({
  countriesCount: getCountriesCount(state),
  countriesOptions: getCountriesOptions(state),
})

const mapDispatchToProps = {
  getCountryList,
}


export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(LadiesFilter)
