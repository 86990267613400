export default theme => ({
  title: {
    textAlign: 'center',
    fontWeight: 700,
    padding: '6px 0 12px',
    textTransform: 'uppercase',
  },
  ladiesCounter: {
    alignSelf: 'center',
  },
  counter: {
    fontWeight: 700,
  },
})
