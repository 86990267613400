export default theme => ({
  clickableItem: {
    cursor: 'pointer',
  },
  ladyItemInfoContainer: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'row',
    columnGap: 16,
  },
  ladyTopItemInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 32,
    padding: '24px 0',
  },

  ladyTextInfoContainer: {
    paddingTop: 8,
  },
  ladyTopTextInfoContainer: {
    alignSelf: 'center',
  },


  ladyIDInfo: {
    fontWeight: 700,
    color: theme.palette.grey[400],
  },
  ladyTopOneIDInfo: {
    textAlign: 'center',
    fontWeight: 700,
    color: theme.palette.grey[400],
  },


  ladyTopActionsContainer: {
    columnGap: 8,
    display: 'flex',
    alignSelf: 'center',
  },

  ladyTopNumberContainer: {
    alignSelf: 'center',
    marginRight: 16,
  },
  ladyTopNumber: {
    color: theme.palette.grey[300],
    fontWeight: 700,
    textAlign: 'end',
  },


  ladyTopOneContainer: {
    padding: '24px 0',
  },


  ladyNameInfo: {
    display: 'inline-block',
    fontWeight: 700,
  },
  ladyAgeInfo: {
    display: 'inline-block',
    fontWeight: 700,
    paddingLeft: 4,
    color: theme.palette.grey[400],
  },

  cardRoot: {
    paddingLeft: 32,
    borderRadius: 16,
    boxShadow: 'unset',
  },
  paginationRoot: {
    padding: '12px 0 0 0',
  },
  noOneFound: {
    textAlign: 'center',
    margin: '64px 0',
  },
  onlineStatusText: {
    fontWeight: 500,
    color: theme.palette.grey[500],
  },
  avatarContainer: {
    position: 'relative',
  },

  title: {
    textAlign: 'center',
    fontWeight: 700,
    padding: '6px 0 12px',
    textTransform: 'uppercase',
  },
  ladiesCounter: {
    alignSelf: 'center',
  },
  counter: {
    fontWeight: 700,
  },

  filterContainer: {
    padding: '24px 0 0 0',
  },
  accordionRoot: {
    'boxShadow': 'unset',
    '&:before': {
      backgroundColor: 'unset',
    },
  },
  accordionDetailsRoot: {
    padding: 0,
    marginBottom: 16,
  },
  expandText: {
    color: theme.palette.link.main,
  },
  counter: {
    fontWeight: 700,
  },
})
