import React, { useCallback, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'react-i18next'

import Map from './Map'

const SocialIcon = ({ type, sx }) => (
  <Avatar sx={sx}>
    <FontAwesomeIcon icon={['fab', type]} size="2x" fixedWidth />
  </Avatar>
)
const addresses = [
  {
    title: 'London',
    email: 'dreamonelove.official@gmail.com',
    address: '3rd Floor, 207 Regent Street, W1B 3HH',
    info: 'DreamOneLove ltd',
    marker: {
      lat: 51.517320,
      lng: -0.142342,
    },
    socials: [],
  },
  {
    title: 'CHICAGO, IL',
    email: 'dreamonelove.official@gmail.com',
    address: '3923 Hull St, Skokie, IL 60607, US',
    phone: '+17085154753',
    marker: {
      lat: 42.023285,
      lng: -87.7275193,
    },
    socials: [
      {
        type: 'telegram',
        sx: {
          color: '#1e96c8',
          background: 'unset',
        },
      },
      {
        type: 'whatsapp',
        sx: {
          color: 'white',
          background: '#25d366',
          fontSize: '0.8em',
        },
      },
      {
        type: 'viber',
        sx: {
          color: 'white',
          background: '#7f4da0',
          fontSize: '0.8em',
        },
      },
    ],
  },
]

const Address = () => {
  const downMD = useMediaQuery(theme => theme.breakpoints.down('md'))
  const { t } = useTranslation('home')

  const [selected, setSelected] = useState(1)

  const renderTab = useCallback((item, index) => {
    if (downMD) {
      return <MenuItem value={index}>{item.title}</MenuItem>
    }

    return (
      <Tab
        key={item.title}
        label={
          <Typography variant="h6" text>
            {item.title}
          </Typography>
        }
        sx={{
          width: '-webkit-fill-available',
        }}
      />
    )
  }, [downMD])

  const renderTextField = useCallback((label, value, props) => {
    if (!value) {
      return null
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography>
            <b>
              {`${label}:`}
            </b>
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography {...props}>
            {value}
          </Typography>
        </Grid>
      </Grid>
    )
  }, [])

  const handleOnChange = useCallback((event, value) => setSelected(value), [])

  return (
    <Stack
      direction={downMD ? 'column' : 'row'}
      justifyContent="space-between"
      rowGap={2}
      columnGap={4}
    >
      {downMD
        ? (
          <TextField
            value={selected}
            select
          >
            {addresses.map(renderTab)}
          </TextField>
        )
        : (
          <Tabs
            value={selected}
            onChange={handleOnChange}
            orientation="vertical"
            centered
            sx={{
              borderRight: 1,
              borderColor: 'divider',
              minWidth: 'fit-content',
            }}
          >
            {addresses.map(renderTab)}
          </Tabs>
        )}
      <Grid container pt={2} spacing={2} rowGap={2}>
        <Grid item xs={12} md={6}>
          <Stack rowGap={2}>
            {renderTextField(t('pages.contact.info.field.email'), addresses[selected].email, {
              component: 'a',
              href: `mailto:${addresses[selected].email}`,
            })}
            {renderTextField(t('pages.contact.info.field.address'), addresses[selected].address)}
            {renderTextField(t('pages.contact.info.field.info'), addresses[selected].info)}
            {renderTextField(t('pages.contact.info.field.phone'), addresses[selected].phone)}
            <Grid container spacing={2}>
              <Grid item xs={3} />
              <Grid item xs={9}>
                {addresses[selected].socials?.length > 0 &&
                <Stack direction="row" columnGap={1}>
                  {addresses[selected].socials.map(item => (
                    <SocialIcon
                      key={item.type}
                      type={item.type}
                      sx={item.sx}
                    />
                  ))}
                </Stack>}
              </Grid>
            </Grid>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Map marker={addresses[selected].marker} />
        </Grid>
      </Grid>
    </Stack>
  )
}

export default Address
