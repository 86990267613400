import { grey } from '@mui/material/colors'

const spacing = 3

export default {
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  totalVotes: {
    border: `1px solid ${grey[500]}`,
    borderRadius: 1,
    fontWeight: 500,
    width: 40,
    textAlign: 'center',
    background: 'white',
    position: 'absolute',
    bottom: spacing,
    left: spacing,
  },
  like: {
    width: 36,
    height: 36,
    position: 'absolute',
    bottom: spacing,
    right: spacing,
    background: 'white',
  },
}
