import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Tabs,
  Tab,
} from '@mui/material'
import { TYPE } from 'components/ViewMedia/ViewMedia'
import ProfileMediaCard from './ProfileMediaCard'
import sx from './sx'

const ContentHorizontalScroll = ({ classes, items, renderItem, type, onSelect, centerItems }) => {
  const [activeItem, setActiveItem] = useState(0)

  function handleOnClick(index) {
    setActiveItem(index)
    onSelect(index)
  }

  function handleRenderItem(props) {
    if (renderItem) {
      return renderItem(props)
    }

    return (
      <ProfileMediaCard
        {...props}
      />
    )
  }

  /* TODO saved if another viewer will be required DO NOT DELETE */
  // const renderPhotoItem = item => (
  //   <Box
  //     className={classes.photoCard}
  //     sx={{
  //       background: `url(${item.photoUrl}${PHOTO.MEDIUM})`,
  //     }}
  //   />
  // )
  // const renderVideoItem = item => (
  //   <Box
  //     className={classes.videoCard}
  //     sx={{
  //       background: `url(${item.videoUrl}${VIDEO.NORMAL})`,
  //     }}
  //   >
  //     <PlayCircle />
  //   </Box>
  // )
  // const renderCard = (index, item) => (type === TYPE.VIDEO
  //   ? renderVideoItem(index, item)
  //   : renderPhotoItem(index, item)
  // )

  return (
    <Tabs
      TabIndicatorProps={{ sx: sx.indicator }}
      variant="scrollable"
      scrollButtons
      value={activeItem}
      centered={centerItems}
    >
      {items.map((item, index) => (
        <Tab
          key={item.mediaName}
          sx={sx.card}
          disableRipple
          label={handleRenderItem({
            item,
            index,
            type,
            onClick: handleOnClick,
          })}
        />
      ))}
    </Tabs>
  )
}

ContentHorizontalScroll.propTypes = {
  /** Items to be displayed. */
  items: PropTypes.arrayOf(PropTypes.any),
  /** Place all items starting from center. */
  centerItems: PropTypes.bool, // TODO NOT WORKING
  /** Type of card to show content. */
  type: PropTypes.oneOf([TYPE.PHOTO, TYPE.VIDEO]),
  /** Callback for card action. If not set no action will appear. Applicable as well for video as well for photo. */
  onDelete: PropTypes.func,
  /** Callback for card action. If not set no action will appear. Applicable only for photo. */
  onMain: PropTypes.func,
  /** Show additional info for content. */
  profile: PropTypes.bool,
  /** Show additional elements for visitor. */
  visitor: PropTypes.bool,
  /** Called for each item. Expect component as return param. */
  renderItem: PropTypes.func,
}

ContentHorizontalScroll.defaultProps = {
  items: [],
  centerItems: false,
  type: TYPE.PHOTO,
  profile: false,
  visitor: false,
}

export default ContentHorizontalScroll
