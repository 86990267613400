import { createSelectorCreator, defaultMemoize, createSelector } from 'reselect'
import isEqual from 'lodash/isEqual'
import createGeneralPagination from 'utils/pagination'
import { ladyPhotoURL, ladyVideoURL } from 'navigation/routes'

const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual,
)

const getLoading = createSelector(
  state => state.favoriteMedia.fetching,
  loading => loading,
)

// PHOTOS

const getPhotos = createDeepEqualSelector(
  state => state.favoriteMedia.photos,
  data => data?.map(item => ({
    id: item.id,
    mediaName: item.mediaName,
    totalVotes: item.totalVotes,
    favorite: item.favorite,
    possibleToLike: item.possibleToLike,
    photoUrl: `${ladyPhotoURL}${item.mediaName}` 
  })) || [],
)

const getPhotosCount = createSelector(
  state => state.favoriteMedia.photosCount,
  count => count,
)

const getPhotosFilter = createSelector(
  state => state.favoriteMedia.filterPhoto,
  filter => filter,
)

const getPhotosPagination = createSelector(
  state => state.favoriteMedia.photosCount,
  state => state.favoriteMedia.filterPhoto,
  (count, filter) => createGeneralPagination(count, filter),
)

// VIDEOS

const getVideos = createDeepEqualSelector(
  state => state.favoriteMedia.videos,
  data => data?.map(item => ({
    id: item.id,
    mediaName: item.mediaName,
    totalVotes: item.totalVotes,
    favorite: item.favorite,
    extension: item.extension,
    videoUrl: `${ladyVideoURL}${item.mediaName}`
  })) || [],
)

const getVideosCount = createSelector(
  state => state.favoriteMedia.videosCount,
  count => count,
)

const getVideosFilter = createSelector(
  state => state.favoriteMedia.filterVideo,
  filter => filter,
)

const getVideosPagination = createSelector(
  state => state.favoriteMedia.videosCount,
  state => state.favoriteMedia.filterVideo,
  (count, filter) => createGeneralPagination(count, filter),
)


export {
  getLoading,

  getPhotos,
  getPhotosCount,
  getPhotosFilter,
  getPhotosPagination,

  getVideos,
  getVideosCount,
  getVideosFilter,
  getVideosPagination,
}
