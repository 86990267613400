export default {
  container: {
    borderRadius: 1,
    overflow: 'hidden',
    position: 'relative',
  },
  item: {
    'position': 'relative',
    'height': 256,
    'width': '100%',
    'backgroundSize': 'cover !important',
    'backgroundPosition': 'center',
    'overflow': 'hidden',
    ':hover': {
      cursor: 'pointer',
      opacity: 0.85,
    },
  },
  itemLabel: {
    bottom: 0,
    position: 'absolute',
    color: 'white',
    width: '100%',
    textAlign: 'center',
    padding: '16px 0px',
    background: '#0000007a',
  },
}
