export default theme => ({
  content: {
    borderTop: '1px solid',
    borderColor: theme.palette.grey[300],
    padding: '52px 0',
  },
  unsignedContent: {
    fontWeight: 400,
    color: 'black',
    textAlign: 'center',
    padding: '90px 0 50px',
  },
  item: {
    alignSelf: 'center',
  },
  dreamOneLoveLink: {
    'textDecoration': 'none',

    '&:hover': {
      textDecoration: 'none',
    },
  },
  wordDream: {
    fontWeight: 300,
    color: 'red',
  },
  wordOne: {
    fontWeight: 700,
    color: '#037b77',
  },
  wordLove: {
    fontWeight: 700,
    color: 'red',
  },
  socialNetworkItem: {
    color: '#000',
    width: 20,
    height: 20,
    margin: '0 10px',
  },
  navLink: {
    'fontWeight': 700,
    'fontSize': 12,
    'textTransform': 'uppercase',
    'color': '#01124f',
    'padding': '10px 15px',
    'borderRadius': 11,
    'textDecoration': 'none',

    '&:hover': {
      backgroundColor: '#eee',
      textDecoration: 'none',
    },
  },
})
