/* eslint-disable react-redux/no-unused-prop-types */
import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Box, Divider, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import styles from './styles'
import innerSx from './sx'

const Section = ({ sx, classes, className, title, children, variant, TitleProps }) => (
  <Box
    className={cx({ [className]: !!className })}
    sx={{
      ...innerSx.container,
      ...sx,
    }}
  >
    <Divider>
      <Typography
        className={cx(classes.title)}
        variant={variant}
        {...TitleProps}
      >
        <span>{title}</span>
      </Typography>
    </Divider>

    <div className={classes.content}>
      {children}
    </div>
  </Box>
)

Section.propTypes = {
  /** @deprecated v4 MUI */
  classes: PropTypes.object,
  /** MUI theme styles applying to the top container. */
  sx: PropTypes.object,
  /** Classname for main container */
  className: PropTypes.string,
  /** Title of section. */
  title: PropTypes.string,
  /** Set Typography variant for title. */
  variant: PropTypes.string,
  /** Title has less font weight. Used direct in styles. */
  tight: PropTypes.bool,
  /** Text will not be in uppercase. Used direct in styles. */
  caseRespect: PropTypes.bool,
}

Section.defaultProps = {
  sx: {},
  variant: 'body1',
  tight: false,
  caseRespect: false,
}

export default withStyles(styles)(Section)
