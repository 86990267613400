export default {
  services: {
    title: 'Featured and Services',
    item: {
      additional: 'Additional services',
      date: 'Date a Lady',
      letters: 'HTML letters',
      gifts: 'Gifts and flowers',
      liveChat: 'Free Live Chat WebRTC',
      voiceChat: 'Voice Chat WebRTC',
      videoChat: 'Video Chat WebRTC',
    },
  },
  follow: {
    title: 'Follow us',
  },
  stories: {
    video: {
      title: 'Stories and feedback',
    },
    text: {
      title: 'Read some us our success stories',
    },
  },
  news: {
    title: 'News',
  },
}
