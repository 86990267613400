import { createAction, handleActions } from 'redux-actions'

const initialState = {
  fetching: false,
  fetchingPassword: false,
  fetchingNotifications: false,
  me: {},
}

// actions
const manGetMeStart = createAction('MAN_GET_ME_START')
const manGetMeFail = createAction('MAN_GET_ME_FAIL')
const manGetMeDone = createAction('MAN_GET_ME_DONE')

const manUpdateMeStart = createAction('MAN_UPDATE_ME_START')
const manUpdateMeFail = createAction('MAN_UPDATE_ME_FAIL')
const manUpdateMeDone = createAction('MAN_UPDATE_ME_DONE')

const manUpdatePasswordStart = createAction('MAN_UPDATE_PASSWORD_START')
const manUpdatePasswordFail = createAction('MAN_UPDATE_PASSWORD_FAIL')
const manUpdatePasswordDone = createAction('MAN_UPDATE_PASSWORD_DONE')

const manUpdateNotificationsStart = createAction('MAN_UPDATE_NOTIFICATIONS_START')
const manUpdateNotificationsFail = createAction('MAN_UPDATE_NOTIFICATIONS_FAIL')
const manUpdateNotificationsDone = createAction('MAN_UPDATE_NOTIFICATIONS_DONE')

const manClearUploadedMediaStart = createAction('MAN_CLEAR_UPLOADED_MEDIA_START')
const manClearUploadedMediaFail = createAction('MAN_CLEAR_UPLOADED_MEDIA_FAIL')
const manClearUploadedMediaDone = createAction('MAN_CLEAR_UPLOADED_MEDIA_DONE')

// reducer
const getMeStart = state => ({ ...state, fetching: true })
const getMeFail = state => ({ ...state, fetching: false })
const getMeDone = (state, { payload }) => ({ ...state, fetching: false, me: payload })

const updateMeStart = state => ({ ...state, fetching: true })
const updateMeFail = state => ({ ...state, fetching: false })
const updateMeDone = (state, { payload }) => ({ ...state, fetching: false, me: payload })

const updatePasswordStart = state => ({ ...state, fetchingPassword: true })
const updatePasswordFail = state => ({ ...state, fetchingPassword: false })
const updatePasswordDone = (state, { payload }) => ({
  ...state,
  fetchingPassword: false,
  me: {
    ...state.me,
    manNotificationDTO: payload,
  },
})

const updateNotificationsStart = state => ({ ...state, fetchingNotifications: true })
const updateNotificationsFail = state => ({ ...state, fetchingNotifications: false })
const updateNotificationsDone = state => ({ ...state, fetchingNotifications: false })

const clearUploadedMediaStart = state => ({ ...state, fetchingPassword: true })
const clearUploadedMediaFail = state => ({ ...state, fetchingPassword: false })
const clearUploadedMediaDone = state => ({ ...state, fetchingPassword: false })

const reducers = handleActions({
  MAN_GET_ME_START: getMeStart,
  MAN_GET_ME_FAIL: getMeFail,
  MAN_GET_ME_DONE: getMeDone,

  MAN_UPDATE_ME_START: updateMeStart,
  MAN_UPDATE_ME_FAIL: updateMeFail,
  MAN_UPDATE_ME_DONE: updateMeDone,

  MAN_UPDATE_PASSWORD_START: updatePasswordStart,
  MAN_UPDATE_PASSWORD_FAIL: updatePasswordFail,
  MAN_UPDATE_PASSWORD_DONE: updatePasswordDone,

  MAN_UPDATE_NOTIFICATIONS_START: updateNotificationsStart,
  MAN_UPDATE_NOTIFICATIONS_FAIL: updateNotificationsFail,
  MAN_UPDATE_NOTIFICATIONS_DONE: updateNotificationsDone,

  MAN_CLEAR_UPLOADED_MEDIA_START: clearUploadedMediaStart,
  MAN_CLEAR_UPLOADED_MEDIA_FAIL: clearUploadedMediaFail,
  MAN_CLEAR_UPLOADED_MEDIA_DONE: clearUploadedMediaDone,
}, initialState)

export {
  manGetMeStart,
  manGetMeFail,
  manGetMeDone,

  manUpdateMeStart,
  manUpdateMeFail,
  manUpdateMeDone,

  manUpdatePasswordStart,
  manUpdatePasswordFail,
  manUpdatePasswordDone,

  manUpdateNotificationsStart,
  manUpdateNotificationsFail,
  manUpdateNotificationsDone,

  manClearUploadedMediaStart,
  manClearUploadedMediaFail,
  manClearUploadedMediaDone,
}

export default reducers
