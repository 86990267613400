const HEIGHT_CM = {
  label: 'cm',
  value: 'CM',
}

const HEIGHT_FT = {
  label: 'ft',
  value: 'FT',
}

const heightOptions = [HEIGHT_CM, HEIGHT_FT]

export {
  HEIGHT_CM,
  HEIGHT_FT,
  heightOptions,
}
