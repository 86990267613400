import React from 'react'

const Chat = () => (
  <svg width="77" height="55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M42 2a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2v10.955a2 2 0 0 1-2 2H50.674a2 2 0 0 0-1.406.578l-1.987 1.965c-.939.928-2.531.263-2.531-1.057v-.111c0-.76-.616-1.375-1.375-1.375-.76 0-1.375-.616-1.375-1.375V2zM44 27a2 2 0 0 1 2-2h29a2 2 0 0 1 2 2v18.432a2 2 0 0 1-2 2H56.6a2 2 0 0 0-1.406.578l-5.366 5.306c-.632.624-1.703.177-1.703-.711v-3.173a2 2 0 0 0-2-2H46a2 2 0 0 1-2-2V27zM38 10a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v22.171a2 2 0 0 0 2 2h21.607a2 2 0 0 1 1.416.587l4.811 4.82c1.26 1.262 3.416.37 3.416-1.412V36.17a2 2 0 0 1 2-2H36a2 2 0 0 0 2-2V10zM38 48a2 2 0 0 0-2-2h-6a2 2 0 0 0-2 2v2.73a2 2 0 0 0 2 2h3.615a2 2 0 0 1 1.399.57l1.31 1.282a.251.251 0 0 0 .426-.179v-1.048c0-.345.28-.625.625-.625s.625-.28.625-.625V48z" fill="#F7D223" />
    <path d="M19.912 18.8h3.776v8.272h5.056V30h-8.832V18.8zm9.875 0h3.776V30h-3.776V18.8zm17.562 0L42.613 30H38.9l-4.736-11.2h4.064l2.672 6.544 2.736-6.544h3.712zm9.688 8.352V30h-9.313V18.8h9.105v2.848h-5.392v1.312h4.736v2.72h-4.736v1.472h5.6z" fill="#01124F" />
  </svg>
)

export default Chat
