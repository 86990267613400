import MediaApi from 'api/FavoriteMediaApi'
import {
  filterFavoritePhotosDone,
  filterFavoritePhotosFail,
  filterFavoritePhotosStart,
  filterFavoriteVideosDone,
  filterFavoriteVideosFail,
  filterFavoriteVideosStart,
} from 'redux/reducers/favoriteMedia'
import enqueueMessage from 'service/notification/enqueue'
import { mapFilterToApi } from '../mapper'

const filterPhotos = (filter = {}) => (dispatch, getState) => {
  const newFilter = {
    ...getState().favoriteMedia.filterPhoto,
    ...filter,
  }

  dispatch(filterFavoritePhotosStart(newFilter))

  return MediaApi.filterFavoritePhotos(mapFilterToApi(newFilter))
    .then(data => {
      if (data.errMsg) {
        throw Error(data.message || data.errMsg)
      }

      return dispatch(filterFavoritePhotosDone(data))
    })
    .catch(error => {
      dispatch(enqueueMessage(error.message))

      dispatch(filterFavoritePhotosFail())

      return Promise.reject()
    })
}

const filterVideos = (filter = {}) => (dispatch, getState) => {
  const newFilter = {
    ...getState().favoriteMedia.filterVideo,
    ...filter,
  }

  dispatch(filterFavoriteVideosStart(newFilter))

  return MediaApi.filterFavoriteVideos(mapFilterToApi(newFilter))
    .then(data => {
      if (data.errMsg) {
        throw Error(data.message || data.errMsg)
      }

      return dispatch(filterFavoriteVideosDone(data))
    })
    .catch(error => {
      dispatch(enqueueMessage(error.message))

      dispatch(filterFavoriteVideosFail())

      return Promise.reject()
    })
}

export {
  filterPhotos,
  filterVideos,
}
