const NEVER = {
  label: 'None',
  value: 'NEVER',
}
const SOCIALLY = {
  label: 'Socially',
  value: 'SOCIALLY',
}
const OCCASIONALLY = {
  label: 'Occasionally',
  value: 'OCCASIONALLY',
}

const englishSkillOptions = [
  NEVER,
  SOCIALLY,
  OCCASIONALLY,
]

export {
  NEVER,
  SOCIALLY,
  OCCASIONALLY,
  englishSkillOptions,
}
