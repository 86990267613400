import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import { useNavigate } from 'react-router'
import sx from './sx'

const HeaderMenuItem = ({ item }) => {
  const navigate = useNavigate()

  const onClick = useCallback(() => navigate(item.link), [item.link, navigate])

  return (
    <MenuItem onClick={onClick}>
      {item.text}
    </MenuItem>
  )
}

const HomeNavigationButton = ({ label, options, accent, url, openNewTab, ...rest }) => {
  const navigate = useNavigate()

  const [menu, setMenu] = useState(null)

  const handleOnClick = useCallback(event => {
    if (options) {
      setMenu(event.target)

      return
    }

    if (openNewTab) {
      return
    }

    navigate(url)
  }, [navigate, openNewTab, options, url])

  const handleOnCloseMenu = useCallback(() => setMenu(null), [])

  return (
    <>
      <Button
        {...rest}
        endIcon={options ? <KeyboardArrowDown /> : undefined}
        sx={sx.navBtn(!accent)}
        variant={accent ? 'contained' : 'text'}
        onClick={handleOnClick}
        {...openNewTab && {
          component: 'a',
          href: url,
          target: '_blank',
        }}
      >
        {label}
      </Button>
      {options &&
      <Menu
        disableAutoFocusItem
        anchorEl={menu}
        open={Boolean(menu)}
        onClose={handleOnCloseMenu}
      >
        {options.map(item => (
          <HeaderMenuItem
            key={item.text}
            item={item}
          />
        ))}
      </Menu>}
    </>
  )
}

HomeNavigationButton.propTypes = {
  /** Define button label. */
  label: PropTypes.string.isRequired,
  /** Define menu options. */
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  })),
}

export default HomeNavigationButton
