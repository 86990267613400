import React from 'react'
import {
  Box,
  Card,
  Container,
} from '@mui/material'
import left1 from 'assets/home/registration/1_1.jpg'
import left2 from 'assets/home/registration/1_2.png'
import left3 from 'assets/home/registration/1_3.png'
import left4 from 'assets/home/registration/1_4.png'
import right1 from 'assets/home/registration/2_1.png'
import right2 from 'assets/home/registration/2_2.png'
import right3 from 'assets/home/registration/2_3.png'
import right4 from 'assets/home/registration/2_4.png'
import Image from 'components/Image'
import sx from './sx'

const Layout = ({ children }) => (
  <Container maxWidth="lg">
    <Card sx={sx.card}>
      <Box sx={sx.container}>
        <Box sx={sx.photosContainer}>
          <Image src={left1} alt="left1" />
          <Image src={left2} alt="left2" />
          <Image src={left3} alt="left3" />
          <Image src={left4} alt="left4" />
        </Box>
        <Box sx={sx.childrenContainer}>
          {children}
        </Box>
        <Box sx={sx.photosContainer}>
          <Image src={right1} alt="right1" />
          <Image src={right2} alt="right2" />
          <Image src={right3} alt="right3" />
          <Image src={right4} alt="right4" />
        </Box>
      </Box>
    </Card>
  </Container>
)


export default Layout
