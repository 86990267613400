import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import sx from './sx'

const itemsCount = 5

const CarouselItem = ({ item, index, renderItem, onClick }) => {
  return (
    <Box sx={{
      ...sx.items[index],
      ...sx.transition,
    }}
    >
      {renderItem(item, index === 2, onClick)}
    </Box>
  )
}

const Carousel = ({ items, labelKey, itemKey, renderItem, onClick }) => {
  const [selectedItem, setSelectedItem] = useState(4)

  const orderIndexes = []
  const centeredItem = Math.floor(itemsCount / 2) + 1

  for (let i = 0; i < itemsCount; i++) {
    const shiftIndex = selectedItem + i + centeredItem
    const index = shiftIndex >= itemsCount ? shiftIndex % itemsCount : shiftIndex

    orderIndexes.push(index)
  }

  function handleOnClick(item) {
    onClick(item)
  }

  function showNext() {
    setSelectedItem(selectedItem - 1 !== -1 ? selectedItem - 1 : itemsCount - 1)
  }

  function showPrevious() {
    setSelectedItem(selectedItem + 1 !== itemsCount ? selectedItem + 1 : 0)
  }

  return (
    <>
      <Box sx={sx.container}>
        {items.map((item, index) => (
          <CarouselItem
            key={item[itemKey]}
            item={item}
            index={orderIndexes[index]}
            renderItem={renderItem}
            onClick={handleOnClick}
          />
        ))}
      </Box>
      <Box sx={sx.titleContainer}>
        <IconButton size="large" onClick={showPrevious}>
          <ArrowBackIosNew />
        </IconButton>
        <Grid container alignItems="center" justifyContent="center">
          <Typography variant="h6">
            {items[selectedItem][labelKey]}
          </Typography>
        </Grid>
        <IconButton size="large" onClick={showNext}>
          <ArrowForwardIos />
        </IconButton>
      </Box>
    </>
  )
}

export default Carousel
