import { grey } from '@mui/material/colors'

export default {
  container: {
    border: `1px solid ${grey[300]}`,
    borderRadius: 1,
    padding: 2,
  },
  divider: {
    mt: 1,
  },
  toolbarContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 32px)',
    gridGap: 4,
    justifyContent: 'center',
  },
  toolbarState: active => ({
    ...active && { background: 'grey.500' },
  }),
}
