export default theme => ({
  buttonRoot: {
    backgroundColor: '#fff',

    border: 'none',
    color: '#0081f1',
    padding: '20px 30px',
    display: 'flex',
    fontWeight: 700,
    justifyContent: 'space-between',
    textDecoration: 'none',
    alignItems: 'center',

    width: '100%',
    height: 100,
  },
  expressButtonText: {
    fontWeight: 700,
  },
  sidebar: {
    height: 90,
  },
  selected: {
    backgroundColor: '#0081f126',
  },
})
