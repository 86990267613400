import React from 'react'

const Finances = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="29" height="30" viewBox="0 0 30 31" fill="none">
    <path d="M30 21.7v-3.3l-1.2v2.1l1.3zM26.7 7.5V4.2L25 6.7l1.7zM4.2 7.5V4.2l1.7 1.3v1.7l-1.7zM0 13.3V10l1.7v2.5H0zM22.5 10.4v2.9l-0.8-1.7-1.2zM2.5 18.3v-2.1l0.8v0.8l-0.8zM1.7 26.7v-3.3l1.3v2.9h-1.2zM24.2 24.2v2.5l-1.2-0.8-0.4-1.7h1.7z" fill="#01124F" />
    <path d="M24.2 26.7c0 2.3-5 4.2-11.2 4.2-6.2 0-11.2-1.9-11.2-4.2 0-2.3 5-4.2 11.3-4.2 6.2 0 11.3 1.9 11.3 4.2z" fill="#01124F" />
    <path d="M24.2 22.9c0 2.5-5 4.6-11.2 4.6-6.2 0-11.2-2.1-11.2-4.6 0-2.5 5-4.6 11.3-4.6 6.2 0 11.3 2.1 11.3 4.6z" fill="#01124F" />
    <path d="M23.3 22.9c0 2.1-4.5 3.8-10 3.8-5.5 0-10-1.7-10-3.7s4.5-3.7 10-3.7c5.5 0 10 1.7 10 3.8z" fill="#fff" />
    <path d="M30 21.7c0 2.3-5 4.2-11.2 4.2S7.5 24 7.5 21.7c0-2.3 5-4.2 11.3-4.2S30 19.4 30 21.7z" fill="#01124F" />
    <path d="M30 18.3c0 2.3-5 4.2-11.2 4.2S7.5 20.6 7.5 18.3c0-2.3 5-4.2 11.3-4.2S30 16 30 18.3z" fill="#01124F" />
    <path d="M28.3 18.8c0 2.1-4.1 3.8-9.2 3.8C14.1 22.5 10 20.8 10 18.8S14.1 15 19.2 15c5.1 0 9.2 1.7 9.2 3.8z" fill="#fff" />
    <path d="M24.2 18.3c0 2.8-4.9 5-10.8 5-6 0-10.8-2.2-10.8-5s4.9-5 10.8-5c6 0 10.8 2.2 10.8 5z" fill="#01124F" />
    <path d="M24.2 15.4c0 2.5-4.9 4.6-10.8 4.6-6 0-10.8-2.1-10.8-4.6 0-2.5 4.9-4.6 10.8-4.6 6 0 10.8 2.1 10.8 4.6z" fill="#01124F" />
    <path d="M22.5 14.6c0 2.5-4.3 4.6-9.6 4.6-5.3 0-9.6-2.1-9.6-4.6C3.3 12.1 7.6 10 12.9 10c5.3 0 9.6 2.1 9.6 4.6z" fill="#fff" />
    <path d="M22.5 13.3c0 2.8-5 5-11.2 5S0 16.1 0 13.3s5-5 11.3-5 11.3 2.2 11.3 5z" fill="#01124F" />
    <path d="M22.5 10c0 2.3-5 4.2-11.2 4.2S0 12.3 0 10C0 7.7 5 5.8 11.3 5.8S22.5 7.7 22.5 10z" fill="#01124F" />
    <path d="M21.7 10.4c0 2.1-4.5 3.8-10 3.8-5.5 0-10-1.7-10-3.7 0-2.1 4.5-3.7 10-3.7 5.5 0 10 1.7 10 3.8z" fill="#fff" />
    <path d="M26.7 7.5c0 2.3-5 4.2-11.2 4.2-6.2 0-11.2-1.9-11.2-4.2 0-2.3 5-4.2 11.3-4.2 6.2 0 11.3 1.9 11.3 4.2z" fill="#01124F" />
    <path d="M26.7 4.2c0 2.3-5 4.2-11.2 4.2-6.2 0-11.2-1.9-11.2-4.2C4.2 1.9 9.2 0 15.4 0c6.2 0 11.3 1.9 11.3 4.2z" fill="#01124F" />
    <path d="M25 4.6c0 2.1-4.1 3.8-9.2 3.8-5.1 0-9.2-1.7-9.2-3.7 0-2.1 4.1-3.7 9.2-3.7 5.1 0 9.2 1.7 9.2 3.8z" fill="#fff" />
    <path d="M24.2 18.3v-2.5h-0.8l-0.4 2.1 1.3z" fill="#01124F" />
  </svg>
)

export default Finances
