import React, { useCallback, useMemo } from 'react'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import headerImage from 'assets/home/letters_header.png'
import emoji from 'assets/home/letters/emoji.png'
import youtube from 'assets/home/letters/youtube.png'
import photos from 'assets/home/letters/photos.png'
import links from 'assets/home/letters/links.png'
import { useTranslation } from 'react-i18next'
import HomeLayout from 'views/Home/HomeLayout'
import Image from 'components/Image'

import mailRed from 'assets/home/letters/mail_red.png'
import mailBlue from 'assets/home/letters/mail_blue.png'
import mailGreen from 'assets/home/letters/mail_green.png'

const LettersView = () => {
  const { t } = useTranslation('home')

  const attachements = useMemo(() => [emoji, youtube, photos, links], [])
  const mailBoxes = useMemo(() => [mailRed, mailBlue, mailGreen], [])

  const renderAttachments = useCallback(points => (
    <Grid container spacing={2}>
      {points.map((text, index) => (
        <>
          <Grid item container justifyContent="center" md={3} xs={6}>
            <Stack rowGap={2}>
              <Image src={attachements[index]} />
              <Typography variant="subtitle2" textAlign="center">
                {text}
              </Typography>
            </Stack>
          </Grid>
        </>
      ))}
    </Grid>
  ), [attachements])


  const renderDotList = useCallback(points => (
    <ul>
      <Stack rowGap={1}>
        {points.map(text => (
          <>
            <Typography component="li">{text}</Typography>
          </>
        ))}
      </Stack>
    </ul>
  ), [])

  const renderColors = useCallback(points => (
    <Stack rowGap={3}>
      {points.map((text, index) => (
        <>
          <Stack direction="row" alignItems="center" columnGap={1} sx={{ height: 64 }}>
            <Image src={mailBoxes[index]} />
            <Typography sx={{ ml: 2 }}>
              {text}
            </Typography>
          </Stack>
        </>
      ))}
    </Stack>
  ), [mailBoxes])

  const additionallContent = useMemo(() => [
    renderAttachments,
    renderDotList,
    renderColors,
    renderDotList,
  ], [renderAttachments, renderColors, renderDotList])

  return (
    <HomeLayout
      image={headerImage}
      header={t('sections.services.item.letters')}
      title={t('features.letters.title')}
      margin
      useTitle
    >
      <Stack container rowGap={4}>
        {t('features.letters.content', { returnObjects: true }).map((item, index) => (
          <>
            <Grid container justifyContent="center" rowGap={2}>
              <Typography variant="h6">
                {item.title}
              </Typography>
              <Grid container spacing={2} mt={1}>
                {additionallContent[index](item.points)}
              </Grid>
            </Grid>
          </>
        ))}
      </Stack>
    </HomeLayout>

  )
}

export default LettersView
