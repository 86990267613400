import { grey } from '@mui/material/colors'

export default {
  header: {
    py: 4,
  },
  title: {
    color: grey[500],
  },
  paper: {
    // backgroundColor: 'unset',
    background: 'linear-gradient(0deg, black 0%, black 1%, rgba(0,0,0,.6509803921568627) 54%)',
  },

  carousell: {
    height: '200px',
  },

  headerAction: {
    p: 0,
    mx: 1,
    width: 48,
    height: 48,
    minWidth: 0,
    color: grey[500],
  },

  mediaViewContainer: {
    display: 'grid',
    gridTemplateColumns: '75px auto 75px',
    height: '100%',
  },
  mediaView: {
    // width: 'min-content',
    // margin: '0 auto',
    height: '100%',
  },
  mediaViewActions: {
    color: grey[500],
    height: '100%',
    borderRadius: 0,
  },
  photo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },

}
