export default {
  container: clickable => ({
    height: '100%',
    cursor: clickable && 'pointer',
    position: 'relative',
  }),
  hover: {
    'position': 'absolute',
    'height': '100%',
    'width': '100%',
    'opacity': 0,
    ':hover': {
      opacity: 1,
      background: '#00000099',
    },
  },
  icon: {
    color: 'white',
    height: 72,
    width: 72,
    right: 'calc(50% - 1.5em)',
    top: 'calc(50% - 1.5em)',
    position: 'absolute',
  },
}
