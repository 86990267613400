import { isMobile } from 'react-device-detect'

const mapSignInToApi = stateData => ({
  login: stateData.login,
  password: stateData.password,
  // bonusKey: stateData.,
  mobile: isMobile,
  // loginFromEmail: stateData.,
})

const mapSignUpToApi = stateData => ({
  firstName: stateData.firstName.trim(),
  lastName: stateData.lastName.trim(),
  email: stateData.email.trim(),
  password: stateData.password.trim(),
  confPassword: stateData.repeatPassword.trim(),
  // domenName: stateData,
  birthdayStr: stateData.birthday.trim(),
  countryId: stateData.countryId,
  mobile: isMobile,
  noMail: !stateData.emailNotifications,
})

export {
  mapSignUpToApi,
  mapSignInToApi,
}
