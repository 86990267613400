import Api from './Api'

class LocationApi {
  static getUserLocation = () => {
    const path = `${Api.basePath}auth/validateip`
    // const path = 'https://api.ipapi.com/api/check?access_key=8ba17db09a57be52e8ff189fffadaa61&fields=country_code,continent_code'
    // const path = 'https://geolocation-db.com/json/'
    return Api.get(path, { headers: {} }).then(response => response.json())
  }
}

export default LocationApi
