import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import MuiCheckbox from '@mui/material/Checkbox'
import Check from '@mui/icons-material/Check'
import Remove from '@mui/icons-material/Remove'
import { withStyles } from '@mui/styles'
import styles from './styles'

const Checkbox = ({ classes, size, ...rest }) => {
  const classesChecked = classNames({
    [classes.checkedIconSmall]: size === 'small',
    [classes.checkedIconMedium]: size === 'medium',
    [classes.checkedIconLarge]: size === 'large',
  })
  const classesUnchecked = classNames({
    [classes.uncheckedIconSmall]: size === 'small',
    [classes.uncheckedIconMedium]: size === 'medium',
    [classes.uncheckedIconLarge]: size === 'large',
  })

  const CheckboxClasses = {
    ...Boolean(classes.root) && { root: classes.root },
    ...Boolean(classes.checked) && { checked: classes.checked },
    ...Boolean(classes.disabled) && { disabled: classes.disabled },
    ...Boolean(classes.indeterminate) && { indeterminate: classes.indeterminate },
    ...Boolean(classes.colorPrimary) && { colorPrimary: classes.colorPrimary },
    ...Boolean(classes.colorSecondary) && { colorSecondary: classes.colorSecondary },
  }

  return (
    <MuiCheckbox
      classes={CheckboxClasses}
      icon={<Check className={classesUnchecked} />}
      indeterminateIcon={<Remove className={classesChecked} />}
      checkedIcon={<Check className={classesChecked} />}
      {...rest}
    />
  )
}

Checkbox.propTypes = {
  /** Classes passed to checkbox. Include core & wrapper classes. */
  classes: PropTypes.object,
  /** Sizes of checkbox */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
}

Checkbox.defaultProps = {
  size: 'small',
}

export default withStyles(styles)(Checkbox)
