import React, { useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import headerImage from 'assets/home/gifts_header.png'
import { useTranslation } from 'react-i18next'
import HomeLayout from 'views/Home/HomeLayout'
import { getVideoStoryByID } from 'service/home/actions/stories'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getVideoStoriesSingle } from 'service/home/selectors/stories'
import moment from 'moment'
import ReactPlayer from 'react-player'
import sx from './sx'

const DetailsStoryView = ({ item, getVideoStoryByID }) => {
  const { id } = useParams()

  const { t } = useTranslation('home')

  useEffect(() => {
    getVideoStoryByID(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <HomeLayout
      image={headerImage}
      header={t('news.title')}
    >
      <Grid container rowGap={4}>
        <Box sx={sx.header}>
          <Grid item container rowGap={4}>
            <Grid item xs={12}>
              <Typography variant="h4">
                {item.author}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {moment(item.date).format('DD-mm-YYYY HH:mm')}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={sx.image}>
          <ReactPlayer
            width="105%"
            height="auto"
            url={item.videoUrl}
            controls
          />
        </Box>
      </Grid>
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  item: getVideoStoriesSingle(state),
})

const mapDispatchToProps = {
  getVideoStoryByID,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(DetailsStoryView)
