import CountryApi from 'api/CountryApi'
import { countryGetListStart, countryGetListFail, countryGetListDone } from 'redux/reducers/country'
import { mapFilterToApi } from '../mapper'

const getList = filter => (dispatch, getState) => {
  dispatch(countryGetListStart())

  const newFilter = {
    ...getState().country.filter,
    ...filter,
  }

  return CountryApi.getList(mapFilterToApi(newFilter))
    .then(data => {
      if (data.errMsg) {
        throw Error(data.message || data.errMsg)
      }

      return dispatch(countryGetListDone(data))
    })
    .catch(error => {
      dispatch(countryGetListFail())

      return Promise.reject()
    })
}

export {
  getList,
}
