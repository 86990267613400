import Api from './Api'

class AuthApi {
  static signIn = data => {
    const path = `${Api.basePath}auth/login`
    return Api.post(path, data).then(response => response.json())
  }

  static signUp = data => {
    const path = `${Api.basePath}auth/register`
    return Api.post(path, data).then(response => response.json())
  }
}

export default AuthApi
