import React from 'react'
import cx from 'classnames'
import { ButtonBase, Card, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { compose } from 'redux'
import { useTranslation } from 'react-i18next'
import styles from './styles'
import sx from './sx'

const ExpressButton = ({ classes, item, sidebar, selected, onClick }) => {
  const { t } = useTranslation('text')

  function handleOnClick() {
    onClick(item)
  }

  const className = cx(classes.buttonRoot, {
    [classes.sidebar]: sidebar,
    [classes.selected]: selected,
  })

  return (
    <Card sx={sx.cardButton}>
      <ButtonBase className={className} onClick={handleOnClick}>
        {item.icon && <img src={item.icon} alt={item.text} />}
        {item.iconSVG && <item.iconSVG />}
        <Typography className={classes.expressButtonText}>{t(item.text)}</Typography>
      </ButtonBase>
    </Card>
  )
}

export default compose(
  withStyles(styles),
)(ExpressButton)
