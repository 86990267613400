import { common } from '@mui/material/colors'

const commonSx = {
  'color': common.white,
  'borderRadius': '50%',
  ':hover': {
    opacity: 0.8,
    color: 'white',
  },
}

export default [
  {
    type: 'facebook',
    link: 'https://www.facebook.com/DreamoneLove7/?ref=bookmarks',
    sx: {
      ...commonSx,
      backgroundColor: '#3b5998',
    },
  },
  {
    type: 'youtube',
    link: 'https://www.youtube.com/channel/UCWcwoWesqxiq8t-J25PxUcQ/featured',
    sx: {
      ...commonSx,
      backgroundColor: '#ff0000',
    },
  },
  {
    type: 'instagram',
    link: 'https://www.instagram.com/dreamonelove1/',
    sx: {
      ...commonSx,
      background: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)',
    },
  },
  {
    type: 'twitter',
    link: 'https://twitter.com/DreamOneLove1',
    sx: {
      ...commonSx,
      backgroundColor: '#55acee',
    },
  },
  // TODO linkedIn instead of pinterest, check with Igor
  {
    type: 'linkedin',
    link: 'https://www.linkedin.com/in/ikharatin-kharatin-687a1649/?originalSubdomain=us',
    sx: {
      ...commonSx,
      backgroundColor: '#0077b5',
    },
  },
  {
    type: 'google-plus',
    link: 'https://plus.google.com/u/0/113749736488334826651',
    sx: {
      ...commonSx,
      backgroundColor: '#dc4e41',
    },
  },
]
