import React, { useCallback, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'

import CustomTable from 'components/Table'
import { getAuthUserInfo } from 'service/auth/selectors'
import { ladiesAddFavorite, ladiesRemoveFavorite } from 'service/ladies/actions/favorite'
import { filterContacts as filterLadies } from 'service/ladies/actions/filter'
import {
  getContacts,
  getContactsCount,
  getContactsFilter,
  getContactsPagination,
} from 'service/ladies/selectors'

import LadiesTableRow from './LadiesTable/LadiesTableRow'

const LadiesContacts = ({ items, itemsCount, pagination, filterLadies, ladiesFilterObject, ladiesRemoveFavorite, ladiesAddFavorite }) => {
  const [filterObj, setFilterObj] = useState({
    from: 0,
    size: 20,
  })

  useEffect(() => {
    filterLadies(filterObj)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { t } = useTranslation('text')

  const headers = [
    {
      name: t('profile.fields.id'),
      align: 'left',
    },
    {
      name: t('profile.fields.firstName'),
      align: 'right',
    },
    {
      name: t('profile.fields.from'),
      align: 'right',
    },
    {
      name: t('profile.fields.mail'),
      align: 'right',
    },
    {
      name: t('profile.fields.letter'),
      align: 'right',
    },
  ]

  const handleOnPageChanged = useCallback(pageNumber => {
    filterLadies({
      from: (pageNumber - 1) * ladiesFilterObject.size,
    })
  }, [filterLadies, ladiesFilterObject.size])

  const handleOnSort = useCallback(value => {
    filterLadies({
      sort: {
        fldName: value.sortField,
        sortType: value.sortType,
      },
    })
  }, [filterLadies])

  const renderTableRow = useCallback(item => <LadiesTableRow item={item} />, [])

  return (
    <CustomTable
      header={headers}
      data={items}
      page={pagination.currentPage}
      count={pagination.pagesCount}
      renderRow={renderTableRow}
      onPageChanged={handleOnPageChanged}
    />
  )
}


const mapStateToProps = state => ({
  userInfo: getAuthUserInfo(state),
  items: getContacts(state),
  itemsCount: getContactsCount(state),
  pagination: getContactsPagination(state),
  ladiesFilterObject: getContactsFilter(state),
})

const mapDispatchToProps = {
  filterLadies,
  ladiesAddFavorite,
  ladiesRemoveFavorite,
}


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(LadiesContacts)
