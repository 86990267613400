import UploadApi from 'api/UploadApi'
import {
  uploadManPhotoDone,
  uploadManPhotoFail,
  uploadManPhotoStart,
  uploadManVideoDone,
  uploadManVideoFail,
  uploadManVideoStart,
} from 'redux/reducers/upload'

const mimeType = require('mime-db')

const uploadManPhoto = photo => dispatch => {
  dispatch(uploadManPhotoStart())

  return UploadApi.uploadManPhoto(photo)
    .then(data => {
      if (data.errMsg) {
        throw Error(data.message || data.errMsg)
      }

      dispatch(uploadManPhotoDone(data))

      return data
    })
    .catch(error => {
      dispatch(uploadManPhotoFail())

      return Promise.reject()
    })
}

const uploadManVideo = video => dispatch => {
  dispatch(uploadManVideoStart())

  const fileExt = mimeType[video.type]

  if (fileExt.extensions.length === 0) {
    return Promise.reject()
  }

  return UploadApi.uploadManVideo(video, fileExt.extensions[0])
    .then(data => {
      if (data.errMsg) {
        throw Error(data.message || data.errMsg)
      }

      dispatch(uploadManVideoDone(data))

      return data
    })
    .catch(error => {
      dispatch(uploadManVideoFail())

      return Promise.reject()
    })
}

export {
  uploadManPhoto,
  uploadManVideo,
}
