import React from 'react'
import {
  Box,
  Grid,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getEye, getHair, getHeight, getLookingAge, getWeight } from 'utils/getProfileOptions'
import { PHOTO } from 'consts/thubnails'
import { drinkingOptions, educationOptions, getLabel, smokingOptions } from 'consts/profileEditOptions'
import styles from './styles'

const GeneralInfoSection = ({ general, additional, photoURL }) => {
  const { t } = useTranslation('text')
  const sx = styles()

  const photoLink = `url(${photoURL}${general.mediaName}${PHOTO.NORMAL})`

  const renderInfo = (main, label, value) => {
    return (
      <Grid container sx={sx.fieldContainer}>
        <Grid item md={4} xs={7}>
          <Typography sx={sx.fieldLabel}>
            {`${label}:`}
          </Typography>
        </Grid>
        <Grid item md={8} xs={5}>
          <Typography sx={sx.fieldValue}>
            {value || t('general.na')}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container sx={sx.infoSection} spacing={2}>
      <Grid item xs={6}>
        {renderInfo(true, t('profile.info.country'), general.countryName)}
        {renderInfo(true, t('profile.info.birthday'), additional.birthday)}
        {renderInfo(true, t('profile.info.heightView'), getHeight(t, additional))}
        {renderInfo(true, t('profile.info.weightView'), getWeight(t, additional))}
        {renderInfo(true, t('profile.info.hair'), getHair(t, additional))}
        {renderInfo(true, t('profile.info.eyes'), getEye(t, additional))}
        {renderInfo(true, t('profile.info.drinking'), getLabel(drinkingOptions, additional.drinker))}
        {renderInfo(true, t('profile.info.smoking'), getLabel(smokingOptions, additional.smoker))}
        {renderInfo(true, t('profile.info.education'), getLabel(educationOptions, additional.education))}
        {renderInfo(true, t('profile.info.lookingAge'), getLookingAge(additional.lookingAgeFrom, additional.lookingAgeTo))}
      </Grid>
      <Grid item xs={6}>
        <Box sx={sx.mainPhoto(photoLink)} />
      </Grid>
    </Grid>
  )
}

export default GeneralInfoSection
