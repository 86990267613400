import React from 'react'
import PropTypes from 'prop-types'

import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'

// components
import AutocompleteMUI from '@mui/material/Autocomplete'

const Autocomplete = ({ name, manualFilter, loading, TextFieldProps, TextFieldInputProps, LoaderIndicatorProps,
  onChange, onInputChange, ...rest }) => {
  function getOptionSelected(testOption, selectedOptions) {
    return testOption.value === selectedOptions.value
  }

  function getOptionLabel(option) {
    return option.label
  }

  function handleOnChange(event, value) {
    onChange({
      target: {
        name,
        value,
      },
    })
  }

  const handleOnFilterInnerSkip = (opts, state) => (opts)

  function handleOnInputChange(event, value, source) {
    if (onInputChange) {
      onInputChange(event, value, source)
    }
  }

  function renderInput(params) {
    return (
      <TextField
        {...TextFieldProps}
        {...params} // has higher prio in order to apply Autocomplete required props
        InputProps={{
          ...TextFieldInputProps,
          ...params.InputProps, // has higher prio in order to apply Autocomplete required props
          endAdornment: (
            <>
              {loading && <CircularProgress color="inherit" size={20} {...LoaderIndicatorProps} />}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    )
  }

  return (
    <AutocompleteMUI
      onChange={handleOnChange}
      onInputChange={handleOnInputChange}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={getOptionSelected}
      renderInput={renderInput}
      {...manualFilter && { filterOptions: handleOnFilterInnerSkip }}
      {...rest}
    />
  )
}

Autocomplete.propTypes = {
  /** Field name property. */
  name: PropTypes.string,
  /** Display loading indication if true. */
  loading: PropTypes.bool,
  /** Autocomplete will not filter options by itself. */
  manualFilter: PropTypes.bool,
  /** Return selected option. */
  onChange: PropTypes.func.isRequired,
  /** Return typed input value. */
  onInputChange: PropTypes.func,
  /** Set or override [TextField](https://v4.mui.com/api/text-field/) element props. To pass InputProps see TextFieldInputProps prop. Attention, those props applies before autocomplete input props. */
  TextFieldProps: PropTypes.object,
  /** Set or override [TextField](https://v4.mui.com/api/text-field/) element InputProps props. Attention, those props applies before autocomplete input props. */
  TextFieldInputProps: PropTypes.object,
  /** Set or override [CircularProgress](https://v4.mui.com/api/circular-progress/) element props. */
  LoaderIndicatorProps: PropTypes.object,
}

Autocomplete.defaultProps = {
  name: '',
  options: [],
  value: null,
  loading: false,
  multiple: false,
  manualFilter: false,
  onChange: () => {},
  onInputChange: () => {},
  TextFieldProps: null,
  TextFieldInputProps: null,
  LoaderIndicatorProps: null,
}

export default (Autocomplete)
