import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getSelectedLadyProfile } from 'service/ladies/selectors'

import { PHOTO } from 'consts/thubnails'
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import sx from './sx'

const PersonInfo = ({ photoURL, item }) => {
  const photoLink = `url(${photoURL}${item.mediaName}${PHOTO.NORMAL})`

  return (
    <Box sx={sx.photo(photoLink)} />
  )
}

export default PersonInfo
