const basePath = window._env_.BASE_PATH

const ladyPhotoURL = 'https://kiev2.dreamonelove.com/gloriabrides/services/test/img/lady/'
const ladyVideoURL = 'https://kiev2.dreamonelove.com/gloriabrides/services/test/media/lady/'

const manPhotoURL = 'https://kiev2.dreamonelove.com/gloriabrides/services/test/img/man/'
const manVideoURL = 'https://kiev2.dreamonelove.com/gloriabrides/services/test/media/man/'

const appURLs = {
  HOME: '*',
  HOME_SERVICE_ADDITIONAL: `${basePath}services/additional`,
  HOME_SERVICE_VIDEO_CHAT: `${basePath}services/video_chat`,
  HOME_SERVICE_LIVE_CHAT: `${basePath}services/live_chat`,
  HOME_SERVICE_VOICE_CHAT: `${basePath}services/voice_chat`,
  HOME_SERVICE_GIFTS: `${basePath}services/gifts`,
  HOME_SERVICE_LETTERS: `${basePath}services/letters`,
  HOME_SERVICE_DATE: `${basePath}services/date`,

  HOME_INFO_POLICY: `${basePath}policy`,
  HOME_INFO_PARTNERS: `${basePath}partners`,
  HOME_INFO_FAQ: `${basePath}faq`,
  HOME_INFO_PRIVACY: `${basePath}privacy`,
  HOME_INFO_CUSTOMER: `${basePath}customer`,
  HOME_INFO_TERMS: `${basePath}terms`,

  HOME_NEWS_DETAILS_BASE: `${basePath}services/news/`,
  HOME_NEWS_DETAILS: `${basePath}services/news/:id`,
  HOME_NEWS_OVERVIEW: `${basePath}services/news_overview`,

  HOME_STORY_DETAILS_BASE: `${basePath}services/story/`,
  HOME_STORY_DETAILS: `${basePath}services/story/:id`,
  HOME_STORIES_OVERVIEW: `${basePath}services/stories_overview`,

  HOME_FEEDBACK_DETAILS_BASE: `${basePath}services/feedback/`,
  HOME_FEEDBACK_DETAILS: `${basePath}services/feedback/:id`,
  HOME_FEEDBACKS_OVERVIEW: `${basePath}services/feedbacks_overview`,

  HOME_CONTACT_US: `${basePath}contact-us`,
  HOME_ABOUT: `${basePath}about`,
  HOME_BLOG: `${basePath}blog`,

  PROFILE: `${basePath}profile`,
  SIGN_UP: `${basePath}sign-up`,
  RECOVER_PASSWORD: `${basePath}recovering`,
  SETTINGS: `${basePath}settings`,
  LADY_PROFILE_VIEW_VISITOR: `${basePath}lady/:ladyID`,
  LADY_PROFILE_VIEW_VISITOR_BASE: `${basePath}lady/`,
  LAIDES_SEARCH: `${basePath}ladies/search`,
  LAIDES_FAVORITE: `${basePath}ladies/favorite`,
  LAIDES_NEWEST_SEARCH: `${basePath}ladies/newest/search`,
  LAIDES_TOP_SEARCH: `${basePath}ladies/top/search`,
  LAIDES_VISITORS: `${basePath}ladies/visitors`,
  LAIDES_CONTACTS: `${basePath}ladies/contacts`,
  // FINANSES
  PURCHASES_COINS: `${basePath}purchases/coins`,
  PURCHASES_HISTORY: `${basePath}purchases/history`,

  MAN_FAVORITE_PHOTOS: `${basePath}favorite/photos`,
  MAN_FAVORITE_VIDEOS: `${basePath}favorite/videos`,

  MAIL_WRITE_NEW: `${basePath}mail/write/:personID`,
  MAIL_WRITE_NEW_BASE: `${basePath}mail/write/`,
}

export {
  appURLs,
  ladyPhotoURL,
  ladyVideoURL,
  manPhotoURL,
  manVideoURL,
}
