import Api from './Api'

class ManApi {
  static getMe = () => {
    const path = `${Api.basePath}person/man/me`
    return Api.get(path).then(response => response.json())
  }

  static updateMe = data => {
    const path = `${Api.basePath}person/man/me`
    return Api.put(path, data).then(response => response.json())
  }

  static updatePassword = data => {
    const path = `${Api.basePath}person/man/password`
    return Api.put(path, data).then(response => (`${response.status}`[0] !== '2' ? response.json() : response.text()))
  }

  static updateNotificatioins = data => {
    const path = `${Api.basePath}person/man/me/notification`
    return Api.put(path, data).then(response => (`${response.status}`[0] !== '2' ? response.json() : response.text()))
  }

  static clearUploadedMedia = data => {
    const path = `${Api.basePath}person/man/cleanmedia`
    return Api.put(path, data).then(response => (`${response.status}`[0] !== '2' ? response.json() : response.text()))
  }
}

export default ManApi
