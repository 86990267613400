export default {
  header: {
    margin: '-24px -24px 0 -24px',
    position: 'relative',
    height: 370,
    overflow: 'hidden',
  },
  headerText: {
    background: '#0000007a',
    position: 'absolute',
    bottom: 120,
    top: 120,
    color: 'white',
    textAlign: 'center',
    width: '100%',
    padding: '0 128px',
  },
  paginationCard: {
    mt: 4,
  },
}
