import React, { useCallback } from 'react'

import { Card, Grid } from '@mui/material'
import { withStyles } from '@mui/styles'
import Chat from 'icons/express/Chat'
import New from 'icons/express/New'
import Search from 'icons/express/Search'
import Top from 'icons/express/Top'
import { manRoutes } from 'navigation/loginRequiredViews'
import { appURLs } from 'navigation/routes'
import { useTranslation } from 'react-i18next'
import { Route, Routes, useNavigate } from 'react-router-dom'

import Container from 'components/Container'

import CollapseList from './CollapseList/CollapseList'
import ExpressButton from './ExpressButton'
import sidebarMenu from './sidebarMenu'
import styles from './styles'
import sx from './sx'


const Layout = ({ classes }) => {
  const { t } = useTranslation('text')

  const navigate = useNavigate()

  const handleOnLinkButtonClick = useCallback(item => navigate(item.link), [navigate])

  const expressButtons = [
    {
      iconSVG: Search,
      text: 'layout.sidebar.search',
      link: appURLs.LAIDES_SEARCH,
    },
    {
      iconSVG: New,
      text: 'layout.express.newLadies',
      link: appURLs.LAIDES_NEWEST_SEARCH,
    },
    {
      iconSVG: Top,
      text: 'layout.express.top100',
      link: appURLs.LAIDES_TOP_SEARCH,
    },
    {
      iconSVG: Chat,
      text: 'layout.express.liveChat',
      link: '/wip',
    },
  ]

  const prepareSidebar = () => sidebarMenu(t).map(item => (
    <Grid
      className={classes.menus}
      key={item.label}
      lg={12}
      md={6}
      sm={6}
      xs={12}
      item
    >
      <CollapseList
        item={item}
        onClick={handleOnLinkButtonClick}
      />
    </Grid>
  ))

  const renderSidePanel = () => (
    <Grid container columnSpacing={2} rowSpacing={2}>
      {prepareSidebar()}
    </Grid>
  )

  const renderQuickActions = () => expressButtons.map(item => (
    <Grid key={item.text} lg={3} md={6} sm={6} xs={12} item>
      <ExpressButton
        item={item}
        onClick={handleOnLinkButtonClick}
        selected={window.location.href.includes(item.link)}
      />
    </Grid>
  ))

  return (
    <Container>
      <Grid
        container
        rowSpacing={2}
        sx={sx.container}
      >
        <Grid
          columnSpacing={2}
          rowSpacing={2}
          container
          item
        >
          {renderQuickActions()}
        </Grid>
        <Grid
          className="menu-info-container"
          columnSpacing={2}
          rowSpacing={2}
          container
          item
        >
          <Grid lg={3} md={12} sm={12} xs={12} item rowSpacing={2}>
            {renderSidePanel()}
          </Grid>
          <Grid lg={9} md={12} sm={12} xs={12} item rowSpacing={2}>
            <Card elevation={2}>
              <Routes>
                {manRoutes.map(route => (
                  <Route
                    key={route.key}
                    path={route.path}
                    element={route.component}
                  />
                ))}
              </Routes>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default withStyles(styles)(Layout)
