import servicesImage from 'assets/home/services_small.png'
import dateImage from 'assets/home/date_small.png'
import lettersImage from 'assets/home/letters.png'
import giftsImage from 'assets/home/gifts_small.png'
import liveChatImage from 'assets/home/liveChat_small.png'
import voiceChatImage from 'assets/home/voiceChat_small.png'
import videoChatImage from 'assets/home/videoChat_small.png'
import { appURLs } from 'navigation/routes'

export default (t, upMD) => {
  const additional = {
    text: t('sections.services.item.additional'),
    img: servicesImage,
    path: appURLs.HOME_SERVICE_ADDITIONAL,
  }
  const date = {
    text: t('sections.services.item.date'),
    img: dateImage,
    path: appURLs.HOME_SERVICE_DATE,
  }
  const letters = {
    text: t('sections.services.item.letters'),
    img: lettersImage,
    path: appURLs.HOME_SERVICE_LETTERS,
  }
  const gifts = {
    text: t('sections.services.item.gifts'),
    img: giftsImage,
    path: appURLs.HOME_SERVICE_GIFTS,
  }
  const liveChat = {
    text: t('sections.services.item.liveChat'),
    img: liveChatImage,
    path: appURLs.HOME_SERVICE_LIVE_CHAT,
  }
  const voiceChat = {
    text: t('sections.services.item.voiceChat'),
    img: voiceChatImage,
    path: appURLs.HOME_SERVICE_VOICE_CHAT,
  }
  const videoChat = {
    text: t('sections.services.item.videoChat'),
    img: videoChatImage,
    path: appURLs.HOME_SERVICE_VIDEO_CHAT,
  }

  if (upMD) {
    return {
      top: [additional, date, letters, gifts],
      bottom: [liveChat, voiceChat, videoChat],
    }
  }

  return {
    top: [additional, date, letters, gifts, liveChat, voiceChat],
    bottom: [videoChat],
  }
}
