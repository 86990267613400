export default {
  container: {
    mt: 8,
  },

  goToSearch: {
    mt: 2,
    textTransform: 'none',
    height: 'min-content',
  },

  photo: link => ({
    width: 168,
    backgroundSize: 'cover !important',
    backgroundPositionY: '50% !important',
    height: 168,
    borderRadius: '50%',
    background: link,
  }),
}
