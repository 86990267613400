import React, { useMemo } from 'react'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import { withStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import socials from 'consts/socials'
import styles from './styles'
import HomeSection from '../HomeSection'

const FollowUsSection = ({ classes }) => {
  const { t } = useTranslation('home')

  // TODO make a single render function for socials.
  return (
    <HomeSection title={t('sections.follow.title')}>
      <Grid className={cx(classes.container)} container spacing={2}>
        {socials.map(item => (
          <Grid key={item.type} item container xs={2}>
            <a
              href={item.link}
              target="_blank"
              rel="noreferrer"
            >
              <Avatar
                className={classes[item.type]}
                sx={item.sx}
                alt={item.name}
              >
                <FontAwesomeIcon icon={['fab', item.type]} />
              </Avatar>
            </a>
          </Grid>
        ))}
      </Grid>
    </HomeSection>
  )
}

export default withStyles(styles)(FollowUsSection)
