import Api from './Api'

class UploadApi {
  static uploadManPhoto = photo => {
    const path = `${Api.basePath}upload/man/photo`

    const data = new FormData()
    data.append('file', photo)

    return Api.postFormData(path, data).then(response => response.json())
  }

  static uploadManVideo = (videoFile, extenstion) => {
    const path = `${Api.basePath}upload/man/video`

    const data = new FormData()
    data.append('file', videoFile)
    data.append('extension', extenstion)

    return Api.postFormData(path, data).then(response => response.json())
  }
}

export default UploadApi
