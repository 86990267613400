import React from 'react'
import { connect } from 'react-redux'
import { getAuthUserInfo } from 'service/auth/selectors'
import ManSettings from './ManSettings'

class Settings extends React.PureComponent {
  render() {
    const { userInfo } = this.props

    return [
      userInfo.loginAsMan && <ManSettings />,
    ]
  }
}


const mapStateToProps = state => ({
  userInfo: getAuthUserInfo(state),
})


export default connect(mapStateToProps, null)(Settings)
