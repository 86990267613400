import React from 'react'
import { connect } from 'react-redux'
import { getCountriesCount, getCountriesOptions } from 'service/country/selectors'
import { getList as getCountryList } from 'service/country/actions/getList'
import { signUp } from 'service/auth/actions'
import { appURLs } from 'navigation/routes'
import updateHistory from 'utils/history'
import { getUserIpInfo } from 'service/location/actions'
import { locationLoading, registrationIsRestricted } from 'service/location/selectors'
import {
  Grid,
  Button,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import Checkbox from 'components/Checkbox'
import { compose } from 'redux'
import { withStyles } from '@mui/styles'
import { withTranslation } from 'react-i18next'
import Layout from 'views/Home/UserView/Layout'
import styles from './styles'

class Registration extends React.Component {
  constructor(props) {
    super(props)

    props.getUserIpInfo()

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      repeatPassword: '',
      birthday: '',
      countryId: 1,

      emailNotifications: true,
      passwordVisiable: false,
    }
  }

  componentDidMount() {
    const { countriesCount, getCountryList } = this.props

    if (!countriesCount) {
      getCountryList()
    }
  }

  handleOnChange = event => this.setState({ [event.target.name]: event.target.value })

  handleOnSignInClick = () => this.props.signUp(this.state)
    .then(() => updateHistory('push', appURLs.PROFILE))

  renderTextField = (label, name) => (
    <Grid item xs={12} md={6}>
      <TextField
        label={label}
        name={name}
        onChange={this.handleOnChange}
        required
        fullWidth
      />
    </Grid>
  )

  renderSelectField = (label, name, options) => (
    <>
      <Grid item xs={12} md={6} margin="0 auto">
        <TextField
          label={label}
          name={name}
          value={this.state[name]}
          onChange={this.handleOnChange}
          required
          select
          fullWidth
        >
          {options.map(option => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
        </TextField>
      </Grid>
    </>
  )

  renderForm = () => {
    const { t, countriesOptions } = this.props
    const { emailNotifications } = this.state

    return (
      <>
        <Grid container spacing={4}>
          {this.renderTextField(t('registration.field.firstName'), 'firstName')}
          {this.renderTextField(t('registration.field.lastName'), 'lastName')}
          {this.renderTextField(t('registration.field.email'), 'email')}
          {this.renderTextField(t('registration.field.birthday'), 'birthday')}
          {this.renderTextField(t('registration.field.password'), 'password')}
          {this.renderTextField(t('registration.field.repeatPassword'), 'repeatPassword')}
          {this.renderSelectField(t('registration.field.country'), 'countryId', countriesOptions)}
        </Grid>
        <Grid container justifyContent="center" className="reg-action" py={4}>
          <FormControlLabel
            name="emailNotifications"
            label={t('registration.field.emailNotifications')}
            control={<Checkbox />}
            checked={emailNotifications}
            onChange={this.handleOnChange}
          />
          <Button onClick={this.handleOnSignInClick}>
            {t('registration.action.signUp')}
          </Button>
        </Grid>
      </>
    )
  }

  renderView = () => {
    const { t, locationInfoLoadgin, registrationIsRestricted } = this.props

    if (locationInfoLoadgin) {
      return <Typography>{t('general.loading')}</Typography>
    }

    return registrationIsRestricted
      ? <Typography>{t('registration.info.restricted')}</Typography>
      : this.renderForm()
  }

  render() {
    const { t } = this.props

    return (
      <Layout>
        <Grid container justifyContent="center">
          <Typography variant="h5">
            {t('registration.title')}
          </Typography>
        </Grid>
        <Grid paddingTop={4}>
          {this.renderView()}
        </Grid>
      </Layout>
    )
  }
}


const mapStateToProps = state => ({
  countriesCount: getCountriesCount(state),
  countriesOptions: getCountriesOptions(state),
  registrationIsRestricted: registrationIsRestricted(state),
  locationInfoLoadgin: locationLoading(state),
})

const mapDispatchToProps = {
  signUp,
  getCountryList,
  getUserIpInfo,
}

export default compose(
  withTranslation('text'),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(Registration)
