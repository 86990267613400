import React from 'react'
import PropTypes from 'prop-types'
import { TYPE } from 'components/ViewMedia/ViewMedia'
import {
  Button,
  Box,
  Grid,
  Typography,
} from '@mui/material'
import Delete from '@mui/icons-material/Delete'
import PlayCircle from '@mui/icons-material/PlayCircle'
import { VIDEO, PHOTO } from 'consts/thubnails'

const FavoriteMediaItem = ({ t, classes, type, item, onClick, onDelete }) => {
  const preview = type === TYPE.VIDEO
    ? `url(${item.videoUrl}${VIDEO.NORMAL})`
    : `url(${item.photoUrl}${PHOTO.MEDIUM})`

  function handleOnClick() {
    onClick(item)
  }

  function handleOnDelete() {
    onDelete(item)
  }

  const renderDeleteText = (t, type) => (type === TYPE.PHOTO
    ? t('favorite.photo.delete')
    : t('favorite.video.delete'))

  return (
    <Grid item container spacing={2}>
      <Grid item xs={6}>
        <Box
          className={classes.mediaCard}
          sx={{
            background: preview,
            borderRadius: 2,
          }}
          onClick={handleOnClick}
        >
          {type === TYPE.VIDEO && <PlayCircle />}
        </Box>
      </Grid>
      <Grid item xs={6} className={classes.cardContent}>
        <div className={classes.ladyTextInfoContainer}>
          <Box className={classes.clickableItem}>
            <Typography className={classes.ladyNameInfo}>{item.name}</Typography>
            <Typography className={classes.ladyAgeInfo}>{item.age}</Typography>
          </Box>
          <Typography className={classes.ladyIDInfo}>
            {t('ladies.search.result.id', { value: item.id })}
          </Typography>
        </div>
        <Button
          className={classes.cardDeleteAction}
          startIcon={<Delete />}
          onClick={handleOnDelete}
        >
          {renderDeleteText(t, type)}
        </Button>
      </Grid>
    </Grid>
  )
}

FavoriteMediaItem.propTypes = {
  classes: PropTypes.object,
  /** Data for view. */
  item: PropTypes.object.isRequired,
  /** Type of card to show content. */
  type: PropTypes.oneOf([TYPE.PHOTO, TYPE.VIDEO]),
  /** Callback for card click action. (media only) */
  onClick: PropTypes.func.isRequired,
  /** Callback for card delete action. */
  onDelete: PropTypes.func.isRequired,
}

FavoriteMediaItem.defaultProps = {
  classes: {},
  t: () => {},
}

export default FavoriteMediaItem
