import React from 'react'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { StyledEngineProvider } from '@mui/styled-engine'
import { withStyles } from '@mui/styles'
import loginFreeViews from 'navigation/loginFreeViews'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { compose } from 'redux'

import Footer from 'components/Footer'
import Header from 'components/Header'
import Notification from 'components/Notification'
import { getAuthUserInfo } from 'service/auth/selectors'
import Layout from 'views/Layout'

import styles from './styles'

library.add(fab)

const Application = ({ userInfo }) => (
  <StyledEngineProvider injectFirst>
    <BrowserRouter>
      <Header />
      {
        userInfo.logged
          ? <Layout />
          : (
            <Routes>
              {loginFreeViews.map(route => (
                <Route
                  key={route.key}
                  path={route.path}
                  element={route.component}
                />
              ))}
            </Routes>
          )
        }
      <Notification />
      <Footer />
    </BrowserRouter>
  </StyledEngineProvider>
)

const mapStateToProps = state => ({
  userInfo: getAuthUserInfo(state),
})

export default compose(
  withTranslation('text'),
  connect(mapStateToProps, null),
  withStyles(styles),
)(Application)
