export default {
  services: {
    title: 'There are some additional services at the site.',
    content: [
      {
        title: 'Free Live Chat WebRTC:',
        points: [
          '- These functions are added to make communications with ladies easier and help you to meet the single one lady of your dream!',
        ],
      },
      {
        title: 'Vote for photos:',
        points: [
          '- Upload as many photos in your profile as you want. Ladies can vote for your photos. (One lady may vote for one your photo one time in 2 days).',
          '- You may watch the history of ladies who voted for your photos. Click on the button under the photo.',
        ],
      },
      {
        title: 'Vote for lady\'s photo. Leave comments for free!',
        points: [
          '- You may vote and comment lady\'s photos.',
          '- Go to lady\'s profile. Down in her profile you see her photos. You may vote for it.',
          '- When you click on the button "Vote" you will see pop up window. You may leave your comments to that photo.',
          '- Lady may see your message that you left in comments voting for her photo. She will pay you more attention and be interested in communicating with you.',
          '- Do not lose your chance to find lady of your dreams. Like her photo leave a comment and get in touch with her.',
        ],
      },
      {
        title: 'Online ladies:',
        points: [
          '- On the left menu you will see function "Top beautiful ladies. When you click on it you will see the list of ladies on the site according the rate of votes under the photos. The more votes under the photo has the lady the highest rate has lady in that list. According to that list you will see who are the most acctive ladies at site and are online.',
          '- Ladies have "Top handsome men" in their profiles. They also can see who is the most popular man at site and who takes the highest rate in that list.',
        ],
      },
      {
        title: 'Top beautiful ladies:',
        points: [
          '- The rate of online ladies list is according to the votes.',
          '- The more lady has total votes in her profile the upper in the list she is. You may watch what ladies are the most popular at the site in this list.',
        ],
      },
      {
        title: 'Exchange photos for FREE in the chat:',
        points: [
          '- You may send as much as you wish photos in the chat for free.',
          '- If you have no coins at the site you gave one free minute with one lady per day. During this free minute you may send photos. If your time is over you are unable to send photos. ',
          '- You can receive and save photos from ladies if you does not have coins at the site.',
          '- You can save photos until your chat is open. If you close the chat the history of the chat is disappear and photos are removed from the chat.',
        ],
      },
      {
        title: 'Virtual Gifts:',
        points: [
          '- You may receive virtual gifts from ladies. Lady can send you one gift per day.',
          '- Virtual gift is marked in the list of ladies by brown box under lady\'s photo.',
          '- You can send virtual gift to any lady one time per day.',
          '- In the history of sent gifts you can see an "eye". If it is crossed it means lady has not opened your gift. If it is not crossed it means lady has opened your gift.',
        ],
      },
    ],
  },
  date: {
    title: 'There are some additional services at the site.',
  },
  letters: {
    title: 'There is the HTML editor in letters',
    content: [
      {
        title: 'You can add:',
        icons: true,
        points: [
          'Smiles',
          'Youtube video',
          'Photos',
          'Links',
        ],
      },
      {
        title: 'How this service works:',
        points: [
          'If you want to send a letter, simply click “Write new letter to lady” or “envelope” under lady\'s avatar.',
          'A message box will appear and you may start writing her a letter.',
          'When your letter is finished simply click “Send”.',
          'Cost for opening one letter is 10 coins and cost for sending one letter is also 10 coins (exception: if the  lady will write you first, this letter will be free for you to open)',
          'The more coins your purchase, the cheaper prices you get on our services.',
        ],
      },
      {
        title: 'You may see letters of different colors in your mail box. What does it mean?',
        points: [
          'Red (yellow) - you receive the letter from the lady.',
          'Blue - you\'ve sent the letter to the lady but she did not reply it.',
          'Green - you\'ve sent the letter to the lady and she\'s read it.',
        ],
      },
      {
        title: 'Additional services are FREE in letters:',
        points: [
          'The first letter from the lady.',
          'Attach to the letter photos jpg, png, gif.',
          'Attach to the letter up to 5 photos with the size of no more then 5 Mb.',
          'Exchange personal information with the lady in the letter.',
        ],
      },
    ],
  },
  gifts: {
    title: 'The gift is the romantic sign which shows your attitude to your darling.',
    content: [
      {
        title: 'Do you want to brighten the day of the lady you write to? Is it her birthday or some other kind of holiday? Or special date for both of you? Or you just want to say more than you can express in a letter? We will help you to express your feelings with a gift! Gifts and flowers say what words can\'t express! Oceans and lands may divide you, but a bouquet of roses will express your love, candies will let her know how sweet she is, and so on.',
      },
      {
        title: 'You may be half a world away but a bouquet of fresh roses will let her know that she is on your mind and your intentions are serious.',
      },
      {
        title: 'You can choose any gift listed below and we will deliver it to your lady.',
      },
    ],
  },
  chat: {
    content: [
      {
        points: [
          'Live Chat is a service for registered members who want to make conversation instantly, in real time with one or several of DreamOneLove\'s Ladies. With Live Chat you\'re just a click away from chatting with one or more of DreamOneLove\'s remarkable ladies. By simply clicking Live Chat, you are able to quickly set up a private chat session with any Lady who is currently online. There is no need to download special software or adjust your computer settings.',
        ],
      },
      {
        title: 'Free Live Chat',
        points: [
          'Every man registered at the site has opportunity to use one free minute a day with every lady. When you log in the site you will see pop ups with green buttons "Start Free Chat". Click it chat runs and you have 1 minute to chat with this lady for free. You may invite another lady in chat to have free communication. <br/> If you do not have coins in your account you may use free text chat but you are not able to use Live Video or Voice of the lady.',
        ],
      },
      {
        title: 'How to use Live text Chat?',
        points: [
          'Chose the lady from the list or click on the invitation in the right corner. Lady appears in the chat. To start conversation click on the button "Start Chat" in the pop up window. <br/> You have 1 Free minute per day with one lady. After that billing start with cost 1 coin per minute.',
        ],
      },
      {
        title: 'You pay in the Live Chat:',
        points: [
          '(the billing start only after you reply lady in the chat)',
          '- You switch on your camera for Free',
          '- You switch on your Voice for Free',
          '- You pay 1 coin per minute when you communicate with lady via text (Text chat)',
          '- You pay 1 coin per minute when you switch on lady\'s camera (Video Chat)',
          '- You pay 1 coin per minute when you switch on lady\'s voice (Voice Chat)',
          'If you use only Video Chat and Voice Chat with lady the Text Chat will be charged from your account by default. <br/> If you want to stop chat you may click the button "End chat".  <br/> When you finish the chat and wish to leave the site  do not forget to close chat window.',
        ],
      },
      {
        title: 'You can see this message "You have left the site. Please, close this page. Refresh your chat page". in the chat window because:',
        points: [
          '<strong>1.</strong> You opened the chat in different browsers and different folders. You log out from one browser. You can see this message in another browser.',
          '<strong>2.</strong> You opened chat in two folders in one browser. The first folder is inactive chat (you see this message) another folder is active chat.',
          '<strong>3.</strong> You opened the chat in different browsers. In the first opened chat you can see this message. You need to close it.',
          '<strong>4.</strong> You opened site and chat. then you log out or close the folder with site. After that you can see this message in chat.',
        ],
      },
      {
        title: 'Have a nice time using our Services.',
        points: [],
      },
    ],
  },
}
