/* eslint-disable no-param-reassign */
import React from 'react'

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.H1) {
    children = <h1 {...attributes}>{children}</h1>
  }
  if (leaf.H2) {
    children = <h2 {...attributes}>{children}</h2>
  }
  if (leaf.H3) {
    children = <h3 {...attributes}>{children}</h3>
  }
  if (leaf.H4) {
    children = <h4 {...attributes}>{children}</h4>
  }
  if (leaf.H5) {
    children = <h5 {...attributes}>{children}</h5>
  }
  if (leaf.H6) {
    children = <h6 {...attributes}>{children}</h6>
  }
  if (leaf.BOLD) {
    children = <b {...attributes}>{children}</b>
  }
  if (leaf.ITALIC) {
    children = <i {...attributes}>{children}</i>
  }
  if (leaf.UNDERLINE) {
    children = <u {...attributes}>{children}</u>
  }
  if (leaf.STRIKETHROUGH) {
    children = <s {...attributes}>{children}</s>
  }

  return <span {...attributes}>{children}</span>
}

export default Leaf
