const convertFeetToCm = value => {
  const [feet, inch] = value.split("'")

  return `${Math.trunc(feet * 30.48 + inch * 2.54)}`
}

const convertCmToFeet = value => {
  const feet = Math.trunc(Number(value) / 30.48)
  const inch = Math.trunc((Number(value) - feet * 30.48) / 2.54)

  return `${feet}'${inch}''`
}


const convertLbsToKg = value => `${value * 0.454}`

const convertKgToLbs = value => `${value / 0.454}`

export {
  convertFeetToCm,
  convertCmToFeet,
  convertLbsToKg,
  convertKgToLbs,
}
