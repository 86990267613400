import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import headerImage from 'assets/home/gifts_header.png'
import { useTranslation } from 'react-i18next'
import HomeLayout from 'views/Home/HomeLayout'

const GiftsView = () => {
  const { t } = useTranslation('home')

  return (
    <HomeLayout
      image={headerImage}
      header={t('sections.services.item.gifts')}
      title={t('features.gifts.title')}
      margin
      useTitle
    >
      <Stack rowGap={4}>
        {t('features.gifts.content', { returnObjects: true }).map(item => (
          <>
            <Typography>
              {item.title}
            </Typography>
          </>
        ))}
      </Stack>
    </HomeLayout>

  )
}

export default GiftsView
