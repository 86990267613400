import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Paragraph from 'components/Paragraph'
import Typography from '@mui/material/Typography'
import { appURLs } from 'navigation/routes'
import Layout from './Layout'


const PointText = ({ items }) => {
  if (!items || items.length === 0) {
    return null
  }

  return items.map(item => (
    <>
      <Paragraph dangerouslySetInnerHTML={{ __html: item }} />
      <br />
    </>
  ))
}

const Point = ({ content, useBottomBR = true }) => (
  <>
    <Typography variant="h6">{content.point}</Typography>
    <br />
    <PointText items={content?.subpoints} />
    {useBottomBR ? <br /> : null}
  </>
)

const CustomerServiceView = () => {
  const { t } = useTranslation('home')

  const links = useMemo(() => ({
    scam: appURLs.HOME_INFO_POLICY,
    coints: 'http://dreamonelove.com/#!EDITSTATICTEXT',
    gift: appURLs.HOME_SERVICE_GIFTS,
    imbra: appURLs.HOME_INFO_PRIVACY,
    terms: appURLs.HOME_INFO_TERMS,
  }), [])

  const content = useMemo(() => t('pages.service.content', {
    returnObjects: true,
    links,
  }).map(item => (
    <>
      <Point content={item} />
    </>
  )), [links, t])

  return (
    <Layout header={t('pages.service.header')}>
      {content}
      <Paragraph component="a" href="http://dreamonelove.com/#!NEWAGENCY">
        {t('pages.faq.footer')}
      </Paragraph>
    </Layout>

  )
}

export default CustomerServiceView
