import React, { useCallback, useEffect } from 'react'
import HomeLayout from 'views/Home/HomeLayout'
import { useTranslation } from 'react-i18next'
import {
  clearStories,
  filterStories,
} from 'service/home/actions/stories'
import { connect } from 'react-redux'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Image from 'components/Image'
import { appURLs } from 'navigation/routes'
import {
  getStories,
  getStoriesFilter,
  getStoriesPagination,
} from 'service/home/selectors/stories'
import { useNavigate } from 'react-router'
import sx from './sx'

const OverviewItem = ({ item, onClick }) => {
  const handleOnClick = useCallback(() => onClick(item), [item, onClick])

  return (
    <Grid
      item
      onClick={handleOnClick}
      md={4}
      sm={6}
      xs={12}
      rowGap={1}
      container
    >
      <Stack rowGap={1} sx={sx.itemContainer}>
        <Box sx={sx.photo}>
          <Image
            src={item.headerPhoto}
            useBackground
            fullHeight
          />
        </Box>
        <Typography sx={sx.title}>
          {item.author}
        </Typography>
      </Stack>
    </Grid>
  )
}

const StoriesOverview = ({ stories, filter, pagination, clearStories, filterStories }) => {
  const { t } = useTranslation('home')
  const navigate = useNavigate()

  useEffect(() => {
    // if user from home view
    if (stories.length <= 7) {
      clearStories().then(filterStories)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnClick = useCallback(item => navigate(`${appURLs.HOME_STORY_DETAILS_BASE}${item.id}`), [navigate])

  const handleOnPageChanged = useCallback(pageNumber => filterStories({
    from: (pageNumber - 1) * filter.size,
  }), [filter.size, filterStories])

  return (
    <HomeLayout
      header={t('sections.stories.text.title')}
      pageCounts={pagination.count}
      currentPage={pagination.page}
      onPageChanged={handleOnPageChanged}
      pagination
      margin
    >
      <Grid container spacing={4}>
        {stories.map(item => <OverviewItem key={item.id} item={item} onClick={handleOnClick} />)}
      </Grid>
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  stories: getStories(state),
  pagination: getStoriesPagination(state),
  filter: getStoriesFilter(state),
})

const mapDispatchToProps = {
  filterStories,
  clearStories,
}

export default connect(mapStateToProps, mapDispatchToProps)(StoriesOverview)
