import { grey } from '@mui/material/colors'
import { link } from 'theme'

export default {
  title: {
    fontWeight: 400,
    color: grey[600],
    textAlign: 'center',
    whiteSpace: 'pre-line',
    fontSize: '1.9rem',
    margin: '10px 0px 35px',
  },

  textField: {
    height: 42,
    marginBottom: 3,
    paddingLeft: 1,
    paddingRight: 1,
  },

  input: {
    fontWeight: 700,
    // fontSize: '1rem',
  },

  recoverLink: {
    color: link.main,
    marginTop: 24,
    display: 'block',
    textAlign: 'center',
    textDecoration: 'none',
  },

  recoverText: {
    ':hover': {
      textDecoration: 'underline',
    },
  },

  card: {
    width: '100%',
    padding: 4,
    maxWidth: 316,
  },
}
