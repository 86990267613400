export default {
  purchase: {
    label: 'Purchase coins',
    description: 'Coins required to do in app purchases such as gifts.',
    item: {
      coins: '{{count}} coins',
      euroPer: '{{count}} Euro coin',
      euroTotal: '{{count}} Euro total',
    },
    buy: 'Buy {{count}} coins for {{price}} Euro',
  },
  history: {
    purchase: {
      table: {
        header: {
          date: 'Date',
          amount: 'Amount',
          status: 'Status',
          pendgin: 'Status',
        },
      },
      total: 'Total: {{USD}}$/{{EUR}}€',
    },
  },
}
