import { eyeOptions, hairOptions } from 'consts/profileEditOptions'

const getHeight = (t, info) => `${info.heightCm || t('general.na')} / ${info.heightFt || t('general.na')}`
const getWeight = (t, info) => `${info.weightKg || t('general.na')} / ${info.weightPnd || t('general.na')}`
const getHair = (t, info) => hairOptions.find(item => item.value === info.hairColor)?.label
const getEye = (t, info) => eyeOptions.find(item => item.value === info.eyeColor)?.label
const getLookingAge = (min, max) => {
  if ((Boolean(min) === false && min === 0) || (Boolean(max) === false && max === 0)) {
    return `${min === 0 ? 'Any' : min} - ${max === 0 ? 'Any' : max}`
  }

  return Boolean(min) === false || Boolean(max) === false ? null : `${min} - ${max}`
}

export {
  getLookingAge,
  getHeight,
  getWeight,
  getHair,
  getEye,
}
