import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import { compose } from 'redux'
import { useTranslation, withTranslation } from 'react-i18next'
import FilterSection from 'components/FilterSection'
import Section from 'components/Section'
import { Typography } from '@mui/material'
import createGeneralPagination from 'utils/pagination'
import styles from './styles'
import LadiesResulItem from './LadiesResultItem'

const LadiesResultSection = ({
  classes, data, title, filter, filterPanel, itemsCount, quickMessage,
  onSearch, onFavorite, onFilterChange,
}) => {
  const { t } = useTranslation('text')

  if (!filter) {
    return null
  }

  const pagination = createGeneralPagination(itemsCount, filter)
  const getSortOptins = [
    { value: 'id', label: t('ladies.search.sort.options.id') },
    { value: 'firstName', label: t('ladies.search.sort.options.name') },
    { value: 'countryName', label: t('ladies.search.sort.options.from') },
  ]

  // filter

  function onPageChanged(pageNumber) {
    onFilterChange({
      from: (pageNumber - 1) * filter.size,
    }, true)
  }

  function handleOnSort(value) {
    onFilterChange({
      sort: {
        fldName: value.sortField,
        sortType: value.sortType,
      },
    }, true)
  }

  // render

  function renderItem(item) {
    return (
      <LadiesResulItem
        item={item}
        quickMessage={quickMessage}
        onFavoriteClick={onFavorite}
      />
    )
  }

  return (
    <div>
      <Typography
        key="settings-title"
        className={classes.title}
        variant="h4"
      >
        {t('ladies.title')}
      </Typography>
      <Section title={title} />
      <FilterSection
        items={data}
        page={pagination.page}
        count={pagination.count}
        itemsCount={itemsCount}
        labelItemsCount={t('ladies.search.result.foundLabel')}
        sortOptions={getSortOptins}
        noDataMessage={t('general.noPeople')}
        onPageChanged={onPageChanged}
        onSort={handleOnSort}
        onSearch={onSearch}
        renderComponent={renderItem}
        renderPrimaryFilter={filterPanel}
      />
    </div>
  )
}

LadiesResultSection.propTypes = {
  /** Array of data for view. */
  data: PropTypes.arrayOf(PropTypes.object),
  /** Page title. */
  title: PropTypes.string,
  /** All items total count. */
  itemsCount: PropTypes.number,
  /** On favorite button click. */
  onFavorite: PropTypes.func,
  /** On search button click. */
  onSearch: PropTypes.func,
  /** Flag to show quick message button. */
  quickMessage: PropTypes.func,
}

LadiesResultSection.defaultProps = {
  data: [],
  onFavoriteClick: () => {},
  quickMessage: false,
}


export default compose(
  withStyles(styles),
)(LadiesResultSection)
