export default {
  toolbar: {
    H1: 'Header 1',
    H2: 'Header 2',
    H3: 'Header 3',
    H4: 'Header 4',
    H5: 'Header 5',
    H6: 'Header 6',
    // LIST_ITEM: ,
    LIST_BULLETED: 'Bulleted list',
    LIST_NUMBERED: 'Numbered list',
    BOLD: 'Bold',
    ITALIC: 'Italic',
    UNDERLINE: 'Underline',
    STRIKETHROUGH: 'Strikethrough',
    HORIZONAL: 'Horizontal line',
    QUOTE: 'Quote',
    UNDO: 'Undo',
    REDO: 'Redo',
    ALIGNT_LEFT: 'Alignt left',
    ALIGNT_CENTER: 'Alignt center',
    ALIGNT_RIGHT: 'Alignt right',
    ALIGNT_JUSTIFY: 'Alignt justify',
    LINK_CREATE: 'Create link',
    LINK_REMOVE: 'Remove link',
    IMAGE: 'Insert image',
    YOUTUBE: 'Insert youtube video',
    EMOJI: 'Insert emoji',
    STICKER: 'Insert sticker',
    HTML: 'Switch edit mode',
  },
  dialog: {
    youtube: {
      title: 'Insert youtube video',
      error: 'Wrong link provided',
      action: {
        cancel: 'Cancel',
        accept: 'Attach',
      },
    },
  },
}
