import { grey } from '@mui/material/colors'

export default {
  root: {
    display: 'grid',
    gridTemplateColumns: 'auto 116px',
    padding: 0,
    overflow: 'hidden',
    border: '1px solid #ced4da',
    borderRadius: 20,
  },
  label: {
    fontWeight: 500,
    margin: 0,
    padding: 1,
    height: '100%',
    textAlign: 'center',
    cursor: 'pointer',
    borderLeft: `1px solid ${grey[400]}`,
    backgroundColor: grey[200],
    alignSelf: 'baseline',
  },
  text: {
    fontWeight: 500,
    alignSelf: 'center',
    marginLeft: 2,
    color: '$text-dark',
  },
  input: {
    display: 'none',
  },
}
