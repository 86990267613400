import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Section from 'components/Section'

const ImageDialog = ({ open, onClose, onAccept }) => {
  const { t } = useTranslation('mailEditor')

  function handleOnAdd(event) {
    event.preventDefault()

    onAccept(event.target.link.value)
  }

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={onClose}
      fullWidth
    >
      <form onSubmit={handleOnAdd}>
        <DialogTitle>{t('dialog.youtube.title')}</DialogTitle>
        <DialogContent dividers>
          <Section title="Insert via a link">
            <TextField
              placeholder="Paste link"
              name="link"
              fullWidth
            />
          </Section>
          {/* <Section title="Or upload your own image">
            <TextField
              placeholder="Paste link"
              name="link"
              fullWidth
            />
          </Section> */}
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={onClose}>{t('dialog.youtube.action.cancel')}</Button>
          <Button type="submit">{t('dialog.youtube.action.accept')}</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ImageDialog
