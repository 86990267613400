const mapFilterToApi = filter => ({
  ...filter.id && { id: Number(filter.id) },
  ...filter.country && { countryId: Number(filter.country) },
  ...filter.ageFrom && { minAge: Number(filter.ageFrom) },
  ...filter.ageTo && { maxAge: Number(filter.ageTo) },

  ...filter.eye && { eye: filter.eye },
  ...filter.hair && { hair: filter.hair },
  ...filter.maritalStatus && { maritalStatus: filter.maritalStatus },

  ...filter.firstName && { firstName: filter.firstName },
  ...filter.lastName && { lastName: filter.lastName },
  ...filter.englishSkill && { englishSkill: filter.englishSkill },

  // "cityId": 0,

  ...filter.smoking && { smoker: filter.smoking },
  ...filter.alchohol && { drinker: filter.alchohol },
  ...filter.religion && { religion: filter.religion },

  ...filter.minWeight && { minWeight: Number(filter.minWeight) },
  ...filter.maxWeight && { maxWeight: Number(filter.maxWeight) },
  ...(filter.minWeight || filter.maxWeight) && filter.weightType && { weightType: filter.weightType },

  ...filter.minHeight && { minHeight: Number(filter.minHeight) },
  ...filter.maxHeight && { maxHeight: Number(filter.maxHeight) },
  ...(filter.minHeight || filter.maxHeight) && filter.heightType && { heightType: filter.heightType },

  ...filter.children && { children: filter.children === 1 },
  ...filter.online && { online: filter.online === 1 },
  ...filter.birthdaySoon && { birthdaySoon: filter.birthdaySoon === 1 },

  ...filter.firstName && { firstName: filter.firstName },
  ...filter.lastName && { lastName: filter.lastName },

  from: filter.from,
  size: filter.size,

  ...filter.sort && { sort: filter.sort },
})

export {
  mapFilterToApi,
}
