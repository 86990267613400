import LocationApi from 'api/LocationApi'
import { userGetIpInfoDone, userGetIpInfoFail, userGetIpInfoStart } from 'redux/reducers/location'

const getUserIpInfo = () => dispatch => {
  dispatch(userGetIpInfoStart())

  return LocationApi.getUserLocation()
    .then(data => {
      if (data.errMsg) {
        throw Error(data.message || data.errMsg)
      }

      return dispatch(userGetIpInfoDone(data))
    })
    .catch(error => {
      dispatch(userGetIpInfoFail())

      return Promise.reject()
    })
}

export {
  getUserIpInfo,
}
