import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  Card,
} from '@mui/material'
import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { withStyles } from '@mui/styles'
import CollapseListItem from './CollapseListItem'
import styles from './styles'


const CollapseList = ({ classes, item, onClick = () => {} }) => {
  function onItemClick(clickedItem) {
    onClick(clickedItem)
  }

  return (
    <Accordion
      variant="sidebar"
      disableGutters
    >
      <AccordionSummary
        classes={{
          root: classes.clickable,
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <div className={classes.header}>
          {item.icon}
          <Typography variant="button">{item.label}</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: classes.content,
        }}
      >
        <List>
          {item.items.map(subItem => (
            <CollapseListItem
              key={subItem.label}
              item={subItem}
              onClick={onItemClick}
              selected={window.location.href.includes(subItem.link)}
            />
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  )
}

export default withStyles(styles)(CollapseList)
