import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  filter as filterLadies,
  updateFilter,
  clearData,
} from 'service/ladies/actions/filter'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { WEIGHT_KG } from 'consts/weightOptions'
import { HEIGHT_CM } from 'consts/heightOptions'
import { getAuthUserInfo } from 'service/auth/selectors'
import { ladiesAddFavorite, ladiesRemoveFavorite } from 'service/ladies/actions/favorite'
import {
  getLadies,
  getLadiesCount,
  getLadiesFilter,
} from 'service/ladies/selectors'
import LadiesResultSection from './LadiesResultSection/LadiesResultSection'
import LadiesFilter from './LadiesFilter'

const baseFilter = {
  from: 0,
  size: 6,
  children: 0,
  online: undefined,
  birthdaySoon: 0,
  weightType: WEIGHT_KG.value,
  heightType: HEIGHT_CM.value,
}

const LadiesSearch = ({ items, count, filterLadies, filter,
  ladiesRemoveFavorite, ladiesAddFavorite, updateFilter, clearData }) => {
  const { t } = useTranslation('text')

  useEffect(() => {
    clearData()
      .then(() => updateFilter(baseFilter, 'search'))
      .then(() => filterLadies('search'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  function handleOnFavoriteClick(item) {
    const resultFunc = item.favorite
      ? ladiesRemoveFavorite
      : ladiesAddFavorite

    resultFunc(item.id).then(() => filterLadies('search'))
  }

  function handleOnSearch() {
    filterLadies('search')
  }

  // filter

  function handleOnFilterChanged(data, run = false) {
    updateFilter(data, 'search')
      .then(() => {
        if (run) {
          filterLadies('search')
        }
      })
  }

  return (
    <LadiesResultSection
      data={items}
      filter={filter}
      itemsCount={count}
      title={t('ladies.search.title.general')}
      filterPanel={<LadiesFilter filter={filter} onChange={handleOnFilterChanged} />}
      onFilterChange={handleOnFilterChanged}
      onSearch={handleOnSearch}
      onFavorite={handleOnFavoriteClick}
    />
  )
}


const mapStateToProps = state => ({
  userInfo: getAuthUserInfo(state),
  items: getLadies(state),
  count: getLadiesCount(state),
  filter: getLadiesFilter(state).search,
})

const mapDispatchToProps = {
  updateFilter,
  filterLadies,
  clearData,
  ladiesAddFavorite,
  ladiesRemoveFavorite,
}


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(LadiesSearch)
