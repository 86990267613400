import { createSelectorCreator, defaultMemoize, createSelector } from 'reselect'
import isEqual from 'lodash/isEqual'

const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual,
)

const getAuthUserInfo = createSelector(
  state => state.auth,
  info => info || {},
)

export {
  getAuthUserInfo,
}
