import { withStyles } from '@mui/styles'
import Section from 'components/Section'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { updatePassword } from 'service/man/actions/me'
import { withTranslation } from 'react-i18next'
import { Button, Grid, TextField } from '@mui/material'
import styles from './styles'

class ChangePasswordSection extends React.Component {
  constructor() {
    super()

    this.state = {
      oldPassword: '',
      newPassword: '',
      repeatedPassword: '',
    }
  }

  handleOnSave = () => this.props.updatePassword(this.state)
    .then(() => this.props.onConfirm())
    .catch(() => {})

  handleOnHide = () => {}

  handleOnCancel = () => this.props.onCancel()

  handleOnChange = event => this.setState({ [event.target.name]: event.target.value })

  render() {
    const { t, classes } = this.props

    return (
      <Section title={t('settings.password.title')}>
        <div className={classes.container}>
          <Grid container rowGap={2}>
            <TextField
              placeholder={t('settings.password.old')}
              name="oldPassword"
              type="password"
              onChange={this.handleOnChange}
              fullWidth
            />
            <TextField
              placeholder={t('settings.password.new')}
              name="newPassword"
              type="password"
              onChange={this.handleOnChange}
              fullWidth
            />
            <TextField
              placeholder={t('settings.password.repeat')}
              name="repeatedPassword"
              type="password"
              onChange={this.handleOnChange}
              fullWidth
            />
          </Grid>
          <div className={classes.actions}>
            <Button onClick={this.handleOnSave}>
              {t('settings.password.action.update')}
            </Button>
          </div>
        </div>
      </Section>
    )
  }
}

const mapDispatchToProps = {
  updatePassword,
}


export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps),
  withTranslation('text'),
)(ChangePasswordSection)
