import { createAction, handleActions } from 'redux-actions'

const initialState = {
  fetching: false,
  uploadedManPhotoData: {},
  uploadedManVideoData: {},
}

// actions
const uploadManPhotoStart = createAction('UPLOAD_MAN_PHOTO_START')
const uploadManPhotoFail = createAction('UPLOAD_MAN_PHOTO_FAIL')
const uploadManPhotoDone = createAction('UPLOAD_MAN_PHOTO_DONE')

const uploadManVideoStart = createAction('UPLOAD_MAN_VIDEO_START')
const uploadManVideoFail = createAction('UPLOAD_MAN_VIDEO_FAIL')
const uploadManVideoDone = createAction('UPLOAD_MAN_VIDEO_DONE')

// reducer
const manPhotoUploadStart = state => ({ ...state, fetching: true })
const manPhotoUploadFail = state => ({ ...state, fetching: false })
const manPhotoUploadDone = (state, { payload }) => ({ ...state, fetching: false, uploadedManPhotoData: payload })

const manVideoUploadStart = state => ({ ...state, fetching: true })
const manVideoUploadFail = state => ({ ...state, fetching: false })
const manVideoUploadDone = (state, { payload }) => ({ ...state, fetching: false, uploadedManVideoData: payload })

const reducers = handleActions({
  UPLOAD_MAN_PHOTO_START: manPhotoUploadStart,
  UPLOAD_MAN_PHOTO_FAIL: manPhotoUploadFail,
  UPLOAD_MAN_PHOTO_DONE: manPhotoUploadDone,

  UPLOAD_MAN_VIDEO_START: manVideoUploadStart,
  UPLOAD_MAN_VIDEO_FAIL: manVideoUploadFail,
  UPLOAD_MAN_VIDEO_DONE: manVideoUploadDone,
}, initialState)

export {
  uploadManPhotoStart,
  uploadManPhotoFail,
  uploadManPhotoDone,

  uploadManVideoStart,
  uploadManVideoFail,
  uploadManVideoDone,
}

export default reducers
