import en from './en'
import finanses from './finanses'
import auth from './auth'
import mailEditor from './mail_editor'
import home from './home'
import headerFooter from './header_footer'

export default {
  text: en,
  common: en,
  finanses,
  auth,
  mailEditor,
  home,
  headerFooter,
}
